import { Box, Container } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICountryWithoutInstances } from '../../services/api/requests/getCountriesWithoutInstances';
import { selectCountriesWithoutInstances } from '../../store/entities/selectors';
import pageLinks from '../../utils/pageLinks';

export default function CountriesNotification() {
  const dispatch = useDispatch();
  const list = useSelector(selectCountriesWithoutInstances);

  const handleLinkClick = () => dispatch(push(pageLinks.instances));

  const getCountriesList = (list: ICountryWithoutInstances[], limit: number) => {
    const countries = (list as ICountryWithoutInstances[])
      .filter((_, index) => index < limit)
      .map((item) => item.name)
      .join(', ')
      .trim();

    return list.length > limit ? countries + `, and ${list.length - limit} more` : countries;
  };

  if (!list.length) {
    return null;
  }

  return (
    <Container style={{ padding: '0 12px' }}>
      <Box pt={2}>
        <Alert severity="error">
          <AlertTitle>There are countries for which no instances are assigned.</AlertTitle>
          <Box>{getCountriesList(list, 5)}</Box>
          <Box mb={0} mt={1}>
            <Link onClick={handleLinkClick} style={{ cursor: 'pointer' }}>
              Learn more
            </Link>
          </Box>
        </Alert>
      </Box>
    </Container>
  );
}
