import {
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { IPromoDiscount } from '@root/services/api/requests/promo-discount';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import pageLinks from '../../utils/pageLinks';
import { useForm } from './hooks/use-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const CreateEditPromoDiscount: FC = () => {
  const classes = useStandardFormStyles();
  const [{ isEdit, fk, options }] = useForm();
  const createEditText = isEdit ? 'Edit' : 'Create';
  const createInputField = createInput<IPromoDiscount>(fk);
  const location = useLocation<{ prevPath: string }>();

  return (
    <Grid item xs={12}>
      <BackButton
        name={'Back'}
        link={location.state?.prevPath || pageLinks.promoDiscounts}
        margin={'0 0 10px 0'}
      />
      <Paper className={classes.paper}>
        <Title>{createEditText} Promo Discounts </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('title', 'Title', 6, 'text', true)}
              {createInputField('description', 'Description', 6, 'text', true)}
              {createInputField(
                'data.RENEW.3' as keyof IPromoDiscount,
                '3 Months',
                6,
                'number',
                true
              )}
              {createInputField(
                'data.RENEW.6' as keyof IPromoDiscount,
                '6 Months',
                6,
                'number',
                true
              )}
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Plans</InputLabel>
                  <Select
                    id="planIds"
                    label={'Gateways Methods'}
                    variant="outlined"
                    multiple
                    value={fk.values.planIds}
                    onChange={(e) => fk.setFieldValue('planIds', e.target.value)}
                    input={<Input />}
                    renderValue={(selected: unknown): string =>
                      (selected as number[])
                        ?.map(
                          (id: number) => options.find((item) => item.value === id)?.label || ''
                        )
                        .join(', ')
                    }
                    MenuProps={MenuProps}
                  >
                    {options.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.planIds.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  value="published"
                  control={
                    <Checkbox
                      color="primary"
                      id="published"
                      name="published"
                      checked={fk.values.published}
                      onChange={fk.handleChange}
                    />
                  }
                  label="Published"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditPromoDiscount;
