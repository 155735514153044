import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  status: {
    '& .status': {
      padding: '4px 5px',
      borderRadius: '6px',
      border: '1px solid',
      color: '#fff',
      textAlign: 'center',
      '&.green': {
        backgroundColor: '#8ba929',
      },
      '&.blue': {
        backgroundColor: '#1460a5',
      },
      '&.red': {
        backgroundColor: '#f44c46',
      },
      '&.orange': {
        backgroundColor: '#f89a06',
      },
    },
  },
}));
