import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { ICountry } from './getCountries';
import { IGateway } from './getGatewayList';

export interface IVatItem {
  id: number;
  value: number;
  createdAt: Date;
  countries: Array<Pick<ICountry, 'code' | 'name'>>;
  gatewayMethods: Array<Pick<IGateway, 'id' | 'name'>>;
}

export interface IVatFormItem {
  id?: number;
  value: number;
  countries: string[];
  gatewayMethods: number[];
}

export interface IVatItemResponse {
  data: IVatItem[];
}

export interface ICreateVatItem extends Omit<IVatItem, 'id'> {}

export interface ICreateEditVatItemResponse {
  data: IVatItem;
}

export interface IEditVatItemParams extends Pick<IVatItem, 'id' | 'value'> {
  countryCodes: string[];
  gatewayMethodIds: number[];
}

const getVatList = () => {
  return apiClient.get<IVatItemResponse>(ApiUrls.vat).then((response) => response);
};

const createVatItem = (data: IEditVatItemParams) => {
  return apiClient.post<ICreateEditVatItemResponse>(ApiUrls.vat, data).then((response) => response);
};

const editVatItem = (data: IEditVatItemParams) =>
  apiClient
    .put<ICreateEditVatItemResponse>(`${ApiUrls.vat}/${data.id}`, data)
    .then((response) => response);

const deleteVatItem = (id: string | number) => {
  return apiClient
    .delete<ICreateEditVatItemResponse>(`${ApiUrls.vat}/${id}`)
    .then((response) => response);
};

const vatItemRequests = {
  getVatList,
  createVatItem,
  editVatItem,
  deleteVatItem,
};

export default vatItemRequests;
