import { Box, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import FlagIcon from '@material-ui/icons/Flag';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISwitchingLocalRules } from '../../services/api/requests/switchingRules';
import { selectCountryList } from '../../store/entities/selectors';
import Can, { Permissions } from '../Can';
import { useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import Modal from '../Modal';

interface IProps {
  openEditRule(): void;
  handleDeleteRule(): void;
  rule: ISwitchingLocalRules;
}

const SwitchingRuleItem = ({ rule, openEditRule, handleDeleteRule }: IProps) => {
  const classes = useStandardTableStyles();
  const countryList = useSelector(selectCountryList(false, false));

  return (
    <Button
      className={clsx(classes.boxButton, rule.enabled ? {} : classes.boxButtonDisabled)}
      fullWidth
      color="primary"
      variant={'outlined'}
    >
      <Box display={'flex'} justifyContent="space-between" width={'100%'} alignItems={'center'}>
        <Box display={'flex'} flexDirection="column" alignItems="flex-start" marginTop={0}>
          <span>
            <strong>From</strong>
            {` ${rule.nameFrom}`}
          </span>
          <span>
            <strong>to</strong>
            {` ${rule.nameTo}`}
          </span>
        </Box>
        <div>
          <span>{`Price: ${Number(rule.price).toFixed(2)}`}</span>
        </div>
        <div>
          {rule?.countryCodes?.length && (
            <Modal
              button={
                <IconButton aria-label="edit" title={'Show countries'} size={'small'}>
                  <FlagIcon />
                </IconButton>
              }
              renderContent={() => (
                <div>
                  <h2> Countries </h2>
                  <Box display="flex" flexWrap="wrap">
                    {rule.countryCodes.map((code: any, idx: number, arr: any) => {
                      const country = countryList[code];
                      return (
                        <Box m={0.5} key={country.code} display="flex" flexWrap="wrap">{`${
                          country.name
                        }(${country.code})${arr.length - 1 === idx ? '' : ', '}`}</Box>
                      );
                    })}
                  </Box>
                </div>
              )}
            />
          )}

          <Can perform={Permissions.updateSwitchingRules}>
            <IconButton aria-label="edit" title={'Edit'} size={'small'} onClick={openEditRule}>
              <EditIcon />
            </IconButton>
          </Can>

          <Can perform={Permissions.deleteSwitchingRules}>
            <DeleteModalButton
              name={`From ${rule.nameFrom} to ${rule.nameTo}`}
              entity={'Rule'}
              onDelete={handleDeleteRule}
            />
          </Can>
        </div>
      </Box>
    </Button>
  );
};

export default SwitchingRuleItem;
