import FormData from 'form-data';
import { FormikProps } from 'formik';
import pickBy from 'lodash/pickBy';
import * as React from 'react';
import {
  AVAILABLE_REGIONAL_DATASET_FIELDS,
  IMAGE_MIME_TYPES,
  IMAGE_UPLOAD_ENTITY,
  PLAN_SHOP_TYPE,
  VIDEO_MIME_TYPES,
} from '../constants';
import { IAttachment } from '../services/api/requests/editProductPlan';
import {
  INormalizedCouponUsageRule,
  INormalizedCouponUsageRuleSet,
} from '../services/api/requests/getCouponUsageRules';
import { ICustomerData, INormalizedPayout } from '../services/api/requests/getCustomerPayoutList';
import { IPlan } from '../services/api/requests/getProductPlans';
import {
  INormalizedPurchaseRule,
  INormalizedPurchaseRuleSet,
  IPurchaseRuleSet,
} from '../services/api/requests/getPurchaseRules';
import { IPermission, IPermissionGroup } from '../services/api/requests/permissions';
import { ISwitchingLocalRules, ISwitchingRule } from '../services/api/requests/switchingRules';
import uploadAttachment from '../services/api/requests/uploadAttachment';
import uploadMultipleImageAtachments from '../services/api/requests/uploadMultipleImageAtachments';
import uploadPicture from '../services/api/requests/uploadPicture';
import { getTokens } from '../services/api/tokenHandler';
import { IOption } from './commonTypes';

export const checkConsecutives = (word: string, length: number): boolean => {
  if (word?.length >= length) {
    for (let i = 0; i <= word.length; i += 1) {
      // iterating over 3-char sequences
      const charCode = word[i]?.charCodeAt(0);
      const charCodeTwo = word[i + 1]?.charCodeAt(0);
      const charCodeThree = word[i + 2]?.charCodeAt(0);
      if (charCodeTwo - charCode === 1 && charCodeThree - charCode === 2) {
        return true;
      }
    }
  }
  return false;
};

export const hasRequiredSymbols = (value: any = '') => {
  if (value?.length) {
    return value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/);
  }
  return false;
};

export const hasSpecialCharacters = (value: any = ''): any => {
  return value.match(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/);
};

export const transformPermissionText = (text: string): string => text.replaceAll('_', ' ');
export const transformPermissionGroupText = (text: string): string =>
  'group of '.concat(text.replaceAll('_', ' '));

export const hasConsecutiveSymbols = (value: any = ''): boolean => {
  const numbers: string[] = value.replace(/\W/g, ' ').match(/(\d+)/g);
  const letters: string[] = value.replace(/\W/g, ' ').match(/[a-zA-Z]+/g);

  const isConsecutiveNumbers = numbers && numbers.find((item) => checkConsecutives(item, 3));
  const isConsecutiveLetters = letters && letters.find((item) => checkConsecutives(item, 3));

  return !(isConsecutiveNumbers || isConsecutiveLetters);
};

export const anyLetterRegExp =
  /^[a-zA-Z\u00C0-\u00FFŸŒ¢]+([a-zA-Z\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢]+$/;

export const isUserLogged = () => getTokens().accessToken;

export function createQueryParams<P>(params: P) {
  return Object.keys(params as {})
    .map((param) => {
      const value: any = params[param as keyof P];
      if (value) {
        if (typeof value === 'string' && value.toLocaleLowerCase() !== 'all') {
          return `${param}=${value}`;
        } else if (typeof value !== 'string' || value.toString() === 'false') {
          return `${param}=${value}`;
        }
      }
      return null;
    })
    .filter((item) => !!item)
    .join('&');
}

export const onlyUnique = <V = string, I = number, S = Array<string>>(
  value: V,
  index: I,
  self: S
): boolean => {
  // @ts-ignore
  return self.indexOf(value) === index;
};

export function toIsoString(dateObj: Date) {
  var tzo = dateObj.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num: number) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

  return (
    dateObj.getFullYear() +
    '-' +
    pad(dateObj.getMonth() + 1) +
    '-' +
    pad(dateObj.getDate()) +
    'T' +
    pad(dateObj.getHours()) +
    ':' +
    pad(dateObj.getMinutes()) +
    ':' +
    pad(dateObj.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
}

export const createFormData = <T>(
  requestData: T
): { formData: any; headers: { [key: string]: string } } => {
  let formData: any = new FormData();

  for (let key in requestData) {
    formData.append(`${key}`, requestData[key]);
  }

  const headers = {
    accept: 'application/json',
    'Accept-Language': 'en-US,en;q=0.8',
    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
  };

  return { formData, headers };
};

export const normalizePayoutListResponse = (list: ICustomerData[]) =>
  list.map(
    (customerData: ICustomerData): INormalizedPayout => ({
      customerId: customerData.id,
      ...customerData.payout,
    })
  );

export function normalizePlanData<T>(data: T, isRecurring?: any, hasRetail?: any): T {
  if (!isRecurring || !hasRetail) {
    const { recurring, retail, retailRecurring } = AVAILABLE_REGIONAL_DATASET_FIELDS;
    const recurringFieldKeys = !isRecurring ? recurring : [];
    const retailFieldKeys = !hasRetail ? retail : [];
    const recurringRetailFieldKeys = !hasRetail || !isRecurring ? retailRecurring : [];

    const filteredKeys = [...recurringFieldKeys, ...retailFieldKeys, ...recurringRetailFieldKeys];

    //@ts-ignore
    return pickBy(data, (value, key) => !filteredKeys.includes(key) && value !== '');
  }
  return data;
}

export const onAddImage = async ({
  e,
  entity,
  fk,
  setImage,
  imageUrlField,
}: {
  e: React.ChangeEvent<any>;
  entity: IMAGE_UPLOAD_ENTITY;
  fk: FormikProps<any>;
  setImage?: (image: string) => void;
  imageUrlField?: string;
}) => {
  const fieldId = e.target.id;
  const urlField = imageUrlField || 'imageUrl';

  try {
    const file = e.currentTarget.files?.[0];
    if (file?.size > 500000) {
      fk.setFieldError(fieldId, 'Max size allowed is 500kb');
      e.target.value = '';
      return;
    }

    if (!file) {
      return;
    }

    const response = await uploadPicture({
      entity,
      mimetype: file?.type,
      file,
    });

    if (response) {
      fk.setFieldValue(urlField, response);
      setImage && setImage(response);
      return response;
    }
  } catch (error) {
    fk.setFieldError(fieldId, 'An error occurred while uploading picture to s3');
    e.target.value = '';
  }
};

export const onAddMultipleMedia = async ({
  e,
  fk,
  id,
  list,
}: {
  e: React.ChangeEvent<any>;
  fk: FormikProps<any>;
  id: string;
  list: IAttachment[] | [];
}) => {
  const formData = new FormData();
  [...new Array(e.currentTarget.files.length)].map((file: any, id: number) =>
    formData.append(`attachmentFiles`, e.currentTarget.files[id])
  );
  formData.append(`language`, 'en');

  try {
    const response = await uploadMultipleImageAtachments(formData);
    const result = response.data.data.map((video) => ({
      ...video,
      mimetype: IMAGE_MIME_TYPES[0],
    }));
    id && fk.setFieldValue(id, [...result, ...list]);
  } catch (error) {
    fk.setFieldError(id, 'An error occurred while uploading image');
    e.target.value = '';
  }
};

export const onAddVideo = async ({
  videoUrl,
  previewUrl,
  language,
  fk,
  id,
  list,
  cleanVideoInputs,
}: {
  videoUrl: string;
  previewUrl: string;
  language: string;
  fk: FormikProps<any>;
  id: string;
  list: IAttachment[] | [];
  cleanVideoInputs: () => void;
}) => {
  const data = {
    language,
    url: videoUrl,
    previewUrl,
    type: 'video/mp4',
    size: 0,
    name: 'vimeo',
  };

  const items = [data];

  try {
    const response = await uploadAttachment({ items });
    const result = response.data.data.map((video) => ({
      ...video,
      mimetype: VIDEO_MIME_TYPES[0],
    }));

    id && fk.setFieldValue(id, [...result, ...list]);
  } catch (error) {
    fk.setFieldError(id, 'An error occurred while uploading video');
  } finally {
    cleanVideoInputs();
  }
};

export const createOptions = <T>(
  array: T[],
  valueFieldName: keyof T,
  labelFieldName: keyof T
): IOption[] => {
  return array.map((item) => ({
    value: item[valueFieldName],
    label: item[labelFieldName] as unknown as string,
  }));
};

export const getPlanName = (plan: IPlan): string =>
  `${plan?.name || ''}${plan?.name && plan?.product?.name ? ' ' : ''}${plan?.product?.name || ''}`;

export const normalizePurchaseRulesData = (
  data: IPurchaseRuleSet[]
): Array<INormalizedPurchaseRuleSet> =>
  data.map((ruleSet) => ({
    ...ruleSet,
    purchaseRules: ruleSet.purchaseRules.map((rule) => ({
      ...rule,
      requiredPlanName: rule.name,
    })),
  }));

export const normalizeCouponUsageRulesData = (
  data: INormalizedCouponUsageRuleSet[]
): Array<INormalizedCouponUsageRuleSet> =>
  data.map((ruleSet) => ({
    ...ruleSet,
    usageRules: ruleSet.usageRules.map((rule) => ({
      ...rule,
      requiredPlanName: getPlanName(rule.requiredPlan),
    })),
  }));

export const filterAlreadyAddedRequiredPlans = (
  plans: IOption[],
  rules: INormalizedPurchaseRule[] | INormalizedCouponUsageRule[]
): IOption[] =>
  plans.filter(
    (plan) =>
      // @ts-ignore
      !rules.find(
        (rule: any) => rule?.requiredPlan?.id === plan.value || rule?.requiredPlanId === plan.value
      )
  );

export const getPermissionOptions = (
  permissions: IPermission[],
  permissionGroups: IPermissionGroup[]
) => {
  try {
    const usedPermissionIds = permissionGroups
      .map((i) => i.permissions)
      .flat()
      .map((i) => i.id);
    return permissions
      .filter((i) => !usedPermissionIds.includes(i.id))
      .map((item) => ({
        value: item.id,
        label: transformPermissionText(item.name),
      }));
  } catch (error) {
    console.log('error:', error);
    return [];
  }
};

export const modifyQueryParams = (key: string, value: string) => {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search.slice(1));
  params.set(key, value);
  return window.location.pathname + '?' + params.toString();
};

export const getSearchParam = (param: string, url?: string): string | null =>
  new URLSearchParams(new URL(url || window.location.href).search).get(param);

export const normalizeRulesList = (
  rules: ISwitchingRule[],
  services: IOption[]
): ISwitchingLocalRules[] => {
  if (!rules || !services) {
    return [];
  }

  return rules.map((rule) => {
    const nameFrom = services.find((service) => service.value === rule.serviceIdFrom)?.label;
    const nameTo = services.find((service) => service.value === rule.serviceIdTo)?.label;
    return {
      ...rule,
      nameFrom,
      nameTo,
    };
  });
};

export const shopTypeOptions = Object.entries(PLAN_SHOP_TYPE).map((option) => ({
  label: option[0].replace('_', ''),
  value: option[1],
}));

export const getTextFieldLength = (field: string) =>
  `(${field?.length > '<p> </p>'.length ? field?.length : 0} symbols)`;

export const getOptionsFromEnum = (enumObj: any): IOption[] => {
  return Object.values(enumObj).reduce(
    (arr: IOption[], value) => {
      arr.push({
        label: value as string,
        value: value,
      });
      return arr;
    },
    [{ value: 'All', label: 'All' }] as IOption[]
  );
};
