import { initialValues } from '@root/pages/CreateEditSwitchSubscriptionRule/static-data';
import { getValuesFromSelectedRule } from '@root/pages/CreateEditSwitchSubscriptionRule/utils';
import switchSubscriptionRuleRequests, {
  ICreateSwitchSubscriptionRule,
  IEditSwitchSubscriptionRule,
  ISwitchSubscriptionRule,
} from '@root/services/api/requests/switchSubscriptionRule';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { IValues } from './types';
import { validationSchema } from './validation';

const replaceEmptyValue = (value: number | string) => (typeof value === 'number' ? value : null);

export const useModuleFormik = (
  selectedSwitchSubscriptionRule: ISwitchSubscriptionRule | null,
  splitOption: boolean
) => {
  const dispatch = useDispatch();

  const fk: FormikProps<IValues> = useFormik({
    initialValues: selectedSwitchSubscriptionRule
      ? {
          ...getValuesFromSelectedRule(selectedSwitchSubscriptionRule, initialValues),
          enabled: selectedSwitchSubscriptionRule
            ? selectedSwitchSubscriptionRule.enabled
            : initialValues.enabled,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values: IValues) => {
      const createData = {
        ghlTag: values.ghlTag || null,
        sku: values.sku,
        adjustmentSku: values.adjustmentSku,
        enabled: values.enabled,
        planIdFrom: values.planIdFrom,
        planIdTo: values.planIdTo,
        fsbPeriod: values.fsbPeriod,
        priceAdjustment: values.priceAdjustment as number,
        spendingsAdjustment: values.spendingsAdjustment,
        withRenew: values.withRenew,
        freeRenew: values.freeRenew,
        changeNested: values.changeNested,
        ...(!!values?.membershipId ? { membershipId: values.membershipId } : {}),
        splitSKU: values.splitSKU,
        splitBaseValue: values.splitBaseValue,
        splitValues: values.splitValues || null,
        splitFSB: values.splitFSB || null,
        splitPeriod: splitOption ? values.splitPeriod || null : null,
        uiOriginalPrice: values.uiOriginalPrice || null,
        pointRewardsAdjustment: {
          cv: values.cv,
          qv: values.qv,
          scv: values.scv,
          sqv: values.sqv,
          fsb: values.fsb,
          matrixBV: values.matrixBV,
        },
        membershipSettings: values.membershipSettings?.map((item) => ({
          priceAdjustment: replaceEmptyValue(item.priceAdjustment),
          uiOriginalPrice: replaceEmptyValue(item.uiOriginalPrice),
          splitBaseValue: replaceEmptyValue(item.splitBaseValue),
          splitFSB: replaceEmptyValue(item.splitFSB),
          splitValues: replaceEmptyValue(item.splitValues),
          splitPeriod: replaceEmptyValue(item.splitPeriod),
          fsbPeriod: replaceEmptyValue(item.fsbPeriod),
          spendingsAdjustment: replaceEmptyValue(item.spendingsAdjustment),
          membershipLevelId: item.membershipLevelId,
          pointRewardsAdjustment: {
            cv: replaceEmptyValue(item.pointRewardsAdjustment.cv),
            qv: replaceEmptyValue(item.pointRewardsAdjustment.qv),
            scv: replaceEmptyValue(item.pointRewardsAdjustment.scv),
            sqv: replaceEmptyValue(item.pointRewardsAdjustment.sqv),
            fsb: replaceEmptyValue(item.pointRewardsAdjustment.fsb),
            matrixBV: replaceEmptyValue(item.pointRewardsAdjustment.matrixBV),
          },
        })),
      };

      if (!selectedSwitchSubscriptionRule || !selectedSwitchSubscriptionRule.id) {
        switchSubscriptionRuleRequests
          .createSwitchSubscriptionRule(createData as ICreateSwitchSubscriptionRule)
          .then((response) => {
            if (response) {
              setNotification('success', {
                message: 'Success',
              });
              dispatch(push(pageLinks.bundleSwitchSubscriptionRule));
            }
          });
      } else {
        const editData: IEditSwitchSubscriptionRule = {
          ...createData,
          id: selectedSwitchSubscriptionRule.id,
        };

        switchSubscriptionRuleRequests.editSwitchSubscriptionRule(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.bundleSwitchSubscriptionRule));
          }
        });
        // return
      }
    },
  });

  return fk;
};
