import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import notificationsRequests, { INotification } from '../../services/api/requests/notifications';
import { editNotificationData, setNotifications } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

export const useNotificationsTable = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<INotification[]>([]);

  const fetchNotificationsList = async () => {
    try {
      const response = await notificationsRequests.readNotificationList();
      setLoading(false);
      const dataItems = response.data;
      setList(dataItems);
      dispatch(setNotifications(dataItems));
    } catch (e) {
      console.warn((e as AxiosError).message);
    }
  };

  const handleDelete = async (id: number | string) => {
    try {
      await notificationsRequests.deleteNotification(id);
      setNotification('success', {
        message: 'Success',
      });
      await fetchNotificationsList();
    } catch (e) {
      console.warn((e as AxiosError).message);
    }
  };

  const handleEdit = (item: INotification) => {
    dispatch(editNotificationData(item));
    dispatch(push(pageLinks.createEditNotification));
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchNotificationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = {
    list,
    loading,
  };

  const handlers = { handleEdit, handleDelete };

  return [state, handlers] as [typeof state, typeof handlers];
};
