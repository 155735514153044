import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  FormControlLabel,
  IconButton,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { PaymentMethodListFilter } from '@root/components/PaymentMethodList/PaymentMeithodListFilter';
import { push } from 'connected-react-router';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getPaymentMethod from '../../services/api/requests/getPaymentMethod';
import {
  getPaymentMethodList,
  IPaymentMethod,
  PAYMENT_METHOD_STATUSES,
  updatePaymentMethod,
} from '../../services/api/requests/getPaymentMethodList';
import { IMeta } from '../../services/api/types';
import { editPaymentMethodData } from '../../store/entities/actions';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import Can, { Permissions } from '../Can';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import TableSkeleton from '../TableSkeleton';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', disableSort: true },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title', disableSort: true },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', disableSort: true },
  {
    id: 'defaultProcessingFee',
    numeric: false,
    disablePadding: false,
    label: 'Default Processing Fee',
    disableSort: true,
  },
  {
    id: 'customFlow',
    numeric: false,
    disablePadding: false,
    label: 'Custom Flow',
    disableSort: true,
  },
  {
    id: 'priority',
    numeric: false,
    disablePadding: false,
    label: 'UI priority order',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Updated',
    disableSort: true,
  },
];

const useStyles = makeStyles(() => ({
  switchCell: {
    width: 20,
  },
}));

const VatRulesList: FC<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setMeta: (meta: IMeta) => void;
  page: number;
  rowsPerPage: number;
}> = (props) => {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const dateFns = new DateFnsUtils();
  const permissions = useSelector(selectUserPermissions);
  const dispatch = useDispatch();
  const [quickEditPaymentMethod, setQuickEditPaymentMethod] = useState<number | null>(null);
  const [newData, setNewData] = useState<
    Pick<IPaymentMethod, 'defaultProcessingFee' | 'title' | 'status'>
  >({
    defaultProcessingFee: 0,
    title: '',
    status: PAYMENT_METHOD_STATUSES.ACTIVE,
  });
  const [filterByStatus, setFilterByStatus] = useState<PAYMENT_METHOD_STATUSES | 'all'>('all');

  const { loading, setLoading, setMeta, page, rowsPerPage } = props;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readPayments)) {
      fetchPaymentMethodsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const [list, setList] = useState<IPaymentMethod[] | null>(null);

  const fetchPaymentMethodsList = () => {
    getPaymentMethodList({ page, limit: rowsPerPage, status: filterByStatus }).then((response) => {
      if (response) {
        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    });
  };

  const handlePaymentMethodEdit = async (paymentMethod: IPaymentMethod) => {
    try {
      const response = await getPaymentMethod(paymentMethod.id);
      dispatch(editPaymentMethodData(response.data));
      dispatch(push(pageLinks.createEditPaymentMethod));
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleNewDataChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;

    if (name === 'defaultProcessingFee') {
      setNewData({
        ...newData,
        defaultProcessingFee: value as number,
      });
    } else {
      setNewData({
        ...newData,
        [name as string]: value,
      });
    }
  };

  const handleNewStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewData({
      ...newData,
      status: e.target.checked ? PAYMENT_METHOD_STATUSES.ACTIVE : PAYMENT_METHOD_STATUSES.INACTIVE,
    });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterByStatus(e.target.value as PAYMENT_METHOD_STATUSES);
  };

  const handleFilterApply = () => {
    if (!loading) {
      fetchPaymentMethodsList();
    }
  };

  const submitNewData = (id: number) => {
    if (newData.title && newData.defaultProcessingFee) {
      updatePaymentMethod(id, {
        ...newData,
        defaultProcessingFee: Number(newData.defaultProcessingFee),
      }).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          setQuickEditPaymentMethod(null);
          fetchPaymentMethodsList();
        }
      });
    }
  };

  useEffect(() => {
    if (quickEditPaymentMethod) {
      const paymentMethod = list?.find((item) => item.id === quickEditPaymentMethod);
      if (paymentMethod) {
        setNewData({
          defaultProcessingFee: paymentMethod.defaultProcessingFee,
          title: paymentMethod.title,
          status: paymentMethod.status,
        });
      }
    }
  }, [quickEditPaymentMethod]);

  return (
    <>
      <PaymentMethodListFilter
        filterData={filterByStatus}
        handelFilterChange={handleFilterChange}
        handleFilterApply={handleFilterApply}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              list?.map((paymentMethod: IPaymentMethod) => {
                const {
                  id,
                  name,
                  title,
                  status,
                  defaultProcessingFee,
                  priority,
                  customFlow,
                  createdAt,
                  updatedAt,
                } = paymentMethod;
                const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
                const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

                return (
                  <TableRow key={id}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">
                      {quickEditPaymentMethod === paymentMethod.id ? (
                        <TextField
                          name="title"
                          variant="standard"
                          fullWidth
                          id="title"
                          type="text"
                          value={newData.title}
                          onChange={handleNewDataChange}
                          required
                          error={!newData.title}
                        />
                      ) : (
                        title
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {quickEditPaymentMethod === paymentMethod.id ? (
                        <FormControlLabel
                          labelPlacement="top"
                          className={classes.switchCell}
                          control={
                            <Switch
                              id="active"
                              checked={newData.status === PAYMENT_METHOD_STATUSES.ACTIVE}
                              onChange={handleNewStatusChange}
                              name="active"
                              color="primary"
                            />
                          }
                          label={newData.status}
                        />
                      ) : (
                        status
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {quickEditPaymentMethod === paymentMethod.id ? (
                        <TextField
                          name="defaultProcessingFee"
                          variant="standard"
                          fullWidth
                          id="defaultProcessingFee"
                          type="number"
                          value={newData.defaultProcessingFee}
                          onChange={handleNewDataChange}
                          required
                          error={!newData.defaultProcessingFee}
                        />
                      ) : (
                        defaultProcessingFee
                      )}
                    </TableCell>
                    <TableCell align="left">{customFlow ? 'yes' : 'no'}</TableCell>
                    <TableCell align="left">{priority}</TableCell>
                    <TableCell>{create}</TableCell>
                    <TableCell>{update}</TableCell>
                    <TableCell align="left">
                      <Can perform={Permissions.updatePaymentMethod}>
                        {quickEditPaymentMethod === paymentMethod.id && (
                          <IconButton
                            type="button"
                            aria-label="edit"
                            title="Save"
                            size="small"
                            onClick={() => submitNewData(paymentMethod.id)}
                          >
                            <CheckIcon />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label="edit-price"
                          title="Quick edit"
                          size="small"
                          onClick={() => {
                            setQuickEditPaymentMethod(
                              quickEditPaymentMethod === paymentMethod.id ? null : paymentMethod.id
                            );
                          }}
                        >
                          {quickEditPaymentMethod === paymentMethod.id ? (
                            <CloseIcon />
                          ) : (
                            <EditIcon />
                          )}
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell>
                      <Can perform={Permissions.updatePaymentMethod}>
                        <Button
                          onClick={() => handlePaymentMethodEdit(paymentMethod)}
                          variant="outlined"
                          color="primary"
                        >
                          Edit
                        </Button>
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })}
            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no payment methods</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VatRulesList;
