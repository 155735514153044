import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { subMonths } from 'date-fns';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import InputSelect from '../../components/InputSelect';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import generateUserKycReport from '../../services/api/requests/userKycReport';
import { IOption } from '../../utils/commonTypes';

export const VERIFICATION_STATUSES: IOption[] = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'SUCCESS', label: 'Success' },
  { value: 'ACCEPTABLE', label: 'Success with Names or country mismatch' },
  { value: 'ERROR', label: 'Error' },
];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();

  const [isAll, setIsAll] = useState(false);
  const [verificationStatusFilter, selectVerificationStatusFilter] = React.useState<string>('all');
  const [dateState, setDateState] = React.useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
    key: 'selection';
  }>({ startDate: subMonths(new Date(), 1), endDate: new Date(), key: 'selection' });

  const dateRangeValue =
    dateState.startDate &&
    dateFns.isValid(dateState.startDate) &&
    dateState.endDate &&
    dateFns.isValid(dateState.endDate)
      ? `${dateFns.format(new Date(dateState.startDate), 'MMM d, yyyy')} - ${dateFns.format(
          new Date(dateState.endDate),
          'MMM d, yyyy'
        )}`
      : '';

  const disabledDateRange = isAll;

  const handleRangeClear = (e: React.SyntheticEvent<{}>) => {
    e.stopPropagation();
    setDateState({ startDate: undefined, endDate: undefined, key: 'selection' });
  };

  const handleAllClicked = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (!isAll) {
      setDateState({ startDate: undefined, endDate: undefined, key: 'selection' });
      selectVerificationStatusFilter('all');
    }
    setIsAll((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Reports</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Box display="flex" alignItems="center">
                    <Box marginRight="20px">Customer KYC Status</Box>
                    <Modal
                      button={
                        <TextField
                          autoComplete="new-password"
                          variant="outlined"
                          fullWidth
                          type={'text'}
                          label={'Date Range'}
                          autoFocus
                          disabled={disabledDateRange}
                          value={dateRangeValue}
                          style={{ minWidth: 280 }}
                          onClick={(e) => (disabledDateRange ? e.stopPropagation() : null)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => handleRangeClear(e)}
                                  edge="end"
                                  disabled={disabledDateRange}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      }
                      renderContent={() => (
                        <DateRangePicker
                          onChange={(item: any) => {
                            setDateState(item.selection);
                          }}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={[dateState]}
                          direction="horizontal"
                        />
                      )}
                    />

                    <Box style={{ maxWidth: 300, width: '100%', marginLeft: 16 }}>
                      <InputSelect
                        id="verification"
                        value={verificationStatusFilter}
                        onChange={(e): void => {
                          selectVerificationStatusFilter(e.target.value as string);
                        }}
                        options={[...VERIFICATION_STATUSES, { value: 'all', label: 'All' }]}
                      />
                    </Box>

                    <FormControlLabel
                      value={isAll}
                      style={{ marginLeft: 16 }}
                      control={
                        <Checkbox
                          color="primary"
                          id="enabled"
                          name="enabled"
                          checked={isAll}
                          onChange={handleAllClicked}
                        />
                      }
                      label="All"
                      labelPlacement="end"
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box>
                    <Can perform={Permissions.generateUserKycReport}>
                      <IconButton
                        aria-label="Download"
                        title="Download"
                        onClick={() =>
                          generateUserKycReport(
                            isAll,
                            verificationStatusFilter,
                            dateState.startDate,
                            dateState.endDate
                          )
                        }
                      >
                        <GetApp />
                      </IconButton>
                    </Can>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default EnhancedTable;
