import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import TableHead from '@material-ui/core/TableHead';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import InputSearch from '../../components/InputSearch';
import PaymentProcessors from '../../components/PaymentProcessors';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import {
  addFile,
  getAdminPayoutRequests,
  IPayoutAdminRequest,
  IPayoutAdminRequestListItem,
  ProcessorType,
} from '../../services/api/requests/massPayouts';
import { IMeta } from '../../services/api/types';
import { showPayoutRequestInfo } from '../../store/entities/actions';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },

  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name', disableSort: true },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email', disableSort: true },
  { id: 'filename', numeric: false, disablePadding: false, label: 'File Name', disableSort: true },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date Created' },
];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const [paymentProcessor, setPaymentProcessor] = React.useState<ProcessorType>('dlocal');
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = React.useState<'createdAt' | 'updatedAt' | 'method'>('createdAt');
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(15);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [list, setList] = React.useState<IPayoutAdminRequest[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<any>([]);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortDirection, rowsPerPage, searchTerm, paymentProcessor]);

  const fetchList = async () => {
    try {
      const response = await getAdminPayoutRequests({
        page,
        sortBy,
        sortDirection,
        limit: rowsPerPage,
        search: searchTerm,
        type: paymentProcessor,
      });

      setLoading(false);
      setList(response.data.data.items);
      setMeta(response.data.data.meta);
    } catch (e) {}
  };

  const handleRequestSort = (event: any, property: 'createdAt' | 'updatedAt' | 'method') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const onAddFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.currentTarget.files?.[0];
      if (file) {
        await addFile({ file }, paymentProcessor);
        fetchList();
      }
    } catch (e) {
      const message = e.response?.data?.response?.message;

      if (Array.isArray(message)) {
        setErrors(e.response.data.response.message);
      }
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDetails = (data: IPayoutAdminRequestListItem[]) => {
    dispatch(push(pageLinks.massPayoutRequestDetails));
    dispatch(showPayoutRequestInfo(data));
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Payout Requests</Title>

            <Can perform={Permissions.createAdmin}>
              <div>
                <input
                  type="file"
                  className={classes.hidden}
                  accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                  onChange={onAddFile}
                />
              </div>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <div className={classes.searchRow}>
          <PaymentProcessors onSelectClick={setPaymentProcessor} type={paymentProcessor} />
          <InputSearch onSubmit={(val) => setSearchTerm(val)} />
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={sortDirection}
              orderBy={sortBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {loading && <TableSkeleton />}

              {!loading &&
                list?.map((item: IPayoutAdminRequest) => {
                  const {
                    id,
                    admin: { firstName, lastName, email },
                    users,
                    fileName,
                    createdAt,
                  } = item;

                  const create =
                    createdAt && dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');

                  return (
                    <TableRow
                      className={classes.hoveredRow}
                      key={id}
                      onClick={() => handleDetails(users)}
                    >
                      <TableCell align="left">{id || '------'}</TableCell>
                      <TableCell align="left">{firstName || '------'}</TableCell>
                      <TableCell align="left">{lastName || '------'}</TableCell>
                      <TableCell align="left">{email || '------'}</TableCell>
                      <TableCell align="left">{fileName || '------'}</TableCell>
                      <TableCell align="left">{create || '------'}</TableCell>
                    </TableRow>
                  );
                })}

              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>
                    There are no payout requests{' '}
                    {searchTerm.length > 0 ? `for current search - ${searchTerm} ` : ''}{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={errors?.length}
        onClose={() => setErrors(null)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paperModal}>
          {errors?.map((errorItem: any) => (
            <TableContainer key={errorItem.sheet}>
              <Title> Sheet: {errorItem.sheet} </Title>

              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {['Index', ...errorItem.header, 'Explanation'].map(
                      (item: string, idx: number) => (
                        <TableCell key={idx} align={'left'} padding={'default'}>
                          {item}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorItem.errors?.map((item: any) => {
                    const { idx, message, value, data } = item;
                    const { exigoId, amount, email } = data || {};

                    if (item.idx === 0) {
                      return (
                        <TableRow className={classes.hoveredRow} key={idx}>
                          <TableCell align="left">{idx}</TableCell>
                          <TableCell align="left" />
                          <TableCell align="left" />
                          <TableCell align="left" />
                          <TableCell align="left">{message}</TableCell>
                        </TableRow>
                      );
                    }

                    return (
                      <TableRow className={classes.hoveredRow} key={idx}>
                        <TableCell align="left">{idx || '-'}</TableCell>

                        <TableCell align="left">
                          <span className={!exigoId ? classes.wrongField : ''}>
                            {exigoId || value}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <span className={!email ? classes.wrongField : ''}>{email || value}</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className={!amount ? classes.wrongField : ''}>
                            {amount || value}
                          </span>
                        </TableCell>
                        <TableCell align="left">{message}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default EnhancedTable;
