import { EntityType, IEntity } from '@root/pages/CreateCountriesGroup/types';
import { ICountryGroup } from '@root/services/api/requests/getCountries';
import { COUNTRY_GROUP_RELATED_ENTITY } from '@root/services/api/requests/relatedEntity';

export function createInitialState<T>(obj: typeof COUNTRY_GROUP_RELATED_ENTITY) {
  return Object.values(obj).reduce<EntityType<T>>(
    (newObj, key) => ((newObj[key] = [...(newObj[key] || [])]), newObj),
    {} as EntityType<T>
  );
}

export const initialStateCountriesToSave = (group: ICountryGroup | undefined): string[] | [] =>
  group ? (group?.countries.map((v) => v.code) as string[]) : [];

export const entityPresent = <T>(entity: keyof IEntity, relatedEntity: IEntity) =>
  relatedEntity[entity] && (relatedEntity[entity] as T[]).length > 0 ? relatedEntity[entity] as T : null;
