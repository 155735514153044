export const getValuesFromSelectedRule = (rule: any | null, initialValues: any) => {
  if (rule) {
    const data = { ...initialValues };

    Object.keys(initialValues).map(
      // @ts-ignore
      (item: any) => (data[item] = rule[item] || initialValues[item])
    );

    if (rule?.pointRewardsAdjustment) {
      // @ts-ignore
      Object.keys(rule?.pointRewardsAdjustment).map(
        (key) => (data[key] = rule?.pointRewardsAdjustment[key])
      );
    }

    return data;
  }
  return initialValues;
};
