import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import { EVENT_STATUSES } from '../../services/api/requests/triggerEvents';

interface IProps {
  status?: EVENT_STATUSES;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.8, 0.1),
    borderRadius: 3,
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: '0.95rem',
  },

  green: {
    backgroundColor: '#008000a3',
  },
  blue: {
    backgroundColor: '#08918bb5',
  },
  red: {
    backgroundColor: '#e24e4ee8',
  },
}));

const TriggerEventStatus: React.FC<IProps> = (props) => {
  const classes = useStyles(props);
  const { status } = props;
  if (!status) {
    return <div />;
  }

  const statusText = status.replace(/_/g, ' ');

  const renderStatus = () => {
    switch (status) {
      case EVENT_STATUSES.COMPLETED:
      case EVENT_STATUSES.PUBLISHED:
        return <div className={clsx(classes.container, classes.green)}>{status}</div>;
      case EVENT_STATUSES.IN_PROGRESS:
      case EVENT_STATUSES.PROCESSED:
      case EVENT_STATUSES.RETRY_AFTER_ERROR:
        return <div className={clsx(classes.container, classes.blue)}>{statusText}</div>;
      case EVENT_STATUSES.CANCELLED:
      case EVENT_STATUSES.FAILED:
        return <div className={clsx(classes.container, classes.red)}>{status}</div>;

      default:
        return <div />;
    }
  };

  return renderStatus();
};

export default TriggerEventStatus;
