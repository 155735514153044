import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Autocomplete } from '@material-ui/lab';
import { FormikProps, useFormik } from 'formik';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import createRequiredPlan from '../../services/api/requests/createRequiredPlan';
import { INormalizedPurchaseRule } from '../../services/api/requests/getPurchaseRules';
import { selectPlansOptionsValueID, selectServicesOptions } from '../../store/entities/selectors';
import { SELECT_OPTION, SUBSCRIPTION_STATUS } from '../../utils/commonTypes';
import { filterAlreadyAddedRequiredPlans } from '../../utils/helpers';
import EditStatusButton from '../EditStatusButton';
import { useStandardFormStyles } from '../FormBase';
import { shouldHaveStatusOptions } from '../PurchaseRules';

type ValueType = 'service' | 'plan';

const useStyles = makeStyles(() => ({
  input: {
    marginRight: 20,
  },
}));

const PurchaseRulesControl: FC<{
  purchaseRuleId: number;
  purchaseRules: Array<INormalizedPurchaseRule>;
  fetchPurchaseRules: () => void;
}> = ({ purchaseRuleId, purchaseRules, fetchPurchaseRules }) => {
  const plans = useSelector(selectPlansOptionsValueID);
  const servicesOptions = useSelector(selectServicesOptions);

  const classes = { ...useStandardFormStyles(), ...useStyles() };

  const [showCreateRulePanel, togglePanel] = useState<boolean>(false);
  const [shouldHaveStatus, setShouldHaveStatus] = useState<SUBSCRIPTION_STATUS | null>(null);

  const fk: FormikProps<{ label: string; value: number; type: ValueType }> = useFormik({
    initialValues: { label: '', value: 0, type: 'plan' as ValueType },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const data = {
          planId: (values.type as ValueType) === 'plan' ? values.value : undefined,
          shouldHaveStatus: shouldHaveStatus,
          serviceId: (values.type as ValueType) === 'service' ? values.value : undefined,
        };

        await createRequiredPlan({ id: purchaseRuleId, data });
        fetchPurchaseRules();
        togglePanel(false);
      } catch (e) {
        console.warn('create required plan of purchase rules error', e);
      }
    },
  });

  return (
    <Box className={classes.purchaseRuleControlContainer}>
      {showCreateRulePanel && (
        <Box className={classes.purchaseRuleControlsWrapper}>
          {fk.values.type === 'plan' ? (
            <>
              <Autocomplete
                options={filterAlreadyAddedRequiredPlans(plans, purchaseRules)}
                fullWidth
                id="requiredPlanId"
                getOptionLabel={(option) => option.label}
                onChange={(_, value) => fk.setFieldValue('value', value?.value || 0)}
                className={classes.input}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    required
                    label="Required Plan"
                    value={fk.values.value}
                    onBlur={fk.handleBlur}
                  />
                )}
              />

              <EditStatusButton
                status={shouldHaveStatus || SELECT_OPTION.ANY}
                options={shouldHaveStatusOptions as SUBSCRIPTION_STATUS & SELECT_OPTION}
                onChange={(status: SUBSCRIPTION_STATUS & SELECT_OPTION) => {
                  setShouldHaveStatus(status === SELECT_OPTION.ANY ? null : status);
                }}
              />
            </>
          ) : (
            <Autocomplete
              options={servicesOptions}
              fullWidth
              id="requiredPlanId"
              getOptionLabel={(option) => option.label}
              onChange={(_, value) => fk.setFieldValue('value', value?.value || 0)}
              className={classes.input}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  required
                  label="Required Service"
                  value={fk.values.value}
                  onBlur={fk.handleBlur}
                />
              )}
            />
          )}
        </Box>
      )}
      <Box className={classes.flexEnd}>
        {showCreateRulePanel ? (
          <Button
            type="button"
            disabled={showCreateRulePanel && fk.values.value === 0}
            variant={showCreateRulePanel ? 'contained' : 'outlined'}
            color="primary"
            startIcon={showCreateRulePanel ? null : <AddCircleIcon />}
            // @ts-ignore
            onClick={() => fk.handleSubmit(fk.values)}
          >
            save
          </Button>
        ) : (
          <>
            <Button
              type="button"
              disabled={showCreateRulePanel && fk.values.value === 0}
              variant={showCreateRulePanel ? 'contained' : 'outlined'}
              color="primary"
              startIcon={showCreateRulePanel ? null : <AddCircleIcon />}
              onClick={(e) => {
                togglePanel(!showCreateRulePanel);
                fk.setFieldValue('type', 'plan');
              }}
            >
              add plan
            </Button>

            <div style={{ width: '16px' }} />

            <Button
              type="button"
              disabled={showCreateRulePanel && fk.values.value === 0}
              variant={showCreateRulePanel ? 'contained' : 'outlined'}
              color="primary"
              startIcon={showCreateRulePanel ? null : <AddCircleIcon />}
              onClick={(e) => {
                togglePanel(!showCreateRulePanel);
                fk.setFieldValue('type', 'service');
              }}
            >
              add service
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PurchaseRulesControl;
