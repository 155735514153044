import {
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import 'date-fns';
import capitalize from 'lodash/capitalize';
import * as React from 'react';
import BackButton from '../../components/BackButton';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import pageLinks from '../../utils/pageLinks';
import { MenuProps } from './static-data';
import { IValues } from './types';
import { useForm } from './use-form';

const CreateEditNotification: React.FC<{}> = () => {
  const classes = useStandardFormStyles();
  const [{ isEdit, fk, typeOptions, pageOptions }] = useForm();
  const actionText = isEdit ? 'Edit' : 'Create';

  const createInputField = createInput<IValues>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.notifications} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Notification`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="isForLoggedInOnly"
                    value={fk.values.isForLoggedInOnly ? 'true' : 'false'}
                    onChange={(e) =>
                      fk.setFieldValue(
                        'isForLoggedInOnly',
                        e.target.value === 'true'
                          ? true
                          : e.target.value === 'false'
                            ? false
                            : undefined
                      )
                    }
                  >
                    <FormControlLabel
                      value={'true'}
                      control={<Radio color="primary" />}
                      label="For logged-in users only"
                    />
                    <FormControlLabel
                      value={'false'}
                      control={<Radio color="primary" />}
                      label="For all users"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <InputSelect
                  id="type"
                  label={'Type'}
                  value={fk.values.type}
                  onChange={(e) => fk.setFieldValue('type', e.target.value)}
                  options={typeOptions}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  multiline
                  fullWidth
                  id={`message`}
                  label={'Message'}
                  name={`message`}
                  autoComplete="new-password"
                  value={fk.values.message}
                  onBlur={fk.handleBlur}
                  onChange={fk.handleChange}
                  error={!!(fk.errors.message && fk.touched.message)}
                  helperText={fk.touched.message && fk.errors.message}
                />
              </Grid>

              <Grid item xs={12} style={{ marginLeft: 8, marginBottom: 8 }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!(fk.errors.pageNames && fk.touched.pageNames)}
                >
                  <InputLabel style={{ marginLeft: -8 }}>Pages</InputLabel>
                  <Select
                    id="pageNames"
                    label={'Pages'}
                    variant="outlined"
                    multiple
                    value={fk.values.pageNames}
                    onBlur={fk.handleBlur}
                    onChange={(e) => fk.setFieldValue('pageNames', e.target.value)}
                    input={<Input />}
                    renderValue={(selected) =>
                      (selected as string[]).map((item) => capitalize(item)).join(', ')
                    }
                    MenuProps={MenuProps}
                  >
                    {pageOptions.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.pageNames.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                  {fk.touched.pageNames && fk.errors.pageNames && (
                    <FormHelperText>{fk.errors.pageNames}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                {createInputField('intervalDisplayInHours', 'Interval Display, h', 12, 'number')}
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditNotification;
