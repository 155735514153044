import PromoDiscount, { IPromoDiscount } from '@root/services/api/requests/promo-discount';
import { editPromoDiscount } from '@root/store/entities/actions';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSuccessNotification } from '../../../utils/notifications';
import pageLinks from '../../../utils/pageLinks';
import { initialValues } from '../static-data';
import { validationSchema } from '../validation';

export const usePromoDiscountFormik = (editItem: IPromoDiscount | null) => {
  const dispatch = useDispatch();
  const isEdit = !!editItem;

  const fk: FormikProps<IPromoDiscount> = useFormik({
    initialValues: editItem || initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async ({ id, ...values }) => {
      try {
        if (isEdit && editItem?.id) {
          await PromoDiscount.edit(values, editItem?.id);
        } else {
          await PromoDiscount.create(values);
        }
        dispatch(push(pageLinks.promoDiscounts));
        setSuccessNotification();
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  useEffect(() => {
    return () => {
      dispatch(editPromoDiscount(null));
    };
  }, []);

  return fk;
};
