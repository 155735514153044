export const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'username', numeric: false, disablePadding: false, label: 'Username/ExigoId' },
  { id: 'rebillyTransactionId', numeric: false, disablePadding: false, label: 'Exigo Order Id' },
  { id: 'transactionId', numeric: false, disablePadding: false, label: 'Transaction Id' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'product', numeric: false, disablePadding: false, label: 'Name/Membership' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Price' },
  { id: 'paymentMethod', numeric: false, disablePadding: false, label: 'Payment Method' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'action', numeric: false, disablePadding: false, label: '' },
];

export const methodOptions = [
  { label: 'All', value: 'all' },
  { label: 'Stripe', value: 'Stripe' },
  { label: 'PayPal', value: 'PayPal' },
  { label: 'TestProcessor', value: 'TestProcessor' },
  { label: 'EBANX', value: 'EBANX' },
  { label: 'NGenius', value: 'NGenius' },
  { label: 'BitPay', value: 'BitPay' },
  { label: 'CoinPayments', value: 'CoinPayments' },
  { label: 'PayULatam', value: 'PayULatam' },
  { label: 'AstroPayCard', value: 'AstroPayCard' },
  { label: 'Plaid', value: 'Plaid' },
  { label: 'e-wallet', value: 'e-wallet' },
  { label: 'MyFatoorah', value: 'MyFatoorah' },
  { label: 'Unknown', value: 'Unknown' },
];

export const typeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Multiple', value: 'Multiple' },
  { label: 'Renewal', value: 'Renewal' },
  { label: 'Subscription', value: 'Subscription' },
  { label: 'Upgrade', value: 'Upgrade' },
  { label: 'One-time', value: 'One-time' },
];

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Paid', value: 'Paid' },
  { label: 'On hold', value: 'On hold' },
  { label: 'Processing', value: 'Processing' },
  { label: 'Canceled', value: 'Canceled' },
  { label: 'Refunded', value: 'Refunded' },
  { label: 'Blockchain Pending', value: 'Blockchain Pending' },
  { label: 'Error', value: 'Error' },
  { label: 'Expired', value: 'Expired' },
];
