import { IExchangeRateFieldsData } from '@root/pages/EditExchangeRate/types';
import { changeExchangeRate } from '@root/services/api/requests/exchangeRateList';
import { selectEditExchangeRateItem } from '@root/store/entities/selectors';
import notify from "@root/utils/notifications";
import pageLinks from '@root/utils/pageLinks';
import { push } from "connected-react-router";
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useCreateExchangeRate = () => {
  const dispatch = useDispatch();
  const exchangeRate = useSelector(selectEditExchangeRateItem);

  const [exchangeRateFieldsData, setExchangeRateFieldsData] = useState<IExchangeRateFieldsData>({
    from: 1000,
    to: exchangeRate ? exchangeRate.currencyTo : 1,
    newRate: exchangeRate ? exchangeRate.rate : 0,
  });


  useEffect(() => {
    if (exchangeRate) {
      setExchangeRateFieldsData((prev) => ({
        ...prev,
        to: Number(prev.from) * exchangeRate.rate,
        newRate: exchangeRate.rate,
      }));
    }
  }, []);

  const handleCalcExchangeRateFrom = (e: ChangeEvent<HTMLInputElement>) => {
    const value: number = Number(e.target.value);
    
    if (exchangeRate) {
      setExchangeRateFieldsData((prev) => ({
        ...prev,
        from: value !== 0 ? value : '',
        newRate: value > 1 ? Number((Number(prev.to) / Number(exchangeRateFieldsData.from)).toFixed(4)) : Number(prev.to) / 1,
      }));
    }
  };

  const handleCalcExchangeRateTo = (e: ChangeEvent<HTMLInputElement>) => {
    const value: number = Number(e.target.value);

    if (exchangeRate) {
      setExchangeRateFieldsData((prev) => ({
        ...prev,
        to: value !== 0 ? value : '',
        newRate: Number((Number(prev.to) / Number(exchangeRateFieldsData.from)).toFixed(4)),
      }));
    }
  };

  const submitNewExchangeRate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (exchangeRate) {
        await changeExchangeRate(exchangeRate.id, {
          rate: exchangeRateFieldsData.newRate,
        });
      }

      notify('success')
      setTimeout(() => {
        dispatch(push(pageLinks.exchangeRateList))
      }, 1000)
    } catch (err) {
      console.error(err);
    }
  };

  const state = {
    exchangeRateFieldsData,
  };

  const handlers = {
    handleCalcExchangeRateFrom,
    handleCalcExchangeRateTo,
    submitNewExchangeRate,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
