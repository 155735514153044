import { Box, Button, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import MembershipLevelsTable from '../../components/MembershipLevelsTable';
import MembershipRulesTable from '../../components/MembershipRulesTable';
import Title from '../../components/Title';
import membershipLevelRequests, {
  IMembershipLevel,
} from '../../services/api/requests/membershipLevels';
import membershipRuleRequests, {
  IMembershipRule,
} from '../../services/api/requests/membershipRules';
import {
  editMembershipLevelData,
  editMembershipRulesData,
  fetchMembershipLevels,
} from '../../store/entities/actions';
import { selectMembershipLevels } from '../../store/entities/selectors';
import { selectLoading, selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const MembershipSettings: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const permissions = useSelector(selectUserPermissions);
  const levels = useSelector(selectMembershipLevels);
  const systemLoading = useSelector(selectLoading);
  const [rules, setRules] = React.useState<IMembershipRule[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isRulesTab, setIsRulesTab] = React.useState<number>(0);

  const fetchMembershipLevelsList = async () => {
    dispatch(fetchMembershipLevels());
  };

  const fetchMembershipRulesList = async () => {
    if (permissions?.includes(Permissions.readUpgradeMembershipRuleList)) {
      try {
        const response = await membershipRuleRequests.readMembershipRulesList();

        setLoading(false);
        setRules(response.data.data);
      } catch (e) {
        console.warn(e.message);
      }
    }
  };

  useEffect(() => {
    fetchMembershipLevelsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isRulesTab ? fetchMembershipRulesList() : fetchMembershipLevelsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRulesTab]);

  const deleteMembershipLevelItem = async (id: number | string) => {
    try {
      await membershipLevelRequests.deleteMembershipLevelItem(id);
      setNotification('success', {
        message: 'Success',
      });
      await fetchMembershipLevelsList();
    } catch (e) {
      console.warn(e.message);
    }
  };

  const editMembershipLevelItem = async (item: IMembershipLevel) => {
    dispatch(editMembershipLevelData(item));
    dispatch(push(`${pageLinks.createEditMembershipLevel}`));
  };

  const deleteMembershipRulesItem = async (id: number | string) => {
    try {
      await membershipRuleRequests.deleteMembershipRulesItem(id);
      setNotification('success', {
        message: 'Success',
      });
      await fetchMembershipRulesList();
    } catch (e) {
      console.warn(e.message);
    }
  };

  const editMembershipRulesItem = (item: IMembershipRule) => {
    dispatch(editMembershipRulesData(item));
    dispatch(push(`${pageLinks.createEditMembershipRules}`));
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    setLoading(true);
    setIsRulesTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Membership settings</Title>
            <Box display={'flex'}>
              <Box marginRight={1}>
                <Can perform={Permissions.createMembershipLevel}>
                  <Button
                    onClick={() => dispatch(push(pageLinks.createEditMembershipLevel))}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon />}
                  >
                    Add a level
                  </Button>
                </Can>
              </Box>
              <Can perform={Permissions.createUpgradeMembershipRule}>
                <Button
                  onClick={() => dispatch(push(pageLinks.createEditMembershipRules))}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<AddCircleIcon />}
                >
                  Add a rule
                </Button>
              </Can>
            </Box>
          </div>
        </Paper>
      </Grid>

      <Tabs
        value={isRulesTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabClick}
        aria-label="membership tabs"
      >
        {Can({
          perform: Permissions.readMembershipLevelList,
          children: <Tab label="Membership levels" />,
        })}
        {Can({
          perform: Permissions.readUpgradeMembershipRuleList,
          children: <Tab label="Membership rules" />,
        })}
      </Tabs>

      {isRulesTab ? (
        <MembershipRulesTable
          loading={loading}
          rules={rules}
          handleDelete={deleteMembershipRulesItem}
          handleEdit={editMembershipRulesItem}
          levels={levels}
        />
      ) : (
        <MembershipLevelsTable
          loading={systemLoading}
          levels={levels}
          handleDelete={deleteMembershipLevelItem}
          handleEdit={editMembershipLevelItem}
        />
      )}
    </div>
  );
};

export default MembershipSettings;
