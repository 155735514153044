import { IPromoDiscount } from '@root/services/api/requests/promo-discount';

export const initialValues: IPromoDiscount = {
  id: undefined,
  title: '',
  description: '',
  data: {
    RENEW: {
      '3': 0,
      '6': 0,
    },
  },
  planIds: [],
  published: false,
};
