import DateFnsUtils from '@date-io/date-fns';
import { IconButton, TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Can, { Permissions } from '@root/components/Can';
import InputSelect from '@root/components/InputSelect';
import { ISageRolesList, sageRolesOptions, SAGE_ROLE_TYPE } from '@root/pages/SageRoles/types';
import { useSageRoleRow } from '@root/pages/SageRoles/use-editable-row';
import { ChangeEvent, FC } from 'react';
import DeleteModalButton from '../../components/DeleteModalButton';

interface IProps {
  item: ISageRolesList;
  fetchSageRolesList: () => void;
  handleDelete: (name: string) => void;
}

export const SageRoleRow: FC<IProps> = ({ item, handleDelete, fetchSageRolesList }) => {
  const dateFns = new DateFnsUtils();
  const { name, type, createdAt, updatedAt } = item;

  const createDate = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
  const updateDate = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

  const [{ editable, newType }, { setEditable, setValue, setNewType, onSave }] = useSageRoleRow(
    type as SAGE_ROLE_TYPE
  );

  return (
    <TableRow>
      <TableCell align="left">{name}</TableCell>
      <TableCell>
        {editable ? (
          <InputSelect
            size="small"
            value={newType}
            options={sageRolesOptions}
            variant="outlined"
            name="type"
            onChange={(e) => setValue(e as ChangeEvent<HTMLInputElement>)}
          />
        ) : (
          type
        )}
      </TableCell>
      <TableCell>{createDate}</TableCell>
      <TableCell>{updateDate}</TableCell>
      <TableCell align="left">
        <Can perform={Permissions.updateSageRole}>
          {editable ? (
            <>
              <IconButton
                aria-label="edit"
                title="Cancel"
                size="small"
                onClick={() => {
                  setEditable(!editable);
                  setNewType(newType);
                  // !item.id && deleteNewItem();
                }}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                type="button"
                aria-label="edit"
                title="Save"
                size="small"
                onClick={() => (onSave({ name, type: newType }), fetchSageRolesList())}
              >
                <CheckIcon />
              </IconButton>
            </>
          ) : (
            <IconButton aria-label="edit" title="Edit" onClick={() => setEditable(!editable)}>
              <EditIcon />
            </IconButton>
          )}
        </Can>
      </TableCell>

      <TableCell align="left">
        <Can perform={Permissions.deleteSageRole}>
          <DeleteModalButton name={name} entity="Sage Role" onDelete={() => handleDelete(name)} />
        </Can>
      </TableCell>
    </TableRow>
  );
};
