import { Button, FormControlLabel, Grid, Paper, Switch } from '@material-ui/core';
import { FormikProps, useFormik } from 'formik';
import React, { useEffect } from 'react';
import agreementsSettingsRequests, {
  IAgreementsSettings,
} from '../../services/api/requests/agreements-settings';
import setNotification from '../../utils/notifications';
import { useStandardTableStyles } from '../DefaultTable';
import Title from '../Title';

interface IValues extends Omit<IAgreementsSettings, 'enabled'> {
  enabled: boolean;
}

const AgreementsSettings = () => {
  const classes = useStandardTableStyles();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [agreementsSettings, setAgreementsSettings] = React.useState<IAgreementsSettings | null>(
    null
  );

  const fetchAgreementsSettings = async () => {
    try {
      const response = await agreementsSettingsRequests.readAgreementsSettings();

      setLoading(false);
      setAgreementsSettings(response.data.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const createAgreementsSettings = async (values: IValues) => {
    try {
      const response = await agreementsSettingsRequests.createAgreementsSettings(values);

      setAgreementsSettings(response.data.data);
      setNotification('success', {
        message: 'Successfully changed',
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const editAgreementsSettings = async (values: IValues) => {
    try {
      await agreementsSettingsRequests.editAgreementsSettings(values);

      setNotification('success', {
        message: 'Successfully changed',
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const initialValues = {
    enabled: false,
  };

  const fkAgreementsSettings: FormikProps<IValues> = useFormik({
    initialValues: !!agreementsSettings
      ? {
          enabled: agreementsSettings.enabled,
        }
      : initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const createData = {
        enabled: values.enabled,
      };

      !!agreementsSettings ? editAgreementsSettings(values) : createAgreementsSettings(createData);
    },
  });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    fetchAgreementsSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <form>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Agreements Settings: </Title>

            <Grid item xs={2} style={{ marginLeft: 'auto' }}>
              <FormControlLabel
                labelPlacement="start"
                label="Enabled: "
                control={
                  <Switch
                    id={'enabled'}
                    checked={fkAgreementsSettings.values.enabled}
                    onChange={fkAgreementsSettings.handleChange}
                    name="enabled"
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => fkAgreementsSettings.handleSubmit()}
              >
                Save
              </Button>
            </Grid>
          </div>
        </Paper>
      </form>
    </Grid>
  );
};

export default AgreementsSettings;
