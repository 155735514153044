import { getNameWithRecurringType } from '@root/components/CreateEditProduct/helpers';
import { createInput } from '@root/components/FormBase';
import { initialOptions } from '@root/pages/CreateEditSwitchSubscriptionRule/static-data';
import { IValues } from '@root/pages/CreateEditSwitchSubscriptionRule/types';
import { IOption } from '@root/utils/commonTypes';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editSwitchSubscriptionRulesData,
  fetchMembershipLevels,
} from '../../store/entities/actions';
import {
  selectMembershipLevels,
  selectPlansAsObjects,
  selectProducts,
  selectSwitchSubscriptionRulesForEditing,
} from '../../store/entities/selectors';
import { useModuleFormik } from './use-formik';

export const useForm = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const planData = useSelector(selectPlansAsObjects);
  const selectedSwitchSubscriptionRule = useSelector(selectSwitchSubscriptionRulesForEditing);
  const [splitOption, setSplitOption] = useState<boolean>(
    !!selectedSwitchSubscriptionRule?.splitSKU
  );

  const fk = useModuleFormik(selectedSwitchSubscriptionRule, splitOption);
  const membershipLevels = useSelector(selectMembershipLevels);
  const [productId, setProductId] = React.useState<string | number>('');
  const [membershipLevelsOptions, setMembershipLevelsOptions] =
    React.useState<IOption[]>(initialOptions);
  const [productListOptions, setProductListOptions] = React.useState<IOption[]>(initialOptions);
  const [planIdFromOptions, setPlanIdFromOptions] = React.useState<IOption[]>(initialOptions);
  const [planIdToOptions, setPlanIdToOptions] = React.useState<IOption[]>(initialOptions);
  const actionText = selectedSwitchSubscriptionRule?.id ? 'Edit' : 'Create';

  const findProductId = () => {
    if (selectedSwitchSubscriptionRule) {
      const { planIdTo, planIdFrom } = selectedSwitchSubscriptionRule;
      products.forEach((product) => {
        const productHasPlanIdFrom = product.plans?.some((elem) => elem.id === planIdFrom);
        const productHasPlanIdTo = product.plans?.some((elem) => elem.id === planIdTo);
        if (productHasPlanIdTo && productHasPlanIdFrom) {
          setProductId(product.id);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(fetchMembershipLevels());
    return () => {
      dispatch(editSwitchSubscriptionRulesData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (products) {
      const options = products?.map((product) => ({
        label: product.name,
        value: product.id,
      }));
      setProductListOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, products]);

  useEffect(() => {
    if (!productId) {
      findProductId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSwitchSubscriptionRule?.id]);

  useEffect((): any => {
    if (!selectedSwitchSubscriptionRule) {
      if (fk?.values?.planIdTo && fk?.values?.planIdFrom) {
        const { planIdTo, planIdFrom } = fk?.values;
        const to = planIdTo ? planData[planIdTo as number] : null;
        const from = planIdFrom ? planData[planIdFrom as number] : null;
        if (to && from) {
          const difference = to?.setupPrice - from?.setupPrice;
          fk.setFieldValue('spendingsAdjustment', difference);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fk?.values?.planIdTo]);

  useEffect(() => {
    fk.setFieldValue('changeNested', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fk?.values?.planIdFrom]);

  useEffect((): any => {
    if (typeof productId === 'number' && products) {
      const options = products
        ?.find((product) => product.id === productId)
        ?.plans?.map((plan) => ({
          label: getNameWithRecurringType(plan.name, plan?.recurringType) || '',
          value: plan.id,
        }));

      const planToOptions = fk?.values?.planIdTo
        ? options?.filter((o) => o.value !== fk?.values?.planIdTo)
        : options;

      const planFromOptions = fk?.values?.planIdFrom
        ? options?.filter((o) => o.value !== fk?.values?.planIdFrom)
        : options;

      setPlanIdFromOptions(planToOptions || []);
      setPlanIdToOptions(planFromOptions || []);
    }
    if (!!membershipLevels?.length) {
      const membershipLevelsOptions = membershipLevels.map((m) => ({
        label: m.name,
        value: m.id,
      }));
      setMembershipLevelsOptions(membershipLevelsOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fk?.values?.planIdFrom, fk?.values?.planIdTo, productId, membershipLevels]);

  const createInputField = createInput<IValues>(fk);

  const isMainPlanSelected = useMemo(() => {
    return (
      planData[fk.values.planIdFrom as number] &&
      !planData[fk.values.planIdFrom as number]?.name.includes('(')
    );
  }, [planData, fk.values.planIdFrom]);

  const state = {
    actionText,
    fk,
    membershipLevelsOptions,
    productListOptions,
    planIdFromOptions,
    planIdToOptions,
    isMainPlanSelected,
    productId,
    splitOption,
    membershipLevels,
  };

  const handlers = { createInputField, setProductId, setSplitOption };

  return [state, handlers] as [typeof state, typeof handlers];
};
