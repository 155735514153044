import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  planIdFrom: Yup.number()
    .typeError('Please, specify a number')
    .min(0, `Please, select plan`)
    .required('Field is required'),
  planIdTo: Yup.number()
    .typeError('Please, specify a number')
    .min(0, `Please, select plan`)
    .required('Field is required'),
  sku: Yup.string().required(),
  membershipId: Yup.number()
    .typeError('Please, specify a number')
    .min(0, `Please, select Membership`),
  fsbPeriod: Yup.number(),
  cv: Yup.number(),
  qv: Yup.number(),
  scv: Yup.number(),
  sqv: Yup.number(),
  fsb: Yup.number(),
  uiOriginalPrice: Yup.number(),
  splitBaseValue: Yup.number().min(0, "Value can't be less than 0"),
  splitPeriod: Yup.number().min(1, "Value can't be less than 1"),
  splitFSB: Yup.number().when('splitValues', (splVal: number) => {
    if (splVal) {
      return Yup.number().test(
        'has-min-required-characters',
        'Should be the same as SplitValues or 0 and shouldn\'t be equal "1"',
        (fsb) => {
          const splitValue = Number(splVal);
          if (fsb === 1) {
            return false;
          } else if (!fsb) {
            return true;
          }
          return splitValue === fsb;
        }
      );
    }

    return Yup.number()
      .min(0, "Value can't be less than 0")
      .test('has-min-required-characters', 'Shouldn\'t be equal "1"', (val) => val !== 1);
  }),

  splitValues: Yup.number()
    .min(0, "Value can't be less than 0")
    .test('has-min-required-characters', 'Shouldn\'t be equal "1"', (val) => val !== 1),
});
