import DateFnsUtils from '@date-io/date-fns';
import { subMonths } from 'date-fns';
import { useEffect, useState } from 'react';
import getCustomersLogs, { ILog } from '../../services/api/requests/getCustomersLogs';

export class LogItemMapper {
  public static toDomain(item: any): ILog {
    return {
      id: item.id,
      action: item.action,
      tag: item.tag,
      level: item.level,
      ip: item.ip,
      url: item.url,
      os: item.os,
      browser: item.browser,
      device: item.device,
      oldData: item.old_data,
      newData: item.new_data,
      data: {
        ...JSON.parse(item.data),
        adminId: Number(JSON.parse(item.data).adminId),
      },
      shopType: item.shop_type,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      result: item.result,
      userId: item.user_id,
    };
  }
}

export const useAdminsLogsTable = () => {
  const dateFns = new DateFnsUtils();
  const logDataInputItems = ['instance'];

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [activeLogId, setActiveLogId] = useState<number>(0);
  const [isOpenPortal, setIsOpenPortal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [logsList, setList] = useState<ILog[]>([]);
  const [data, setData] = useState<{
    clientId?: number;
    username?: string;
    instance?: string;
  }>({});
  const [action, setAction] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [from, setFrom] = useState<string>(dateFns.format(subMonths(new Date(), 1), 'yyyy-MM-dd'));
  const [to, setTo] = useState<string>(dateFns.format(new Date(), 'yyyy-MM-dd'));
  const [logDataInputs, setLogDataInputs] = useState<{ id: number; component: React.ReactNode }[]>(
    []
  );
  const [activeLogDataInputsValues, setActiveLogDataInputsValues] = useState<string[]>([]);

  const fetchCustomersLogs = async () => {
    try {
      setLoading(true);
      const response = await getCustomersLogs({
        page,
        limit,
        action,
        data,
        from,
        to,
        userId,
        shopType: 'ADMIN',
      });
      setLoading(false);
      setList(response.data.data.rows.map((item) => LogItemMapper.toDomain(item)));
      // setMeta(response.data.meta);
    } catch (e) {
      console.warn(e.message);
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => setPage(newPage + 1);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onTypeChange = (type: string) => {
    setAction(type);
  };

  const setDataValues = (dataType: string, value: string | number) => {
    setActiveLogDataInputsValues((prevValue) => [...prevValue, dataType]);
    setData((prevValue) => ({ ...prevValue, [dataType]: value }));
  };

  const deleteInput = (id: number, key: string) => {
    setActiveLogDataInputsValues(activeLogDataInputsValues.filter((i) => i !== key));
    setLogDataInputs((prevValue) => prevValue.filter((v) => v.id !== id));
    if (data) {
      setData(
        Object.entries(data).reduce((acc, value) => {
          if (key !== value[0]) {
            return { ...acc, [value[0]]: value[1] };
          }
          return acc;
        }, {})
      );
    }
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchCustomersLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, action, data, from, to, userId]);

  const state = {
    logDataInputItems,
    data,
    from,
    to,
    action,
    userId,
    logDataInputs,
    activeLogDataInputsValues,
    logsList,
    loading,
    limit,
    page,
    activeLogId,
    isOpenPortal,
  };

  const handlers = {
    setUserId,
    setFrom,
    setTo,
    deleteInput,
    onTypeChange,
    setDataValues,
    setLogDataInputs,
    setActiveLogDataInputsValues,
    handleChangePage,
    handleChangeRowsPerPage,
    setActiveLogId,
    setIsOpenPortal,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
