import { Button, FormControlLabel, Grid, Switch } from '@material-ui/core';
import CountrySelect from '@root/components/CountrySelect';
import { useStyles } from '@root/components/CreateEditProduct/group-item-styles';
import { statusOptions } from '@root/components/CreateEditProduct/static-data';
import { IFilters } from '@root/components/CreateEditProduct/types';
import InputSelect from '@root/components/InputSelect';
import { IOption } from '@root/utils/commonTypes';
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';

interface IProps {
  filterState: {
    filters: IFilters;
    recurringOptions: IOption[];
    chooseCountries: string[];
  };
  filterHandlers: {
    handleFilterChange: (
      e: ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>
    ) => void;
    handleFilterApply: () => void;
    setChooseCountries: Dispatch<SetStateAction<string[]>>;
  };
}

export const PlansGroupFilters: FC<IProps> = ({ filterState, filterHandlers }) => {
  const { filters, recurringOptions, chooseCountries } = filterState;
  const { handleFilterChange, handleFilterApply, setChooseCountries } = filterHandlers;
  const classes = useStyles();

  return (
    <div className={classes.filterWrapper}>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12} sm={4}>
          <InputSelect
            id="Recurring Type"
            label="Recurring Type"
            name="recurringType"
            variant="outlined"
            fullWidth
            options={recurringOptions}
            value={filters.recurringType}
            onChange={handleFilterChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputSelect
            id="Status"
            label="Status"
            name="status"
            variant="outlined"
            fullWidth
            options={statusOptions}
            value={filters.status}
            onChange={handleFilterChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            label="Is Regional"
            control={
              <Switch
                color="primary"
                id="isRegional"
                name="isRegional"
                onChange={handleFilterChange}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CountrySelect
            id="countryCodes"
            value={chooseCountries}
            onChange={(_, value) => setChooseCountries(value as string[])}
            withGroups
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            className={classes.filterButton}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleFilterApply}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
