import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ICoupon } from '../../services/api/requests/createCoupon';
import { selectAvailablePlansOptions } from '../../store/entities/selectors';

interface IProps {
  formValues: ICoupon;
  onSubmit(): void;
  onClose(): void;
}

type Value = Partial<ICoupon>;
type Key = Partial<keyof ICoupon>;

const labels: Partial<Record<keyof ICoupon, string>> = {
  couponCode: 'Coupon code',
  title: 'Title',
  description: 'Description',
  userId: 'User Id',
  perUserLimit: 'Per user limit',
  stock: 'Stock',
  startAt: 'Start at',
  expiredAt: 'Expired at',
  elite: 'Is Elite',
  published: 'Published',
  allowCrossUsage: 'Can be used with another coupon',
  isForBundleSwitch: 'Allow for a bundle switch',
  planIds: 'Plans',
  discount: 'Discount',
  currency: 'Currency',
  forAction: 'For action',
  cv: 'CV',
  qv: 'QV',
  scv: 'SCV',
  sqv: 'SQV',
  fsb: 'FSB',
};

const dateFns = new DateFnsUtils();

const createValue = (key: Key, value: Value) => {
  if (typeof value === 'boolean') {
    return value ? 'yes' : 'no';
  } else if (['startAt', 'expiredAt'].includes(key)) {
    return dateFns.format(new Date(value as string), 'dd/MM/yyyy');
  } else if (Array.isArray(value)) {
    return value.join(',');
  }
  return value?.toString();
};

export default function CouponSummary({ formValues, onClose, onSubmit }: IProps) {
  const availablePlansOptions = useSelector(selectAvailablePlansOptions);

  const values = useMemo(() => {
    const array: Array<{ value: any; label: string }> = [];

    for (const key in labels) {
      if (formValues.currency !== 'USD' && ['cv', 'qv', 'scv', 'sqv', 'fsb'].includes(key)) {
        continue;
      }

      if (key === 'planIds') {
        const value = createValue(
          key as Key,
          formValues.planIds.map(
            (id) => availablePlansOptions?.find((item) => item.value === id)?.label
          ) as any
        );

        if (value) {
          array.push({ value, label: labels[key] as string });
        }
        continue;
      }
      // @ts-ignore
      const value = createValue(key as Key, formValues[key] as any);

      if (value) {
        // @ts-ignore
        array.push({ value, label: labels[key] });
      }
    }

    return array;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const noUserIdOrPerLimit = !formValues.userId || !formValues.perUserLimit;

  console.log('values', values);

  return (
    <div>
      <Box display={'flex'} flexDirection={'column'} padding={'0 20px'} alignItems={'center'}>
        <Typography variant="h6" component="h6">
          View your Coupon Details
        </Typography>

        {noUserIdOrPerLimit && (
          <Typography color={'error'} variant="h6" component="h6">
            "User Id or Per User Limit" parameter is not specified.
          </Typography>
        )}
      </Box>

      <Box style={{ overflow: 'auto' }}>
        {values.map((item) => {
          return (
            <ListItem divider key={item.label}>
              <Grid item xs={6} sm={3}>
                {item.label}
              </Grid>
              <div style={{ overflow: 'auto' }}>
                <ListItemText secondary={item.value} />
              </div>
            </ListItem>
          );
        })}
      </Box>

      <Box display="flex" gridGap={20} marginTop={2}>
        <Button variant="contained" color="default" fullWidth onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color={'primary'}
          fullWidth
          type="submit"
          onClick={() => {
            onSubmit();
            onClose();
          }}
        >
          Save
        </Button>
      </Box>
    </div>
  );
}
