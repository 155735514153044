import { Box, TextareaAutosize, Typography } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles, styled } from '@material-ui/core/styles';
import InputSelect from '@root/components/InputSelect';
import { useRefundOrderModal } from '@root/components/RefundOrderModal/use-refund-order-modal';
import * as React from 'react';
import Modal from '../Modal';
import { OrderStatuses } from "@root/components/RefundOrderModal/types";
import { useStyles } from "@root/components/RefundOrderModal/styles";

interface IProps {
  name?: string;
  title?: string;

  onOk(status: OrderStatuses | string, reason: string): void;

  status?: string;
  message?: string;
}

const RefundOrderModal: React.FC<IProps> = ({ onOk, message, status }) => {
  const classes = useStyles();
  const [
    { filteredStatusOptions, statusOrder, description },
    { setStatusOrder, setReason, clearFields },
  ] = useRefundOrderModal({ message, status });

  return (
    <Modal
      button={
        <Button className={classes.changeStatusButton} variant="outlined" size="small" color="primary">
          Change Status
        </Button>
      }
      renderContent={(onClose: () => void) => (
        <div>
          <Typography variant="h6" component="h2" style={{ marginBottom: '20px' }}>
            Change Status
          </Typography>

          <InputSelect
            options={filteredStatusOptions}
            id="statusOfOrder"
            label={'Status of order'}
            value={statusOrder}
            onChange={(e) => setStatusOrder(e.target.value as string)}
          />

          <TextareaAutosize
            style={{ resize: 'none', padding: '5px 10px', width: '100%', marginTop: '20px' }}
            aria-disabled={true}
            value={description}
            onChange={(e) => setReason(e.target.value)}
            minRows={3}
            placeholder="Please enter a reason"
            required
          />

          <Box className={classes.boxButton}>
            <Button variant="contained" color="default" onClick={() => (clearFields(), onClose())}>
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={statusOrder.length === 0}
              onClick={() => {
                onOk(statusOrder, description);
                clearFields();
                onClose();
              }}
            >
              Yes
            </Button>
          </Box>
        </div>
      )}
    />
  );
};

export default RefundOrderModal;
