import {
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import { RichEditorField } from '../../components/RichEditor/rich-editor-field';
import Title from '../../components/Title';
import faqItemRequests, {
  ICreateFaqItem,
  IEditFaqItemParams,
  planFaqItemRequests,
} from '../../services/api/requests/faq';
import { editFaqItemData } from '../../store/entities/actions';
import { selectFaqItemForEditing, selectPlans } from '../../store/entities/selectors';
import { getSearchParam, getTextFieldLength } from '../../utils/helpers';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      maxWidth: 250,
    },
  },
};

const validationSchema = Yup.object().shape({
  question: Yup.string().required().max(200).label('Question'),
  answer: Yup.string().required().max(2000).label('Answer'),
  priority: Yup.number().required('Please, specify a number').min(1).max(100).label('Priority'),
});

interface IValues extends ICreateFaqItem {}

const initialValues: IValues = {
  question: '',
  answer: '',
  priority: 1,
  planIds: [],
};

const CreateEditFAQItem: React.FC<{}> = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const selectedFaqItem = useSelector(selectFaqItemForEditing);
  const plans = useSelector(selectPlans);
  const actionText = selectedFaqItem ? 'Edit' : 'Create';
  const isPlanFAQ = Number(getSearchParam('isPlanFAQ'));
  const FAQApi = isPlanFAQ ? planFaqItemRequests : faqItemRequests;

  const availablePlansOptions = useMemo(() => {
    return plans.map((plan) => ({
      value: plan.id,
      label: `${plan.productName} ${plan.name || ''}`,
    }));
  }, [plans]);

  console.log('isPlanFAQ', isPlanFAQ);

  useEffect((): any => {
    return () => dispatch(editFaqItemData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<IValues> = useFormik({
    initialValues: selectedFaqItem
      ? {
          question: selectedFaqItem.question,
          answer: selectedFaqItem.answer,
          priority: selectedFaqItem.priority,
          planIds: selectedFaqItem.planIds,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values: IValues) => {
      const createData: ICreateFaqItem = {
        question: values.question,
        answer: values.answer,
        priority: values.priority,
        planIds: values.planIds?.length ? values.planIds : undefined,
      };

      if (selectedFaqItem) {
        const editData: IEditFaqItemParams = {
          ...createData,
          id: selectedFaqItem.id,
        };

        FAQApi.editFaqItem(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(`${pageLinks.faqList}?isPlanFAQ=${isPlanFAQ}`));
          }
        });
        return;
      }

      FAQApi.createFaqItem(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(`${pageLinks.faqList}?isPlanFAQ=${isPlanFAQ}`));
        }
      });
    },
  });

  const createInputField = createInput<IValues>(fk);

  const answerLength = getTextFieldLength(fk?.values?.answer);

  return (
    <Grid item xs={12}>
      <BackButton
        name={'Back'}
        link={`${pageLinks.faqList}?isPlanFAQ=${isPlanFAQ}`}
        margin={'0 0 10px 0'}
      />
      <Paper className={classes.paper}>
        <Title>{`${actionText} FAQ`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('question', 'Question', 12)}
              <Grid item xs={12} md={12}>
                <RichEditorField
                  label={`Answer ${answerLength}`}
                  value={fk.values.answer}
                  setFieldValue={(val: string) => {
                    fk.setFieldValue('answer', val.replace(/"/g, "'"));
                  }}
                  error={fk.errors?.answer}
                />
              </Grid>
              {createInputField('priority', 'Priority', 12, 'number')}

              {!!isPlanFAQ && (
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Plans</InputLabel>
                    <Select
                      id="planIds"
                      label={'Plans'}
                      variant="outlined"
                      multiple
                      value={fk.values.planIds}
                      onChange={(e) => fk.setFieldValue('planIds', e.target.value)}
                      input={<Input />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {availablePlansOptions.map(({ value, label }) => (
                        <MenuItem key={label} value={value}>
                          <Checkbox
                            checked={fk.values?.planIds?.some(
                              (item) => item?.toString() === value.toString()
                            )}
                          />
                          <ListItemText primary={label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditFAQItem;
