export const headCells = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
    disableSort: true,
  },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name', disableSort: true },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  { id: 'url', numeric: false, disablePadding: false, label: 'PDF' },
  { id: 'siteUrl', numeric: false, disablePadding: false, label: 'OrderID', disableSort: true },
  {
    id: 'exigoUserId',
    numeric: false,
    disablePadding: false,
    label: 'Exigo Order ID',
    disableSort: true,
  }
];
