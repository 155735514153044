export enum TypeFilter {
  all = 'all',
  Multiple = 'Multiple',
  Renewal = 'Renewal',
  Subscription = 'Subscription',
  OneTime = 'One-time',
  Upgrade = 'Upgrade',
  AffiliateUpgrade = 'AffiliateUpgrade',
}

export enum StatusFilter {
  all = 'all',
  Pending = 'Pending',
  Paid = 'Paid',
  Delivered = 'Delivered',
  OnHold = 'On hold',
  Processing = 'Processing',
  Canceled = 'Canceled',
  Refunded = 'Refunded',
  WaitingForPayment = 'Waiting for payment',
  WaitingForApproval = 'Waiting for approval',
  BlockchainPending = 'Blockchain Pending',
  Error = 'Error',
  Expired = 'Expired',
  Denied = 'Denied',
  PartiallyPaid = 'Partially paid',
}

export enum SortOptions {
  CUSTOMER_ID = 'customerID',
  ORDER_ID = 'orderID',
  ORIGINAL_PAYMENT_METHOD = 'originalPaymentMethod',
  TRANSACTION_NUMBER = 'transactionNumber',
  ORIGINAL_PAYMENT_STATUS = 'originalPaymentStatus',
  ORIGINAL_PAYMENT_DATE = 'originalPaymentDate',
  ORIGINAL_PAYMENT_METHOD2 = 'originalPaymentMethod2',
  TRANSACTION_NUMBER2 = 'transactionNumber2',
  ORIGINAL_PAYMENT_STATUS2 = 'originalPaymentStatus2',
  ORIGINAL_PAYMENT_DATE2 = 'originalPaymentDate2',
  NEW_PAYMENT_METHOD = 'newPaymentMethod',
  NEW_PAYMENT_LINK = 'newPaymentLink',
}

export enum PaymentStatus {
	ERROR = 'Error',
	CANCELED = 'Canceled', //! === fail
  DENIED = 'Denied', //! === fail
  REFUNDED = 'Refunded', //! === fail/refunded
  EXPIRED = 'Expired',
}

export type SortDirection = 'asc' | 'desc';

export type OrderSortDirection = Uppercase<SortDirection>;
