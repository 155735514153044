import { TextareaAutosize } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { RichEditorField } from '../RichEditor/rich-editor-field';

interface IProps {
  onChange(a: string[]): void;
  bullets: string[] | [];
  asHtmlRichText?: boolean;
  title?: string;
}

interface ITextData {
  [key: number]: string;
}

const BulletDescriptions: React.FC<IProps> = ({
  bullets,
  onChange,
  title = 'Points',
  asHtmlRichText = false,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [editableIndex, setEditableIndex] = React.useState<number | null>(null);
  const [textData, setTextData] = useState<ITextData>({});

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDelete = (index: number) => {
    const newBullets = [...(bullets as [])];
    newBullets.splice(index, 1);
    onChange(newBullets);
  };

  const handleEdit = (index: number) => {
    setEditableIndex(index);
    setTextData((prev: ITextData) => ({ ...prev, [index]: bullets[index] }));
  };

  const handleSave = (index: number) => {
    const newValue = textData[index];
    const newBullets = bullets ? [...bullets] : [];
    newBullets[index] = newValue;
    setEditableIndex(null);
    setTextData({});
    setExpanded(false);
    onChange(newBullets);
  };

  const renderBullet = (item: string) => {
    const htmlTag = /<\/?[a-z][\s\S]*>/i;
    if (htmlTag.test(item)) {
      return <Typography dangerouslySetInnerHTML={{ __html: item }} />;
    }

    return <Typography>{item}</Typography>;
  };

  return (
    <div>
      <Typography>{title} </Typography>
      {(bullets as Array<string>).map((item: string, index: number) => (
        <Accordion
          key={index}
          square
          expanded={expanded === 'panel' + index}
          onChange={handleChange('panel' + index)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            {renderBullet(item)}
          </AccordionSummary>

          <AccordionDetails>
            {editableIndex === index &&
              (asHtmlRichText ? (
                <RichEditorField
                  value={textData[index]}
                  setFieldValue={(val) =>
                    setTextData((prev) => ({ ...prev, [index]: val.replace(/"/g, "'") }))
                  }
                />
              ) : (
                <TextareaAutosize
                  style={{ resize: 'none', padding: '5px 10px' }}
                  aria-disabled={true}
                  defaultValue={item}
                  value={textData[index]}
                  onChange={(e) => setTextData((prev) => ({ ...prev, [index]: e.target.value }))}
                  rowsMin={3}
                  disabled={editableIndex !== index}
                />
              ))}

            <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
              <IconButton color="secondary" aria-label="delete" onClick={() => handleDelete(index)}>
                <DeleteIcon />
              </IconButton>

              {editableIndex === index ? (
                <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  onClick={() => handleSave(index)}
                  disabled={!textData[index]?.length}
                >
                  <CheckIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  aria-label="add an alarm"
                  onClick={() => handleEdit(index)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      <Accordion
        square
        expanded={expanded === 'panel_new_item'}
        onChange={handleChange('panel_new_item')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Box display="flex" alignItems={'center'}>
            <AddIcon /> Add New Item
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          {asHtmlRichText ? (
            <RichEditorField
              value={''}
              setFieldValue={(val) =>
                setTextData((prev) => ({ [bullets?.length]: val.replace(/"/g, "'") }))
              }
            />
          ) : (
            <TextareaAutosize
              style={{ resize: 'none', padding: '5px 10px' }}
              aria-disabled={true}
              defaultValue={''}
              value={textData[bullets?.length] || ''}
              onChange={(e) =>
                setTextData((prev) => ({ ...prev, [bullets?.length]: e.target.value }))
              }
              rowsMin={3}
            />
          )}

          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <IconButton
              color="primary"
              title="save"
              aria-label="add to shopping cart"
              disabled={!textData[bullets?.length]}
              onClick={() => handleSave(bullets?.length)}
            >
              <CheckIcon />
            </IconButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BulletDescriptions;

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);
