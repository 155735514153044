import { Grid, makeStyles, Button } from '@material-ui/core';
import { FilterToggle } from '@root/components/FilterToggle';
import InputSelect from '@root/components/InputSelect';
import { FC, useState } from 'react';

const statusOptions = [
	{ value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const useStyles = makeStyles(() => ({
  filterWrapper: {
    padding: 20,
  },
}));

export const PaymentMethodListFilter: FC<{
  filterData: string;
  handelFilterChange: (e: any) => void;
  handleFilterApply: () => void;
}> = ({ filterData, handelFilterChange, handleFilterApply }) => {
  const [toggleFilterPanel, setToggleFilterPanel] = useState(false);
  const classes = useStyles();

  return (
    <>
      <FilterToggle onFilterClick={() => setToggleFilterPanel(!toggleFilterPanel)} />
      {toggleFilterPanel && (
        <Grid className={classes.filterWrapper} container spacing={2} alignItems={'center'}>
          <Grid item xs={12} sm={4}>
            <InputSelect
              label="Status"
              name="status"
              variant="outlined"
              fullWidth
              options={statusOptions}
              value={filterData}
              onChange={handelFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" color="primary" fullWidth onClick={handleFilterApply}>
              Apply
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
