export const headCell = [
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created Date' },
  { id: 'redeemedAt', numeric: false, disablePadding: false, label: 'Redeemed Date' },
  { id: 'couponCode', numeric: false, disablePadding: false, label: 'Cart code' },
  { id: 'planPurchase', numeric: false, disablePadding: false, label: 'Plan purchased' },
  { id: 'purchaseType', numeric: false, disablePadding: false, label: 'Purchase type' },
  { id: 'customerID', numeric: false, disablePadding: false, label: 'Customer ID' },
  { id: 'exigoOrderId', numeric: false, disablePadding: false, label: 'Exigo OrderID' },
];
