export const headCells = [
  {
    id: 'paymentMethod',
    numeric: false,
    disablePadding: false,
    label: 'Original payment method',
  },
  {
    id: 'rebillyTransactionId',
    numeric: false,
    disablePadding: false,
    label: 'Original Payment TRN Number',
  },
  {
    id: 'rebillyGatewayAccountId',
    numeric: false,
    disablePadding: false,
    label: 'Original Gateway Id',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Original payment status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Original payment date',
  },
];

export const editFields = [
  {
    id: 'currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency',
  },
  {
    id: 'newPaymentMethod',
    numeric: false,
    disablePadding: false,
    label: 'New Payment Method',
  },
  {
    id: 'gatewayAccountId',
    numeric: false,
    disablePadding: false,
    label: 'New Gateway ID',
  },
  {
    id: 'newPaymentLink',
    numeric: false,
    disablePadding: false,
    label: 'New payment link',
  },
];

export const currenciesOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
]
