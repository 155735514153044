import { IProduct } from '@root/services/api/requests/getProducts';
import * as Yup from 'yup';

export const createValidationSchema = (products: IProduct[] | null) =>
  Yup.object().shape({
    name: Yup.string()
      .required('name is required')
      .max(40, '40 characters max')
      .trim()
      .test('name-check', 'The product with this name already exist', (value) => {
        let isNotSameName: boolean = true;
        if (products) isNotSameName = !products.some((product) => product.name === value);

        return isNotSameName;
      }),
    rebillyProductId: Yup.string()
      .required('Rebilly Product Id is required')
      .matches(/^[a-zA-Z0-9-_]+([-_]?)+[a-zA-Z0-9]+$/, 'These characters are not supported'),
    description: Yup.string().max(200, '200 characters max '),
  });
