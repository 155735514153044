import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from './../types';

export interface IAgreementsSettings {
  enabled: boolean;
}

const readAgreementsSettings = () => {
  return apiClient
    .get<IResponseAxios<IAgreementsSettings>>(ApiUrls.readAgreementsSettings)
    .then((response) => response);
};

const createAgreementsSettings = (data: IAgreementsSettings) => {
  return apiClient
    .post<IResponseAxios>(ApiUrls.createAgreementsSetting, data)
    .then((response) => response);
};

const editAgreementsSettings = (data: IAgreementsSettings) => {
  return apiClient
    .put<IAgreementsSettings>(ApiUrls.editAgreementsSetting, data)
    .then((response) => response.data);
};

const deleteAgreementsSettings = () =>
  apiClient.delete(ApiUrls.deleteAgreementsSetting).then((response) => response);

const agreementsSettingsRequests = {
  readAgreementsSettings,
  createAgreementsSettings,
  editAgreementsSettings,
  deleteAgreementsSettings,
};

export default agreementsSettingsRequests;
