import cloneDeep from 'lodash/cloneDeep';

const isDeletable = (value: unknown) => {
  return (
    (value instanceof Object && Object.keys(value).length === 0) ||
    value === null ||
    value === undefined ||
    value === ''
  );
};

export const sanitizeRefObject = (object: object) => {
  if (object instanceof Array) {
    for (let index = 0; index < object.length; index++) {
      if (isDeletable(object[index])) {
        object.splice(index, 1);
      } else if (object[index] instanceof Object) {
        sanitizeRefObject(object[index]);
      }
    }
  } else {
    for (let key in object) {
      // @ts-ignore
      if (isDeletable(object[key])) {
        // @ts-ignore
        delete object[key];
        // @ts-ignore
      } else if (object[key] instanceof Object) {
        // @ts-ignore
        sanitizeRefObject(object[key]);
      }
    }
  }
};

export const sanitizeObject = (input: object) => {
  const object = cloneDeep(input);

  sanitizeRefObject(object);
  sanitizeRefObject(object);

  return object;
};
