import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	exchangeRate: {
		margin: '10px',
	},

	exchangeRateWrapper: {
		padding: '10px 20px',
		display: 'flex',
		flexDirection: 'column',
	},

	changeableFieldWrapper: {
		width: '100%',
	},

	exchangeRateForm: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		marginTop: '10px',
	},

	input: {
		width: '50%',
		margin: '10px 25px',
	},

	inputRange: {
		width: '20%',
		margin: '10px 25px'
	}
}))
