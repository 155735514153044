import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import * as React from 'react';

export const useStandardFormStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formContainer: {
    maxWidth: 600,
  },
  formControlSelect: {
    width: '100%',
  },
  textWrap: {
    whiteSpace: 'normal',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  checkbox: {
    display: 'flex',
  },
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },

  purchaseRuleSetsContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '10px',
  },

  purchaseRuleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '10px 10px 0 10px',
    justifyContent: 'space-between',
  },

  buttonContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },

  purchaseRuleControlContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    margin: '8px',
  },

  purchaseRuleControlsWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '10px',
  },

  purchaseRulePlanContainer: {
    marginTop: '8px',
    padding: '8px 20px',
  },

  bordered: {
    border: '1px solid',
    borderRadius: '7px',
    margin: '10px 0',
  },

  flexEnd: {
    width: '100$',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  textSizeSmall: {
    textTransform: 'none',
  },

  rulesDescription: {
    marginTop: '10px',
  },
  noMargin: {
    margin: 0,
  },
}));

export type ICreateInputFields<T> = (
  id: keyof T,
  label: string,
  md?: 4 | 6 | 12,
  type?: string,
  isRequired?: boolean,
  customChangeFn?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  disabled?: boolean,
  prefix?: string,
  value?: string | number | boolean | null
) => React.ReactNode;

export const createInput: <T>(fk: FormikProps<T>) => ICreateInputFields<T> =
  (fk) =>
  (
    id,
    label,
    md = 6,
    type = 'text',
    isRequired = false,
    customChangeFn,
    disabled,
    prefix,
    value
  ) => (
    <Grid item xs={12} md={md}>
      <TextField
        autoComplete={String(id)}
        name={String(id)}
        variant="outlined"
        fullWidth
        required={isRequired}
        id={String(id)}
        type={type}
        label={label}
        disabled={disabled}
        onBlur={fk.handleBlur}
        error={!!(get(fk.errors, id) && get(fk.touched, id))}
        helperText={get(fk.touched, id) && (get(fk.errors, id) as string)}
        value={value ? value : get(fk.values, id)}
        onChange={customChangeFn || fk.handleChange}
        InputProps={{
          startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : '',
        }}
      />
    </Grid>
  );
