import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { CustomTableHead, useStandardTableStyles } from '@root/components/DefaultTable';
import { FilterToggle } from '@root/components/FilterToggle';
import InputSearch from '@root/components/InputSearch';
import TableSkeleton from '@root/components/TableSkeleton';
import Title from '@root/components/Title';
import { GiftCardsReportsFilters } from '@root/pages/GiftCardsReport/gift-card-reports-filters';
import { headCell } from '@root/pages/GiftCardsReport/static-data';
import { useGiftCardsReport } from '@root/pages/GiftCardsReport/use-gift-cards-report';
import { useStyle } from "@root/pages/GiftCardsReport/use-style";
import { FC } from 'react';

export const GiftCardsReport: FC = () => {
  const classes = {...useStandardTableStyles(), ...useStyle()};
  const dateFns = new DateFnsUtils();
  const [
    { giftCardsReportsList, sortDirection, meta, loading, filtersState, isFiltersOpen },
    {
      handleSearchSubmit,
      handleChangePage,
      handleChangeRowsPerPage,
      filtersHandlers,
      setIsFiltersOpen,
    },
  ] = useGiftCardsReport();

  return (
    <Grid className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Gift cards reports</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={`${classes.paper} ${classes.paperSearchWrapper}`}>
        <InputSearch isLarge onSubmit={handleSearchSubmit} />
        <FilterToggle onFilterClick={() => setIsFiltersOpen(!isFiltersOpen)} />
        {isFiltersOpen && (
          <GiftCardsReportsFilters
            filtersState={filtersState}
            filtersHandlers={filtersHandlers}
          />
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CustomTableHead
              // order={sortDirection}
              classes={classes}
              headCells={headCell}
            />
            <TableBody>
              {loading && <TableSkeleton />}
              {!loading &&
                giftCardsReportsList?.map((card) => {
                  const createdDate = dateFns.format(new Date(card.createdAt), 'dd/MM/yyyy hh:mm');
                  const redeemedDate = card.redeemedAt
                    ? dateFns.format(new Date(card.redeemedAt), 'dd/MM/yyyy hh:mm')
                    : '-';

                  return (
                    <TableRow key={card.id}>
                      <TableCell align="left">{card.status}</TableCell>
                      <TableCell align="left">{card.amount}</TableCell>
                      <TableCell align="left">{createdDate}</TableCell>
                      <TableCell align="left">{redeemedDate}</TableCell>
                      <TableCell align="left">{card.couponCode}</TableCell>
                      <TableCell align="left">{card.planId}</TableCell>
                      <TableCell align="left">{card.orderType}</TableCell>
                      <TableCell align="left">{card.usedBy}</TableCell>
                      <TableCell align="left">{card.exigoOrderId}</TableCell>
                    </TableRow>
                  );
                })}
              {!loading && giftCardsReportsList?.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    There are no gift cards
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
};
