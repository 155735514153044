export const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', disableSort: true },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Group Name',
    disableSort: true,
  },
  {
    id: 'plans',
    numeric: false,
    disablePadding: false,
    label: 'Plans',
    disableSort: true,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    disableSort: true,
  },
];

export const emptyItem = {
  name: '',
  planGroupIds: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 400,
    },
  },
};
