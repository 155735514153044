import { Box, Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Title from '@root/components/Title';
import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStandardFormStyles } from '../../components/FormBase';
import createPurchaseRule from '../../services/api/requests/createPurchaseRule';
import deletePurchaseRule from '../../services/api/requests/deletePurchaseRule';
import deleteRequiredPlan from '../../services/api/requests/deleteRequiredPlan';
import editRequiredPlan from '../../services/api/requests/editRequiredPlan';
import getPurchaseRules, {
  INormalizedPurchaseRule,
  INormalizedPurchaseRuleSet,
} from '../../services/api/requests/getPurchaseRules';
import { setPurchaseRules } from '../../store/entities/actions';
import { selectPurchaseRules } from '../../store/entities/selectors';
import { SELECT_OPTION, SUBSCRIPTION_STATUS } from '../../utils/commonTypes';
import DeleteModalButton from '../DeleteModalButton';
import EditStatusButton from '../EditStatusButton';
import PurchaseRulesControl from '../PurchaseRulesControl';

export const shouldHaveStatusOptions = [
  SELECT_OPTION.ANY,
  SUBSCRIPTION_STATUS.ACTIVE,
  SUBSCRIPTION_STATUS.EXPIRED,
];

const PlanPurchaseRules: FC<{ planId: number }> = ({ planId }) => {
  const dispatch = useDispatch();
  const classes = useStandardFormStyles();
  const purchaseRules: INormalizedPurchaseRuleSet[] | [] = useSelector(selectPurchaseRules);

  useEffect(() => {
    fetchPurchaseRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPurchaseRules = async () => {
    try {
      const response = await getPurchaseRules(planId);
      dispatch(setPurchaseRules(response));
    } catch (e) {
      console.warn('fetch purchase rules error', e);
    }
  };

  const handleCreatePurchaseRule = async () => {
    try {
      await createPurchaseRule(planId);
      fetchPurchaseRules();
    } catch (e) {
      console.warn('create purchase rule error', e);
    }
  };

  const handleDeletePurchaseRule = async (id: number) => {
    try {
      await deletePurchaseRule(id);
      fetchPurchaseRules();
    } catch (e) {
      console.warn('delete purchase rule error', e);
    }
  };

  const handleEditRequiredPlan = async (
    id: number,
    shouldHaveStatus: SUBSCRIPTION_STATUS | null
  ) => {
    try {
      await editRequiredPlan(id, shouldHaveStatus);
      fetchPurchaseRules();
    } catch (e) {
      console.warn('create purchase rule error', e);
    }
  };

  const handleDeleteRequiredPlan = async (id: number) => {
    try {
      await deleteRequiredPlan(id);
      fetchPurchaseRules();
    } catch (e) {
      console.warn('delete required plan error', e);
    }
  };

  return (
    <Grid container xs={12}>
      <Box margin={'20px 0 0 0'} width="100%">
        <Title>Purchase Rules </Title>
      </Box>

      <Box className={classes.purchaseRuleSetsContainer}>
        {purchaseRules &&
          !isEmpty(purchaseRules) &&
          (purchaseRules as Array<INormalizedPurchaseRuleSet>).map(
            ({ id: ruleSetId, purchaseRules }) => {
              return (
                <Grid container spacing={2} className={classes.bordered}>
                  <Box className={classes.purchaseRuleContainer}>
                    <Typography>Purchase Rule</Typography>

                    <DeleteModalButton
                      name="Purchase Rule Set"
                      title="Delete Purchase Rule Set"
                      entity="Plan"
                      onDelete={() => handleDeletePurchaseRule(ruleSetId)}
                    />
                  </Box>
                  <Grid item xs={12}>
                    <Box justifyContent={'space-between'}>
                      {purchaseRules &&
                        !isEmpty(purchaseRules) &&
                        (purchaseRules as Array<INormalizedPurchaseRule>).map(
                          ({ id, requiredPlanName, shouldHaveStatus, requiredServiceId }) => {
                            const isService = !!requiredServiceId;

                            return (
                              <Button
                                className={classes.purchaseRulePlanContainer}
                                fullWidth
                                color="primary"
                                variant={'outlined'}
                              >
                                <Box
                                  className={`${classes.buttonContainer} ${classes.spaceBetween}`}
                                >
                                  <Grid container item xs={12} spacing={3}>
                                    <div
                                      style={{ padding: '12px 0' }}
                                    >{`${isService ? 'Service - ' : ''}${requiredPlanName}`}</div>
                                  </Grid>

                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={3}
                                    className={`${classes.buttonContainer} ${classes.flexEnd}`}
                                  >
                                    <div>
                                      {!isService && (
                                        <EditStatusButton
                                          status={shouldHaveStatus || SELECT_OPTION.ANY}
                                          options={
                                            shouldHaveStatusOptions as SUBSCRIPTION_STATUS &
                                              SELECT_OPTION
                                          }
                                          onChange={(
                                            status: SUBSCRIPTION_STATUS & SELECT_OPTION
                                          ) => {
                                            handleEditRequiredPlan(
                                              id,
                                              status === SELECT_OPTION.ANY ? null : status
                                            );
                                          }}
                                        />
                                      )}
                                      <DeleteModalButton
                                        name={requiredPlanName}
                                        entity={'Plan'}
                                        onDelete={() => handleDeleteRequiredPlan(id)}
                                      />
                                    </div>
                                  </Grid>
                                </Box>
                              </Button>
                            );
                          }
                        )}
                    </Box>
                  </Grid>
                  <PurchaseRulesControl
                    purchaseRuleId={ruleSetId}
                    purchaseRules={purchaseRules}
                    fetchPurchaseRules={fetchPurchaseRules}
                  />
                </Grid>
              );
            }
          )}

        <Button
          type="button"
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreatePurchaseRule}
        >
          create purchase rule
        </Button>
      </Box>
    </Grid>
  );
};

export default PlanPurchaseRules;
