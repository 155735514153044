import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  filterWrapper: {
    maxWidth: '100%',
  },

  filterButton: {
    height: 45,
    fontSize: 16,
  },

  multiselectActionsBlock: {
    position: 'fixed',
    top: 100,
    right: 20,
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'row',
    width: '20%',
    height: 'auto',
    padding: 10,
  },

  boxButtonPadding: {
    padding: 15,
  },

  multiselectActionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },

  selectedItemsText: {
    width: '50%',
  },

  editButton: {
    height: 30,
  },

  changeField: {
    width: '70px',
    paddingBottom: '10px',
  },
}));
