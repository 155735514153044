import { Button, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { useStandardTableStyles } from '@root/components/DefaultTable';
import RecurringTypeSelect from '@root/components/RecurringTypeSelect';
import { useStyles } from '@root/components/ServicesList/styles';
import { IServiceFilters } from '@root/pages/Services/types';
import { FC } from 'react';
import InputSearch from '../InputSearch';

interface IProps {
  toggleFilters: IServiceFilters;
  recurringTypes: string[];
  setSelectedRecurringTypes: (types: string[]) => void;
  setPage: (page: number) => void;
  setSearch: (search: string) => void;
  handleFilters: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitFilters: () => void;
}

export const ServiceListFilters: FC<IProps> = (props) => {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const {
    toggleFilters,
    recurringTypes,
    setSelectedRecurringTypes,
    setPage,
    setSearch,
    handleFilters,
    submitFilters,
  } = props;

  return (
    <Grid container spacing={3} className={classes.filterContainer}>
      <Grid item xs={12} sm={3} alignContent="center">
        <FormControlLabel
          label="Requires Agreement"
          control={
            <Switch
              id="isAgreementRequired"
              name="isAgreementRequired"
              color="primary"
              checked={toggleFilters.isAgreementRequired}
              onChange={handleFilters}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={3} alignContent="center" justifyContent="center">
        <FormControlLabel
          label="Active"
          control={
            <Switch
              id="isActive"
              name="isActive"
              color="primary"
              checked={toggleFilters.isActive}
              onChange={handleFilters}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={3} alignContent="center" justifyContent="center">
        <FormControlLabel
          label="Technical"
          control={
            <Switch
              id="isTechnical"
              name="isTechnical"
              color="primary"
              checked={toggleFilters.isTechnical}
              onChange={handleFilters}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={3} alignContent="center" justifyContent="center">
        <FormControlLabel
          label="One-time"
          control={
            <Switch
              id="isOneTime"
              name="isOneTime"
              color="primary"
              checked={toggleFilters.isOneTime}
              onChange={handleFilters}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <RecurringTypeSelect
          value={recurringTypes}
          onChange={setSelectedRecurringTypes}
          allowEmpty
        />
      </Grid>
      <Grid item xs={12} sm={3} alignContent="center">
        <Button variant="contained" color="primary" fullWidth size="large" onClick={submitFilters}>
          Apply
        </Button>
      </Grid>
      <Grid item xs={12} sm={4} alignContent='center'>
        <InputSearch
          onSubmit={(val) => {
            setPage(1);
            setSearch(val);
          }}
        />
      </Grid>
    </Grid>
  );
};
