import { ICreateExchangeRate } from "@root/services/api/requests/exchangeRateList";

export enum PointesExchangeCurrencies {
  BE_POINTS = 'be-points',
  SHOP_POINTS = 'shop-points',
}

export interface IExchangeRate extends ICreateExchangeRate {
  id: number;
}

export enum ExchangeRateLabels {
  'be-points' = 'Be points',
  'shop-points' = 'Shop points',
  rate = 'Rate'
}
