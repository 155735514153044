import { CssBaseline, Grid, Paper } from '@material-ui/core';
import 'date-fns';
import * as React from 'react';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import pageLinks from '../../utils/pageLinks';
import CreateProduct from './form';
import PlanGroups from './group-item';
import { ProductProvider } from './product-context';

const ProductDetails: React.FC<{}> = () => {
  const classes = useStandardFormStyles();
  return (
    <ProductProvider>
      <Grid item xs={12}>
        <BackButton name={'Back'} link={pageLinks.products} margin={'0 0 10px 0'} />
        <Paper className={classes.paper}>
          <CssBaseline />
          <CreateProduct />
          <PlanGroups />
        </Paper>
      </Grid>
    </ProductProvider>
  );
};

export default ProductDetails;
