import fileDownload from 'js-file-download';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

const request = (name: string): Promise<void> => {
  return apiClient
    .get<IResponseAxios>(ApiUrls.payoutBase + `/file/${name}`, {
      headers: {
        'Content-Disposition': 'attachment;filename=report.xls',
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then((response) => {
      fileDownload(response.data as unknown as ArrayBuffer, name);
    });
};

export default request;
