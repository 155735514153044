import { IDateState } from '@root/components/OrdersSearch/types';
import { SortDirection, StatusFilter, TypeFilter } from '@root/pages/Orders/types';
import { getOrders, IOrder } from '@root/services/api/requests/orders';
import { IMeta } from '@root/services/api/types';
import { selectPaymentMethodOptions } from '@root/store/entities/selectors';
import { IOption } from '@root/utils/commonTypes';
import { toIsoString } from '@root/utils/helpers';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useOrders = () => {
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [sortBy, setSortBy] = useState<string>('createdAt');
  const [sortDirection, setDirection] = useState<SortDirection>('desc');
  const [typeFilter, setTypeFilter] = useState<TypeFilter>(TypeFilter.all);
  const [filterPanel, togglePanel] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<StatusFilter>(StatusFilter.all);
  const [paymentMethodFilter, setPaymentMethodFilter] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [ordersList, setOrdersList] = useState<IOrder[]>([]);
  const [searchByProduct, setSearchByProduct] = useState<string>('');
  const [dateState, setDateState] = useState<IDateState>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });
  const [meta, setMeta] = useState<IMeta>({ limit: 10, page: 1, total: 0, totalPages: 0 });
  const paymentMethods: IOption[] = useSelector(selectPaymentMethodOptions);

  const fetchList = async () => {
    try {
      const response = await getOrders({
        page,
        limit: rowsPerPage,
        adminSortKey: sortBy,
        sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
        typeFilter,
        statusFilter,
        paymentMethodFilter,
        searchByUser: searchTerm,
        fromDate: dateState.startDate ? toIsoString(dateState.startDate) : 'all',
        toDate: dateState.endDate ? toIsoString(dateState.endDate) : 'all',
        searchByProduct,
      });

      if (response) {
        setLoading(false);
        setOrdersList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  const state = {
    page,
    rowsPerPage,
    sortBy,
    sortDirection,
    typeFilter,
    filterPanel,
    statusFilter,
    paymentMethodFilter,
    searchTerm,
    loading,
    ordersList,
    searchByProduct,
    dateState,
    meta,
    methodOptions: [
      { value: 'all', label: 'All' },
      ...paymentMethods.map((item) => ({ ...item, value: item.label })),
    ],
  };

  const handlers = {
    setPage,
    setRowsPerPage,
    setSortBy,
    setDirection,
    setTypeFilter,
    togglePanel,
    setStatusFilter,
    setPaymentMethodFilter,
    setSearchTerm,
    setLoading,
    setSearchByProduct,
    setDateState,
    setMeta,
    fetchList,
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchList();
  }, [page, sortBy, sortDirection, rowsPerPage, searchTerm, searchByProduct]);

  useEffect(() => {
    if (!dateState.endDate && !dateState.startDate) {
      fetchList();
    }
  }, [dateState.endDate, dateState.startDate]);

  return [state, handlers] as [typeof state, typeof handlers];
};
