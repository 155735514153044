import pickBy from 'lodash/pickBy';
import { RECURRING_TYPES } from '../enums/recurring-type';
import { ICreatePlan } from '../services/api/requests/createProductPlan';
import { IPlanResponse } from '../services/api/requests/editProductPlan';
import { PLAN_RECURRING_CHARGE_TYPE } from '../services/api/requests/getProductPlans';

const fieldsForTable = {
  productId: '',
  planId: '',
  name: '',
  titleDescription: '',
  currency: '',
  color: '#000',
  bgColor: '#fff',
  description: '',
  fullDescription: '',
  itemDescription: '',
  additionalInfo: '',
  rebillyPlanId: '',
  intervalUnit: 'day',
  intervalSize: '',
  sku: '',
  recurringSKU: '',
  retailExigoItem: '',
  discountExigoItem: '',
  questDollarsReward: '',
  priority: '',
  setupPrice: '',
  retailPrice: '',
  recurringPrice: '',
  recurringRetailPrice: '',
  retailCV: '',
  retailFSB: '',
  retailQV: '',
  retailRecurringCV: '',
  retailRecurringFSB: '',
  retailRecurringQV: '',
  retailRecurringSCV: '',
  retailRecurringSQV: '',
  retailSCV: '',
  retailSQV: '',
  cv: '',
  qv: '',
  scv: '',
  sqv: '',
  fsb: '',
  rcv: '',
  rqv: '',
  rscv: '',
  matrixBV: '',
  rsqv: '',
  rfsb: '',
  oneDayRecurringSKU: '',
  noExigo: false,
  autopayable: false,
  membershipDiscountAllowed: false,
  rewardingDays: 0,
  fsbEnabled: false,
  isAddon: false,
  bullets: [],
  countryCodes: [],
  regionalDatasets: [],
  serviceIDs: [],
  isRecurring: false,
  recurringChargeType: PLAN_RECURRING_CHARGE_TYPE.DEFAULT,
  hasRetail: false,
  retailOnly: false,
  icon: '',
  imageUrl: '',
  gatewayMethodIds: [],
  status: 'active',
  primaryServiceId: '',
  tLevelJoin: false,
  isSage: false,
  requireVerification: true,
  splitValues: '',
  splitFSB: '',
  splitSKU: '',
  splitBaseValue: '',
  splitPeriod: '',
  attachments: [],
  saleShopType: '',
  enrollerCashback: 0,
  customerCashback: 0,
  recurringType: RECURRING_TYPES.MONTHLY,
  uiPriority: 1,
  resignDate: null,
  addSageRoles: [],
  splitBaseRenewValue: 0,
  stripeCustomPlanId: null,
  exigoField1: null,
  trialPeriod: null,
  rmatrixBV: 0,
  uiOriginalPrice: 0,
  renewalOptions: [1],
  ghlTag: '',
};

export const normalizePlan = (plan: IPlanResponse): ICreatePlan => {
  const normalized: ICreatePlan = {} as ICreatePlan;

  //@ts-ignore
  const gatewayMethodIds = (plan?.gatewayMethods || []).map((item) => item.id);

  //@ts-ignore
  const recurringSKU = plan.recurringSku;

  const pointRewards = plan.pointRewards || {};

  Object.keys(fieldsForTable).forEach((fieldKey) => {
    if (plan[fieldKey as keyof IPlanResponse] !== undefined) {
      // @ts-ignore
      normalized[fieldKey] = plan[fieldKey as keyof IPlanResponse];
    }
  });

  return { ...(normalized || {}), gatewayMethodIds, recurringSKU, ...pointRewards };
};

export const clearEmptyFields = (plan: ICreatePlan): any => {
  return pickBy(plan, (val) => val !== null && val !== '');
};
