import { IValues } from './types';

export const initialValues: IValues = {
  name: '',
  email: '',
  phone: '',
  registrationNumber: '',
  isShown: false,
  isDefault: false,
  instanceIds: [],
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  twitter: '',
  instagram: '',
  facebook: '',
};
