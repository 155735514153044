import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { headCellsTransactions } from '../../pages/Installmentsorders/table-head';
import { IInstallmentOrder } from '../../services/api/requests/installmentsOrders';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import Modal from '../Modal';

interface IProps {
  status: string;
  options?: string[];
  onChange(values: Record<string, string>): void;
  title?: string;
  buttonText?: string;
  data: IInstallmentOrder;
}

const useStyles = makeStyles(() => ({
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },

  statusButton: {
    borderColor: (props: Pick<IProps, 'status'>) =>
      props.status === 'inactive' ? '#b82525' : '#4ae91e',
  },
}));

const renderStatus = (value: string | string[]) => {
  switch (true) {
    case Array.isArray(value): {
      return Array.isArray(value) ? value.join(', ') : value;
    }

    default: {
      return value;
    }
  }
};

const TransactionsDetailsModal: React.FC<IProps> = ({
  status,
  onChange,
  options = ['active', 'inactive'],
  title = 'Please select a status',
  buttonText,
  data,
}) => {
  const classes = useStyles({ status } as Pick<IProps, 'status'>);
  const tableClasses = useStandardTableStyles();

  const values = data.transactions.reduce((acc, val) => {
    // @ts-ignore
    acc[val.id] = val.status;
    return acc;
  }, {});

  const [transactionStatuses, setTransactionStatuses] =
    React.useState<Record<string, string>>(values);

  const handleClick = () => {
    onChange(transactionStatuses);
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setTransactionStatuses((data) => ({ ...data, [id]: e.target.value }));
  };

  return (
    <Modal
      button={
        <Button className={classes.statusButton} variant="outlined" endIcon={<EditIcon />}>
          {buttonText || renderStatus(status)}
        </Button>
      }
      renderContent={(onClose: any) => (
        <div>
          <h2>{title}</h2>

          <TableContainer>
            <Table
              className={tableClasses.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <CustomTableHead headCells={headCellsTransactions} classes={tableClasses} />
              <TableBody>
                {data.transactions.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align={'left'}>{item.id}</TableCell>
                      <TableCell align={'left'}>{item.paymentMethod}</TableCell>
                      <TableCell align={'left'}>{item.amount}</TableCell>
                      <TableCell align={'left'}>{item.amountFee}</TableCell>
                      <TableCell align={'left'}>{item.amountVat}</TableCell>
                      <TableCell align={'left'}>{item.currency}</TableCell>
                      <TableCell align="left">{item.status}</TableCell>
                      <TableCell align="left">
                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              value={transactionStatuses[item.id]}
                              onChange={(e) => handleChangeStatus(e, item.id)}
                            >
                              {options.map((it) => (
                                <FormControlLabel
                                  style={{ textTransform: 'capitalize' }}
                                  value={it}
                                  control={<Radio color="primary" />}
                                  label={it}
                                  disabled={item.status !== 'Waiting approval'}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box className={classes.boxButton}>
            <Button variant="contained" color="default" onClick={() => onClose()}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={
                !Object.values(transactionStatuses).some((item) =>
                  ['approve', 'deny'].includes(item)
                )
              }
              onClick={() => {
                handleClick();
                onClose();
              }}
            >
              Save
            </Button>
          </Box>
        </div>
      )}
    />
  );
};

export default TransactionsDetailsModal;
