import dateFnsUtils from '@date-io/date-fns';
import { SortDirection } from '@material-ui/core';
import { IDateState } from '@root/components/OrdersSearch/types';
import { IGiftCardsReport, IGiftCardsReportFilters } from '@root/pages/GiftCardsReport/types';
import { getGiftCardsReport } from '@root/services/api/requests/giftCardsReport';
import { IMeta } from '@root/services/api/types';
import { toIsoString } from '@root/utils/helpers';
import { AxiosError } from 'axios';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

export const useGiftCardsReport = () => {
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>({
    limit: 10,
    page: 1,
    total: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDirection, setDirection] = useState<SortDirection>('desc');
  const [giftCardsReportsList, setGiftCardsReportsList] = useState<IGiftCardsReport[] | null>(null);
  const [userId, setUserId] = useState<string>('');
  const [filtersFields, setFiltersFields] = useState<IGiftCardsReportFilters>({
    purchaseType: 'All',
    status: 'All',
    amount: {
      amountTo: null,
      amountFrom: null,
    },
  });
  const [redeemedDateState, setRedeemedDateState] = useState<IDateState>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });
  const dateFns = new dateFnsUtils();
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const fetchGiftCardsReport = useCallback(async () => {  
    try {
      const res = await getGiftCardsReport({
        page,
        limit: rowsPerPage,
        creatorId: userId,
        purchaseType: filtersFields.purchaseType,
        status: filtersFields.status,
        amountFrom: filtersFields.amount.amountFrom,
        amountTo: filtersFields.amount.amountTo,
        redeemedDateFrom: redeemedDateState.startDate
          ? toIsoString(redeemedDateState.startDate)
          : undefined,
        redeemedDateTo: redeemedDateState.endDate
          ? toIsoString(redeemedDateState.endDate)
          : undefined,
      });

      if (res) {
        setGiftCardsReportsList(res.data.data.items);
        setMeta(res.data.data.meta);
      }
    } catch (err) {
      console.error((err as AxiosError).message);
    } finally {
      setLoading(false);
    }
  }, [userId, page, rowsPerPage, filtersFields, redeemedDateState]);

  const handleSearchSubmit = (value: string) => {
    setUserId(value);

    if (value === '') {
      setGiftCardsReportsList(null);
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterChange = (
    e: ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>
  ) => {
    const { name, value } = e.target;

    if (name === 'amountFrom' || name === 'amountTo') {
      setFiltersFields((prev: IGiftCardsReportFilters) => ({
        ...prev,
        amount: {
          ...prev.amount,
          [name as string]: value,
        },
      }));
      return;
    }
    setFiltersFields((prev: IGiftCardsReportFilters) => ({ ...prev, [name as string]: value }));
  };

  const dateRangeValue =
    redeemedDateState.startDate &&
    dateFns.isValid(redeemedDateState.startDate) &&
    redeemedDateState.endDate &&
    dateFns.isValid(redeemedDateState.endDate)
      ? `${dateFns.format(new Date(redeemedDateState.startDate), 'MMM d, yyyy')} - ${dateFns.format(
          new Date(redeemedDateState.endDate),
          'MMM d, yyyy'
        )}`
      : '';

  const handleRangeClear = (e: React.SyntheticEvent<{}>) => {
    e.stopPropagation();
    setRedeemedDateState({ startDate: undefined, endDate: undefined, key: 'selection' });
  };

  const filtersApply = () => {
    fetchGiftCardsReport();
  };

  useEffect(() => {
    if (userId) {
      setLoading(true);
      fetchGiftCardsReport();
    }
  }, [userId, page, rowsPerPage]);

  const state = {
    page,
    rowsPerPage,
    sortDirection,
    giftCardsReportsList,
    userId,
    loading,
    meta,
    isFiltersOpen,
    filtersState: {
      redeemedDateState,
      dateRangeValue,
      filtersFields,
    },
  };

  const handlers = {
    handleSearchSubmit,
    handleChangePage,
    handleChangeRowsPerPage,
    setIsFiltersOpen,
    filtersHandlers: {
      setRedeemedDateState,
      handleRangeClear,
      handleFilterChange,
      filtersApply,
    },
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
