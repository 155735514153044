import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStandardTableStyles } from '@root/components/DefaultTable';
import { FilterToggle } from '@root/components/FilterToggle';
import InputSearch from '@root/components/InputSearch';
import InputSelect from '@root/components/InputSelect';
import Modal from '@root/components/Modal';
import { statusOptions, typeOptions } from '@root/components/OrdersSearch/static-date';
import { IOrderSearch } from '@root/components/OrdersSearch/types';
import { StatusFilter, TypeFilter } from '@root/pages/Orders/types';
import React from 'react';
import { DateRangePicker } from 'react-date-range';

export const OrderSearch: React.FC<IOrderSearch> = ({ state, handlers }) => {
  const dateFns = new DateFnsUtils();
  const classes = useStandardTableStyles();
  const {
    dateState,
    filterPanel,
    page,
    methodOptions,
    typeFilter,
    statusFilter,
    paymentMethodFilter,
  } = state;

  const {
    fetchList,
    setSearchTerm,
    setSearchByProduct,
    togglePanel,
    setTypeFilter,
    setStatusFilter,
    setPaymentMethodFilter,
    setPage,
    setDateState,
  } = handlers;

  const dateRangeValue =
    dateState.startDate &&
    dateFns.isValid(dateState.startDate) &&
    dateState.endDate &&
    dateFns.isValid(dateState.endDate)
      ? `${dateFns.format(new Date(dateState.startDate), 'MMM d, yyyy')} - ${dateFns.format(
          new Date(dateState.endDate),
          'MMM d, yyyy'
        )}`
      : '';

  const toggleFilterPanel = () => {
    togglePanel(!filterPanel);
  };

  const handleFilterApply = () => {
    if (page !== 1) {
      setPage(1);
      return;
    }
    fetchList();
  };

  const handleRangeApply = () => {
    fetchList();
  };

  const handleRangeClear = (e: React.SyntheticEvent<{}>) => {
    e.stopPropagation();
    setDateState({ startDate: undefined, endDate: undefined, key: 'selection' });
  };

  return (
    <>
      <FilterToggle onFilterClick={toggleFilterPanel} />
      {filterPanel && (
        <Grid container spacing={2} alignItems={'center'} className={classes.filterRow}>
          <Grid item xs={12} sm={6} lg={3}>
            <InputSelect
              label={'Type'}
              value={typeFilter}
              onChange={(e): void => setTypeFilter(e.target.value as TypeFilter)}
              options={typeOptions}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <InputSelect
              label={'Status'}
              value={statusFilter}
              onChange={(e): void => setStatusFilter(e.target.value as StatusFilter)}
              options={statusOptions}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <InputSelect
              label={'Method'}
              value={paymentMethodFilter}
              onChange={(e: React.ChangeEvent<any>): void => setPaymentMethodFilter(e.target.value)}
              options={methodOptions}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Button variant="contained" color="primary" fullWidth onClick={handleFilterApply}>
              Apply
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} className={classes.searchRow}>
        <Grid item xs={12} sm={6}>
          <InputSearch
            onSubmit={(val) => {
              setPage(1);
              setSearchTerm(val);
            }}
            placeholder={'by user'}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputSearch
            placeholder={'by name'}
            onSubmit={(val) => {
              setPage(1);
              setSearchByProduct(val);
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Modal
            onCloseCb={handleRangeApply}
            button={
              <TextField
                variant="outlined"
                fullWidth
                type={'text'}
                label={'Date Range'}
                autoFocus
                value={dateRangeValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={(e) => handleRangeClear(e)} edge="end">
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            renderContent={() => (
              <DateRangePicker
                onChange={(item) => {
                  // @ts-ignore
                  setDateState(item.selection);
                }}
                moveRangeOnFirstSelection={true}
                months={2}
                ranges={[dateState]}
                direction="horizontal"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
