import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface ISKUItem {
  sku: string;
  description: string;
}

export interface IParams {
  searchTerm?: string;
}

export interface IGetSKUListResponse {
  data: ISKUItem[];
}

const request = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<IGetSKUListResponse>(ApiUrls.skuList + `?${query}`)
    .then((response) => response);
};

export default request;
