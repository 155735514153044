import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';
import { IGetInstance } from './getInstances';

export interface ICompanyInfo {
  id: number | string;
  name: string;
  email: string;
  phone: string;
  registrationNumber: string;
  address: {
    line1: string;
    line2: string;
    line3: string;
  };
  socialMedia: {
    twitter: string;
    facebook: string;
    instagram: string;
  };
  isDefault: boolean;
  isShown: boolean;
  instances: IGetInstance[];
  createdAt?: string;
  updatedAt?: string;
}

export interface ICompanyInfoResponse {
  data: {
    items: ICompanyInfo[];
    meta: IMeta;
  };
}

export interface ICreateCompanyInfo extends Omit<ICompanyInfo, 'id' | 'instances'> {
  instanceIds: number[];
}

export interface ICreateEditDeleteCompanyInfoResponse {
  data: ICompanyInfo;
}

export interface IEditCompanyInfoParams extends Partial<Omit<ICompanyInfo, 'id'>> {
  id: number | string;
}

const readCompanyInfoList = ({ page, limit }: IPaginationParams) => {
  const query = `?page=${page}&limit=${limit}`;
  return apiClient
    .get<ICompanyInfoResponse>(ApiUrls.companyInfoList + query)
    .then((response) => response);
};

const createCompanyInfo = (data: ICreateCompanyInfo) => {
  return apiClient
    .post<ICreateEditDeleteCompanyInfoResponse>(ApiUrls.createCompanyInfo, data)
    .then((response) => response);
};

const editCompanyInfo = (data: IEditCompanyInfoParams) =>
  apiClient
    .put<ICreateEditDeleteCompanyInfoResponse>(ApiUrls.editCompanyInfo + data.id, data)
    .then((response) => response);

const deleteCompanyInfo = (id: string | number) => {
  return apiClient
    .delete<ICreateEditDeleteCompanyInfoResponse>(ApiUrls.deleteCompanyInfo + `${id}`)
    .then((response) => response);
};

const companyInfoRequests = {
  readCompanyInfoList,
  createCompanyInfo,
  editCompanyInfo,
  deleteCompanyInfo,
};

export default companyInfoRequests;
