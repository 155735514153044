import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useList } from '@root/pages/PromoDiscounts/hooks/use-list';
import React from 'react';
import { useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import { selectPlansAsObjects } from '../../store/entities/selectors';

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  { id: 'discount', numeric: false, disablePadding: false, label: 'Discounts' },
  { id: 'plans', numeric: false, disablePadding: false, label: 'plans' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'published', numeric: false, disablePadding: false, label: 'Status' },
];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const plansAsObjects = useSelector(selectPlansAsObjects);

  const [
    { sortDirection, sortBy, meta, list, loading },
    {
      create,
      handleRequestSort,
      handleDelete,
      handleChangeRowsPerPage,
      handleChangePage,
      handleEdit,
    },
  ] = useList();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Admins</Title>
            <Can perform={Permissions.createPromoDiscount}>
              <Button
                onClick={create}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Create Promo Discount
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={sortDirection}
              orderBy={sortBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {loading && <TableSkeleton />}
              {!loading &&
                list?.map((item) => {
                  const discounts = Object.keys(item.data.RENEW)
                    .map((key) => `${key} Month(s): ${item.data.RENEW[key]}%`)
                    .join(', ');
                  const plans = item.planIds
                    .map((id) => `${plansAsObjects[id]?.name} (${id})`)
                    .join(', ');

                  return (
                    <TableRow key={item.id}>
                      <TableCell align="left">{item.id}</TableCell>
                      <TableCell align="left">{item.title}</TableCell>
                      <TableCell align="left">{discounts}</TableCell>
                      <TableCell align="left">{plans}</TableCell>
                      <TableCell align="left">{item.description}</TableCell>
                      <TableCell align="left">
                        {item.published ? 'Published' : 'Not Published'}
                      </TableCell>
                      <TableCell align="left">
                        <Can perform={Permissions.updatePromoDiscount}>
                          <IconButton
                            aria-label="edit"
                            title={'Edit'}
                            onClick={() => handleEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Can>
                      </TableCell>
                      <TableCell align="left">
                        <Can perform={Permissions.deletePromoDiscount}>
                          <DeleteModalButton
                            name={item.title}
                            entity={'Promo discount'}
                            onDelete={() => handleDelete(item?.id as number)}
                          />
                        </Can>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>There are no promo discount rules</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
