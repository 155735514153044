import { Box, Button, CssBaseline, Grid, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RecurringTypeSelect from '@root/components/RecurringTypeSelect';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import SKUSelect from '../../components/SKUSelect';
import Title from '../../components/Title';
import { IMembershipLevel } from '../../services/api/requests/membershipLevels';
import membershipRuleRequests, {
  ICreateMembershipRules,
  IEditMembershipRulesParams,
  IMembershipRuleSkus,
} from '../../services/api/requests/membershipRules';
import { editMembershipRulesData, fetchMembershipLevels } from '../../store/entities/actions';
import {
  selectMembershipLevels,
  selectMembershipRulesForEditing,
} from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  membershipLevelFromId: Yup.number()
    .typeError('Please, specify a number')
    .min(0, `Please, select the level`)
    .required('Field is required'),
  membershipLevelToId: Yup.number()
    .typeError('Please, specify a number')
    .min(0, `Please, select the level`)
    .required('Field is required'),
  skus: Yup.object().shape({
    Annual: Yup.string(),
    Monthly: Yup.string(),
  }),
});

const initialValues: {
  skus: Record<string, string>;
  oneDayDeductSkus: IMembershipRuleSkus;
  oneDayRecurringSkus: IMembershipRuleSkus;
  membershipLevelFromId: string;
  membershipLevelToId: string;
} = {
  skus: {},
  oneDayDeductSkus: {},
  oneDayRecurringSkus: {},
  membershipLevelFromId: '',
  membershipLevelToId: '',
};

const CreateEditMembershipRules: React.FC<{}> = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const selectedMembershipRules = useSelector(selectMembershipRulesForEditing);
  const levels = useSelector(selectMembershipLevels);
  const [levelOptions, setLevelOptions] = useState<IOption[]>([{ label: 'Empty', value: -1 }]);
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>([
    { label: 'Empty', value: -1 },
  ]);
  const [recurringTypes, setSelectedRecurringTypes] = React.useState<string[]>(
    Object.keys(selectedMembershipRules?.skus || {})
  );

  const actionText = selectedMembershipRules ? 'Edit' : 'Create';

  useEffect((): any => {
    if (!selectedMembershipRules && !levels) {
      dispatch(fetchMembershipLevels());
    }
    return () => dispatch(editMembershipRulesData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): any => {
    if (levels?.length) {
      const options = levels?.map((level: IMembershipLevel) => ({
        label: level.name,
        value: level.id,
      }));
      setLevelOptions(options);
    }
  }, [levels]);

  const getValuesFromSelectedRule = (rule: any | null, initialValues: any) => {
    if (rule) {
      const data = { ...initialValues };

      Object.keys(initialValues).map(
        // @ts-ignore
        (item: any) => (data[item] = rule[item] || initialValues[item])
      );

      return data;
    }
    return initialValues;
  };

  const fk: FormikProps<ICreateMembershipRules> = useFormik({
    initialValues: selectedMembershipRules
      ? getValuesFromSelectedRule(selectedMembershipRules, initialValues)
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (selectedMembershipRules) {
        const editData: IEditMembershipRulesParams = {
          ...values,
          id: selectedMembershipRules.id,
        };

        membershipRuleRequests.editMembershipRulesItem(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.membershipSettings));
          }
        });
        return;
      }

      membershipRuleRequests.createMembershipRulesItem(values).then((response) => {
        console.log('debug-response', response);
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.membershipSettings));
        }
      });
    },
  });

  useEffect((): any => {
    if (levels?.length) {
      const usedLevel = levels.find((item) => item.id === fk?.values?.membershipLevelFromId);

      const options =
        usedLevel &&
        levels?.reduce((acc: IOption[], item) => {
          if (item.priority > usedLevel?.priority) {
            acc = [
              ...acc,
              {
                label: item.name,
                value: item.id,
              },
            ];
          }
          return acc;
        }, []);
      setFilteredOptions(options || []);
    }
  }, [fk?.values?.membershipLevelFromId, levels]);

  const handleRecurringTypeChange = (value: string[]) => {
    const deletedValue = recurringTypes.find((item) => !value.includes(item));
    const addedValue = value.find((item) => !recurringTypes.includes(item));

    if (addedValue) {
      const values = cloneDeep(fk.values);
      values.skus[addedValue] = '';
      values.oneDayDeductSkus[addedValue] = '';
      values.oneDayRecurringSkus[addedValue] = '';
      fk.setValues(values);
    }

    if (deletedValue) {
      const values = cloneDeep(fk.values);
      delete values.skus[deletedValue];
      delete values.oneDayDeductSkus[deletedValue];
      delete values.oneDayRecurringSkus[deletedValue];
      fk.setValues(values);
    }
    setSelectedRecurringTypes(value);
  };

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.membershipSettings} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Membership Rules`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box ml={1} mt={2} mb={1}>
                  Recurring Types
                </Box>
                <RecurringTypeSelect value={recurringTypes} onChange={handleRecurringTypeChange} />
              </Grid>

              {recurringTypes.map((type) => (
                <>
                  <Grid item xs={12}>
                    <Box ml={1} mt={2} mb={1}>
                      {type}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SKUSelect
                      id={`skus.${type}`}
                      label={`SKU ${type}`}
                      value={fk.values.skus?.[type] || ''}
                      onChange={fk.setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SKUSelect
                      id={`oneDayDeductSkus.${type}`}
                      label={`One Day Deduct SKU ${type}`}
                      value={fk.values.oneDayDeductSkus?.[type] || ''}
                      onChange={fk.setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SKUSelect
                      id={`oneDayRecurringSkus.${type}`}
                      label={`One Day Recurring SKU ${type}`}
                      value={fk.values.oneDayRecurringSkus?.[type] || ''}
                      onChange={fk.setFieldValue}
                    />
                  </Grid>
                </>
              ))}

              <Grid item xs={12} md={12}>
                <Box display={'flex'}>
                  <InputSelect
                    id="membershipLevelFromId"
                    label={'From'}
                    value={fk.values.membershipLevelFromId}
                    required
                    onChange={(e) => {
                      fk.setFieldValue('membershipLevelFromId', e.target.value);
                      fk.setFieldValue('membershipLevelToId', '');
                    }}
                    // @ts-ignore
                    options={levelOptions}
                    error={!!(fk.errors.membershipLevelFromId && fk.touched.membershipLevelFromId)}
                    onBlur={fk.handleBlur}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('membershipLevelFromId', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box display={'flex'}>
                  <InputSelect
                    id="membershipLevelToId"
                    label={'To'}
                    value={fk.values.membershipLevelToId}
                    required
                    onChange={(e) => fk.setFieldValue('membershipLevelToId', e.target.value)}
                    // @ts-ignore
                    options={filteredOptions}
                    error={!!(fk.errors.membershipLevelToId && fk.touched.membershipLevelToId)}
                    onBlur={fk.handleBlur}
                    disabled={!filteredOptions.length}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('membershipLevelToId', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditMembershipRules;
