import pickBy from 'lodash/pickBy';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { ICoupon } from './createCoupon';

const request = (data: ICoupon) => {
  const finalData = pickBy(data, (val) => val !== 'all' && val !== '');
  return apiClient
    .put<{ data: ICoupon }>(ApiUrls.editCoupon + data.id, finalData)
    .then((response) => response);
};

export default request;
