import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import VatCrud, {
  IEditVatItemParams,
  IVatFormItem,
  IVatItem,
} from '../../services/api/requests/vat-crud';
import { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { initialValues } from './static-data';
import { validationSchema } from './validation';

export const useVatFormik = (editVatItem: IVatItem | null) => {
  const dispatch = useDispatch();
  const isEdit = !!editVatItem;

  const fk: FormikProps<IVatFormItem> = useFormik({
    initialValues: isEdit
      ? ({
          ...editVatItem,
          countries: editVatItem?.countries.map((item) => item.code),
          gatewayMethods: editVatItem?.gatewayMethods.map((item) => item.id),
        } as IVatFormItem)
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async ({ id, gatewayMethods, countries, value }) => {
      const data = {
        countryCodes: countries,
        id,
        gatewayMethodIds: gatewayMethods.map(Number),
        value: Number(value),
      } as IEditVatItemParams;

      try {
        if (isEdit && editVatItem?.id) {
          await VatCrud.editVatItem(data);
        } else {
          await VatCrud.createVatItem(data);
        }

        dispatch(push(pageLinks.payments + '/2'));
        setSuccessNotification();
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  return fk;
};
