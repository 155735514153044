import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import deleteAdminRole from '../../services/api/requests/deleteAdminRole';
import { fetchAdminRoles } from '../../store/entities/actions';
import { selectAdminRoles } from '../../store/entities/selectors';
import { selectUserPermissions } from '../../store/system/selectors';
import { transformPermissionText } from '../../utils/helpers';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Date Updated' },
  { id: 'permissions', numeric: false, disablePadding: false, label: 'Permissions' },
];

export default function EnhancedTable() {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const permissions = useSelector(selectUserPermissions);
  const adminRolesList = useSelector(selectAdminRoles);

  useEffect(() => {
    if (permissions?.includes(Permissions.readAdminRoleList)) {
      dispatch(fetchAdminRoles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createRole = () => {
    dispatch(push(pageLinks.createEditPermissionRole, 1));
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteAdminRole({
        roleId: id,
      });
      setNotification('success');
      dispatch(fetchAdminRoles());
    } catch (e) {}
  };

  const handleEdit = (id: number) => {
    dispatch(push(pageLinks.createEditPermissionRole + `/${id}`));
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Admin Roles</Title>

            <Can perform={Permissions.createAdminRole}>
              <Button
                onClick={createRole}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AddCircleIcon />}
              >
                Create new Role
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={'asc'}
              orderBy={''}
              onRequestSort={() => {}}
              headCells={headCells}
            />

            <TableBody>
              {!adminRolesList && <TableSkeleton />}

              {adminRolesList?.map((role) => {
                const { id, name, createdAt, updatedAt, permissions } = role;
                const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
                const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');
                const permissionsStr =
                  permissions?.map((item) => transformPermissionText(item.name)) || role.name
                    ? transformPermissionText(role.name)
                    : '';

                return (
                  <TableRow key={role.name}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell>{create}</TableCell>
                    <TableCell align="left">{update}</TableCell>
                    <TableCell align="left">{permissionsStr.toString()}</TableCell>
                    <TableCell align="left">
                      <Can perform={Permissions.updateAdminRole}>
                        <IconButton aria-label="edit" title={'Edit'} onClick={() => handleEdit(id)}>
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="left">
                      <Can perform={Permissions.deleteAdminRole}>
                        <DeleteModalButton
                          name={name}
                          entity={'Role'}
                          onDelete={() => handleDelete(id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
