import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IDataSet } from './createProductPlan';
import { IProduct } from './getProducts';

export interface IAttachment {
  id: number;
  type: string;
  preview: string | null;
  url: string;
  previewUrl?: string;
  language?: string;
}

export interface IPlanResponse {
  attachments: IAttachment[];
  serviceIDs: number[];
  additionalInfo: null | string;
  adjustPrice: number;
  adjustRecurringPrice: number;
  autopayable: boolean;
  membershipDiscountAllowed: boolean;
  rewardingDays: number;
  bgColor: null | string;
  bullets: null | Array<string>;
  color: null | string;
  countries: string[];
  currency: 'USD' | 'EURO';
  discountExigoItem: null | string;
  fsbEnabled: boolean;
  fullDescription: null | string;
  intervalSize: null | number;
  intervalUnit: 'day';
  itemDescription: null | string;
  priority: number;
  product?: IProduct;
  createdAt?: Date;
  description: null | string;
  id: number;
  imageUrl: null | string;
  name: string;
  titleDescription: string;
  questDollarsReward: number;
  rebillyPlanId: string;
  recurringPrice: number;
  recurringRetailPrice: number;
  recurringSKU: null | string;
  regionalDatasets: IDataSet[];
  regionalPlans: IDataSet[];
  retailExigoItem: null | string;
  retailPrice: number;
  setupPrice: number;
  sku: string;
  updatedAt?: Date;
  isRecurring: boolean;
  hasRetail: boolean;
  noExigo: boolean;
  requireVerification: boolean;
  tLevelJoin: boolean;
  isSage: boolean;
  pointRewards: {
    cv: number;
    qv: number;
    scv: number;
    sqv: number;
    fsb: number;
    rcv: number;
    rqv: number;
    rscv: number;
    matrixBV: number;
    rsqv: number;
    rfsb: number;
  };
  requireAgreement: boolean;
  enrollerCashback: number;
  customerCashback: number;
  stripeCustomPlanId: string | null;
  trialPeriod: number | null;
}

const request = (data: any, productId: string) => {
  return apiClient
    .put<
      IResponseAxios<IPlanResponse[]>
    >(ApiUrls.baseProduct + +productId + '/plan/' + data.id, data)
    .then((response) => response);
};

export default request;
