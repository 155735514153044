import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { ICountry } from './getInstanceList';
import { IGetInstance } from './getInstances';

export interface IMeta {
  limit: number;
  page: number;
  total: number;
  totalPages: number;
}

export interface IParams {
  page: number;
  limit: number;
  sortDirection: 'ASC' | 'DESC';
  couponTypeFilter: string;
  discountTypeFilter: string;
  publishedFilter: string;
  activeFilter: string;
  autogenerated: string;
  instanceIds: number;
}

export interface IDocument {
  countries: ICountry[];
  createdAt: Date;
  id: number;
  instances: IGetInstance[];
  type: string;
  updatedAt: Date;
  url: string;
}

export interface IAdminCouponResponse {
  data: {
    items: IDocument[];
    meta: IMeta;
  };
}

const request = (params: any) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<IAdminCouponResponse>(ApiUrls.legalDocuments + `?${query}`)
    .then((response) => response);
};

export default request;
