import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

interface IProps {
  children: string;
}
const useStyles = makeStyles((theme) => ({
  color: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: 3,
    backgroundColor: (props: { color: string }) => props?.color || '#fff',
  },
}));

const ColorCell: React.FC<IProps> = ({ children }) => {
  const localClasses = useStyles({ color: children });

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <div className={localClasses.color} />
      <div>{children}</div>
    </Box>
  );
};

export default ColorCell;
