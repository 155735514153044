import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface ITwillioSettings {
  data: boolean;
}

export interface ITwillioSettingsParams {
  isEnabled: boolean;
}

export const updateTwillioSetting = (data: ITwillioSettingsParams) =>
  apiClient.put(ApiUrls.twillioSettings, data).then((response) => response.data);

export const getTwillioSettings = () =>
  apiClient.get<ITwillioSettings>(ApiUrls.twillioSettings).then((response) => response.data);
