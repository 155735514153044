export const headCells = [
  {
    id: 'membershipLevelFromId',
    numeric: false,
    disablePadding: false,
    label: 'From',
    disableSort: true,
  },
  {
    id: 'membershipLevelToId',
    numeric: false,
    disablePadding: false,
    label: 'To',
    disableSort: true,
  },
  { id: 'edit', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];
