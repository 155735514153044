import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import notificationsRequests, {
  ICreateNotification,
  IEditNotificationParams,
  INotification,
  NotificationType,
} from '../../services/api/requests/notifications';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { IValues } from './types';
import { validationSchema } from './validation';

export const useModuleFormik = (editItem: INotification | null) => {
  const dispatch = useDispatch();
  const isEdit = !!editItem;

  const initialValues: IValues = {
    type: NotificationType.NOTE,
    message: '',
    pageNames: [],
    intervalDisplayInHours: 0,
    isForLoggedInOnly: false,
  };

  const fk: FormikProps<IValues> = useFormik({
    initialValues: isEdit
      ? {
          type: editItem?.type || initialValues.type,
          message: editItem?.message || initialValues.message,
          pageNames: editItem?.pageNames || initialValues.pageNames,
          intervalDisplayInHours:
            editItem?.intervalDisplayInHours || initialValues.intervalDisplayInHours,
          isForLoggedInOnly: editItem?.isForLoggedInOnly || initialValues.isForLoggedInOnly,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values: IValues) => {
      const createData: ICreateNotification = {
        type: values.type,
        message: values.message,
        pageNames: values.pageNames.length ? values.pageNames : [],
        intervalDisplayInHours: !!values.intervalDisplayInHours
          ? values.intervalDisplayInHours
          : undefined,
        isForLoggedInOnly: values.isForLoggedInOnly,
      };

      if (editItem) {
        const editData: IEditNotificationParams = {
          ...createData,
          id: editItem.id,
        };

        await notificationsRequests.editNotification(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.notifications));
          }
        });
        return;
      }

      await notificationsRequests.createNotification(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.notifications));
        }
      });
    },
  });

  return fk;
};
