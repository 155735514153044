import { Box, Button, CssBaseline, Grid, IconButton, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RotateLeft from '@material-ui/icons/RotateLeft';
import { FormikProps, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import manageCustomerBundlesRequests, {
  ICreateSwitchPlan,
} from '../../services/api/requests/manageCustomerBundles';
import { selectTLevelPlans } from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import setNotification from '../../utils/notifications';

interface IValues extends Omit<ICreateSwitchPlan, 'customerIds' | 'fromPlanId' | 'toPlanId'> {
  customerIds: string;
  fromPlanId: string;
  toPlanId: string;
}

const initialValues: IValues = {
  customerIds: '',
  fromPlanId: '',
  toPlanId: '',
};

const validationSchema = Yup.object().shape({
  customerIds: Yup.number().min(0, "Can't be negative number").required('Field is required'),
  fromPlanId: Yup.number()
    .typeError('Please, specify a number')
    .min(0, `Please, select plan`)
    .required('Field is required'),
  toPlanId: Yup.number()
    .typeError('Please, specify a number')
    .min(0, `Please, select plan`)
    .required('Field is required'),
});

const initialOptions: IOption[] = [
  {
    label: '-',
    value: '-',
  },
];

const customerIdValueNormalizer = (value: string) => {
  const containSpace = value.includes(' ');
  if (containSpace) {
    return value.split(' ').map((string) => +string);
  }
  return [+value];
};

export default function ManageCustomerBundles() {
  const classesTable = useStandardTableStyles();
  const classes = useStandardFormStyles();
  const plans = useSelector(selectTLevelPlans);
  const [planIdFromOptions, setPlanIdFromOptions] = React.useState<IOption[]>(initialOptions);
  const [planIdToOptions, setPlanIdToOptions] = React.useState<IOption[]>(initialOptions);
  const [showInputs, setShowInputs] = React.useState<boolean>(false);

  const requestSwitch = () => {
    setShowInputs(true);
  };

  const fk: FormikProps<IValues> = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const createData = {
        customerIds: customerIdValueNormalizer(values.customerIds.trim()),
        fromPlanId: +values.fromPlanId,
        toPlanId: +values.toPlanId,
      };

      manageCustomerBundlesRequests.createSwitchBundle(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          resetForm();
        }
      });
    },
  });

  useEffect((): any => {
    if (plans) {
      const options = plans?.map((plan) => ({
        label: plan.name,
        value: plan.id,
      }));

      const planToOptions = fk?.values?.toPlanId
        ? options?.filter((o) => o.value !== +fk?.values?.toPlanId)
        : options;

      const planFromOptions = fk?.values?.fromPlanId
        ? options?.filter((o) => o.value !== +fk?.values?.fromPlanId)
        : options;

      setPlanIdFromOptions(planToOptions || []);
      setPlanIdToOptions(planFromOptions || []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fk?.values?.fromPlanId, fk?.values?.toPlanId, plans]);

  const createInputField = createInput<IValues>(fk);

  return (
    <Grid className={classesTable.root}>
      <Grid item xs={12}>
        <Paper className={classesTable.paper}>
          <div className={classesTable.paperHeader}>
            <Title>Manage user bundles</Title>
            <Can perform={Permissions.readSwitchSubscriptionRules}>
              <Button
                onClick={requestSwitch}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<RotateLeft />}
              >
                Request a switch
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>
      {showInputs && (
        <Paper className={classes.paper}>
          <CssBaseline />
          <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={fk.handleSubmit}>
              <Grid container spacing={2}>
                {createInputField('customerIds', 'Customer IDs', 12)}
                <Grid item xs={12} md={6}>
                  <Box display={'flex'}>
                    <InputSelect
                      id="fromPlanId"
                      label={'From Plan'}
                      value={fk.values.fromPlanId}
                      onChange={(e) => {
                        fk.setFieldValue('fromPlanId', e.target.value);
                        fk.setFieldValue('toPlanId', '');
                      }}
                      // @ts-ignore
                      options={planIdFromOptions}
                      error={!!fk.errors.fromPlanId && fk.touched.fromPlanId}
                      onBlur={fk.handleBlur}
                    />
                    <IconButton
                      onClick={() => {
                        fk.setFieldValue('fromPlanId', '');
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box display={'flex'}>
                    <InputSelect
                      id="toPlanId"
                      label={'To Plan'}
                      value={fk.values.toPlanId}
                      onChange={(e) => fk.setFieldValue('toPlanId', e.target.value)}
                      // @ts-ignore
                      options={planIdToOptions}
                      error={!!(fk.errors.toPlanId && fk.touched.toPlanId)}
                      onBlur={fk.handleBlur}
                    />
                    <IconButton
                      onClick={() => {
                        fk.setFieldValue('toPlanId', '');
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!fk.values.customerIds || !fk.values.fromPlanId || !fk.values.toPlanId}
                className={classes.submit}
              >
                Switch
              </Button>
            </form>
          </div>
        </Paper>
      )}
    </Grid>
  );
}
