import { IFilterFields } from '@root/components/MembershipLevelsTable/types';
import { RECURRING_TYPES } from '../../../enums/recurring-type';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IMembershipRuleSkus {
  [RECURRING_TYPES.ANNUAL]?: string;
  [RECURRING_TYPES.MONTHLY]?: string;
}

export interface IMembershipRule {
  id: number | string;
  skus: Record<string, string>;
  oneDayDeductSkus: Record<string, string>;
  oneDayRecurringSkus: Record<string, string>;
  membershipLevelFromId: number | string;
  membershipLevelToId: number | string;
}

export interface IMembershipRulesResponse {
  data: IMembershipRule[];
}

export interface ICreateMembershipRules extends Omit<IMembershipRule, 'id'> {}

export interface ICreateEditDeleteMembershipRulesResponse {
  data: IMembershipRule;
}

export interface IEditMembershipRulesParams
  extends Omit<IMembershipRule, 'membershipLevelFromId' | 'membershipLevelToId'> {
  membershipLevelFromId?: number | string;
  membershipLevelToId?: number | string;
}

const readMembershipRulesList = () => {
  return apiClient
    .get<IMembershipRulesResponse>(ApiUrls.membershipRulesList)
    .then((response) => response);
};

const readMembershipRulesItem = (id: number | string) => {
  return apiClient
    .get<IMembershipRulesResponse>(ApiUrls.membershipRule + `${id}`)
    .then((response) => response);
};

const createMembershipRulesItem = (data: ICreateMembershipRules) => {
  return apiClient
    .post<ICreateEditDeleteMembershipRulesResponse>(ApiUrls.createMembershipRules, data)
    .then((response) => response);
};

const editMembershipRulesItem = (data: IEditMembershipRulesParams) =>
  apiClient
    .put<ICreateEditDeleteMembershipRulesResponse>(ApiUrls.editMembershipRules + data.id, data)
    .then((response) => response);

const deleteMembershipRulesItem = (id: string | number) => {
  return apiClient
    .delete<ICreateEditDeleteMembershipRulesResponse>(ApiUrls.deleteMembershipRules + `${id}`)
    .then((response) => response);
};

const membershipRuleRequests = {
  readMembershipRulesList,
  readMembershipRulesItem,
  createMembershipRulesItem,
  editMembershipRulesItem,
  deleteMembershipRulesItem,
};

export default membershipRuleRequests;
