const links = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  forgotPasswordSuccess: '/forgot-password-success',
  createNewPassword: '/create-new-password',
  createNewPasswordSuccess: '/create-new-password-success',
  createEditAdmin: '/user-create-edit',
  customers: '/customers',
  admins: '/admins',
  promoDiscounts: '/promo-discounts',
  bundleGroups: '/bundle-groups',
  createPromoDiscount: '/create-promo-discount',
  instances: '/instances',
  orders: '/orders',
  declineOrders: '/decline-orders',
  coupons: '/coupons',
  createEditCoupon: '/create-edit-coupon',
  createEditPermissionRole: '/create-edit-permission-role',
  createEditPermissionGroup: '/create-edit-permission-group',
  createEditSwitchSubscriptionRule: '/create-edit-switch-subscription-rule',
  permissions: '/admin-permissions',
  createEditInstance: '/create-edit-instance',
  products: '/products',
  createEditProduct: '/create-edit-product',
  createEditProductCategory: '/create-edit-product-category',
  payments: '/payments',
  settings: '/settings',
  createEditSetting: '/create-edit-setting',
  createEditGateway: '/create-edit-gateway',
  createEditPaymentMethod: '/create-edit-payment-method',
  csvReport: '/payout-export',
  addCsvReport: '/create-payout-report',
  createEditPlan: '/create-edit-plan',
  createEditCombo: '/create-edit-combo',
  customerPayouts: '/customer-payouts',
  userMigrationsReport: '/user-migrations-report',
  userKycReport: '/user-kyc-report',
  payoutDetails: '/payout-details',
  createEditComboProduct: '/create-edit-combo-product',
  nestedRules: '/nested-rules',
  massPayoutRequestDetails: '/payout-request-details',
  massPaymentRequests: '/payments-requests',
  moneySpending: '/money-spending',
  createEditMoneySpending: '/create-edit-money-spending',
  legalDocuments: '/legal-documents',
  createEditLegalDocument: '/create-edit-legal-documents',
  signedDocuments: '/signed-documents',
  invoices: '/invoices',
  whiteList: '/white-list',
  createWhiteListIp: '/create-white-list-ip',
  kyc: '/kyc',
  countriesGroups: '/country-groups',
  editCountriesGroup: '/edit-country-group',
  createCountriesGroup: '/create-country-group',
  membershipSettings: '/membership-settings',
  recurringTypes: '/recurring-types',
  installmentsOrders: '/installments-orders',
  createEditMembershipRules: '/create-edit-membership-rules',
  createEditMembershipLevel: '/create-edit-membership-level',
  customersLogs: '/customers-logs',
  adminsLogs: '/admins-logs',
  productsActivations: '/products-activations',
  createEditConversionTemplate: '/create-edit-conversion-template',
  services: '/services',
  createEditService: '/create-edit-service',
  umustlookSettings: '/umustlook-settings',
  createEditUmustlookItem: '/create-edit-umustlook-item',
  mindhubGroups: '/mindhub-groups',
  createEditMindhubGroup: '/create-edit-mindhub-group',
  faqList: '/faq',
  createEditFaqItem: '/create-edit-faq-item',
  bundleSwitchSubscriptionRule: '/bundle-switch-subscription-rule',
  manageUserBundle: '/manage-user-bundle',
  companyInfo: '/company-info',
  createEditCompanyInfo: '/create-edit-company-info',
  triggerEvents: '/trigger-events',
  createVat: '/create-edit-vat',
  currencyConverter: '/currency-converter',
  createEditCurrencyConverter: '/create-edit-currency-converter',
  notifications: '/notifications',
  createEditNotification: '/create-edit-notification',
  exchangeRateList: '/exchange-rate',
  editExchangeRate: '/edit-exchange-rate',
  createExchangeRate: '/create-exchange-rate',
  purchaseRules: '/purchase-rules',
  sageRoles: '/sage-roles',
  updateSageRoles: '/update-sage-roles',
  giftCardsReport: '/gift-cards-report',
};

export default links as typeof links;
