import DateFnsUtils from '@date-io/date-fns';
import differenceInMilliseconds from 'date-fns/fp/differenceInMilliseconds';
import { ITriggerEventItem } from '../../services/api/requests/triggerEvents';

const dateHelper = (dateFns: DateFnsUtils, date: any, format: string) => {
  if (!date) {
    return undefined;
  }

  return dateFns.format(new Date(date), format);
};

const addDate = (
  date: Date,
  format: string,
  dateFns: DateFnsUtils,
  minutes: number,
  hours: number,
  days: number,
  months: number,
  years: number
) => {
  if (!date) {
    return '';
  }
  const result = new Date(date);
  if (minutes) {
    result.setTime(result.getTime() + minutes * 60 * 1000);
  }
  if (hours) {
    result.setTime(result.getTime() + hours * 60 * 60 * 1000);
  }
  if (days) {
    result.setTime(result.getTime() + days * 24 * 60 * 60 * 1000);
  }
  if (months) {
    result.setTime(result.getTime() + months * 2628000000);
  }
  if (years) {
    result.setTime(result.getTime() + years * 31536000000);
  }

  return format && dateFns ? dateFns.format(new Date(result), format) : result;
};

const calculateDistance = (date: Date) => {
  const daysCount = date.getDate();
  const hoursCount = date.getHours();
  const minutesCont = date.getMinutes();
  const milliseconds = date.getMilliseconds();

  const yearCount = milliseconds / 31536000000;
  const monthCount = milliseconds / 2628000000;

  const years = yearCount > 1 ? `${yearCount.toFixed(0)}yr` : '';
  const months = monthCount > 1 ? `${monthCount.toFixed(0)}mth` : '';
  const days = `${daysCount}d`;
  const hours = `${hoursCount}h`;
  const minutes = `${minutesCont}m`;

  return `${years} ${months} ${days} ${hours} ${minutes}`;
};

export const calculateDate = (
  dateFns: DateFnsUtils,
  triggerEventData: ITriggerEventItem | null
) => {
  const jobStart = dateHelper(dateFns, triggerEventData?.jobData?.timestamp, 'dd/MM/yyyy hh:mm');
  const time = dateHelper(dateFns, triggerEventData?.jobData?.data?.time, 'hh:mm');
  const createdAt = dateHelper(dateFns, triggerEventData?.createdAt, 'dd/MM/yyyy hh:mm');
  const updatedAt = dateHelper(dateFns, triggerEventData?.updatedAt, 'dd/MM/yyyy hh:mm');
  const progress =
    typeof triggerEventData?.jobData?.progress === 'number'
      ? `${triggerEventData?.jobData?.progress}`
      : '';
  const showAttempts =
    typeof triggerEventData?.jobData?.attemptsMade === 'number' &&
    typeof triggerEventData?.jobData?.opts?.attempts === 'number';
  const attempts = showAttempts
    ? `${triggerEventData?.jobData?.attemptsMade}/${triggerEventData?.jobData?.opts?.attempts}`
    : null;

  const delayConverted = triggerEventData?.jobData?.delay
    ? new Date(triggerEventData?.jobData?.delay * 1000)
    : null;

  const delay = delayConverted && calculateDistance(delayConverted);

  const triggerTime = addDate(
    // @ts-ignore
    triggerEventData?.jobData?.timestamp,
    'dd/MM/yyyy hh:mm',
    dateFns,
    delayConverted?.getMinutes(),
    delayConverted?.getHours(),
    delayConverted?.getDate(),
    null,
    null
  );

  const triggerTimeDate = addDate(
    // @ts-ignore
    triggerEventData?.jobData?.timestamp,
    null,
    null,
    delayConverted?.getMinutes(),
    delayConverted?.getHours(),
    delayConverted?.getDate(),
    null,
    null
  );

  const delayNowMs =
    triggerTimeDate && differenceInMilliseconds(new Date(triggerTimeDate), new Date());
  const delayNowMsConverted = new Date(delayNowMs);

  const delayNow =
    delayNowMsConverted && `${(delayNowMs || 0) > 0 ? '-' : ''} ${calculateDistance(delayNowMsConverted)}`;

  return {
    createdAt,
    updatedAt,
    jobStart,
    time,
    attempts,
    delayConverted,
    delay,
    delayNow,
    delayNowMs,
    triggerTime,
    triggerTimeDate,
    progress,
  };
};
