import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IProductCategoryListItem } from './getProductCategoryList';

export interface IProductCategoryRequestData
  extends Omit<IProductCategoryListItem, 'id' | 'products'> {
  products?: [] | number[];
}

const request = (data: IProductCategoryRequestData) => {
  return apiClient
    .post<IResponseAxios<IProductCategoryListItem[]>>(ApiUrls.productCategoriesList, data)
    .then((response) => response);
};

export default request;
