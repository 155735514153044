import { Button, CssBaseline, Grid, Paper, TableContainer } from '@material-ui/core';
import BackButton from '@root/components/BackButton';
import CountrySelect from '@root/components/CountrySelect';
import { createInput, useStandardFormStyles } from '@root/components/FormBase';
import Title from '@root/components/Title';
import {
  EntityGatewayMethod,
  EntityInstance,
  EntityLegalDocument,
  EntityLegalMembershipLevel,
  EntityMindhubGroup,
  EntityPlan,
  EntityVat,
} from '@root/pages/CreateCountriesGroup/entities';
import {
  entityPresent as getEntity,
  initialStateCountriesToSave,
} from '@root/pages/CreateCountriesGroup/helpers';
import { useStyles } from '@root/pages/CreateCountriesGroup/styles';
import { EntityType, IEntity } from '@root/pages/CreateCountriesGroup/types';
import { validationSchema } from '@root/pages/CreateCountriesGroup/validation';
import createCountriesGroup from '@root/services/api/requests/createCountriesGroup';
import editCountriesGroup from '@root/services/api/requests/editCountriesGroup';
import { ICountry } from '@root/services/api/requests/getInstances';
import {
  COUNTRY_GROUP_RELATED_ENTITY,
  getRelatedEntity,
  IRelatedEntityGatewayMethod,
  IRelatedEntityInstance,
  IRelatedEntityLegalDocument,
  IRelatedEntityMindhubGroup,
  IRelatedEntityPlan,
  IRelatedEntityVat,
} from '@root/services/api/requests/relatedEntity';
import { selectCountries, selectCountryGroups } from '@root/store/entities/selectors';
import setNotification from '@root/utils/notifications';
import pageLinks from '@root/utils/pageLinks';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CreateCountriesGroup: FC<{ match: any }> = (props) => {
  const dispatch = useDispatch();
  const classes = { ...useStandardFormStyles(), ...useStyles() };
  const groupId = props.match.params.groupId;
  const countries: ICountry[] = useSelector(selectCountries);
  const groups = useSelector(selectCountryGroups);
  const group = groups?.find((i) => i.id === +groupId);
  const [countriesToSave, setCountriesToSave] = useState<string[]>(() =>
    initialStateCountriesToSave(group)
  );
  const numCountries = useRef<number>(countriesToSave.length);
  const [checkedEntityOptions, setCheckedEntityOptions] = useState<EntityType<number> & {}>({});
  const [relatedEntity, setRelatedEntity] = useState<IEntity>({});
  const entityKeys = Object.values(COUNTRY_GROUP_RELATED_ENTITY);

  const idCountriesToSave = useMemo((): number[] => {
    return countries.filter((country) => countriesToSave.includes(country.code)).map((el) => el.id);
  }, [countriesToSave]);

  const fk: FormikProps<{ name: string }> = useFormik({
    initialValues: { name: group?.name || '' },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        countryCodes: countriesToSave,
        countryIds: idCountriesToSave,
        relations: checkedEntityOptions,
      };
      try {
        if (group) {
          await editCountriesGroup(group.id, data);
        } else {
          await createCountriesGroup(data);
        }
        dispatch(push(pageLinks.countriesGroups));
        setNotification('success', { message: 'Success' });
      } catch (e) {
        console.warn(e.message);
      }
    },
  });

  const handleFetchEntity = async () => {
    if (group) {
      const responses = await Promise.all(
        entityKeys.map((entityTitle) =>
          getRelatedEntity({
            id: groupId,
            entity: entityTitle,
            newCountryIds: idCountriesToSave.join('&newCountryIds='),
          })
        )
      );

      const responseAsObject = responses.reduce<IEntity>(
        (acc, value) => ({ ...acc, ...value }),
        {}
      );
      if (responseAsObject) {
        setRelatedEntity((prev) =>
          countriesToSave.length > 0 ? { ...prev, ...responseAsObject } : {}
        );
      }
    }
  };

  const createInputField = createInput<{ name: string }>(fk);

  const handleChangeEntityStatus = (e: ChangeEvent<HTMLInputElement>) => {
    const title: COUNTRY_GROUP_RELATED_ENTITY = e.target.title as COUNTRY_GROUP_RELATED_ENTITY;
    const value = Number(e.target.value);
    const isChecked = checkedEntityOptions[title]
      ? checkedEntityOptions[title]?.includes(value)
      : false;

    setCheckedEntityOptions((prev) =>
      isChecked
        ? { ...prev, [title]: prev[title]?.filter((el) => el !== value) }
        : { ...prev, [title]: [...(prev[title] || []), Number(value)] }
    );
  };

  useEffect(() => {
    if (countriesToSave.length < numCountries.current) {
      handleFetchEntity();
      setCheckedEntityOptions({});
    }
    numCountries.current = countriesToSave.length;
  }, [countriesToSave]);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.countriesGroups} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${group ? 'Edit' : 'Create'} countries group`}</Title>
        <CssBaseline />
        <Grid style={{ padding: '10px' }}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              {createInputField('name', 'Name', 6)}
            </Grid>

            <Grid container spacing={2} style={{ padding: 10 }}>
              <CountrySelect
                id={'create-countries-select'}
                value={countriesToSave}
                availableOptions={countries.map((v) => v.code) || []}
                onChange={(_, value: string[]) => value && setCountriesToSave(value)}
                multiple
                disableCloseOnSelect
                onBlur={handleFetchEntity}
              />
            </Grid>

            {Object.keys(relatedEntity).map((key, index) => {
              switch (key) {
                case 'plan':
                  const entityPlan = getEntity<IRelatedEntityPlan[]>('plan', relatedEntity);
                  return (
                    entityPlan && (
                      <TableContainer key={index}>
                        <EntityPlan
                          relatedEntity={entityPlan}
                          checkedEntityOptions={checkedEntityOptions}
                          handleChangeEntityStatus={handleChangeEntityStatus}
                        />
                      </TableContainer>
                    )
                  );
                case 'instance':
                  const entityInstance = getEntity<IRelatedEntityInstance[]>(
                    'instance',
                    relatedEntity
                  );
                  return (
                    entityInstance && (
                      <TableContainer key={index}>
                        <EntityInstance
                          relatedEntity={entityInstance}
                          checkedEntityOptions={checkedEntityOptions}
                          handleChangeEntityStatus={handleChangeEntityStatus}
                        />
                      </TableContainer>
                    )
                  );
                case 'legal-document':
                  const entityLegalDocument = getEntity<IRelatedEntityLegalDocument[]>(
                    'legal-document',
                    relatedEntity
                  );
                  return (
                    entityLegalDocument && (
                      <TableContainer key={index}>
                        <EntityLegalDocument
                          relatedEntity={entityLegalDocument}
                          checkedEntityOptions={checkedEntityOptions}
                          handleChangeEntityStatus={handleChangeEntityStatus}
                        />
                      </TableContainer>
                    )
                  );
                case 'membership-level':
                  const entityMembershipLevel = getEntity<IRelatedEntityPlan[]>(
                    'membership-level',
                    relatedEntity
                  );
                  return (
                    entityMembershipLevel && (
                      <TableContainer key={index}>
                        <EntityLegalMembershipLevel
                          relatedEntity={entityMembershipLevel}
                          checkedEntityOptions={checkedEntityOptions}
                          handleChangeEntityStatus={handleChangeEntityStatus}
                        />
                      </TableContainer>
                    )
                  );
                case 'gateway-method':
                  const entityGatewayMethod = getEntity<IRelatedEntityGatewayMethod[]>(
                    'gateway-method',
                    relatedEntity
                  );
                  return (
                    entityGatewayMethod && (
                      <TableContainer key={index}>
                        <EntityGatewayMethod
                          relatedEntity={entityGatewayMethod}
                          checkedEntityOptions={checkedEntityOptions}
                          handleChangeEntityStatus={handleChangeEntityStatus}
                        />
                      </TableContainer>
                    )
                  );
                case 'mindhub-group':
                  const entityMindhubGroup = getEntity<IRelatedEntityMindhubGroup[]>(
                    'mindhub-group',
                    relatedEntity
                  );
                  return (
                    entityMindhubGroup && (
                      <TableContainer key={index}>
                        <EntityMindhubGroup
                          relatedEntity={entityMindhubGroup}
                          checkedEntityOptions={checkedEntityOptions}
                          handleChangeEntityStatus={handleChangeEntityStatus}
                        />
                      </TableContainer>
                    )
                  );
                case 'vat':
                  const entityVat = getEntity<IRelatedEntityVat[]>('vat', relatedEntity);
                  return (
                    entityVat && (
                      <TableContainer key={index}>
                        <EntityVat
                          relatedEntity={entityVat}
                          checkedEntityOptions={checkedEntityOptions}
                          handleChangeEntityStatus={handleChangeEntityStatus}
                        />
                      </TableContainer>
                    )
                  );
              }
            })}
            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              {group ? 'Edit' : 'Add'}
            </Button>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default CreateCountriesGroup;
