import { Box, Button, Grid, Paper } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import Title from '../../components/Title';
import UmustlookItemsTable from '../../components/UmustlookItemsTable';
import umustlookItemRequests, { IUmustlookItem } from '../../services/api/requests/umustlookItems';
import { editUmustlookItemData, fetchUmustlookItems } from '../../store/entities/actions';
import { selectUmustlookItems } from '../../store/entities/selectors';
import { selectLoading } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const UmustlookSettings: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const items = useSelector(selectUmustlookItems);
  const systemLoading = useSelector(selectLoading);

  const fetchUmustlookItemList = async () => {
    dispatch(fetchUmustlookItems());
  };

  useEffect(() => {
    fetchUmustlookItemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUmustlookItem = async (id: number | string) => {
    try {
      await umustlookItemRequests.deleteUmustlookItem(id);
      setNotification('success', {
        message: 'Success',
      });
      await fetchUmustlookItemList();
    } catch (e) {
      console.warn(e.message);
    }
  };

  const editUmustlookItem = async (item: IUmustlookItem) => {
    dispatch(editUmustlookItemData(item));
    dispatch(push(`${pageLinks.createEditUmustlookItem}`));
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Umustlook settings</Title>
            <Box display={'flex'}>
              <Box marginRight={1}>
                <Can perform={Permissions.createUmustlookItem}>
                  <Button
                    onClick={() => dispatch(push(pageLinks.createEditUmustlookItem))}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon />}
                  >
                    Add a card
                  </Button>
                </Can>
              </Box>
            </Box>
          </div>
        </Paper>
      </Grid>

      <UmustlookItemsTable
        loading={systemLoading}
        items={items}
        handleDelete={deleteUmustlookItem}
        handleEdit={editUmustlookItem}
      />
    </div>
  );
};

export default UmustlookSettings;
