import { TableCell, TableRow } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import EditCountryForm, { COUNTRY_EDIT_FIELD } from '../../components/EditCountryForm';
import EditStatusButton from '../../components/EditStatusButton';
import editCountry from '../../services/api/requests/editCountry';
import { setSuccessNotification } from '../../utils/notifications';

const CountryListItem: FC<any> = ({ country, fetchList }) => {
  const { id, code, name, customBaseUrl, customCompanyName, phoneVerificationEnabled } = country;

  const [status, setStatus] = React.useState<string>(String(phoneVerificationEnabled));

  const handlePhoneVerificationEnable = ({ id, value }: { id: number; value: string }) => {
    editCountry({ phoneVerificationEnabled: value === 'true' }, id)
      .then(() => {
        setSuccessNotification();
        setStatus(value);
        fetchList();
      })
      .catch((error) => console.log('error:', error));
  };

  useEffect(() => {
    if (String(phoneVerificationEnabled) !== status) {
      setStatus(String(phoneVerificationEnabled));
    }
  }, [phoneVerificationEnabled, status]);

  return (
    <TableRow key={id}>
      <TableCell align="left">{id}</TableCell>
      <TableCell align="left">{code}</TableCell>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">
        <EditCountryForm
          fetchCountryList={fetchList}
          value={customBaseUrl}
          field={COUNTRY_EDIT_FIELD.URL}
          id={id}
        />
      </TableCell>
      <TableCell align="left">
        <EditCountryForm
          fetchCountryList={fetchList}
          value={customCompanyName}
          field={COUNTRY_EDIT_FIELD.COMPANY_NAME}
          id={id}
        />
      </TableCell>
      <TableCell align="left">
        <EditStatusButton
          status={status}
          options={['true', 'false']}
          onChange={(status: string) => {
            handlePhoneVerificationEnable({ id, value: status });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default CountryListItem;
