import { LEGAL_DOCUMENT_TYPE } from '@root/constants';
import { IEntity } from '@root/pages/CreateCountriesGroup/types';
import ApiUrls from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import { INSTANCE_TYPES } from '@root/services/api/requests/getInstances';
import { createQueryParams } from '@root/utils/helpers';
import { AxiosError } from 'axios';

export enum COUNTRY_GROUP_RELATED_ENTITY {
  PLAN = 'plan',
  INSTANCE = 'instance',
  MEMBERSHIP_LEVEL = 'membership-level',
  LEGAL_DOCUMENT = 'legal-document',
  GATEWAY_METHOD = 'gateway-method',
  MINDHUB_GROUP = 'mindhub-group',
  VAT = 'vat',
}

export interface IRelatedEntity {
  id: number;
  name: string;
}

export interface IRelatedEntityPlan extends IRelatedEntity {
  isRegional: boolean;
  status: string;
}

export interface IRelatedEntityInstance extends IRelatedEntity {
  type: INSTANCE_TYPES;
  defaultBaseUrl: string;
}

export interface IRelatedEntityLegalDocument extends IRelatedEntity {
  url: string;
  type: LEGAL_DOCUMENT_TYPE;
}

export interface IRelatedEntityMindhubGroup extends IRelatedEntity {
  status: 'Active' | 'Inactive';
}

export interface IRelatedEntityGatewayMethod extends IRelatedEntityMindhubGroup {
  title: string;
}

export interface IRelatedEntityVat {
  id: number;
  value: number;
}
interface IRelatedEntityParams {
  id: number;
  newCountryIds: string;
  entity: COUNTRY_GROUP_RELATED_ENTITY;
}

export type RelatedEntityType =
  | IRelatedEntityPlan
  | IRelatedEntityInstance
  | IRelatedEntityLegalDocument
  | IRelatedEntityMindhubGroup
  | IRelatedEntityGatewayMethod
  | IRelatedEntityVat;

interface IRelatedEntityResponse {
  data: RelatedEntityType;
}

export const getRelatedEntity = async (
  params: IRelatedEntityParams
): Promise<IEntity | undefined> => {
  const { id, ...rest } = params;
  const queryParams = createQueryParams<Omit<IRelatedEntityParams, 'id'>>(rest);

  const url = ApiUrls.relatedEntity.replace('{id}', `${id}`);
  try {
    const response = await apiClient.get<IRelatedEntityResponse>(url + `?${queryParams}`);
    return { [rest.entity]: response.data.data };
  } catch (err) {
    console.error((err as AxiosError).message);
  }
};
