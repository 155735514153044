import { IMeta } from '@root/services/api/requests/getAdminList';
import PromoDiscount, {
  IGetListParams,
  IPromoDiscount,
} from '@root/services/api/requests/promo-discount';
import { editPromoDiscount } from '@root/store/entities/actions';
import setNotification from '@root/utils/notifications';
import pageLinks from '@root/utils/pageLinks';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useList = () => {
  const dispatch = useDispatch();
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = React.useState<string>('updatedAt');
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [list, setList] = React.useState<IPromoDiscount[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchList();
  }, [page, sortBy, sortDirection, rowsPerPage]);

  const fetchList = async () => {
    try {
      const response = await PromoDiscount.getList({
        page,
        sortBy,
        sortDirection: sortDirection.toUpperCase() as IGetListParams['sortDirection'],
        limit: rowsPerPage,
      });

      setLoading(false);
      setList(response.data.data.items.map(({ createdAt, updatedAt, ...rest }) => rest));
      setMeta(response.data.data.meta);
    } catch (e) {
      console.error('Error', e);
    }
  };

  const create = () => {
    dispatch(push(pageLinks.createPromoDiscount));
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDelete = async (id: number) => {
    try {
      await PromoDiscount.delete(id);
      fetchList();
      setNotification('success');
    } catch (e) {
      console.warn('DELETE_ADMIN_ERROR', e);
    }
  };

  const handleEdit = (data: IPromoDiscount) => {
    dispatch(editPromoDiscount(data));
    dispatch(push(pageLinks.createPromoDiscount));
  };

  const state = { sortDirection, sortBy, list, meta, loading };
  const handlers = {
    create,
    handleDelete,
    handleEdit,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
