import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import getToken, { ITokenResponse } from '../services/api/requests/getToken';
import { setTokens } from '../services/api/tokenHandler';
import { fetchStartData, fetchStartDataByUrl } from '../store/entities/actions';
import { saveUserData } from '../store/system/actions';
import { LogInAction, LOGIN_USER, LOGOUT } from '../store/system/types';
import pageLinks from '../utils/pageLinks';

function* logOutUser() {
  localStorage.clear();
  yield put(push(pageLinks.login));
}

function* loginUserSaga(action: LogInAction) {
  try {
    const response: ITokenResponse = yield getToken(action.payload);
    const { accessToken, refreshToken, role } = response?.data;
    yield put(saveUserData(response.data));
    yield call(setTokens, { accessToken, refreshToken });
    localStorage.setItem('role', role);

    const urlBeforeLogout = localStorage.getItem('urlBeforeLogout');

    yield put(push(urlBeforeLogout || pageLinks.admins));
    if (urlBeforeLogout) {
      localStorage.removeItem('urlBeforeLogout');
    }

    yield put(fetchStartData());
    yield put(fetchStartDataByUrl());
  } catch (e) {
    console.warn('LOGIN_ERROR', e);
  }
}

export default function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUserSaga);
  yield takeLatest(LOGOUT, logOutUser);
}
