import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Can, { Permissions } from '@root/components/Can';
import DeleteModalButton from '@root/components/DeleteModalButton';
import { MenuProps } from '@root/pages/BundleGroups/static-data';
import { IBundleGroup } from '@root/services/api/requests/bundleGroups';
import { selectTLevelGroupOptions } from '@root/store/entities/selectors';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  item: IBundleGroup;
  onEditRow(p: IBundleGroup): Promise<boolean>;
  deleteNewItem(): void;
  deleteItem?(item: IBundleGroup): void;
}

const BundleGroupRow: React.FC<IProps> = ({ item, deleteNewItem, onEditRow, deleteItem }) => {
  const [editable, setEditable] = useState(!item.id || false);
  const [itemForEdit, setItemForEdit] = useState<IBundleGroup>(item);
  const planOptions = useSelector(selectTLevelGroupOptions);
  const { id, name, planGroupIds } = itemForEdit || {};
  const isValid = name && planGroupIds.length;

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setItemForEdit((prev) => ({ ...prev, [name]: value }));
  };

  const onSave = async () => {
    if (!isValid) {
      return;
    }
    const isSuccess = await onEditRow(itemForEdit);
    if (isSuccess) {
      setEditable(false);
    }
  };

  return (
    <TableRow key={id}>
      <TableCell align="left">{id}</TableCell>
      <TableCell align="left">
        {editable ? (
          <TextField
            required
            value={name}
            name="name"
            onChange={setValue}
            error={!name}
            helperText={!name && 'Required'}
          />
        ) : (
          name
        )}
      </TableCell>

      <TableCell align="left">
        {editable ? (
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Plans</InputLabel>
              <Select
                id="planGroupIds"
                name="planGroupIds"
                label={'Plans'}
                variant="outlined"
                multiple
                value={planGroupIds}
                onChange={(e) => setValue(e as React.ChangeEvent<HTMLInputElement>)}
                input={<Input />}
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={MenuProps}
              >
                {planOptions.map(({ value, label }) => (
                  <MenuItem key={label} value={value}>
                    <Checkbox
                      checked={planGroupIds.some((item) => item?.toString() === value?.toString())}
                    />
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          planGroupIds
            .map((item) => planOptions.find((option) => option.value === item)?.label)
            .sort()
            .join(', ')
        )}
      </TableCell>

      <TableCell align="left">
        {editable ? (
          <>
            <IconButton
              aria-label="edit"
              title={'Cancel'}
              size={'small'}
              onClick={() => {
                setEditable(false);
                setItemForEdit(item);
                !item.id && deleteNewItem();
              }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              type="button"
              aria-label="edit"
              disabled={!isValid}
              title={'Save'}
              size={'small'}
              onClick={onSave}
            >
              <CheckIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Can perform={Permissions.updateBundleGroup}>
              <IconButton
                aria-label="edit"
                title={'Edit'}
                size={'small'}
                onClick={() => setEditable(true)}
              >
                <EditIcon />
              </IconButton>
            </Can>
            <Can perform={Permissions.deleteBundleGroup}>
              <DeleteModalButton
                name={name}
                entity={'Bundle Group'}
                onDelete={() => deleteItem?.(item)}
              />
            </Can>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BundleGroupRow;
