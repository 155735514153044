import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TWILIO_SETTING_CODE } from '../../pages/CreateEditSetting';
import deleteSetting from '../../services/api/requests/deleteSetting';
import getSettingsList, { ISetting } from '../../services/api/requests/getSettingsList';
import {
  getTwillioSettings,
  updateTwillioSetting,
} from '../../services/api/requests/twillioSettings';
import updateSettingValue, {
  IUpdateSettingValue,
} from '../../services/api/requests/updateSettingValue';
import { IMeta } from '../../services/api/types';
import { editSettingData } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import Can, { Permissions } from '../Can';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import EditStatusButton from '../EditStatusButton';
import TableSkeleton from '../TableSkeleton';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', disableSort: true },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Code',
    disableSort: true,
  },
  {
    id: 'value',
    numeric: false,
    disablePadding: false,
    label: 'Selected status',
    disableSort: true,
  },
  {
    id: 'options',
    numeric: false,
    disablePadding: false,
    label: 'Available options',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Updated',
    disableSort: true,
  },
  {
    id: 'edit',
    numeric: false,
    disablePadding: false,
    label: '',
    disableSort: true,
  },
  {
    id: 'delete',
    numeric: false,
    disablePadding: false,
    label: '',
    disableSort: true,
  },
];

const SettingsList: FC<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setMeta: (meta: IMeta) => void;
  page: number;
  rowsPerPage: number;
}> = (props) => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const dispatch = useDispatch();
  const [list, setList] = useState<ISetting[] | null>(null);
  const [twillioSettings, setTwillioSettings] = useState<{ isEnabled: boolean } | null>(null);

  const { loading, setLoading, setMeta, page, rowsPerPage } = props;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchSettingsList().catch(console.log);
    fetchTwillioSettings().catch(console.log);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const fetchSettingsList = async () => {
    try {
      const response = await getSettingsList({ page, limit: rowsPerPage });
      if (response) {
        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const fetchTwillioSettings = async () => {
    try {
      const response = await getTwillioSettings();
      if (response) {
        setTwillioSettings({ isEnabled: response.data });
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const handleSettingDelete = async (id: number) => {
    try {
      const response = await deleteSetting(id);
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        fetchSettingsList().catch(console.log);
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const handleSettingEdit = (setting: ISetting) => {
    dispatch(editSettingData(setting));
    dispatch(push(pageLinks.createEditSetting));
  };

  const handleStatusChange = (data: IUpdateSettingValue) => {
    updateSettingValue(data).then((response) => {
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        fetchSettingsList().catch(console.log);
      }
    });
  };

  const handleTwillioStatusChange = () => {
    updateTwillioSetting({ isEnabled: !twillioSettings?.isEnabled }).then((response) => {
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        setTwillioSettings({ isEnabled: response.data });
      }
    });
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <CustomTableHead headCells={headCells} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}

          {!loading &&
            list?.map((setting: ISetting) => {
              const { id, code, name, options, value, createdAt, updatedAt } = setting;
              const allowMultipleSelection = code === 'INSTANCE_TYPES_IN_MAINTENANCE';
              const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
              const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');
              const isTwillioSetting = setting.code === TWILIO_SETTING_CODE;
              const isTwillioSettingsLoaded = !!twillioSettings;
              const twillioValue = twillioSettings?.isEnabled.toString() || '';

              return (
                <TableRow key={id}>
                  <TableCell align="left">{name}</TableCell>
                  <TableCell align="left">{code}</TableCell>
                  <Can
                    perform={Permissions.updateSettingValue}
                    otherwise={
                      <TableCell align="left">
                        {Array.isArray(value) ? value.map((el) => el) : value}
                      </TableCell>
                    }
                  >
                    <TableCell align="left">
                      {isTwillioSetting && !isTwillioSettingsLoaded ? (
                        <Button variant="outlined" endIcon={<EditIcon />} disabled>
                          {twillioValue}
                        </Button>
                      ) : (
                        <EditStatusButton
                          status={isTwillioSetting ? twillioValue : value.toString()}
                          options={options}
                          onChange={(status: string) => {
                            isTwillioSetting
                              ? handleTwillioStatusChange()
                              : handleStatusChange({ id, value: status });
                          }}
                          allowMultipleSelection={allowMultipleSelection}
                        />
                      )}
                    </TableCell>
                  </Can>
                  <TableCell align="left">
                    <Box display="flex" flexWrap="wrap">
                      {options.map((currency: string, idx: number, arr: any) => (
                        <Box m={0.5} display="flex" flexWrap="wrap" key={currency}>{`${currency}${
                          arr.length - 1 === idx ? '' : ', '
                        }`}</Box>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>{create}</TableCell>
                  <TableCell>{update}</TableCell>
                  <TableCell align="left">
                    <Can perform={Permissions.updateSettings}>
                      <IconButton
                        aria-label="edit"
                        title="Edit"
                        onClick={() => handleSettingEdit(setting)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="left">
                    <Can perform={Permissions.deleteSettings}>
                      <DeleteModalButton
                        name={name}
                        entity={'Gateway'}
                        onDelete={() => handleSettingDelete(id)}
                      />
                    </Can>
                  </TableCell>
                </TableRow>
              );
            })}

          {!loading && !list?.length && (
            <TableRow>
              <TableCell>There are no settings</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SettingsList;
