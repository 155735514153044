import { sageRoleInitialValue } from '@root/pages/SageRoles/static-data';
import { ISageRolesList, SAGE_ROLE_TYPE } from '@root/pages/SageRoles/types';
import {
  createNewSageRole,
  deleteSageRole,
  getSageRolesList,
  ISageRoleData,
} from '@root/services/api/requests/sageRole';
import setNotification from '@root/utils/notifications';
import { AxiosError } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';

export const useSageRoles = () => {
  const [sageRolesList, setSageRolesList] = useState<ISageRolesList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<boolean>(false);
  const [newSageRole, setNewSageRole] = useState<ISageRoleData>(sageRoleInitialValue);

  const fetchSageRolesList = async () => {
    try {
      const res = await getSageRolesList();

      if (res) {
        setLoading(false);        
        setSageRolesList(res.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      }
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setNewSageRole((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleChangeType = (type: SAGE_ROLE_TYPE) => {
    setNewSageRole((prev) => ({ ...prev, type }));
  };

  const addNewSageRole = async () => {
    try {
      const res = await createNewSageRole(newSageRole);

      if (res) {
        setNewItem(false);
        setNewSageRole(sageRoleInitialValue);
        setNotification('success', {
          message: 'Success',
        });

        fetchSageRolesList();
      }
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  const handleDelete = async (name: string) => {
    try {
      const res = await deleteSageRole(name);

      if (res) {
        setNotification('success', {
          message: 'Success',
        });

        fetchSageRolesList();
      }
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchSageRolesList();
  }, []);

  const state = {
    sageRolesList,
    loading,
    newItem,
    newSageRole,
  };

  const handlers = {
    fetchSageRolesList,
    handleDelete,
    setNewItem,
    setNewSageRole,
    handleChangeName,
    handleChangeType,
    addNewSageRole,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
