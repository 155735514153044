import * as React from 'react';

interface IProps {
  name: string;
  item?: string | number | null | Date;
  valueClassName?: string;
}

const PortalListItem: React.FC<IProps> = (props) => {
  const { name, item, valueClassName } = props;
  if (!item) {
    return null;
  }

  return (
    <li>
      <b>{name}</b> : <span className={valueClassName && valueClassName}>{item}</span>
    </li>
  );
};

export default PortalListItem;
