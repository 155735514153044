import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  ClickAwayListener,
  Fab,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import AddIcon from '@material-ui/icons/Add';
import React, { FC } from 'react';
import AdminsLogsDetailModal from '../../components/AdminsLogsDetailsModal';
import { useStandardTableStyles } from '../../components/DefaultTable';
import LogDataInput from '../../components/LogDataInput';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import useCustomTableStyles from './styles';
import { useAdminsLogsTable } from './use-table';

interface getHeaderProps {
  onTypeChange: (type: string) => void;
  setDataValues: (type: string, username: string | number) => void;
  setUserId: (userId: string) => void;
  userId?: string;
  action?: string;
  username?: string;
  styles: any;
}

// TODO - check what select items needed
const logDataInputItems = ['instance'];
const getHeader = ({
  onTypeChange,
  setDataValues,
  setUserId,
  userId,
  action,
  username,
  styles,
}: getHeaderProps) => {
  return [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
      styles: styles.smallCell,
      disableSort: true,
      component: (
        <TextField
          style={{ marginBottom: '10px' }}
          label="ID"
          type={'number'}
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Username',
      disableSort: true,
      styles: styles.cell,
      component: (
        <TextField
          style={{ marginBottom: '10px' }}
          label="Username"
          value={username}
          onChange={(e) => setDataValues('username', e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      id: 'tag',
      numeric: false,
      disablePadding: false,
      label: 'Entity',
      styles: styles.hugeCell,
      disableSort: true,
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Action',
      disableSort: true,
      styles: styles.cell,
      component: (
        <>
          <Select
            fullWidth
            value={action || 0}
            label="Age"
            onChange={(e) => onTypeChange(e.target.value as string)}
          >
            <MenuItem disabled value={0}>
              Action
            </MenuItem>
            <MenuItem value={'CREATE'}>Create</MenuItem>
            <MenuItem value={'UPDATE'}>Update</MenuItem>
            <MenuItem value={'DELETE'}>Delete</MenuItem>
            <MenuItem value={'REFUND'}>Refund</MenuItem>
            <MenuItem value={'all'}>all</MenuItem>
          </Select>
        </>
      ),
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date UTC',
      styles: styles.cell,
      disableSort: true,
    },
    {
      id: 'details',
      numeric: false,
      disablePadding: false,
      label: 'Details',
      disableSort: true,
      styles: styles.detailsCell,
    },
  ];
};

const AdminsLogs: FC<any> = () => {
  const classes = useStandardTableStyles();
  const styles = useCustomTableStyles();
  const dateFns = new DateFnsUtils();

  const [
    {
      logDataInputs,
      activeLogDataInputsValues,
      from,
      to,
      data,
      action,
      userId,
      logsList,
      page,
      limit,
      loading,
      isOpenPortal,
      activeLogId,
    },
    {
      setLogDataInputs,
      setDataValues,
      deleteInput,
      setFrom,
      setTo,
      onTypeChange,
      setUserId,
      handleChangePage,
      handleChangeRowsPerPage,
      setIsOpenPortal,
      setActiveLogId,
    },
  ] = useAdminsLogsTable();

  const addInput = () => {
    if (logDataInputItems.length > logDataInputs.length) {
      const id = Math.ceil(Math.random() * 1000);
      setLogDataInputs([
        {
          id,
          component: (
            <LogDataInput
              menuItems={logDataInputItems}
              activeItems={activeLogDataInputsValues}
              setDataValues={setDataValues}
              id={id}
              key={id}
              deleteInput={deleteInput}
            />
          ),
        },
        ...logDataInputs,
      ]);
    }
  };
  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Admin logs</Title>
          </div>
        </Paper>
      </Grid>

      <TableContainer>
        <div className={classes.searchRow}>
          <TextField
            id="date-from"
            label="from"
            type="date"
            defaultValue={from}
            className={classes.textField}
            onChange={(v) => setFrom(dateFns.format(new Date(v.target.value), 'yyyy-MM-dd'))}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date-to"
            label="to"
            type="date"
            defaultValue={new Date(to)}
            className={classes.textField}
            onChange={(v) => setTo(dateFns.format(new Date(v.target.value), 'yyyy-MM-dd'))}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.searchRow}>
          {activeLogDataInputsValues.length < logDataInputItems.length &&
          logDataInputs.length < logDataInputItems.length ? (
            <Box>
              <Fab size="small" color="primary" aria-label="add" onClick={() => addInput()}>
                <AddIcon />
              </Fab>
            </Box>
          ) : null}
          {logDataInputs.map((item) => item.component)}
        </div>
        <TableHead>
          <TableRow>
            {getHeader({
              onTypeChange,
              setDataValues,
              setUserId,
              userId,
              action,
              username: data?.username || '',
              styles,
            }).map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                className={headCell.styles}
              >
                {headCell.component ? headCell.component : headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <div className={classes.root}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableBody>
              {loading && <TableSkeleton />}
              {!loading && !logsList?.length ? (
                <TableRow>
                  <TableCell align="center">There are no customers logs</TableCell>
                </TableRow>
              ) : (
                logsList.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell align="left" className={styles.smallCell}>
                        {item.data.adminId || '---'}
                      </TableCell>
                      <TableCell align="left" className={styles.cell}>
                        {item.data.adminName || '--------'}
                      </TableCell>
                      <TableCell align="left" className={styles.hugeCell}>
                        {item.tag || '------'}
                      </TableCell>
                      <TableCell align="left" className={styles.cell}>
                        {item.action}
                      </TableCell>

                      <TableCell align="left" className={styles.cell}>
                        {dateFns.format(new Date(item.createdAt), 'dd/MM/yyyy hh:mm')}
                      </TableCell>
                      <TableCell align="left" className={styles.detailsCell}>
                        <ClickAwayListener onClickAway={() => setIsOpenPortal(false)}>
                          <div>
                            <Button
                              variant="contained"
                              onClick={() => {
                                setActiveLogId(item.id);
                                setTimeout(() => setIsOpenPortal(true), 100);
                              }}
                            >
                              View Details
                            </Button>
                          </div>
                        </ClickAwayListener>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            {isOpenPortal ? (
              <AdminsLogsDetailModal logsList={logsList} activeLogId={activeLogId} />
            ) : null}
          </Table>

          {logsList?.length ? (
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={1000}
              rowsPerPage={limit}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </div>
      </TableContainer>
    </div>
  );
};

export default AdminsLogs;
