import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { CustomTableHead, useStandardTableStyles } from '@root/components/DefaultTable';
import SearchPanel from '@root/components/SearchPanel';
import TableSkeleton from '@root/components/TableSkeleton';
import Title from '@root/components/Title';
import { headCells } from '@root/pages/Invoices/static-data';
import { useInvoice } from '@root/pages/Invoices/use-invoice';
import React from 'react';

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const [
    { list, loading, meta, downloading },
    { handleDownloadInvoices, handleChangePage, handleChangeRowsPerPage, setSearchValues },
  ] = useInvoice();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Invoices</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <div className={classes.searchRow}>
          <SearchPanel
            downloading={downloading}
            onSubmit={setSearchValues}
            handleDownloadInvoices={handleDownloadInvoices}
          />
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CustomTableHead classes={classes} headCells={headCells} />

            <TableBody>
              {loading && <TableSkeleton />}

              {!loading &&
                list?.map((item) => {
                  const { firstName, lastName, id, createdAt, url, exigoOrderId, orderId } = item;

                  const create =
                    createdAt && dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');

                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{firstName || '------'}</TableCell>
                      <TableCell align="left">{lastName || '------'}</TableCell>
                      <TableCell>{create || '------'}</TableCell>
                      <TableCell align="left">
                        <a href={url} target="_blank" rel="noreferrer">
                          {url || '------'}
                        </a>{' '}
                      </TableCell>
                      <TableCell align="left">{orderId || '------'}</TableCell>
                      <TableCell align="left">{exigoOrderId || '------'}</TableCell>
                    </TableRow>
                  );
                })}

              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>There are no invoices</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
