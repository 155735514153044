import { createQueryParams } from '@root/utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams, IResponseAxios } from '../types';

interface IFile {
  id: number;
  name: string;
  bucket: string;
}

export interface IPayoutItem {
  admin: { id: number; firstName: string; lastName: string; email: string };
  createdAt: null | Date;
  createdFrom: null | Date;
  createdTo: null | Date;
  editedFrom: null | Date;
  editedTo: null | Date;
  files: IFile[];
  id: string;
  method: string;
}

const request = (params: IPaginationParams) => {
  const query = createQueryParams<IPaginationParams>(params);
  return apiClient
    .get<IResponseAxios<{ items: IPayoutItem[]; meta: IMeta }>>(ApiUrls.payoutBase + `?${query}`)
    .then((response) => response);
};

export const downloadColsCardInfo = () => {
  return apiClient
    .get(ApiUrls.downloadPrepaidCardInfo, {
      responseType: 'blob',
    })
    .then((res) => res.data)
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'prepaid-card-balances.csv');

      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export default request;
