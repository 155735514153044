export enum SAGE_ROLE_TYPE {
  ADDON = 'ADDON',
  PROVIDER = 'PROVIDER',
  COMMON = 'COMMON',
}

export interface ISageRolesList {
  name: string;
  type: SAGE_ROLE_TYPE | string;
  createdAt: Date;
  updatedAt: Date;
}

export const sageRolesOptions = Object.values(SAGE_ROLE_TYPE).map((option) => ({
  label: option,
  value: option,
}));
