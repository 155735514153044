import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IProductCategoryListItem } from './getProductCategoryList';

export interface IProductRequestData extends IProductCategoryListItem {}

const request = (data: IProductRequestData) => {
  return apiClient
    .put<
      IResponseAxios<IProductCategoryListItem>
    >(ApiUrls.editProductCategoriesList + data.id, data)
    .then((response) => response);
};

export default request;
