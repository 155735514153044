import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import companyInfoRequests, { ICompanyInfo } from '../../services/api/requests/companyInfo';
import { IMeta } from '../../services/api/types';
import { editCompanyInfoData } from '../../store/entities/actions';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', disableSort: true },
  { id: 'instances', numeric: false, disablePadding: false, label: 'Instances', disableSort: true },
  { id: 'isDefault', numeric: false, disablePadding: false, label: 'Default', disableSort: true },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Updated',
    disableSort: true,
  },
];

const CompanyInfo: FC<any> = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const permissions = useSelector(selectUserPermissions);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [companyInfoList, setCompanyInfoList] = React.useState<ICompanyInfo[]>([]);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readCompanyInfoList)) {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const fetchList = () => {
    companyInfoRequests.readCompanyInfoList({ page, limit: rowsPerPage }).then((response) => {
      if (response) {
        setLoading(false);
        setCompanyInfoList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    });
  };

  const createCompanyInfo = () => {
    dispatch(push(pageLinks.createEditCompanyInfo));
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDelete = async (id: number | string) => {
    try {
      await companyInfoRequests.deleteCompanyInfo(id);
      setNotification('success', {
        message: 'Success',
      });
      fetchList();
    } catch (e) {
      console.error('DELETE COMPANY INFO ERROR', e);
    }
  };

  const handleEdit = (companyInfo: ICompanyInfo) => {
    dispatch(editCompanyInfoData(companyInfo));
    dispatch(push(pageLinks.createEditCompanyInfo));
  };

  const renderCompanyInfoTable = () => (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              companyInfoList?.map((companyInfo) => {
                const { id, name, isDefault, instances, createdAt, updatedAt } = companyInfo;

                const create = dateFns.format(new Date(createdAt as string), 'dd/MM/yyyy hh:mm');
                const update = dateFns.format(new Date(updatedAt as string), 'dd/MM/yyyy hh:mm');

                return (
                  <TableRow key={id}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">
                      {!isDefault && (
                        <Box display="flex" flexWrap="wrap">
                          {instances.map((instance: any, idx: number, arr: any) => (
                            <Box m={0.5} display="flex" flexWrap="wrap">{`${instance.name}${
                              arr.length - 1 === idx ? '' : ', '
                            }`}</Box>
                          ))}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="left">{isDefault && 'Yes'}</TableCell>
                    <TableCell>{create}</TableCell>
                    <TableCell>{update}</TableCell>
                    <TableCell align="left">
                      <Can perform={Permissions.updateCompanyInfo}>
                        <IconButton
                          aria-label="edit"
                          title={'Edit'}
                          onClick={() => handleEdit(companyInfo)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="left">
                      <Can perform={Permissions.deleteCompanyInfo}>
                        <DeleteModalButton
                          name={name}
                          entity={'Company Info'}
                          onDelete={() => handleDelete(id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })}

            {!loading && !companyInfoList?.length && (
              <TableRow>
                <TableCell>There are no instances</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Company Info</Title>
            <Can perform={Permissions.createCompanyInfo}>
              <Button
                onClick={createCompanyInfo}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add Company Info
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        {renderCompanyInfoTable()}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default CompanyInfo;
