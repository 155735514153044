import { Grid, Paper, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import React, { FC } from 'react';
import { useStandardTableStyles } from '../../components/DefaultTable';
import Title from '../../components/Title';
import TriggerEventsTable from '../../components/TriggerEventsTable';

type IAlign = 'left' | 'center' | 'right' | 'inherit' | 'justify';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
    disableSort: true,
    align: 'left',
    style: { width: '13%' },
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSort: true,
    align: 'left',
    style: { width: '40%' },
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    disableSort: true,
    align: 'left',
    style: { width: '25%' },
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
    disableSort: true,
    align: 'center',
    style: { width: '1%' },
  },
];

const TriggerEvents: FC = () => {
  const classes = useStandardTableStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Event triggers</Title>
          </div>
        </Paper>
      </Grid>

      <TableContainer>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                style={headCell.style}
                key={headCell.id}
                align={headCell.align as IAlign}
                padding={headCell.disablePadding ? 'none' : 'default'}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TriggerEventsTable />
      </TableContainer>
    </div>
  );
};

export default TriggerEvents;
