import * as Yup from 'yup';

const minNumberError = "Can't be less then zero";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(20),
  mindhubBranchId: Yup.number().min(0, minNumberError),
  formAudienceTags: Yup.array().of(
    Yup.object().shape({
      tag: Yup.string().required().label('Audience Tag'),
    })
  ),
  formGroupIds: Yup.array().of(
    Yup.object().shape({
      id: Yup.number()
        .typeError('Please, specify a number')
        .min(0, "Can't be less than 0")
        .required()
        .label('Group ID'),
    })
  ),
});
