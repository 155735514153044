import { Button, CssBaseline, Grid, Paper } from '@material-ui/core';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import baseSpendingRequests, {
  ICreateBaseSpending,
} from '../../services/api/requests/baseSpending';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const numberTypeError = 'Please, specify a number';
const minNumberError = "Can't be less than zero";
const validationSchema = Yup.object().shape({
  amount: Yup.number().typeError(numberTypeError).required(),
  userId: Yup.number().typeError(numberTypeError).min(0, minNumberError).required(),
  comment: Yup.string().max(100).required(),
});

interface IValues extends Omit<ICreateBaseSpending, 'userId'> {
  userId: number | null;
}

const initialValues: IValues = {
  amount: 0,
  userId: null,
  comment: '',
};

const CreateEditMoneySpending: FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedMoneySpending = null; // TODO: add selector (like selectInstanceForEditing) when it gonna be needed, for now we don't need EDIT

  const action = selectedMoneySpending ? 'Edit' : 'Create';

  const fk: FormikProps<IValues> = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const createData: ICreateBaseSpending = {
        amount: values.amount,
        ...(values?.comment ? { comment: values.comment } : {}),
        userId: values.userId as number,
      };

      baseSpendingRequests.createBaseSpendingItem(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.moneySpending));
        }
      });
    },
  });

  const createInputField = createInput<IValues>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name="Back" link={pageLinks.moneySpending} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{action} Money Spending</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('userId', 'User ID', 12, 'number')}
              {createInputField('amount', 'Amount', 12, 'number')}
              {createInputField('comment', 'Comment', 12)}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
            >
              {action}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditMoneySpending;
