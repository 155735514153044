import { IOption } from '@root/utils/commonTypes';
import { IFormValues } from './types';

export const initialValues: IFormValues = {
  name: '',
  rebillyProductId: '',
  description: '',
  file: '',
  bgColor: '#fff',
  color: '#000',
  priority: 0,
  status: 'active',
  imageUrl: null,
  fullImageUrl: null,
  urlParam: '',
};

export const statusOptions: IOption[] = [
  { value: 'All', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'parent-inactive', label: ' Parent-Inactive' },
];
