import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
} from '@material-ui/core';
import RecurringTypeSelect from '@root/components/RecurringTypeSelect';
import { useSageRolesList } from '@root/hooks/useSageRolesList';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import BulletDescriptions from '../../components/BulletDescriptions';
import InputSelect from '../../components/InputSelect';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import pageLinks from '../../utils/pageLinks';
import BackButton from '../BackButton';
import FileInput from '../FileInput';
import { createInput, useStandardFormStyles } from '../FormBase';
import SKUSelect from '../SKUSelect';
import Title from '../Title';
import { IValues } from './types';
import { useServiceForm } from './use-service-form';

const CreateEditService: FC = () => {
  const classes = useStandardFormStyles();

  const [
    { recurringFieldsVisible, selectedRecurringTypes, isEdit, fk },
    { handleTogglesChange, setSelectedRecurringTypes },
  ] = useServiceForm();

  const sageRolesListOptions = useSageRolesList();

  const createEditText = isEdit ? 'Edit' : 'Create';
  const createInputField = createInput<IValues>(fk);
  const location = useLocation<{ prevPath: string }>();

  return (
    <Grid item xs={12}>
      <BackButton
        name={'Back'}
        link={location.state?.prevPath || pageLinks.services}
        margin={'0 0 10px 0'}
      />
      <Paper className={classes.paper}>
        <Title>{createEditText} Service </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form
            className={classes.form}
            onSubmit={fk.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'isVisible'}
                      checked={fk.values.isVisible}
                      onChange={fk.handleChange}
                      name="isVisible"
                      color="primary"
                    />
                  }
                  label="isVisible"
                />

                <FormControlLabel
                  control={
                    <Switch
                      id={'isOneTime'}
                      checked={fk.values.isOneTime}
                      onChange={handleTogglesChange}
                      name="isOneTime"
                      color="primary"
                    />
                  }
                  label="isOneTime"
                />
              </Grid>
              {createInputField('name', 'Name', 6, 'text', true)}
              {createInputField(
                'exigoId',
                'Exigo ID',
                6,
                'number',
                fk?.values?.isTechnical,
                undefined,
                fk?.values?.isTechnical
              )}

              <Grid item xs={12}>
                <Box ml={1} mt={2} color="#673ab7">
                  Service URLs:
                </Box>
              </Grid>
              {createInputField(
                'websiteUrl',
                'Website URL',
                12,
                'text',
                false,
                undefined,
                !fk.values.websiteUrl &&
                  (!!fk.values.mobileAppUrlIos || !!fk.values.mobileAppUrlAndroid)
              )}
              {createInputField(
                'mobileAppUrlIos',
                'IOS Mobile App URL',
                6,
                'text',
                false,
                undefined,
                !!fk.values.websiteUrl
              )}
              {createInputField(
                'mobileAppUrlAndroid',
                'Android Mobile App URL',
                6,
                'text',
                false,
                undefined,
                !!fk.values.websiteUrl
              )}

              {recurringFieldsVisible && (
                <>
                  <Grid item xs={12}>
                    <Box ml={1} mt={2} color="#673ab7">
                      One day SKUs:
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SKUSelect
                      disabled={fk.values.isTechnical}
                      id={'oneDayRecurringSku'}
                      label={'One day Recurring SKU'}
                      value={fk.values.oneDayRecurringSku || ''}
                      onChange={fk.setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SKUSelect
                      disabled={fk.values.isTechnical}
                      id={'oneDayDeductSku'}
                      label={'One day Deduct SKU'}
                      value={fk.values.oneDayDeductSku || ''}
                      onChange={fk.setFieldValue}
                    />
                  </Grid>
                </>
              )}

              {fk.values.isOneTime && recurringFieldsVisible && (
                <>
                  <Grid item xs={12}>
                    <Box ml={1} mt={2} color="#673ab7">
                      Monthly SKUs:
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SKUSelect
                      required={!fk.values.isTechnical}
                      disabled={fk.values.isTechnical}
                      id={'skus.Monthly'}
                      label={'SKU Monthly'}
                      value={fk.values.skus?.Monthly || ''}
                      onChange={fk.setFieldValue}
                    />
                  </Grid>
                </>
              )}

              {!fk.values.isOneTime && recurringFieldsVisible && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box ml={1} mt={2} mb={1} color="#673ab7">
                      Recurring Options:
                    </Box>
                    <RecurringTypeSelect
                      value={selectedRecurringTypes}
                      onChange={setSelectedRecurringTypes}
                    />
                  </Grid>
                  {selectedRecurringTypes.map((item) => (
                    <>
                      <Grid item xs={12}>
                        <Box ml={1} mt={2} color="#673ab7">
                          {item} SKUs:
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SKUSelect
                          required={!fk.values.isTechnical}
                          disabled={fk.values.isTechnical}
                          id={`skus.${item}`}
                          label={`SKU ${item}`}
                          value={fk.values.skus?.[item] || ''}
                          onChange={fk.setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SKUSelect
                          required={!fk.values.isTechnical}
                          disabled={fk.values.isTechnical}
                          id={`recurringSkus.${item}`}
                          label={`Recurring SKU ${item}`}
                          value={fk.values.recurringSkus?.[item] || ''}
                          onChange={fk.setFieldValue}
                        />
                      </Grid>
                    </>
                  ))}
                </Grid>
              )}

              <Grid item xs={12}>
                <Box ml={1} mt={1} color="#673ab7">
                  Sage roles:
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <InputSelect
                  id="addSageRoles"
                  label={'SGM Providers Role'}
                  value={fk.values.addSageRoles}
                  multiple
                  onChange={(e) => fk.setFieldValue('addSageRoles', e.target.value)}
                  options={sageRolesListOptions}
                />
              </Grid>

              <Grid item xs={12}>
                {createInputField('ghlTag', 'GHL Tag', 12)}
              </Grid>

              <Grid item xs={12}>
                <FileInput
                  id="imageUrl"
                  label="Background Image"
                  imageUrl={fk.values.imageUrl}
                  imageUrlField="imageUrl"
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.SERVICE_BG_IMAGE}
                />
              </Grid>

              <Grid item xs={12}>
                <Box ml={1} mt={2} color="#673ab7">
                  Entry:
                </Box>
              </Grid>
              {createInputField('cv', 'Entry CV', 6, 'number')}
              {createInputField('qv', 'Entry QV', 6, 'number')}
              {createInputField('scv', 'Entry SCV', 6, 'number')}
              {createInputField('sqv', 'Entry SQV', 6, 'number')}
              {createInputField('fsb', 'Entry FSB', 6, 'number')}
              {createInputField('matrixBV', 'Entry Matrix BV', 6, 'number')}
              <Grid item xs={12}>
                <Box ml={1} mt={2} color="#673ab7">
                  Renewal:
                </Box>
              </Grid>
              {createInputField('rcv', 'Renewal CV', 6, 'number')}
              {createInputField('rqv', 'Renewal QV', 6, 'number')}
              {createInputField('rscv', 'Renewal SCV', 6, 'number')}
              {createInputField('rsqv', 'Renewal SQV', 6, 'number')}
              {createInputField('rfsb', 'Renewal FSB', 6, 'number')}
              {createInputField('rmatrixBV', 'Renewal Matrix BV', 6, 'number')}

              <Grid item xs={12}>
                <Box ml={1} mt={2} color="#673ab7">
                  Quest Rewards:
                </Box>
              </Grid>
              {createInputField('INITIAL', 'Initial', 6, 'number')}
              {createInputField('RENEW', 'Renew', 6, 'number')}

              <Grid
                item
                xs={12}
                md={12}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'reduceCoreRewards'}
                      checked={fk.values.reduceCoreRewards}
                      onChange={fk.handleChange}
                      name="reduceCoreRewards"
                      color="primary"
                    />
                  }
                  label="Reduce service rewards from core item:"
                />
              </Grid>

              <Grid item xs={12} direction={'row'} alignItems={'center'}>
                <BulletDescriptions
                  onChange={(list: string[]) => fk.setFieldValue('bullets', list)}
                  bullets={fk.values.bullets || []}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value="enabled"
                  control={
                    <Checkbox
                      color="primary"
                      id="enabled"
                      name="enabled"
                      checked={fk.values.enabled}
                      onChange={fk.handleChange}
                    />
                  }
                  label="Enabled"
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value="isTechnical"
                  control={
                    <Checkbox
                      color="primary"
                      id="isTechnical"
                      name="isTechnical"
                      checked={fk.values.isTechnical}
                      onChange={fk.handleChange}
                    />
                  }
                  label="Technical"
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value="isAgreementRequired"
                  control={
                    <Checkbox
                      color="primary"
                      id="isAgreementRequired"
                      name="isAgreementRequired"
                      checked={fk.values.isAgreementRequired}
                      onChange={fk.handleChange}
                    />
                  }
                  label="Require Agreement"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value="isExtended"
                  control={
                    <Checkbox
                      color="primary"
                      id="isExtended"
                      name="isExtended"
                      checked={fk.values.isExtended}
                      onChange={fk.handleChange}
                    />
                  }
                  label="Exclude Service"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditService;
