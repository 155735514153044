import { Button, Grid, TextField } from '@material-ui/core';
import { IRecurringChargesSearch } from '@root/pages/RecurringTypes/types';
import { useStyles } from '@root/pages/RecurringTypes/use-styles';
import { FC } from 'react';

export const RecurringChargesSearch: FC<IRecurringChargesSearch> = ({
  searchField,
  handlesChangeSearch,
  handleSearchApply,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} alignItems="center" className={classes.searchField}>
      <Grid item xs={12} sm={6}>
        <TextField
          id="serviceName"
          label="Service name"
          name="serviceName"
          variant="outlined"
          fullWidth
          value={searchField}
          onChange={handlesChangeSearch}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={handleSearchApply}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};
