import { IValues, MembershipSetting } from '@root/pages/CreateEditSwitchSubscriptionRule/types';
import { IOption } from '@root/utils/commonTypes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

export const initialOptions: IOption[] = [
  {
    label: '-',
    value: '-',
  },
];

export const initialValues: IValues = {
  sku: '',
  adjustmentSku: '',
  planIdFrom: '',
  membershipId: '',
  planIdTo: '',
  fsbPeriod: 0,
  priceAdjustment: 0,
  spendingsAdjustment: 0,
  withRenew: false,
  freeRenew: false,
  changeNested: false,
  enabled: true,
  splitValues: 0,
  splitFSB: 0,
  splitPeriod: '',
  splitSKU: '',
  splitBaseValue: 0,
  cv: 0,
  qv: 0,
  scv: 0,
  sqv: 0,
  fsb: 0,
  matrixBV: 0,
  uiOriginalPrice: 0,
  membershipSettings: [],
  ghlTag: '',
};

export const emptySettings: Omit<MembershipSetting, 'membershipLevelId'> = {
  priceAdjustment: '',
  uiOriginalPrice: '',
  splitBaseValue: '',
  splitFSB: '',
  splitValues: '',
  splitPeriod: '',
  fsbPeriod: '',
  spendingsAdjustment: '',
  pointRewardsAdjustment: {
    cv: '',
    qv: '',
    scv: '',
    sqv: '',
    fsb: '',
    matrixBV: '',
  },
};
