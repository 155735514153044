import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import truncate from 'lodash/truncate';
import * as React from 'react';
import { IFaqItem } from '../../services/api/requests/faq';
import Can, { Permissions } from '../Can';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import TableSkeleton from '../TableSkeleton';

const headCells = [
  {
    id: 'question',
    numeric: false,
    disablePadding: false,
    label: 'Question',
    disableSort: true,
  },
  {
    id: 'priority',
    numeric: false,
    disablePadding: false,
    label: 'Priority',
    disableSort: true,
  },
  { id: 'edit', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];

interface IProps {
  loading: boolean;
  items: IFaqItem[] | null;
  handleEdit: (item: IFaqItem) => void;
  handleDelete: (id: string | number) => void;
}

const FAQItemsTable = (props: IProps) => {
  const { loading, items, handleEdit, handleDelete } = props;
  const classes = useStandardTableStyles();

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={'medium'}
        aria-label="enhanced table"
      >
        <CustomTableHead headCells={headCells} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}
          {!loading && !items?.length ? (
            <TableRow>
              <TableCell>There are no FAQ's</TableCell>
            </TableRow>
          ) : (
            !loading &&
            items?.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.question}</TableCell>
                  <TableCell>{item.priority}</TableCell>
                  <TableCell align="center">
                    <Can perform={Permissions.updateFaqItem}>
                      <IconButton aria-label="edit" title={'Edit'} onClick={() => handleEdit(item)}>
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="center">
                    <Can perform={Permissions.deleteFaqItem}>
                      <DeleteModalButton
                        name={`${truncate(item.question, { length: 50 })}`}
                        entity={'faq item'}
                        onDelete={() => handleDelete(item.id)}
                      />
                    </Can>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FAQItemsTable;
