import {
  Box,
  Button,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import { minNumberError, numberError } from '@root/components/CreateEditMembershipLevel/validation';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import upsellingRuleRequests, {
  ICreateUpsellingRule,
  IUpsellingRule,
} from '../../services/api/requests/upsellingRules';
import { selectPlanForEditing, selectUpsellingRulesServices } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import { useStandardFormStyles } from '../FormBase';

interface IProps {
  ruleForEditing?: IUpsellingRule;
  existedList: IUpsellingRule[];
  onCancelClick(): void;
  onSuccess(): void;
}

const CreateEditUpsellingRule: React.FC<IProps> = (props) => {
  const classes = useStandardFormStyles();
  const plan = useSelector(selectPlanForEditing);
  const upsellingRulesServices = useSelector(selectUpsellingRulesServices);
  const { ruleForEditing, existedList, onCancelClick, onSuccess } = props;

  const filteredServiceOptions = upsellingRulesServices
    ?.map((item) => ({ value: item.id, label: item.name }))
    .filter(
      (option) =>
        ruleForEditing?.addon?.id === option.value ||
        !existedList.some((it) => it.addon.id === option.value)
    );

  const actionText = !!ruleForEditing ? 'Edit' : 'Create';

  const initialValues = {
    planId: plan?.id,
    changeNested: false,
    priority: !!existedList.length ? 0 : 1,
  };

  const fk: FormikProps<ICreateUpsellingRule> = useFormik({
    initialValues: ruleForEditing
      ? {
          planId: plan?.id,
          addonId: ruleForEditing.addon.id,
          changeNested: false,
          priority: ruleForEditing.priority,
        }
      : initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: Yup.lazy(() => {
      return Yup.object().shape({
        addonId: Yup.string().required(),
        priority: Yup.number().typeError(numberError).required().min(0, minNumberError),
      });
    }),

    onSubmit: async (values: ICreateUpsellingRule) => {
      try {
        if (ruleForEditing) {
          await upsellingRuleRequests.editUpsellingRule(values, ruleForEditing.id);
          setNotification('success', {
            message: 'Success',
          });
          onSuccess();
          return;
        }

        await upsellingRuleRequests.createUpsellingRule(values);
        setNotification('success', {
          message: 'Success',
        });
        onSuccess();
      } catch (error) {
        console.error('Error: ', error);
      }
    },
  });

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <Autocomplete
                    options={filteredServiceOptions || []}
                    fullWidth
                    id="fromId"
                    getOptionLabel={(option) => option.label}
                    onChange={(_, value) => fk.setFieldValue('addonId', value?.value || 0)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        label={'Service'}
                        value={fk.values.addonId}
                        onBlur={fk.handleBlur}
                        helperText={fk.errors.addonId}
                      />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: '10px' }}
                  label="Priority"
                  type="number"
                  name="priority"
                  id="priority"
                  variant="outlined"
                  value={fk.values.priority}
                  onChange={fk.handleChange}
                  inputProps={{
                    min: '1',
                  }}
                  helperText={<Box css={{ color: '#d20b0b' }}>{fk.errors.priority}</Box>}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id="changeNested"
                      checked={fk.values.changeNested}
                      onChange={fk.handleChange}
                      name="changeNested"
                      color="primary"
                    />
                  }
                  label="Change Nested"
                />
              </Grid>
            </Grid>

            <Box display={'flex'} justifyContent={'space-between'} className={classes.submit}>
              <Button
                type="button"
                disabled={!fk.isValid}
                variant="contained"
                color="primary"
                // @ts-ignore
                onClick={() => fk.handleSubmit(fk.values)}
              >
                {actionText}
              </Button>

              <Button type="button" onClick={onCancelClick} variant="outlined" color="primary">
                Cancel
              </Button>
            </Box>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditUpsellingRule;
