import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationPage, NotificationType } from '../../services/api/requests/notifications';
import { editNotificationData } from '../../store/entities/actions';
import { selectNotificationForEditing } from '../../store/entities/selectors';
import { useModuleFormik } from './use-formik';

export const useForm = () => {
  const dispatch = useDispatch();
  const itemForEditing = useSelector(selectNotificationForEditing);
  const fk = useModuleFormik(itemForEditing);

  const isEdit = !!itemForEditing;

  const typeOptions = Object.values(NotificationType).map((item) => ({
    label: capitalize(item),
    value: item,
  }));
  const pageOptions = Object.values(NotificationPage).map((item) => ({
    label: capitalize(item.replace('-', ' ')),
    value: item,
  }));

  useEffect((): any => {
    return () => dispatch(editNotificationData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = {
    isEdit,
    fk,
    typeOptions,
    pageOptions,
  };

  return [state] as [typeof state];
};
