import { SUBSCRIPTION_STATUS } from '../../../utils/commonTypes';
import { normalizeCouponUsageRulesData } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IPlan } from './getProductPlans';

export interface ICouponUsageRule {
  id: number;
  shouldHaveStatus: SUBSCRIPTION_STATUS;
  createdAt: Date;
  updatedAt: Date;
  requiredPlan: IPlan;
}

export interface IUsageRuleSet {
  id: number;
  purchaseRules: ICouponUsageRule[];
}

export interface INormalizedCouponUsageRule {
  id: number;
  shouldHaveStatus: SUBSCRIPTION_STATUS;
  createdAt: Date;
  updatedAt: Date;
  requiredPlan: IPlan;
  requiredPlanName: string;
}

export interface INormalizedServiceCouponUsageRule {
  id: number;
  requiredService: {
    id: number;
    name: string;
  };
}

export interface INormalizedCouponUsageRuleSet {
  id: number;
  usageRules: INormalizedCouponUsageRule[];
  serviceUsageRules: INormalizedServiceCouponUsageRule[];
}

const request = (id: number) => {
  const url = ApiUrls.readCouponUsageRules.replace('{id}', `${id}`);
  return apiClient
    .get<IResponseAxios>(url)
    .then((response) => normalizeCouponUsageRulesData(response.data.data));
};

export default request;
