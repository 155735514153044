import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, Portal } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { ILog } from '../../services/api/requests/getCustomersLogs';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 190,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    minWidth: 300,
    maxWidth: 800,
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    '& li': {
      listStyleType: 'none',
    },
  },
  table: {
    width: '100%',
    marginTop: 12,
    marginBottom: 0,
    borderCollapse: 'collapse',

    '& > tr': {
      width: '100%',
      textAlign: 'left',
      border: '1px solid black',
      paddingLeft: 8,

      '& > td': {
        border: '1px solid black',
      },
    },

    '& td': {
      width: '50%',
      verticalAlign: 'top',
      paddingLeft: 8,

      '& ul': {
        padding: 0,

        '& li': {
          listStyleType: 'none',
          marginBottom: 2,
          paddingLeft: 12,

          '&:first-child': {
            marginTop: 4,
          },
          '&:last-child': {
            marginBottom: 4,
          },
        },
      },
    },
  },
}));

interface IProps {
  logsList: ILog[];
  activeLogId: number;
}

const convertCamelToCapitalized = (camelCaseString: string) => {
  let normalString = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  return normalString.replace(/(^|\s)\S/g, (char) => char.toUpperCase());
};

const RenderObjectTable: FC<{ objectData: Record<string, any> }> = ({ objectData }) => {
  const [tableRows, setTableRows] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const rows: JSX.Element[] = [];
    displayNestedData(objectData, rows);
    setTableRows(rows);
  }, [objectData]);

  function displayNestedData(object: Record<string, any>, rows: JSX.Element[], parentKey = '') {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;
        const value = object[key];

        if (Array.isArray(value)) {
          if (value.every((item) => typeof item === 'string' || typeof item === 'number')) {
            rows.push(
              <tr key={currentKey}>
                <td>
                  <b>{convertCamelToCapitalized(currentKey)}</b>: <span>{value.join(', ')}</span>
                </td>
              </tr>
            );
          } else {
            displayNestedData(value, rows, currentKey);
          }
        } else if (typeof value === 'object' && value !== null) {
          rows.push(
            <tr key={currentKey}>
              <div style={{ paddingLeft: 20 }}>
                <div>
                  <b>{convertCamelToCapitalized(currentKey)}</b>:
                </div>
                <div>
                  <RenderObjectTable objectData={value} />
                </div>
              </div>
            </tr>
          );
        } else {
          rows.push(
            <tr key={currentKey}>
              <td>
                <b>{convertCamelToCapitalized(currentKey)}</b>: <span>{String(value)}</span>
              </td>
            </tr>
          );
        }
      }
    }
  }

  return <>{tableRows}</>;
};

const AdminsLogsDetailModal = ({ logsList, activeLogId }: IProps) => {
  const dateFns = new DateFnsUtils();
  const classes = useStyles();
  const [height, setHeight] = useState(0);
  const log = logsList.find((v) => v.id === activeLogId);
  const date = dateFns.format(new Date(log?.createdAt || ''), 'dd/MM/yyyy hh:mm');

  const logOldData = (log?.oldData && JSON.parse(log?.oldData)) || null;
  const logNewData = (log?.newData && JSON.parse(log?.newData)) || null;

  useEffect(() => {
    let top = window.innerHeight / 2 + window.pageYOffset;
    setHeight(top);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  return (
    <Portal>
      <ul id="portal" className={classes.root} style={{ top: height }}>
        <li>
          <b>Entity</b>: <span>{log?.tag || '---'}</span>
        </li>
        <li>
          <b>Action</b>: <span>{log?.action || '---'}</span>
        </li>
        <li>
          <b>Date</b>: <span>{date}</span>
        </li>
        <li>
          <b>IP</b>: <span>{log?.ip || '---'}</span>
        </li>

        {!!log?.data && <RenderObjectTable objectData={log?.data} />}

        {(logOldData || logNewData) && (
          <table className={classes.table}>
            <tr>
              <td>
                <b>Old Data</b>
              </td>
              <td>
                <b>New Data</b>
              </td>
            </tr>
            <tr>
              <td>
                <RenderObjectTable objectData={logOldData} />
              </td>
              <td>
                <RenderObjectTable objectData={logNewData} />
              </td>
            </tr>
          </table>
        )}
      </ul>
    </Portal>
  );
};

export default AdminsLogsDetailModal;
