import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { createInput } from '@root/components/FormBase';
import InputSelect from '@root/components/InputSelect';
import Modal from '@root/components/Modal';
import { useFormik } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { DateRangePicker } from 'react-date-range';
import { initialValues } from './static-data';
import { useStyles } from './styles';
import { IFormValues, IProps, OrderTypes } from './types';
import { validationSchema } from './validation';

const SearchBar: React.FC<IProps> = ({ onSubmit, handleDownloadInvoices, downloading }) => {
  const dateFns = new DateFnsUtils();
  const classes = useStyles();
  const fk = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const dateRangeValue =
    fk.values.dateState.startDate &&
    dateFns.isValid(fk.values.dateState.startDate) &&
    fk.values.dateState.endDate &&
    dateFns.isValid(fk.values.dateState.endDate)
      ? `${dateFns.format(new Date(fk.values.dateState.startDate), 'MMM d, yyyy')} - ${dateFns.format(
          new Date(fk.values.dateState.endDate),
          'MMM d, yyyy'
        )}`
      : '';

  const handleRangeClear = (e: React.SyntheticEvent<{}>) => {
    e.stopPropagation();
    fk.setFieldValue('dateState', initialValues.dateState);
    fk.handleSubmit();
  };

  const handleClear = () => {
    onSubmit(initialValues);
    fk.resetForm();
  };

  const createInputField = createInput<IFormValues>(fk);

  return (
    <form className={classes.root} onSubmit={fk.handleSubmit}>
      <Grid container spacing={1}>
        {createInputField('firstName', 'First Name', 4)}
        {createInputField('lastName', 'Last Name', 4)}
        {createInputField('exigoOrderId', 'Exigo Order ID', 4)}
        {createInputField('orderId', 'OrderID', 4, 'number')}
        {createInputField('userId', 'UserID', 4, 'number')}
        <Grid item xs={12} md={4}>
          <InputSelect
            type="string"
            id="orderType"
            value={get(fk.values, 'orderType')}
            name="orderType"
            onChange={(e) => fk.setFieldValue('orderType', e.target.value)}
            label="Order Type"
            options={Object.entries(OrderTypes).map(([key, label]) => ({
              value: key,
              label: label,
            }))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Modal
            button={
              <TextField
                autoComplete="new-password"
                variant="outlined"
                name="searchValues.dateState"
                fullWidth
                type={'text'}
                label={'Date Range'}
                value={dateRangeValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={(e) => handleRangeClear(e)} edge="end">
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            renderContent={() => (
              <DateRangePicker
                onChange={(item) => {
                  fk.setFieldValue('dateState', item.selection);
                }}
                moveRangeOnFirstSelection={true}
                months={2}
                ranges={[fk.values.dateState]}
                direction="horizontal"
              />
            )}
          />
        </Grid>
        <Grid className={classes.submitContainer}>
          <Button onClick={() => handleClear()} className={classes.button}>
            Clear
          </Button>
          <Button disabled={!fk.isValid} className={classes.button} type="submit">
            Search
          </Button>
          <Button
            disabled={!fk.isValid || downloading}
            onClick={() => handleDownloadInvoices()}
            className={classes.button}
            startIcon={downloading ? <CircularProgress size={18} /> : <GetAppIcon />}
            type="button"
          >
            {downloading ? 'Downloading ...' : 'Download'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchBar;
