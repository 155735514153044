import ApiUrls from '../apiUrls';
import apiClient from '../client';

export enum NotificationType {
  NOTE = 'note',
  TIP = 'tip',
  WARNING = 'warning',
}

export enum NotificationPage {
  HOME = 'home',
  CHECKOUT = 'checkout',
  CHECKOUT_RESULT = 'checkout-result',
  PRODUCT = 'product',
}

export interface INotification {
  id: number | string;
  type: NotificationType;
  message: string;
  pageNames: NotificationPage[];
  intervalDisplayInHours: number;
  isForLoggedInOnly: boolean;

  createdAt?: string;
  updatedAt?: string;
}

export interface INotificationsResponse {
  data: INotification[];
}

export interface ICreateNotification extends Omit<INotification, 'id' | 'intervalDisplayInHours'> {
  intervalDisplayInHours: number | undefined;
}

export interface ICreateEditDeleteNotificationResponse {
  data: INotification;
}

export interface IEditNotificationParams
  extends Partial<Omit<INotification, 'id' | 'intervalDisplayInHours'>> {
  id: number | string;
  intervalDisplayInHours: number | undefined;
}

export const readNotificationList = (): Promise<INotificationsResponse> => {
  return apiClient
    .get<INotificationsResponse>(ApiUrls.notificationsList)
    .then((response) => response.data);
};

const createNotification = (data: ICreateNotification) => {
  return apiClient
    .post<ICreateEditDeleteNotificationResponse>(ApiUrls.createNotification, data)
    .then((response) => response);
};

const editNotification = (data: IEditNotificationParams) =>
  apiClient
    .put<ICreateEditDeleteNotificationResponse>(ApiUrls.editNotification + data.id, data)
    .then((response) => response);

const deleteNotification = (id: string | number) => {
  return apiClient
    .delete<ICreateEditDeleteNotificationResponse>(ApiUrls.deleteNotification + `${id}`)
    .then((response) => response);
};

const notificationsRequests = {
  readNotificationList,
  createNotification,
  editNotification,
  deleteNotification,
};

export default notificationsRequests;
