import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import React, { FC } from 'react';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import TransactionsDetailsModal from '../../components/TransactionDetailsModal';
import { headCells } from './table-head';
import { useInstallmentsOrdersTable } from './use-table';
import { InstallmentsOrdersFilter } from "@root/pages/Installmentsorders/installments-orders-filter";

const InstallmentsOrders: FC = () => {
  const classes = useStandardTableStyles();

  const [
    { list, meta, loading, filterState },
    { handleChangePage, handleChangeRowsPerPage, handleStatusChange, handleListFetch, filterHandlers },
  ] = useInstallmentsOrdersTable();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Installments Orders</Title>
            <Button onClick={handleListFetch} color="inherit">
              <CachedIcon />
              <Typography component="h6">Update List</Typography>
            </Button>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <InstallmentsOrdersFilter filterState={filterState} filterHandlers={filterHandlers} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead headCells={headCells} classes={classes} />
            <TableBody>
              {!loading &&
                !!list?.length &&
                list.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align={'left'}>{item.orderId}</TableCell>
                      <TableCell align={'left'}>{item.createdAt}</TableCell>
                      <TableCell align={'left'}>{item.name}</TableCell>
                      <TableCell align={'left'}>{item.email}</TableCell>
                      <TableCell align={'left'}>{item.username}</TableCell>
                      <TableCell align={'left'}>{item.amount}</TableCell>
                      <TableCell align={'left'}>{item.items}</TableCell>
                      <TableCell align={'left'}>{item.status}</TableCell>

                      <TableCell align="center">
                        <Can perform={Permissions.paySupportApprovalRequest}>
                          <TableCell align="left">
                            <TransactionsDetailsModal
                              status={item.status}
                              data={item}
                              title={'Transactions'}
                              options={['approve', 'deny']}
                              buttonText="Transactions"
                              onChange={(statuses: Record<string, string>) => {
                                handleStatusChange(statuses);
                              }}
                            />
                          </TableCell>
                        </Can>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {loading && <TableSkeleton />}
              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>There are no orders</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={meta.total}
            rowsPerPage={meta.limit}
            page={meta.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

export default InstallmentsOrders;
