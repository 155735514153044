import { createQueryParams } from "@root/utils/helpers";
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

export interface IIp {
  id: number;
  ipAddress: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IWhiteListResponse {
  data: {
    items: IIp[];
    meta: IMeta;
  };
}
export interface IWhiteListAllResponse {
  data: IIp[];
}

interface IWhiteListParams extends IPaginationParams {
  ip?: string;
  description?: string;
}

const request = (params: IWhiteListParams) => {
  const query = createQueryParams<IWhiteListParams>(params);
  return apiClient
    .get<IWhiteListResponse>(ApiUrls.readWhiteList + `?${query}`)
    .then((response) => response.data);
};

export default request;
