import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { push } from 'connected-react-router';
import 'date-fns';
import { FieldArray, FormikProps, FormikProvider, useFormik } from 'formik';
import { History, Location } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import BulletDescriptions from '../../components/BulletDescriptions';
import FileInput from '../../components/FileInput';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import IconMoonSelect from '../../components/IconMoonSelect';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import createComboPlan, {
  IComboPlan,
  ICreateCombo,
} from '../../services/api/requests/createComboPlan';
import editComboPlanAxios from '../../services/api/requests/editComboPlan';
import { IProductComboItem } from '../../services/api/requests/getComboProducts';
import { editComboPlan } from '../../store/entities/actions';
import {
  selectAvailablePlansOptionsById,
  selectComboPlanForEditing,
} from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const requiredMessage = 'This field is required';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage).max(40, "Value can't be more than 40"),
  fullDescription: Yup.string().max(200, "Value can't be more than 40"),
  itemDescription: Yup.string().max(200, "Value can't be more than 40"),
});

const planFields = [
  'discount',
  'recurringDiscount',
  'retailRecurringDiscount',
  'reduceCV',
  'reduceFSB',
  'reduceQV',
  'reduceRecurringCV',
  'reduceRecurringFSB',
  'reduceRecurringQV',
  'reduceRecurringSCV',
  'reduceRecurringSQV',
  'reduceSCV',
  'reduceSQV',
  'retailDiscount',
];

const initialValues: ICreateCombo = {
  name: '',
  level: '',
  fullDescription: '',
  priority: 1,
  itemDescription: '',
  additionalInfo: '',
  icon: '',
  file: {},
  description: '',
  color: '#000',
  bgColor: '#fff',
  plans: [],
  bullets: [],
  status: 'active',
  imageUrl: null,
};

const getValuesFromEditedPlan = (plan: IProductComboItem) => {
  const data = { ...initialValues };
  Object.keys(initialValues).map(
    // @ts-ignore
    (item) => (data[item as keyof ICreateCombo] = plan[item as keyof IProductComboItem])
  );
  return data;
};

const CreateEditComboPlan: React.FC<{
  match: match;
  history: History;
  location: Location;
  staticContext: any;
}> = (props) => {
  const classes = useStandardFormStyles();
  const planForEditing = useSelector(selectComboPlanForEditing);

  const allPlans: IOption[] = useSelector(selectAvailablePlansOptionsById('id'));
  const dispatch = useDispatch();
  const isEdit = !!planForEditing;

  const fk: FormikProps<ICreateCombo> = useFormik({
    initialValues: isEdit ? getValuesFromEditedPlan(planForEditing as any) : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const comboProductId = new URLSearchParams(props.location.search).get('id');

        const data = {
          ...values,
          comboProductId: Number(comboProductId),
        };

        if (isEdit) {
          await editComboPlanAxios(data, (planForEditing as any)?.id as any);
        } else {
          await createComboPlan(data);
        }

        dispatch(push(pageLinks.createEditComboProduct));
        setSuccessNotification();
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  //@ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(editComboPlan(null)), []);

  const createEditText = isEdit ? 'Edit' : 'Create';

  const createInputField = createInput<ICreateCombo>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.createEditComboProduct} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{createEditText} Combo Plan </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('name', 'Level*')}
              {createInputField('fullDescription', 'Full Description')}
              {createInputField('itemDescription', 'Item Description')}
              {createInputField('additionalInfo', 'Additional Info')}
              {createInputField('description', 'Description')}
              {createInputField('priority', 'Bundle priority', 6, 'number')}

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="status"
                  label={'Status'}
                  value={fk.values.status}
                  onChange={(e) => fk.setFieldValue('status', e.target.value)}
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography color="primary">Bundle Style Settings</Typography>

                <FileInput
                  id={'imageUrl'}
                  label={'Bundle Background'}
                  imageUrl={fk.values.imageUrl}
                  imageUrlField={'imageUrl'}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.COMBO_INFO}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <IconMoonSelect id={'icon'} value={fk.values.icon} onChange={fk.setFieldValue} />
              </Grid>

              <Grid item xs={12}>
                <Typography color="primary">Plans* </Typography>
                <span>(at least 2 plans)</span>
                <FormikProvider value={fk}>
                  <FieldArray name="plans">
                    {({ remove, push }) => (
                      <>
                        {fk.values?.plans?.length > 0 &&
                          (fk.values.plans as IComboPlan[]).map((item, index: number) => {
                            const selectedPlans = fk.values.plans
                              .filter((item, idx: number) => idx !== index)
                              .map((pl) => pl.id);

                            const planOptions = allPlans.filter(
                              (plan) => !selectedPlans.includes(plan.value)
                            );

                            return (
                              <Grid container spacing={2} className={classes.bordered}>
                                <Grid item xs={12}>
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                  >
                                    <Typography>Combo Plan</Typography>
                                    <IconButton
                                      aria-label="delete"
                                      title={'Delete'}
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </Grid>

                                <Grid item xs={12} md={6} direction={'row'} alignItems={'center'}>
                                  <InputSelect
                                    label={'PlanId'}
                                    id={`plans.${index}.id`}
                                    name={`plans.${index}.id`}
                                    defaultValue={fk.values.plans[index]['id' as keyof IComboPlan]}
                                    value={fk.values.plans[index]['id' as keyof IComboPlan]}
                                    onChange={fk.handleChange}
                                    options={planOptions}
                                  />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <TextField
                                    variant="outlined"
                                    type={'string'}
                                    fullWidth
                                    id={`plans.${index}.exigoItem`}
                                    label={'exigoItem'}
                                    required
                                    name={`plans.${index}.exigoItem`}
                                    autoComplete="new-password"
                                    onBlur={fk.handleBlur}
                                    value={fk.values.plans[index]['exigoItem' as keyof IComboPlan]}
                                    onChange={fk.handleChange}
                                  />
                                </Grid>

                                {planFields.map((item) => (
                                  <Grid item xs={12} md={6} direction={'row'} alignItems={'center'}>
                                    <TextField
                                      key={item}
                                      variant="outlined"
                                      type={'number'}
                                      fullWidth
                                      id={`plans.${index}.${item}`}
                                      label={item}
                                      required
                                      name={`plans.${index}.${item}`}
                                      autoComplete="new-password"
                                      onBlur={fk.handleBlur}
                                      value={fk.values.plans[index][item as keyof IComboPlan]}
                                      onChange={fk.handleChange}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            );
                          })}

                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<AddIcon />}
                          onClick={() =>
                            push({
                              discount: 0,
                              exigoItem: '',
                              reduceCV: 0,
                              reduceFSB: 0,
                              reduceQV: 0,
                              reduceRecurringCV: 0,
                              reduceRecurringFSB: 0,
                              recurringDiscount: 0,
                              retailRecurringDiscount: 0,
                              reduceRecurringQV: 0,
                              reduceRecurringSCV: 0,
                              reduceRecurringSQV: 0,
                              reduceSCV: 0,
                              reduceSQV: 0,
                              retailDiscount: 0,
                            })
                          }
                        >
                          Create
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </FormikProvider>
              </Grid>

              <Grid item xs={12} direction={'row'} alignItems={'center'}>
                <BulletDescriptions
                  onChange={(list: string[]) => fk.setFieldValue('bullets', list)}
                  bullets={fk.values.bullets || []}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditComboPlan;
