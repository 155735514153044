interface PasswordOptions {
  length: number;
  numbers: boolean;
  symbols: boolean;
  lowercase: boolean;
  uppercase: boolean;
  excludeSimilarCharacters: boolean;
  strict: boolean;
}

class PasswordGenerator {
  generate(options: PasswordOptions): string {
    const defaults: PasswordOptions = {
      length: 10,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      excludeSimilarCharacters: true,
      strict: true,
    };

    const settings: PasswordOptions = { ...defaults, ...options };
    let charset = '';

    if (settings.numbers) charset += '0123456789';
    if (settings.symbols) charset += '!@#$%^&*()-_=+[]{};:,.?';
    if (settings.lowercase) charset += 'abcdefghijklmnopqrstuvwxyz';
    if (settings.uppercase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if (settings.excludeSimilarCharacters) {
      charset = charset.replace(/[il1LoO]/g, '');
    }

    let password = '';
    while (password.length < settings.length) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      // strict mode (avoid repeated characters
      if (settings.strict && password.toLowerCase().includes(charset[randomIndex].toLowerCase()))
        continue;
      password += charset[randomIndex];
    }

    return password;
  }
}

export const passwordGenerator = new PasswordGenerator();
