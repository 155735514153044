import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    overflow: 'auto',
    height: '100vh',
  },
  toolbar: {
    position: 'sticky',
    top: 0,
    zIndex: 1100,
    backgroundColor: '#fff',
  },


  searchField: {
    marginBottom: 20
  }
}));
