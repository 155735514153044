import { FormikProps, useFormik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import membershipLevelRequests, {
  IEditMembershipLevelParams,
  IMembershipLevel,
} from '../../services/api/requests/membershipLevels';
import { editMembershipLevelData } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import { MembershipLevelFormikMapper } from './mapper';
import { initialValues } from './static-data';
import { IValues } from './types';
import { validationSchema } from './validation';

export const useMembershipLevelFormik = (selectedMembershipLevel: IMembershipLevel | null) => {
  const dispatch = useDispatch();
  const initialRecurringTypes = Object.keys(selectedMembershipLevel?.spendings || {});
  const [recurringTypes, setSelectedRecurringTypes] =
    React.useState<string[]>(initialRecurringTypes);

  const fk: FormikProps<IValues> = useFormik({
    initialValues: selectedMembershipLevel
      ? MembershipLevelFormikMapper.toDomain(selectedMembershipLevel)
      : initialValues,
    validationSchema: () => Yup.lazy(() => validationSchema()),
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values: IValues) => {
      const createData = MembershipLevelFormikMapper.toPersistence(values);

      if (selectedMembershipLevel) {
        const editData: IEditMembershipLevelParams = {
          ...createData,
          id: selectedMembershipLevel.id as number,
        };

        membershipLevelRequests.editMembershipLevelItem(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
          }
        });
        return;
      }

      membershipLevelRequests.createMembershipLevelItem(createData).then((response) => {
        if (response) {
          dispatch(editMembershipLevelData(response.data.data));
          setNotification('success', {
            message: 'Success',
          });
        }
      });
    },
  });

  const handleRecurringTypeChange = (value: string[]) => {
    const deletedValue = recurringTypes.find((item) => !value.includes(item));
    if (deletedValue) {
      const values = cloneDeep(fk.values);
      delete values.spendings[deletedValue];
      delete values.pointRewards[deletedValue];
      delete values.recurringPrices[deletedValue];
      delete values?.splitBaseRenewValues?.[deletedValue];
      fk.setValues(values);
    }
    setSelectedRecurringTypes(value);
  };

  return { fk, recurringTypes, handleRecurringTypeChange };
};
