import InputSelect from '@root/components/InputSelect';
import { selectRecurringTypes } from '@root/store/entities/selectors';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  value: string[];
  onChange: (value: string[]) => void;
  allowEmpty?: boolean;
}

export default function RecurringTypeSelect({ value, onChange, allowEmpty }: IProps) {
  const recurringTypes = useSelector(selectRecurringTypes);

  const options = useMemo(
    () =>
      recurringTypes?.map((item) => ({
        value: item.code,
        label: item.code,
      })) || [],
    [recurringTypes]
  );

  const handleChange = (newValue: string[]) => {
    if (!newValue.length && !allowEmpty) {
      return;
    }
    onChange(newValue);
  };

  return (
    <InputSelect
      id="recurringTypes"
      label={'Recurring Types'}
      value={value}
      multiple
      onChange={(e) => handleChange(e.target.value as string[])}
      options={options}
    />
  );
}
