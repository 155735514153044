import { Button, Grid, Paper, TablePagination } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { IServiceFilters } from '@root/pages/Services/types';
import { push } from 'connected-react-router';
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import ServicesList from '../../components/ServicesList';
import Title from '../../components/Title';
import { IMeta } from '../../services/api/types';
import pageLinks from '../../utils/pageLinks';

const EnhancedTable: FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [search, setSearch] = useState<string>('');
  const [toggleFilters, setToggleFilters] = useState<IServiceFilters>({
    isAgreementRequired: false,
    isActive: false,
    isTechnical: false,
    isOneTime: false,
  });

  const createEditService = () => {
    dispatch(push(pageLinks.createEditService));
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilters = (e: ChangeEvent<HTMLInputElement>) => {
    setToggleFilters((prev) => ({
      ...prev,
      [e.target.name]: !prev[e.target.name as keyof IServiceFilters],
    }));
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Services</Title>
            <Can perform={Permissions.createService}>
              <Button
                onClick={createEditService}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add Service
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <ServicesList
          loading={loading}
          setLoading={setLoading}
          setMeta={setMeta}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setSearch={setSearch}
          search={search}
          toggleFilters={toggleFilters}
          handleFilters={handleFilters}
        />

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
