import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface ICreatePayoutReportRequest {
  method?: 'all' | 'PayPal' | 'ACH' | 'bankWire' | 'cash' | 'pushToCard' | 'payoneer';
  createdFrom: string | null;
  createdTo: string | null;
  editedFrom: string | null;
  editedTo: string | null;
}

const request = (data: ICreatePayoutReportRequest) => {
  return apiClient.post<IResponseAxios>(ApiUrls.payoutBase, data).then((response) => response);
};

export default request;
