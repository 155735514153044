import ApiUrls from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import { IMeta } from '@root/services/api/types';
import { createQueryParams } from '@root/utils/helpers';
import { AxiosResponse } from 'axios';
import { IGiftCardsReport } from './../../../pages/GiftCardsReport/types';

export interface IGiftCardsReportResponse {
  items: IGiftCardsReport[];
  meta: IMeta;
}

interface ISendData {
  page: number;
  limit: number;
  creatorId: string;
  purchaseType?: string;
  status?: string;
  amountFrom?: number | null;
  amountTo?: number | null;
  redeemedDateFrom?: string;
  redeemedDateTo?: string;
}

export const getGiftCardsReport = async (
  data: ISendData
): Promise<AxiosResponse<{ data: IGiftCardsReportResponse }>> => {
  const query = createQueryParams(data);
  return apiClient.get(`${ApiUrls.giftCardsReport}?${query}`);
};
