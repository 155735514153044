import { Box, Button, CssBaseline, FormControlLabel, Grid, Paper, Switch } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import switchingRuleRequests, {
  ICreateSwitchingRule,
  ISwitchingRule,
} from '../../services/api/requests/switchingRules';
import { selectPlanForEditing, selectServicesOptions } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import CountrySelect from '../CountrySelect';
import { createInput, useStandardFormStyles } from '../FormBase';
import InputSelect from '../InputSelect';
import SKUSelect from '../SKUSelect';

interface IProps {
  ruleForEditing?: ISwitchingRule;
  existedList?: ISwitchingRule[];
  onCancelClick(): void;
  onSuccess(): void;
  availableCountries: string[];
}

const validationSchema = Yup.object().shape({
  planId: Yup.string(),
  serviceIdFrom: Yup.string().matches(/^[0-9]/, 'Select service, please'),
  serviceIdTo: Yup.string().matches(/^[0-9]/, 'Select service, please'),
  price: Yup.number().typeError('Should be a number'),
  cv: Yup.number().required().label('CV'),
  qv: Yup.number().required().label('QV'),
  scv: Yup.number().required().label('SCV'),
  sqv: Yup.number().required().label('SQV'),
  fsb: Yup.number().required().label('FSB'),
});

const CreateEditSwitchingRule: React.FC<IProps> = (props) => {
  const classes = useStandardFormStyles();
  const plan = useSelector(selectPlanForEditing);
  const serviceOptions = useSelector(selectServicesOptions);
  const { ruleForEditing, onCancelClick, onSuccess, availableCountries } = props;

  const serviceFromOptions = useMemo(() => {
    return serviceOptions.filter((service) =>
      plan?.formData.serviceIDs.includes(service.value as unknown as string)
    );
  }, [plan?.formData.serviceIDs, serviceOptions]);

  const actionText = !!ruleForEditing ? 'Edit' : 'Create';

  const initialValues = {
    countryCodes: [],
    planId: plan?.id || -1,
    serviceIdFrom: -1,
    serviceIdTo: -1,
    enabled: true,
    price: 0,
    cv: 0,
    qv: 0,
    scv: 0,
    sqv: 0,
    fsb: 0,
    changeNested: false,
  };

  const fk: FormikProps<ICreateSwitchingRule> = useFormik({
    // @ts-ignore
    initialValues: ruleForEditing
      ? {
          planId: plan?.id || initialValues.planId,
          serviceIdFrom: ruleForEditing.serviceIdFrom,
          serviceIdTo: ruleForEditing.serviceIdTo,
          price: Number(ruleForEditing.price).toFixed(2),
          enabled: ruleForEditing.enabled,
          sku: ruleForEditing.sku,
          cv: ruleForEditing.pointRewards.cv,
          qv: ruleForEditing.pointRewards.qv,
          scv: ruleForEditing.pointRewards.scv,
          sqv: ruleForEditing.pointRewards.sqv,
          fsb: ruleForEditing.pointRewards.fsb,
          countryCodes: ruleForEditing.countryCodes,
          changeNested: false,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: async (values: ICreateSwitchingRule) => {
      try {
        if (ruleForEditing) {
          await switchingRuleRequests.editSwitchingRule(
            {
              planId: values.planId,
              serviceIdFrom: values.serviceIdFrom,
              serviceIdTo: values.serviceIdTo,
              price: +values.price as number,
              sku: values.sku,
              enabled: values.enabled,
              countryCodes: values.countryCodes,
              changeNested: values.changeNested,
              pointRewards: {
                cv: values.cv,
                qv: values.qv,
                scv: values.scv,
                sqv: values.sqv,
                fsb: values.fsb,
              },
            } as any,
            ruleForEditing.id
          );
          setNotification('success', {
            message: 'Success',
          });
          onSuccess();
          return;
        }

        await switchingRuleRequests.createSwitchingRule({
          planId: values.planId,
          serviceIdFrom: values.serviceIdFrom,
          serviceIdTo: values.serviceIdTo,
          price: +values.price as number,
          sku: values.sku,
          countryCodes: values.countryCodes,
          changeNested: values.changeNested,
          pointRewards: {
            cv: values.cv,
            qv: values.qv,
            scv: values.scv,
            sqv: values.sqv,
            fsb: values.fsb,
          },
        } as any);
        setNotification('success', {
          message: 'Success',
        });
        onSuccess();
      } catch (error) {
        console.error('Error: ', error);
      }
    },
  });

  const createInputField = createInput<ICreateSwitchingRule>(fk);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                md={6}
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'enabled'}
                      checked={fk.values.enabled}
                      onChange={fk.handleChange}
                      name="enabled"
                      color="primary"
                    />
                  }
                  label="Enabled"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id="changeNested"
                      checked={fk.values.changeNested}
                      onChange={fk.handleChange}
                      name="changeNested"
                      color="primary"
                    />
                  }
                  label="Change Nested"
                />
              </Grid>

              <Grid item xs={12}>
                <CountrySelect
                  id={'countryCodes'}
                  availableOptions={availableCountries}
                  value={(fk.values.countryCodes as string[]) || []}
                  onChange={fk.setFieldValue}
                  withGroups
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="serviceIdFrom"
                    label={'From'}
                    value={fk.values.serviceIdFrom}
                    required
                    onChange={(e) => fk.setFieldValue('serviceIdFrom', e.target.value)}
                    options={serviceFromOptions}
                    error={!!(fk.errors.serviceIdFrom && fk.touched.serviceIdFrom)}
                    onBlur={fk.handleBlur}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('serviceIdFrom', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="serviceIdTo"
                    label={'To'}
                    value={fk.values.serviceIdTo}
                    required
                    onChange={(e) => fk.setFieldValue('serviceIdTo', e.target.value)}
                    options={serviceOptions}
                    error={!!(fk.errors.serviceIdTo && fk.touched.serviceIdTo)}
                    onBlur={fk.handleBlur}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('serviceIdTo', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>

              <Grid item xs={6} md={6}>
                <Box display={'flex'}>
                  {createInputField('price', 'Price', 12, 'number', true, (e) =>
                    fk.setFieldValue('price', Number(e.target.value).toFixed(2))
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <SKUSelect
                  id={'sku'}
                  label={'SKU'}
                  value={fk.values.sku}
                  onChange={fk.setFieldValue}
                />
              </Grid>

              {createInputField('cv', 'CV', 6, 'number')}
              {createInputField('qv', 'QV', 6, 'number')}
              {createInputField('scv', 'SCV', 6, 'number')}
              {createInputField('sqv', 'SQV', 6, 'number')}
              {createInputField('fsb', 'FSB', 12, 'number')}
            </Grid>

            <Box display={'flex'} justifyContent={'space-between'} className={classes.submit}>
              <Button
                type="button"
                disabled={!fk.isValid}
                variant="contained"
                color="primary"
                // @ts-ignore
                onClick={() => fk.handleSubmit(fk.values)}
              >
                {actionText}
              </Button>

              <Button type="button" onClick={onCancelClick} variant="outlined" color="primary">
                Cancel
              </Button>
            </Box>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditSwitchingRule;
