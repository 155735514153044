import { Box, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
// import * as React from 'react';
import { ChangeEvent, FC, useState } from 'react';
import Modal from '../Modal';

interface IProps {
  status: string | string[];
  options?: string[];
  onChange(st: string | string[]): void;
  title?: string;
  buttonText?: string;
  allowMultipleSelection?: boolean;
}

const useStyles = makeStyles(() => ({
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },

  statusButton: {
    borderColor: (props: IProps) => (props.status === 'inactive' ? '#b82525' : '#4ae91e'),
  },
}));

const renderStatus = (value: string | string[]) => {
  switch (true) {
    case Array.isArray(value): {
      return Array.isArray(value) ? value.join(', ') : value;
    }

    default: {
      return value;
    }
  }
};

const EditStatusButton: FC<IProps> = ({
  status,
  onChange,
  options = ['active', 'inactive'],
  title = 'Please select a status',
  buttonText,
  allowMultipleSelection,
}) => {
  const classes = useStyles({ status, onChange });

  const [value, setValue] = useState<string>(() => (!Array.isArray(status) ? status : ''));
  const [multiplyValue, setMultiplyValue] = useState<string[]>(Array.isArray(status) ? status : []);

  const handleClick = () => {
    if (value !== status) {
      onChange(value);
    }
  };

  const handleClickToMultiply = () => {
    if (multiplyValue) {
      onChange(multiplyValue);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMultiplyValue((prev) =>
      prev.includes(e.target.value)
        ? [...prev.filter((el) => el !== e.target.value)]
        : [...prev, e.target.value]
    );
  };

  return (
    <Modal
      button={
        Array.isArray(value) && !!value.length ? (
          <span>{renderStatus(status)}</span>
        ) : (
          <Button className={classes.statusButton} variant="outlined" endIcon={<EditIcon />}>
            {buttonText || renderStatus(status)}
          </Button>
        )
      }
      renderContent={(onClose: any) => (
        <div>
          <h2>{title}</h2>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              {allowMultipleSelection ? (
                options.map((it) => (
                  <FormControlLabel
                    value={it}
                    control={
                      <Checkbox
                        checked={multiplyValue.includes(it)}
                        color="primary"
                        onChange={handleChange}
                      />
                    }
                    label={it}
                  />
                ))
              ) : (
                <RadioGroup row value={value} onChange={(e) => setValue(e.target.value)}>
                  {options.map((it) => (
                    <FormControlLabel
                      style={{ textTransform: 'capitalize' }}
                      value={it}
                      control={<Radio color="primary" />}
                      label={it}
                    />
                  ))}
                </RadioGroup>
              )}
            </FormControl>
          </Grid>

          <Box className={classes.boxButton}>
            <Button variant="contained" color="default" onClick={() => onClose()}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color={'primary'}
              onClick={() => {
                allowMultipleSelection ? handleClickToMultiply() : handleClick();
                onClose();
              }}
            >
              Save
            </Button>
          </Box>
        </div>
      )}
    />
  );
};

export default EditStatusButton;
