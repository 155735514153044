import { IServiceItem } from '../../services/api/requests/services';
import { IValues, RecurringTypeData } from './types';

export const getValuesFromEditedService = (
  service: IServiceItem,
  initialValues: IValues
) => {
  if (service) {
    const data = { ...initialValues };

    Object.keys(initialValues).map(
      // @ts-ignore
      (item: any) => (data[item] = service[item] || initialValues[item])
    );

    if (service?.pointRewards) {
      // @ts-ignore
      Object.keys(service?.pointRewards).map((key) => (data[key] = service?.pointRewards[key]));
    }

    if (service?.questRewards) {
      // @ts-ignore
      Object.keys(service?.questRewards).map((key) => (data[key] = service?.questRewards[key]));
    }

    return data;
  }
  return initialValues;
};
