import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IImageProps {
  bucket: string;
  filename: string;
  id: number;
  size: number;
  storageDirectory: string;
  url: string;
  preview: string;
  mimetype: string;
  language: string;
}

const request = (data: any) => {
  return apiClient
    .post<IResponseAxios<IImageProps[]>>(ApiUrls.uploadAttachmentsUrl, data)
    .then((response) => response);
};

export default request;
