import { ISageRolesList } from '@root/pages/SageRoles/types';
import ApiUrls from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import { AxiosResponse } from 'axios';

export interface ISageRolesResponse {
  data: ISageRolesList[];
}

export type ISageRoleData = Pick<ISageRolesList, 'name' | 'type'>;

export const getSageRolesList = async (): Promise<AxiosResponse<ISageRolesResponse>> => {
  return apiClient.get<ISageRolesResponse>(ApiUrls.sageRolesList);
};

export const createNewSageRole = async (body: ISageRoleData): Promise<AxiosResponse<ISageRolesResponse>> => {
  return apiClient.post(ApiUrls.createSageRole, body);
};

export const updateSageRole = async (
  data: ISageRoleData
): Promise<AxiosResponse<ISageRolesResponse>> => {
  return apiClient.put(ApiUrls.updateSageRole + data.name, {
    type: data.type,
  });
};

export const deleteSageRole = async (name: string) => {
  return apiClient.delete(ApiUrls.deleteSageRole + name);
};
