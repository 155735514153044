import ApiUrls from '../apiUrls';
import apiClient from '../client';

export type ICountryWithoutInstances = {
  name: string;
  code: string;
};

export interface ICountriesWithoutInstancesResponse {
  data: ICountryWithoutInstances[];
}

const request = () => {
  return apiClient
    .get<ICountriesWithoutInstancesResponse>(ApiUrls.countriesWithoutInstances)
    .then((response) => response.data);
};

export default request;
