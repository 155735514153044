import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta } from '../types';
import { ICoupon } from './createCoupon';

export interface IParams {
  page: number;
  limit: number;
  sortDirection: 'ASC' | 'DESC';
  couponTypeFilter: string;
  discountTypeFilter: string;
  publishedFilter: 'all' | number;
  activeFilter: string;
  autogenerated: string;
  search?: string;
}

export interface IAdminCouponResponse {
  data: {
    items: ICoupon[];
    meta: IMeta;
  };
}

const request = (params: IParams) => {
  const encodeSearch = params.search && encodeURIComponent(params.search);

  const { search, ...othersParams } = params;

  const query = createQueryParams<IParams>({
    ...othersParams,
    publishedFilter: params.publishedFilter === 'all' ? 'all' : Number(params.publishedFilter),
  });

  const url = `${ApiUrls.couponList}?${query}${encodeSearch && `&search=${encodeSearch}`}`;

  return apiClient.get<IAdminCouponResponse>(url).then((response) => response);
};

export default request;
