import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import Can, { Permissions } from '@root/components/Can';
import { useStandardTableStyles } from '@root/components/DefaultTable';
import Title from '@root/components/Title';
import { useStyles } from '@root/pages/EditExchangeRate/styles';
import { ExchangeRateLabels } from '@root/pages/ExchangeRateList/types';
import { useExchangeRate } from '@root/pages/ExchangeRateList/use-exchange-rate';
import { useDispatch } from 'react-redux';

const EnhancedTable = () => {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const [{ exchangeRatesList }, { handleEdit }] = useExchangeRate();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <Title>Exchange rate</Title>
        </div>
      </Paper>

      <Paper>
        {exchangeRatesList.length > 0 && (
          <TableContainer>
            <Table>
              <TableBody>
                {exchangeRatesList.map((el) => (
                  <TableRow>
                    <TableCell>{`${ExchangeRateLabels[el.currencyFrom] || el.currencyFrom} - ${ExchangeRateLabels[el.currencyTo] || el.currencyTo}`}</TableCell>
                    <TableCell>{el.rate}</TableCell>
                    <TableCell align="right">
                      <Can perform={Permissions.updatePointsExchange}>
                        <IconButton aria-label="edit" title={'Edit'} onClick={() => handleEdit(el)}>
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Grid>
  );
};

export default EnhancedTable;
