import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta } from '../types';

export interface IParams {
  userId?: string;
  isManual?: boolean;
  page: number;
  limit: number;
}

export interface IBaseSpending {
  id: number;
  amount: number;
  comment: string;
  isManual: boolean;
  previousComment?: string | null;
}

export interface IBaseSpendingResponse {
  items: IBaseSpending[];
  meta: IMeta;
}

export type BaseSpendingResponse = Record<'data', IBaseSpendingResponse>;

export interface ICreateBaseSpending extends Omit<IBaseSpending, 'id' | 'comment' | 'isManual'> {
  userId: number;
  comment?: string;
}

const readBaseSpendingUserList = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<BaseSpendingResponse>(ApiUrls.readBaseSpendingUserList + `?${query}`)
    .then((response) => response);
};

const createBaseSpendingItem = (data: ICreateBaseSpending) => {
  return apiClient
    .post<BaseSpendingResponse>(ApiUrls.createBaseSpending, data)
    .then((response) => response);
};

const baseSpendingRequests = {
  readBaseSpendingUserList,
  createBaseSpendingItem,
};

export default baseSpendingRequests;
