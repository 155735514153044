import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IPaginationParams } from '../types';

export interface ISwitchingLocalRules extends ISwitchingRule {
  nameFrom?: string;
  nameTo?: string;
}

export interface ISwitchingRule {
  id: number;
  planId: number;
  price: number;
  serviceIdFrom: number;
  serviceIdTo: number;
  sku: string;
  enabled: boolean;
  countryCodes: string[];
  pointRewards: {
    cv: number;
    qv: number;
    scv: number;
    sqv: number;
    fsb: number;
  };
}

export interface IReadSwitchingRule {
  planId: number;
}

export interface ICreateSwitchingRule {
  planId: number;
  serviceIdFrom: number;
  serviceIdTo: number;
  enabled: boolean;
  price: number;
  sku: string;
  cv: number;
  qv: number;
  scv: number;
  sqv: number;
  fsb: number;
  countryCodes: string[];
  changeNested?: boolean;
}

export interface ISwitchingRuleResponse {
  data: ISwitchingRule[];
}

const readSwitchingRules = ({ planId }: IReadSwitchingRule & IPaginationParams) => {
  const query = `/plan/${planId}`;
  return apiClient
    .get<ISwitchingRuleResponse>(ApiUrls.readSwitchingRules + query)
    .then((response) => response);
};
const createSwitchingRule = (data: ICreateSwitchingRule) =>
  apiClient.post<ISwitchingRule>(ApiUrls.createSwitchingRules, data).then((response) => response);
const editSwitchingRule = (data: ICreateSwitchingRule, id: number) =>
  apiClient.put<ISwitchingRule>(ApiUrls.editSwitchingRules + id, data).then((response) => response);
const deleteSwitchingRule = (id: number) =>
  apiClient.delete<ISwitchingRule>(ApiUrls.deleteSwitchingRules + id).then((response) => response);

const switchingRuleRequests = {
  readSwitchingRules,
  createSwitchingRule,
  editSwitchingRule,
  deleteSwitchingRule,
};

export default switchingRuleRequests;
