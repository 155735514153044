export const USER_MIGRATIONS_DATE_FORMAT = 'MM.dd.yyyy';

export const USER_MIGRATIONS_FILE_NAME_DATE_FORMAT = 'MM/dd/yyyy';

export const USER_KYC_REPORT_DATE_FORMAT = 'yyyy-MM-dd';

export const FILE_SIZE = 5 * 1024;

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export enum IMAGE_UPLOAD_ENTITY {
  PLAN = 'plan',
  PRODUCT = 'product',
  COMBO_INFO = 'comboInfo',
  COMBO_PRODUCT = 'comboProduct',
  PRODUCT_FULL_IMAGE = 'productFullImage',
  COMBO_PRODUCT_FULL_IMAGE = 'comboProductFullImage',
  SERVICE_BG_IMAGE = 'service',
  PAYMENT_METHOD_IMAGE = 'paymentMethod',
  MEMBERSHIP_LEVEL = 'membership-level',
  UMUSTLOOK_ITEM = 'umustlook-item',
  CATEGORY = 'category',
}

export const AVAILABLE_REGIONAL_DATASET_FIELDS: { [key: string]: string[] } = {
  defaultFields: ['setupPrice'],
  retail: [
    'retailExigoItem',
    'retailPrice',
    'retailCV',
    'retailQV',
    'retailSCV',
    'retailSQV',
    'retailFSB',
  ],
  recurring: [
    'recurringSKU',
    'recurringPrice',
    'recurringCV',
    'recurringQV',
    'recurringSCV',
    'recurringSQV',
    'recurringFSB',
    'intervalSize',
  ],
  retailRecurring: [
    'recurringRetailPrice',
    'retailRecurringCV',
    'retailRecurringQV',
    'retailRecurringSCV',
    'retailRecurringSQV',
    'retailRecurringFSB',
  ],
};

export enum LEGAL_DOCUMENT_TYPE {
  PRIVACY_POLICY = 'privacy_policy',
  GDPR_POLICY = 'gdpr_policy',
  TERMS_OF_USE = 'terms_of_use',
  REFUND_POLICY = 'refund_policy',
  CANCELLATION_POLICY = 'cancellation_policy',

  DSA_CODE = 'dsa_code_of_ethics',
  POLICIES_AND_PROCEDURES = 'policies_and_procedures',
  AGREEMENT = 'agreement',
  EARNING_DISCLOSURE = 'earning_disclosure',
  COOKIES_POLICY = 'cookies_policy',
  ANTISPAM_POLICY = 'antispam_policy',
  AML_POLICY = 'aml_policy',
  REFUND_AND_CANCELLATION_POLICY = 'refund_and_cancellation_policy',
  COMPENSATION_PLAN = 'compensation_plan',
  EDUCATIONAL_COMPLIANCE_VIDEOS = 'educational_compliance_videos',
  CODE_ETHICS = 'code_ethics',
  IBI_AGREEMENT = 'ibi_agreement',
}

export enum PLAN_SHOP_TYPE {
  MAIN = 'main_shop',
  IBI = 'ibi_shop',
  TLEVEL = 't_level_shop',
  HEALTH = 'health_shop',
  HEALTH_BUNDLE = 'health_bundle_shop',
  ADDON = 'addon_shop',
  MHA_SHOP = 'mha_shop',
  TWN_SHOP = 'twn_shop',
  TRIVA_SHOP = 'triva_shop',
  AFFILIATE_SHOP = 'affiliate_shop',
}

export const VIDEO_MIME_TYPES = ['video/mp4', 'video/mpeg', 'video/x-msvideo', 'video/avi'];
export const IMAGE_MIME_TYPES = ['image/webp'];

export const LANGUAGE_OPTIONS = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'fr',
    label: 'French',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
  {
    value: 'pt',
    label: 'Portuguese',
  },
  {
    value: 'sk',
    label: 'Slovak',
  },
  {
    value: 'ru',
    label: 'Russian',
  },
  {
    value: 'ar',
    label: 'Arabic',
  },
  {
    value: 'it',
    label: 'Italian',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'zh',
    label: 'Chinese',
  },
];

export const WYSIWYG_EDITOR_EMOJI = [
  '😀',
  '😁',
  '😂',
  '😃',
  '😉',
  '😋',
  '😎',
  '😍',
  '😗',
  '🤗',
  '🤔',
  '😣',
  '😫',
  '😴',
  '😌',
  '🤓',
  '😛',
  '😜',
  '😠',
  '😇',
  '😷',
  '😈',
  '👻',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '🙈',
  '🙉',
  '🙊',
  '👼',
  '👮',
  '🕵',
  '💂',
  '👳',
  '🎅',
  '👸',
  '👰',
  '👲',
  '🙍',
  '🙇',
  '🚶',
  '🏃',
  '💃',
  '⛷',
  '🏂',
  '🏌',
  '🏄',
  '🚣',
  '🏊',
  '⛹',
  '🏋',
  '🚴',
  '👫',
  '💪',
  '👈',
  '👉',
  '👉',
  '👆',
  '🖕',
  '👇',
  '🖖',
  '🤘',
  '🖐',
  '👌',
  '👍',
  '👎',
  '✊',
  '👊',
  '👏',
  '🙌',
  '🙏',
  '🐵',
  '🐶',
  '🐇',
  '🐥',
  '🐸',
  '🐌',
  '🐛',
  '🐜',
  '🐝',
  '🍉',
  '🍄',
  '🍔',
  '🍤',
  '🍨',
  '🍪',
  '🎂',
  '🍰',
  '🍾',
  '🍷',
  '🍸',
  '🍺',
  '🌍',
  '🚑',
  '⏰',
  '🌙',
  '🌝',
  '🌞',
  '⭐',
  '🌟',
  '🌠',
  '🌨',
  '🌩',
  '⛄',
  '🔥',
  '🎄',
  '🎈',
  '🎉',
  '🎊',
  '🎁',
  '🎗',
  '🏀',
  '🏈',
  '🎲',
  '🔇',
  '🔈',
  '📣',
  '🔔',
  '🎵',
  '🎷',
  '💰',
  '🖊',
  '📅',
  '✅',
  '❎',
  '💯',
  '✔️',
];
