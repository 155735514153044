import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { EntitySwitch } from '@root/pages/CreateCountriesGroup/EntitySwitch';
import { useStyles } from '@root/pages/CreateCountriesGroup/styles';
import { EntityType } from '@root/pages/CreateCountriesGroup/types';
import { IRelatedEntityVat } from '@root/services/api/requests/relatedEntity';
import { ChangeEvent, Fragment } from 'react';

interface IProps {
  relatedEntity: IRelatedEntityVat[];
  checkedEntityOptions: EntityType<number>;
  handleChangeEntityStatus: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const EntityVat = ({
  relatedEntity,
  checkedEntityOptions,
  handleChangeEntityStatus,
}: IProps) => {
  const classes = useStyles();
  const entityTitle = 'vat';
  return (
    <Fragment>
      <Typography component="h2" variant="h5" className={classes.title}>
        {entityTitle}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {Object.keys(relatedEntity[0]).map((value) => (
              <TableCell>
                <Typography>{value}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {relatedEntity.map((el) => (
            <TableRow key={el.id}>
              <TableCell>
                <EntitySwitch
                  entityTitle={entityTitle}
                  entity={el}
                  checkedEntityOptions={checkedEntityOptions}
                  handleChangeEntityStatus={handleChangeEntityStatus}
                />
              </TableCell>
              <TableCell>{el.id}</TableCell>
              <TableCell>{el.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fragment>
  );
};
