import { Box, Button, Grid, IconButton, Paper, Tab, Tabs } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import FAQItemsTable from '../../components/FAQItemsTable';
import InputSelect from '../../components/InputSelect';
import { faqItemRequests, IFaqItem, planFaqItemRequests } from '../../services/api/requests/faq';
import { editFaqItemData } from '../../store/entities/actions';
import { selectPlans } from '../../store/entities/selectors';
import { getSearchParam, modifyQueryParams } from '../../utils/helpers';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const FAQList: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const plans = useSelector(selectPlans);

  const [isPlanFAQ, setIsPlanFAQ] = React.useState<number>(
    Number(getSearchParam('isPlanFAQ')) || 0
  );
  const [faqList, setFaqList] = React.useState<IFaqItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = React.useState<number | null>(null);

  const FAQApi = isPlanFAQ ? planFaqItemRequests : faqItemRequests;
  const createButtonText = isPlanFAQ ? 'Create Plan FAQ' : 'Create FAQ';

  const itemsList = useMemo(() => {
    if (isPlanFAQ && selectedPlanId) {
      return faqList.filter((item) => item.planIds?.includes(selectedPlanId));
    } else {
      return faqList;
    }
  }, [isPlanFAQ, selectedPlanId, faqList]);

  const availablePlansOptions = useMemo(() => {
    if (!isPlanFAQ) {
      return [];
    }

    const usedPlanIds = Array.from(new Set(faqList.flatMap((item) => item.planIds)));
    const availablePlans = plans.filter((item) => usedPlanIds.includes(item.id));

    return availablePlans.map((plan) => ({
      value: plan.id,
      label: `${plan.productName} ${plan.name || ''}`,
    }));
  }, [plans, faqList, isPlanFAQ]);

  const fetchFaqItemList = async () => {
    try {
      setLoading(true);
      const response = await FAQApi.readFaqItemList();
      const noSelectedPlans =
        !!selectedPlanId &&
        !response.data.data.find((item) => item.planIds?.includes(selectedPlanId));

      setFaqList(response.data.data);
      noSelectedPlans && setSelectedPlanId(null);
      setLoading(false);
    } catch (e) {
      console.warn(e.message);
    }
  };

  useEffect(() => {
    fetchFaqItemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlanFAQ]);

  const deleteFaqItem = async (id: number | string) => {
    try {
      await FAQApi.deleteFaqItem(id);
      setNotification('success', {
        message: 'Success',
      });
      await fetchFaqItemList();
    } catch (e) {
      console.warn(e.message);
    }
  };

  const editFaqItem = async (item: IFaqItem) => {
    dispatch(editFaqItemData(item));
    dispatch(push(`${pageLinks.createEditFaqItem}?isPlanFAQ=${isPlanFAQ}`));
  };

  const createFaq = () => {
    dispatch(push(`${pageLinks.createEditFaqItem}?isPlanFAQ=${isPlanFAQ}`));
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(push(modifyQueryParams('isPlanFAQ', newValue.toString())));
    setIsPlanFAQ(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Tabs
              value={isPlanFAQ}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabClick}
              aria-label="disabled tabs example"
            >
              <Tab label="General FAQ" disabled={loading} />
              <Tab label="Plan FAQ" disabled={loading} />
            </Tabs>

            {!!isPlanFAQ && (
              <Box
                display={'flex'}
                alignItems={'center'}
                gridGap={4}
                style={{ minWidth: 300, marginLeft: 'auto', marginRight: 40 }}
              >
                <InputSelect
                  id="plan"
                  label={'Plan'}
                  placeholder="Select plan"
                  value={selectedPlanId}
                  onChange={(e) => {
                    setSelectedPlanId(e.target.value as number);
                  }}
                  options={availablePlansOptions}
                  size={'small'}
                />
                {!!selectedPlanId && (
                  <IconButton
                    onClick={() => {
                      setSelectedPlanId(null);
                    }}
                    edge="end"
                    style={{ padding: 4 }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
            )}

            <Box display={'flex'}>
              <Box marginRight={1}>
                <Can perform={Permissions.createFaqItem}>
                  <Button
                    onClick={createFaq}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon />}
                  >
                    {createButtonText}
                  </Button>
                </Can>
              </Box>
            </Box>
          </div>
        </Paper>
      </Grid>

      <FAQItemsTable
        loading={loading}
        items={itemsList}
        handleDelete={deleteFaqItem}
        handleEdit={editFaqItem}
      />
    </div>
  );
};

export default FAQList;
