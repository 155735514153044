import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { FilterToggle } from '@root/components/FilterToggle';
import { MembershipLevelsFilter } from '@root/components/MembershipLevelsTable/MembershipLevelsFilters';
import { useMembershipLevelTable } from '@root/components/MembershipLevelsTable/use-membership-levels-table';
import { isNull, isUndefined } from 'lodash';
import { IMembershipLevel } from '../../services/api/requests/membershipLevels';
import Can, { Permissions } from '../Can';
import ColorCell from '../ColorCell';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import TableSkeleton from '../TableSkeleton';
import { IFilterFields } from "@root/components/MembershipLevelsTable/types";

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSort: true,
  },
  {
    id: 'color',
    numeric: false,
    disablePadding: false,
    label: 'Color',
    disableSort: true,
  },
  {
    id: 'memberName',
    numeric: false,
    disablePadding: false,
    label: 'Member Name',
    disableSort: true,
  },
  {
    id: 'minSpendings',
    numeric: false,
    disablePadding: true,
    label: 'Min Spending',
    disableSort: true,
  },
  {
    id: 'maxSpendings',
    numeric: false,
    disablePadding: true,
    label: 'Max Spending',
    disableSort: true,
  },
  {
    id: 'recurringPrice',
    numeric: false,
    disablePadding: true,
    label: 'Recurring Price',
    disableSort: true,
  },
  { id: 'edit', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];

interface IProps {
  loading: boolean;
  levels: IMembershipLevel[] | null;
  handleEdit: (item: IMembershipLevel) => void;
  handleDelete: (id: number) => void;
}

const MembershipLevelsTable = (props: IProps) => {
  const { loading, levels, handleEdit, handleDelete } = props;
  const classes = useStandardTableStyles();
  const [{ isFilterPanelOpen, filterFields }, { toggleFilterPanel, filterHandlers }] =
    useMembershipLevelTable();

  return (
    <>
      <FilterToggle onFilterClick={toggleFilterPanel} />
      {isFilterPanelOpen && (
        <MembershipLevelsFilter filterFields={filterFields} filterHandlers={filterHandlers} />
      )}
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading && !levels?.length ? (
              <TableRow>
                <TableCell>There are no membership rules</TableCell>
              </TableRow>
            ) : (
              !loading &&
              levels?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <ColorCell>{item.color}</ColorCell>
                  </TableCell>
                  <TableCell>{item.memberName}</TableCell>
                  <TableCell>{`${isNull(item.spendings.Monthly?.min) ? '-' : item.spendings.Monthly?.min} / ${
                    isNull(item.spendings.Annual?.min) || isUndefined(item.spendings.Annual?.min)
                      ? '-'
                      : item.spendings.Annual?.min
                  }`}</TableCell>
                  <TableCell>{`${isNull(item.spendings.Monthly?.max) || isUndefined(item.spendings.Monthly?.max) ? '-' : item.spendings.Monthly?.max} / ${
                    isNull(item.spendings.Annual?.max) || isUndefined(item.spendings.Annual?.max)
                      ? '-'
                      : item.spendings.Annual?.max
                  }`}</TableCell>
                  <TableCell>{`${
                    isNull(item.recurringPrices.Monthly) ||
                    isUndefined(item.recurringPrices.Monthly)
                      ? '-'
                      : item.recurringPrices.Monthly
                  } / ${
                    isNull(item.recurringPrices.Annual) || isUndefined(item.recurringPrices.Annual)
                      ? '-'
                      : item.recurringPrices.Annual
                  }`}</TableCell>
                  <TableCell align="center">
                    <Can perform={Permissions.updateMembershipLevel}>
                      <IconButton aria-label="edit" title={'Edit'} onClick={() => handleEdit(item)}>
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="center">
                    {item?.id && (
                      <Can perform={Permissions.deleteMembershipLevel}>
                        <DeleteModalButton
                          name={`${item.name}`}
                          entity={'membership level'}
                          onDelete={() => handleDelete(item?.id as number)}
                        />
                      </Can>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MembershipLevelsTable;
