import { Button, Grid } from '@material-ui/core';
import FileInput from '@root/components/FileInput';
import InputSelect from '@root/components/InputSelect';
import { IMAGE_UPLOAD_ENTITY } from '@root/constants';
import { createInput, useStandardFormStyles } from '../FormBase';
import { IFormValues } from './types';
import { useFormHandler } from './use-formik';


export default function CreateProduct() {
  const classes = useStandardFormStyles();
  const { fk, isEdit } = useFormHandler();
  const createInputField = createInput<IFormValues>(fk);
  const createEditText = isEdit ? 'Edit' : 'Create';

  return (
    <div className={classes.formContainer}>
      <form className={classes.form} onSubmit={fk.handleSubmit}>
        <Grid container spacing={2}>
          {createInputField('name', 'Product Name*', 12)}
          {createInputField('description', 'Description', 12)}
          {createInputField('rebillyProductId', 'Rebilly Product Id*', 6)}
          {createInputField('priority', 'Product Priority', 6, 'number')}
          {createInputField('urlParam', 'Shop URL name', 6)}

          <Grid item xs={12} md={6}>
            <InputSelect
              id="status"
              label={'Status'}
              value={fk.values.status}
              onChange={(e) => fk.setFieldValue('status', e.target.value)}
              options={[
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
              ]}
            />
          </Grid>

          {!isEdit && (
            <>
              <Grid item xs={12}>
                <FileInput
                  id={'imageUrl'}
                  label={'Product preview image'}
                  imageUrl={fk.values.imageUrl}
                  imageUrlField={'imageUrl'}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.PRODUCT}
                />
              </Grid>
              <Grid item xs={12}>
                <FileInput
                  id={'fullImageUrl'}
                  label={'Bundle Background'}
                  imageUrl={fk.values.fullImageUrl}
                  imageUrlField={'fullImageUrl'}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.PRODUCT_FULL_IMAGE}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!fk.isValid}
          className={classes.submit}
        >
          {createEditText}
        </Button>
      </form>
    </div>
  );
}
