import { Button, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InputSelect from '@root/components/InputSelect';
import Modal from '@root/components/Modal';
import { IDateState } from '@root/components/OrdersSearch/types';
import {
  IGiftCardsReportsFiltersProps,
  purchaseTypeOptions,
  statusOptions,
} from '@root/pages/GiftCardsReport/types';
import { FC } from 'react';
import { DateRangePicker } from 'react-date-range';

export const GiftCardsReportsFilters: FC<IGiftCardsReportsFiltersProps> = ({
  filtersState,
  filtersHandlers,
}) => {
  const { filtersFields, dateRangeValue, redeemedDateState } = filtersState;
  const { handleFilterChange, filtersApply, handleRangeClear, setRedeemedDateState } =
    filtersHandlers;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <InputSelect
          id="purchaseType"
          label="Purchase type"
          name="purchaseType"
          variant="outlined"
          fullWidth
          options={purchaseTypeOptions}
          value={filtersFields.purchaseType}
          onChange={handleFilterChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputSelect
          id="status"
          label="Status"
          name="status"
          variant="outlined"
          fullWidth
          options={statusOptions}
          value={filtersFields.status}
          onChange={handleFilterChange}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          id="amountFrom"
          label="Amount from"
          name="amountFrom"
          variant="outlined"
          type="number"
          fullWidth
          value={filtersFields.amount.amountFrom}
          onChange={handleFilterChange}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          id="amountTo"
          label="Amount to"
          name="amountTo"
          variant="outlined"
          type="number"
          fullWidth
          value={filtersFields.amount.amountTo}
          onChange={handleFilterChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Modal
          button={
            <TextField
              variant="outlined"
              fullWidth
              type={'text'}
              label={'Redeemed date range'}
              value={dateRangeValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={(e) => handleRangeClear(e)} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          }
          renderContent={() => (
            <DateRangePicker
              onChange={(item) => {
                setRedeemedDateState(item.selection as IDateState);
              }}
              moveRangeOnFirstSelection={true}
              months={2}
              ranges={[redeemedDateState]}
              direction="horizontal"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" color="primary" fullWidth onClick={filtersApply}>
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};
