import {
  Box,
  Button,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import 'date-fns';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../components/BackButton';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import { IGetInstance } from '../../services/api/requests/getInstances';
import { editCompanyInfoData } from '../../store/entities/actions';
import { selectCompanyInfoForEditing, selectInstances } from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import { createOptions } from '../../utils/helpers';
import pageLinks from '../../utils/pageLinks';
import { IValues } from './types';
import { useCompanyInfoFormik } from './use-formik';

const CreateEditCompanyInfo: React.FC<{}> = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();

  const selectedCompanyInfo = useSelector(selectCompanyInfoForEditing);
  const instances: IGetInstance[] = useSelector(selectInstances);
  const instanceOptions = createOptions<IGetInstance>(instances, 'id', 'name');

  const fk = useCompanyInfoFormik(selectedCompanyInfo);

  const actionText = selectedCompanyInfo ? 'Edit' : 'Create';

  const createInputField = createInput<IValues>(fk);

  useEffect((): any => {
    return () => dispatch(editCompanyInfoData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.companyInfo} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Company Info`}</Title>
        <CssBaseline />

        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('name', 'Company Name', 6)}
              {createInputField('registrationNumber', 'Registration Number', 6)}

              <Grid item xs={12} md={12}>
                <Autocomplete
                  id={'instanceIds'}
                  options={instanceOptions}
                  disabled={fk?.values.isDefault}
                  value={instanceOptions.filter(
                    (item) => !!fk.values.instanceIds.find((i) => item.value === i)
                  )}
                  onChange={(e: any, instancesOptions: IOption[]) =>
                    fk.setFieldValue(
                      'instanceIds',
                      instancesOptions.map((r) => r.value)
                    )
                  }
                  autoHighlight
                  multiple
                  fullWidth
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose an instance"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6} className={classes.checkbox}>
                <FormControlLabel
                  labelPlacement="start"
                  label={<Typography>Show on Contact Us Page:</Typography>}
                  control={
                    <Switch
                      id={'isShown'}
                      checked={fk.values.isShown}
                      onChange={(e) => {
                        fk.handleChange(e);
                      }}
                      name="isShown"
                      color="primary"
                    />
                  }
                />
              </Grid>
              <Grid item xs={6} md={6} className={classes.checkbox}>
                <FormControlLabel
                  labelPlacement="start"
                  label={<Typography>Default:</Typography>}
                  control={
                    <Switch
                      id={'isDefault'}
                      checked={fk.values.isDefault}
                      onChange={(e) => {
                        fk.handleChange(e);
                        if (!!e.target.checked) {
                          fk.setFieldValue('instanceIds', []);
                        }
                      }}
                      name="isDefault"
                      color="primary"
                    />
                  }
                />
              </Grid>

              <Box ml={1} mt={2} color="#673ab7" width={'100%'}>
                Contact info:
              </Box>
              {createInputField('email', 'Email', 6)}
              {createInputField('phone', 'Phone', 6)}

              <Box ml={1} mt={2} color="#673ab7">
                Address:
              </Box>
              {createInputField('addressLine1', 'Address Line 1', 12)}
              {createInputField('addressLine2', 'Address Line 2', 12)}
              {createInputField('addressLine3', 'Address Line 3', 12)}

              <Box ml={1} mt={2} color="#673ab7">
                Social:
              </Box>
              {createInputField('twitter', 'Twitter', 12)}
              {createInputField('facebook', 'Facebook', 12)}
              {createInputField('instagram', 'Instagram', 12)}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditCompanyInfo;
