import qs from 'qs';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta } from '../types';

export interface ILog {
  id: number;
  action: string;
  tag: string;
  level: string;
  ip: string;
  url: string;
  os: string;
  browser: string;
  device: string;
  data: {
    customerId?: number;
    adminId?: number;
    username?: string;
    adminName?: string;
    instance?: string;
    error?: string;
  };
  oldData?: string;
  newData?: string;
  shopType: string;
  userId: string;
  result: string;

  createdAt: Date;
  updatedAt: Date;
}

export interface ICustomersLogsResponse {
  data: {
    rows: ILog[];
    count: number;
    meta?: IMeta;
  };
}

export type SortBy = 'createdAt';

interface IProps {
  data: any;
  page: number;
  action: string;
  limit: number;
  from: string;
  to: string;
  userId: string;
  shopType?: string;
}

const request = (params: IProps) => {
  const sanitize = (obj: any) => {
    return JSON.parse(
      JSON.stringify(obj, (key, value) => {
        return value === '' || value === 'all' ? undefined : value;
      })
    );
  };

  const query = qs.stringify(sanitize(params), { encodeValuesOnly: true });

  return apiClient
    .get<{ data: ICustomersLogsResponse }>(ApiUrls.readCustomersLogs + `?${query}`)
    .then((response) => response.data);
};

export default request;
