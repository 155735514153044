import DateFnsUtils from '@date-io/date-fns';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import deleteConversionTemplate from '../../services/api/requests/deleteConversionTemplate';
import { IConversionTemplate } from '../../services/api/requests/getConversionTemplatesList';
import { editConversionTemplate } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { PayoutTablesData, PayoutTabs } from '../PayoutExport';
const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created at',
    disableSort: true,
  },
  {
    id: 'fee',
    numeric: false,
    disablePadding: false,
    label: 'Conversion fee',
    disableSort: true,
  },
  { id: 'edit', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];
interface IProps extends PayoutTablesData {
  list: IConversionTemplate[] | null;
}
const ConversionTemplates = ({
  loading,
  list,
  handleChangePage,
  handleChangeRowsPerPage,
  meta,
}: IProps) => {
  const dispatch = useDispatch();
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const handleEdit = (data: IConversionTemplate) => {
    dispatch(editConversionTemplate(data));
    dispatch(push(`${pageLinks.createEditConversionTemplate}?templateId=${data.id}`));
  };
  const handleDelete = async (id: number) => {
    try {
      const response = await deleteConversionTemplate(id);
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
      }
    } catch (e) {
      console.warn(e.message);
    }
  };
  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading && !list?.length ? (
              <TableRow>
                <TableCell align="center">There are no conversion templates</TableCell>
              </TableRow>
            ) : (
              list?.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align="left">{item.name}</TableCell>
                    <TableCell align="left">
                      {dateFns.format(new Date(item.createdAt), 'dd/MM/yyyy hh:mm')}
                    </TableCell>
                    <TableCell align="left">{item.conversionFee}</TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.updateConversionTemplate}>
                        <IconButton
                          aria-label="edit"
                          title={'Edit'}
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.deleteConversionTemplate}>
                        <DeleteModalButton
                          name={item.name}
                          entity={'conversion template'}
                          onDelete={() => handleDelete(item.id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={(e: unknown, newPage: number) =>
            handleChangePage(e, newPage, PayoutTabs.CONVERSION_TEMPLATES)
          }
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(event, PayoutTabs.CONVERSION_TEMPLATES)
          }
        />
      </TableContainer>
    </>
  );
};

export default ConversionTemplates;
