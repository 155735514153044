import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import { INotification, NotificationType } from '../../services/api/requests/notifications';
import pageLinks from '../../utils/pageLinks';
import { useNotificationsTable } from './use-table';

const headCells = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    disableSort: true,
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: 'Message',
    disableSort: true,
  },
  {
    id: 'pages',
    numeric: false,
    disablePadding: false,
    label: 'Pages',
    disableSort: true,
  },
  {
    id: 'intervalDisplayInHours',
    numeric: false,
    disablePadding: false,
    label: 'Interval Display, h',
    disableSort: true,
  },
  {
    id: 'forAuthed',
    numeric: false,
    disablePadding: false,
    label: 'Auth type',
    disableSort: true,
  },

  { id: 'edit', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];

const truncateString = (str: string, limit: number) => {
  if (str.length > limit) {
    return str.substring(0, limit) + '...';
  } else {
    return str;
  }
};

const getLabelColor = (type: INotification['type']) => {
  switch (type) {
    case NotificationType.NOTE:
      return '#3498db';
    case NotificationType.TIP:
      return '#10af4d';
    case NotificationType.WARNING:
      return '#efc20e';
    default:
      return '#b2b1b1';
  }
};

const EnhancedTable: FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const [{ list, loading }, { handleEdit, handleDelete }] = useNotificationsTable();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Notifications</Title>
            <Can perform={Permissions.createNotification}>
              <Button
                onClick={() => dispatch(push(pageLinks.createEditNotification))}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add notification
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading && !list?.length ? (
              <TableRow>
                <TableCell>There are no Notifications</TableCell>
              </TableRow>
            ) : (
              list.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align={'left'}>
                      <Box style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                        <div
                          style={{
                            width: 14,
                            height: 14,
                            background: getLabelColor(item.type),
                            borderRadius: 100,
                          }}
                        ></div>
                        <span style={{ textTransform: 'capitalize' }}>{item.type}</span>
                      </Box>
                    </TableCell>
                    <TableCell align={'left'} style={{ maxWidth: 400 }}>
                      {truncateString(item.message, 100)}
                    </TableCell>
                    <TableCell align={'left'}>
                      <Box display={'flex'} gridGap={8} flexWrap={'wrap'}>
                        {item.pageNames.map((item, index) => (
                          <Chip label={item} key={`${index}_${item}`} />
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell align={'left'}>{item?.intervalDisplayInHours || 0}</TableCell>
                    <TableCell align={'left'}>
                      {item?.isForLoggedInOnly ? 'Authed users' : 'All users'}
                    </TableCell>

                    <TableCell align="center">
                      <Can perform={Permissions.updateNotification}>
                        <IconButton
                          aria-label="edit"
                          title={'Edit'}
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.deleteNotification}>
                        <DeleteModalButton
                          name={`"${truncateString(item.message, 20)}" Notification`}
                          entity={'notifications'}
                          onDelete={() => handleDelete(item.id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EnhancedTable;
