import { IMeta } from '@root/services/api/types';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IPromoDiscount {
  id?: number;
  title: string;
  description: string;
  planIds: number[];
  data: {
    RENEW: Record<string, number>;
  };
  published: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface IPromoDiscountResponse {
  data: {
    items: IPromoDiscount[];
    meta: IMeta;
  };
}

export interface ICreateEditPromoDiscountResponse {
  data: IPromoDiscount;
}

export interface IGetListParams {
  page: number;
  sortBy: string;
  sortDirection: 'DESC' | 'ASC';
  limit: number;
}

const getList = (params: IGetListParams) => {
  const query = new URLSearchParams(params as unknown as Record<string, string>).toString();
  return apiClient
    .get<IPromoDiscountResponse>(`${ApiUrls.promoDiscount}/?${query}`)
    .then((response) => response);
};

const create = (data: IPromoDiscount) => {
  return apiClient
    .post<ICreateEditPromoDiscountResponse>(ApiUrls.promoDiscount, data)
    .then((response) => response);
};

const edit = (data: IPromoDiscount, id: number) =>
  apiClient
    .put<ICreateEditPromoDiscountResponse>(`${ApiUrls.promoDiscount}/${id}`, data)
    .then((response) => response);

const deleteItem = (id: string | number) => {
  return apiClient.delete(`${ApiUrls.promoDiscount}/${id}`).then((response) => response);
};

const PromoDiscount = {
  getList,
  create,
  edit,
  delete: deleteItem,
};

export default PromoDiscount;
