import * as Yup from 'yup';

const maxNumber = 10000;
const questDollarsMaxNumber = 1000000;

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(50),
  enabled: Yup.bool(),
  isVisible: Yup.bool(),
  exigoId: Yup.number(),
  recurringSku: Yup.string(),
  oneDayRecurringSku: Yup.string(),
  oneDayDeductSku: Yup.string(),
  sku: Yup.string(),
  websiteUrl: Yup.string(),
  mobileAppUrlIos: Yup.string(),
  mobileAppUrlAndroid: Yup.string(),
  imageUrl: Yup.string().nullable(),
  bullets: Yup.array().of(Yup.string()),
  cv: Yup.number().max(maxNumber),
  qv: Yup.number().max(maxNumber),
  scv: Yup.number().max(maxNumber),
  sqv: Yup.number().max(maxNumber),
  fsb: Yup.number().max(maxNumber),
  rcv: Yup.number().max(maxNumber),
  rqv: Yup.number().max(maxNumber),
  rscv: Yup.number().max(maxNumber),
  matrixBV: Yup.number(),
  rsqv: Yup.number().max(maxNumber),
  rfsb: Yup.number().max(maxNumber),
  INITIAL: Yup.number().max(questDollarsMaxNumber).min(0),
  RENEW: Yup.number().max(questDollarsMaxNumber).min(0),
  reduceCoreRewards: Yup.bool(),
  skus: Yup.object().shape({
    Annual: Yup.string(),
    Monthly: Yup.string(),
  }),
  recurringSkus: Yup.object().shape({
    Annual: Yup.string(),
    Monthly: Yup.string(),
  }),
});
