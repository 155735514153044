import { EntityType } from "@root/pages/CreateCountriesGroup/types";
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { COUNTRY_GROUP_RELATED_ENTITY } from "@root/services/api/requests/relatedEntity";

export interface CountriesGroupRequest {
  countryIds: number[];
  countryCodes: string[];
  name: string;
  relations: Record<COUNTRY_GROUP_RELATED_ENTITY, EntityType<number>> | {};
}

const request = (data: CountriesGroupRequest) => {
  return apiClient
    .post<CountriesGroupRequest>(ApiUrls.countriesGroupsCreate, data)
    .then((response) => response.data);
};

export default request;
