import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

export enum EVENT_STATUSES {
  PUBLISHED = 'published',
  IN_PROGRESS = 'in_progress',
  PROCESSED = 'processed',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  RETRY_AFTER_ERROR = 'retry_after_error',
  FAILED = 'failed',
}

export interface IEventData {
  subscriptionId: string;
  userId: string;
}

export interface IJobClient {
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

export interface IJobDataData {
  client: IJobClient;
  createdAt: string;
  data: IEventData;
  id: number;
  info: string;
  jobId: number;
  messagePattern: string;
  name: string;
  relatedId: string;
  status: EVENT_STATUSES;
  time: string;
  updatedAt: string;
}

export interface IOpts {
  delay: number;
  attempts: number;
  backoff: {
    delay: number;
    type: string;
  };
  removeOnComplete: boolean;
  removeOnFail: boolean;
  timeout: number;
  timestamp: number;
}

export interface IJobData {
  attemptsMade: number;
  data: IJobDataData;
  delay: number;
  finishedOn: number;
  id: string;
  name: string;
  opts: IOpts;
  processedOn: number;
  progress: number;
  stacktrace: string[];
  timestamp: number;
}

export interface ITriggerEvent {
  id: number | string;
  name: string;
  jobId: string;
  status: EVENT_STATUSES;
  relatedId: string;
  messagePattern: string;
  time: string;
  data: IEventData;
  createdAt: string;
  updatedAt: string;
  returnvalue: number;
}

export interface ITriggerEventItem extends ITriggerEvent {
  jobData: IJobData;
}

export interface ITriggerEventResponse {
  data: {
    items: ITriggerEvent[];
    meta: IMeta;
  };
}

export interface ITriggerEventItemResponse {
  data: ITriggerEventItem;
}

const readTriggerEventList = (params: IPaginationParams) => {
  const query = createQueryParams<IPaginationParams>(params);

  return apiClient
    .get<ITriggerEventResponse>(ApiUrls.triggerEventList + `?${query}`)
    .then((response) => response);
};

const readTriggerEventItem = (id: number | string) => {
  return apiClient
    .get<ITriggerEventItemResponse>(ApiUrls.triggerEvent + `${id}`)
    .then((response) => response);
};

const triggerEventRequests = {
  readTriggerEventList,
  readTriggerEventItem,
};

export default triggerEventRequests;
