import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import CountrySelect from '../../components/CountrySelect';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import createInstance, { ICreateInstance } from '../../services/api/requests/createInstance';
import editInstance, { IEditInstance } from '../../services/api/requests/editInstance';
import getInstanceList from '../../services/api/requests/getInstanceList';
import { ICountry, INSTANCE_TYPES } from '../../services/api/requests/getInstances';
import getInstanceWebsites, {
  IInstanceWebsite,
} from '../../services/api/requests/getInstanceWebsites';
import { editInstanceData, updateInstanceList } from '../../store/entities/actions';
import { selectInstanceForEditing } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { typeOptions } from '../Instances';

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(2),
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .trim()
    .matches(/^[a-zA-Z\s]+$/, 'Only latin letters and spaces')
    .max(40, "Value can't be more than 40 characters long"),
  companyName: Yup.string()
    .required()
    .trim()
    .matches(/^[a-zA-Z\s]+$/, 'Only latin letters and spaces')
    .max(40, "Value can't be more than 40 characters long"),
  code: Yup.string()
    .required()
    .trim()
    .matches(/^[a-zA-Z]+$/, 'Only latin letters')
    .max(40, "Value can't be more than 40 characters long"),
  countryCodes: Yup.string().required(),
  priority: Yup.number().required().min(1, 'Value must be be grater than 0').nullable(),
  baseCountryCode: Yup.string().required(),
  type: Yup.string().required(),
  websiteId: Yup.string().required(),
});

const initialValues: ICreateInstance = {
  name: '',
  code: '',
  countryCodes: [],
  baseCountryCode: '',
  defaultBaseUrl: '',
  companyName: '',
  priority: '',
  type: INSTANCE_TYPES.SHOP,
  websiteId: '',
  oldShop: false,
};

const CreateEditInstance: React.FC<{}> = () => {
  const classes = useStandardFormStyles();
  const localClasses = useStyles();
  const dispatch = useDispatch();
  const selectedInstance = useSelector(selectInstanceForEditing);
  const actionText = selectedInstance ? 'Edit' : 'Create';

  const [websitesList, setWebsitesList] = React.useState<IInstanceWebsite[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [priorityList, setPriorityList] = React.useState<Array<{
    priority: number;
    defaultBaseUrl: string;
  }> | null>(null);

  const websitesOptions = React.useMemo(() => {
    return websitesList.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }, [websitesList]);

  const fetchWebsitesList = async () => {
    try {
      const response = await getInstanceWebsites();

      setWebsitesList(response.data.data);
    } catch (e) {
      console.warn(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchWebsitesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): any => {
    return () => dispatch(editInstanceData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<ICreateInstance> = useFormik({
    initialValues: selectedInstance
      ? {
          name: selectedInstance.name,
          code: selectedInstance.code,
          priority: selectedInstance.priority,
          defaultBaseUrl: selectedInstance.defaultBaseUrl,
          companyName: selectedInstance.companyName,
          countryCodes: selectedInstance.countries.map((country: ICountry) => country.code),
          baseCountryCode: selectedInstance?.baseCountry?.code,
          websiteId: selectedInstance.websiteId,
          type: selectedInstance.type,
          oldShop: selectedInstance.oldShop,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const createData: ICreateInstance = {
        name: values.name.trim(),
        code: values.code.trim(),
        priority: +values.priority,
        countryCodes: values.countryCodes,
        baseCountryCode: values.baseCountryCode,
        defaultBaseUrl: values.defaultBaseUrl.trim(),
        companyName: values.companyName.trim(),
        websiteId: values.websiteId.trim(),
        type: values.type,
        oldShop: values.oldShop,
      };

      if (selectedInstance) {
        const editData: IEditInstance = {
          ...createData,
          id: selectedInstance.id,
        };
        editInstance(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.instances));
            dispatch(updateInstanceList());
          }
        });
        return;
      }

      createInstance(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.instances));
          dispatch(updateInstanceList());
        }
      });
    },
  });

  useEffect(() => {
    const fetchInstancesList = async () => {
      try {
        const response = await getInstanceList({
          page: 1,
          limit: 50,
          type: fk.values.type,
        });

        const priorityList = response.data.data.items
          .map(({ priority, defaultBaseUrl }) => ({
            defaultBaseUrl,
            priority,
          }))
          .sort((a, b) => b.priority - a.priority);

        setPriorityList(priorityList);
      } catch (e) {
        setPriorityList(null);
      }
    };

    fetchInstancesList().catch(console.log);
  }, [fk.values.type, setPriorityList]);

  useEffect(() => {
    if (fk.values.baseCountryCode && !fk.values.countryCodes?.includes(fk.values.baseCountryCode)) {
      fk.setFieldValue('baseCountryCode', '');
    }
  }, [fk]);

  const createInputField = createInput<ICreateInstance>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.instances} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Instance`}</Title>
        <CssBaseline />
        {loading && (
          <Box alignItems={'center'} justifyContent={'center'} my={8} mx="auto">
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={fk.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    labelPlacement="start"
                    label={<Typography className={localClasses.label}>Type:</Typography>}
                    control={
                      <InputSelect
                        id="type"
                        options={typeOptions}
                        value={fk.values.type}
                        onChange={(e): void => {
                          fk.setFieldValue('type', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={6} md={6} className={classes.checkbox}>
                  <FormControlLabel
                    labelPlacement="start"
                    label={<Typography>Old Shop:</Typography>}
                    control={
                      <Switch
                        id={'oldShop'}
                        checked={fk.values.oldShop}
                        onChange={fk.handleChange}
                        name="oldShop"
                        color="primary"
                      />
                    }
                  />
                </Grid>

                {createInputField('name', 'Name', 6)}
                {createInputField('code', 'Code', 6)}
                {createInputField('defaultBaseUrl', 'Default Base Url', 6)}
                {createInputField('companyName', 'Company name', 6)}
                <Grid item xs={12} md={12}>
                  <InputSelect
                    id="websiteId"
                    label={'Rebilly Website ID'}
                    value={fk.values.websiteId}
                    required
                    onChange={(e) => fk.setFieldValue('websiteId', e.target.value)}
                    options={websitesOptions}
                    error={!!(fk.errors.websiteId && fk.touched.websiteId)}
                    onBlur={fk.handleBlur}
                  />
                </Grid>

                <Box ml={1} mt={2} color="#673ab7">
                  Higher value of the Priority field - higher priority of the instance
                  <br />
                  {priorityList ? (
                    <>
                      Already created:
                      {priorityList?.map((item, index) => (
                        <Box color={!index ? 'red' : '#000'}>
                          {item.defaultBaseUrl} - {item.priority} {!index ? '(highest)' : null}
                        </Box>
                      ))}
                    </>
                  ) : null}
                </Box>

                {createInputField('priority', 'Priority', 12)}

                <Grid item xs={12} md={12}>
                  <CountrySelect
                    id={'countryCodes'}
                    value={fk.values.countryCodes}
                    onChange={fk.setFieldValue}
                    availableOptions={null}
                    withGroups
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <CountrySelect
                    disabled={!fk.values.countryCodes.length}
                    id={'baseCountryCode'}
                    label="Select Base country"
                    value={fk.values.baseCountryCode}
                    availableOptions={[...fk.values.countryCodes]}
                    multiple={false}
                    onChange={fk.setFieldValue}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {actionText}
              </Button>
            </form>
          </div>
        )}
      </Paper>
    </Grid>
  );
};

export default CreateEditInstance;
