import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { FilterToggle } from '@root/components/FilterToggle';
import InputSelect from '@root/components/InputSelect';
import Modal from '@root/components/Modal';
import { IDateState } from '@root/components/OrdersSearch/types';
import { useStyles } from '@root/pages/Installmentsorders/styles';
import { IFiltersProps } from '@root/pages/Installmentsorders/types';
import { FC } from 'react';
import { DateRangePicker } from 'react-date-range';

export const InstallmentsOrdersFilter: FC<IFiltersProps> = ({ filterState, filterHandlers }) => {
  const classes = useStyles();
  const { isFilterPanelOpen, dateRangeValue, dateState, statusOptions, filterStatus, userName } =
    filterState;
  const {
    handleToggleFilterPanel,
    setDateState,
    handleRangeClear,
    handleFilterStatusChange,
    applyFilters,
    handleChangeUserName,
    clearUserName,
  } = filterHandlers;

  return (
    <>
      <FilterToggle onFilterClick={handleToggleFilterPanel} />
      {isFilterPanelOpen && (
        <Grid container spacing={2} className={classes.filterWrapper}>
          <Grid item xs={12} sm={4}>
            <InputSelect
              id="Status"
              label="Status"
              name="status"
              variant="outlined"
              fullWidth
              options={statusOptions}
              value={filterStatus}
              onChange={handleFilterStatusChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Modal
              button={
                <TextField
                  variant="outlined"
                  fullWidth
                  type={'text'}
                  label={'Date Range'}
                  value={dateRangeValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={(e) => handleRangeClear(e)} edge="end">
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
              renderContent={() => (
                <DateRangePicker
                  onChange={(item) => {
                    setDateState(item.selection as IDateState);
                  }}
                  moveRangeOnFirstSelection={true}
                  months={2}
                  ranges={[dateState]}
                  direction="horizontal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              type={'text'}
              label={'User Name'}
              value={userName}
              onChange={handleChangeUserName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={clearUserName} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" color="primary" fullWidth onClick={applyFilters}>
              Apply
            </Button>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Button variant="contained" color="primary" fullWidth onClick={applyFilters}>
              Search
            </Button>
          </Grid> */}
        </Grid>
      )}
    </>
  );
};
