import { Button, CssBaseline, FormControlLabel, Grid, Paper, Switch } from '@material-ui/core';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import FileInput from '../../components/FileInput';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import umustlookItemRequests, {
  ICreateUmustlookItem,
  IEditUmustlookItemParams,
} from '../../services/api/requests/umustlookItems';
import { editUmustlookItemData } from '../../store/entities/actions';
import { selectUmustlookItemForEditing } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const numberError = 'Please, specify a number';
const minNumberError = "Can't be less then zero";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(20),
  description: Yup.string().required().max(100),
  priority: Yup.number().typeError(numberError).min(0, minNumberError),
  externalLink: Yup.string().required().max(40),
  imageUrl: Yup.string().required(),
});

interface IValues extends ICreateUmustlookItem {}

const initialValues: IValues = {
  name: '',
  description: '',
  priority: 1,
  externalLink: '',
  imageUrl: '',
  isBestSeller: false,
  isActive: false,
};

const CreateEditUmustlookItem: React.FC<{}> = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const selectedUmustlookItem = useSelector(selectUmustlookItemForEditing);
  const actionText = selectedUmustlookItem ? 'Edit' : 'Create';

  useEffect((): any => {
    return () => dispatch(editUmustlookItemData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<IValues> = useFormik({
    initialValues: selectedUmustlookItem
      ? {
          name: selectedUmustlookItem.name,
          description: selectedUmustlookItem.description,
          priority: selectedUmustlookItem.priority,
          externalLink: selectedUmustlookItem.externalLink,
          imageUrl: selectedUmustlookItem.imageUrl,
          isBestSeller: selectedUmustlookItem.isBestSeller,
          isActive: selectedUmustlookItem.isActive,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values: IValues) => {
      const createData: ICreateUmustlookItem = {
        name: values.name.trim(),
        description: values.description,
        priority: values.priority,
        externalLink: values.externalLink,
        imageUrl: values.imageUrl,
        isBestSeller: values.isBestSeller,
        isActive: values.isActive,
      };

      if (selectedUmustlookItem) {
        const editData: IEditUmustlookItemParams = {
          ...createData,
          id: selectedUmustlookItem.id,
        };

        umustlookItemRequests.editUmustlookItem(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.umustlookSettings));
          }
        });
        return;
      }

      umustlookItemRequests.createUmustlookItem(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.umustlookSettings));
        }
      });
    },
  });

  const createInputField = createInput<IValues>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.umustlookSettings} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Umustlook Card`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('name', 'Name', 6)}
              {createInputField('description', 'Description', 6)}
              {createInputField('priority', 'Priority', 6, 'number')}
              {createInputField('externalLink', 'External Link', 6, 'string')}

              <Grid item xs={12}>
                <FileInput
                  id={'imageUrl'}
                  label={'Image'}
                  imageUrl={fk.values.imageUrl}
                  imageUrlField={'imageUrl'}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.UMUSTLOOK_ITEM}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'isBestSeller'}
                      checked={fk.values.isBestSeller}
                      onChange={fk.handleChange}
                      name="isBestSeller"
                      color="primary"
                    />
                  }
                  label="Is BestSeller"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'isActive'}
                      checked={fk.values.isActive}
                      onChange={fk.handleChange}
                      name="isActive"
                      color="primary"
                    />
                  }
                  label="Is Active"
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditUmustlookItem;
