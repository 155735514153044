import { Box, Button, CssBaseline, Grid, IconButton, Paper, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import Can, { Permissions } from '../../components/Can';
import DeleteModalButton from '../../components/DeleteModalButton';
import EditStatusButton from '../../components/EditStatusButton';
import FileInput from '../../components/FileInput';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import changeComboPlanStatus, { IParams } from '../../services/api/requests/changeComboPlanStatus';
import { ICreateCombo } from '../../services/api/requests/createComboPlan';
import createComboProduct from '../../services/api/requests/createComboProduct';
import deleteCombo from '../../services/api/requests/deleteCombo';
import editComboProductAxios from '../../services/api/requests/editComboProduct';
import getComboProductPlans, {
  IComboPlanData,
  IComboProductWithPlans,
} from '../../services/api/requests/getComboProductPlans';
import { IProductListItem } from '../../services/api/requests/getProductList';
import { editCombo, editComboPlan } from '../../store/entities/actions';
import { selectComboForEditing } from '../../store/entities/selectors';
import setNotification, { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('name is required')
    .max(40, '40 characters max')
    .matches(/^[a-zA-Z\s]+([\s]?)+[a-zA-Z]+$/, 'These characters are not supported'),
  description: Yup.string().max(200, '200 characters max '),
  urlParam: Yup.string().nullable().required('URL param is required'),
});

interface IFormValues {
  name: string;
  description: string;
  file?: any;
  id?: string;
  bgColor: string;
  color: string;
  priority: number;
  status: string;
  imageUrl: string | null;
  fullImageUrl: string | null;
  urlParam: string;
}

const initialValues: IFormValues = {
  name: '',
  description: '',
  file: '',
  bgColor: '#fff',
  color: '#000',
  priority: 0,
  status: 'active',
  imageUrl: null,
  fullImageUrl: null,
  urlParam: '',
};

export default function CreateComboProduct() {
  const classes = useStandardFormStyles();

  const productForEditing = useSelector(selectComboForEditing);

  const {
    name,
    description,
    file,
    bgColor,
    color,
    priority,
    status,
    imageUrl,
    fullImageUrl,
    urlParam,
  } = productForEditing || initialValues;

  const [fullProductData, setData] = useState<IComboProductWithPlans>({} as IComboProductWithPlans);

  const { comboInfo: plans } = fullProductData;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!productForEditing) {
      fetchProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<IFormValues> = useFormik({
    initialValues: {
      name,
      description: description || '',
      file,
      bgColor,
      color,
      priority,
      status,
      imageUrl,
      fullImageUrl,
      urlParam,
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: IFormValues) => {
      try {
        const response = productForEditing
          ? await editComboProductAxios({
              ...values,
              id: productForEditing.id,
            })
          : await createComboProduct(values);

        //!TODO: Orishko, please check!
        // @ts-ignore
        dispatch(editCombo(response.data as IProductListItem));
        setSuccessNotification();
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  const createInputField = createInput<IFormValues>(fk);

  const createPlan = () => {
    dispatch(push(pageLinks.createEditCombo + `?id=${productForEditing?.id}`));
    dispatch(editComboPlan(null));
  };

  const handleEdit = (plan: ICreateCombo) => {
    dispatch(push(pageLinks.createEditCombo + `?id=${productForEditing?.id}`));
    // const data = { plans: plans, editPlanId: plan.id };
    dispatch(editComboPlan(plan));
  };

  const fetchProduct = (id: string = productForEditing?.id as string) => {
    getComboProductPlans(id).then((response) => {
      if (response) {
        setData(response.data.data);
      }
    });
  };

  const handleDeletePlan = (id: string) => {
    deleteCombo(id).then((response) => {
      if (response) {
        setNotification('success', { message: 'Plan has been deleted' });
        fetchProduct();
      }
    });
  };

  const handleStatusChange = async (data: IParams) => {
    const response = await changeComboPlanStatus(data);
    if (response) {
      setSuccessNotification();
      fetchProduct();
    }
  };

  const createEditText = productForEditing ? 'Edit' : 'Create';

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.products} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{createEditText} Combo Product</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Product Name*"
                  disabled={!!productForEditing}
                  error={!!(fk.errors.name && fk.touched.name)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.name && fk.errors.name}
                  value={fk.values.name}
                  onChange={fk.handleChange}
                />
              </Grid>

              {createInputField('description', 'Description', 12)}
              {createInputField('priority', 'Product Priority', 6, 'number')}
              {createInputField('urlParam', 'Shop URL name', 6)}

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="status"
                  label={'Status'}
                  value={fk.values.status}
                  onChange={(e) => fk.setFieldValue('status', e.target.value)}
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <FileInput
                  id={'imageUrl'}
                  label={'Product preview image'}
                  imageUrl={fk.values.imageUrl}
                  imageUrlField={'imageUrl'}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.COMBO_PRODUCT}
                />
              </Grid>

              <Grid item xs={12}>
                <FileInput
                  id={'fullImageUrl'}
                  label={'Bundle Background'}
                  imageUrl={fk.values.fullImageUrl}
                  imageUrlField={'fullImageUrl'}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.COMBO_PRODUCT_FULL_IMAGE}
                />
              </Grid>
            </Grid>

            {productForEditing && (
              <>
                <Grid container xs={12}>
                  <Box margin={'20px 0 0 0'} width="100%">
                    <Title>Plans</Title>
                  </Box>
                </Grid>
                <Grid container xs={12}>
                  <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    justifyContent="space-between"
                    width={'100%'}
                  >
                    <Box
                      display={'flex'}
                      justifyContent="space-between"
                      width={'100%'}
                      alignItems={'center'}
                      padding={'0 15px'}
                    >
                      <div>Level</div>
                      <div>Status/Actions</div>
                    </Box>

                    {plans &&
                      (plans as Array<IComboPlanData>).map((plan) => {
                        const name = `${plan.name}`;
                        return (
                          <Button
                            className={classes.boxButton}
                            fullWidth
                            color="primary"
                            variant={'outlined'}
                          >
                            <Box
                              display={'flex'}
                              justifyContent="space-between"
                              width={'100%'}
                              alignItems={'center'}
                            >
                              <div>{name}</div>

                              <div>
                                <EditStatusButton
                                  status={plan.status}
                                  onChange={(status: string) =>
                                    handleStatusChange({ id: plan.id, status })
                                  }
                                />

                                <Can perform={Permissions.readProductList}>
                                  <IconButton
                                    aria-label="edit"
                                    title={'Edit'}
                                    size={'small'}
                                    onClick={() => handleEdit(plan)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Can>

                                <Can perform={Permissions.readProductList}>
                                  <DeleteModalButton
                                    name={plan.name}
                                    entity={'Plan'}
                                    onDelete={() => handleDeletePlan(plan.id)}
                                  />
                                </Can>
                              </div>
                            </Box>
                          </Button>
                        );
                      })}
                    <Box
                      width={'100%'}
                      justifyContent="space-between"
                      alignItems={'center'}
                      padding={'20px 0 0 0'}
                      onClick={createPlan}
                    >
                      <Button fullWidth variant="outlined" color="primary">
                        + Create Bundle
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
}
