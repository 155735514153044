import { useSelector } from 'react-redux';
import {
  selectPlansOptionsValueID,
  selectPromoDiscountForEditing,
} from '../../../store/entities/selectors';
import { usePromoDiscountFormik } from './use-formik';

export const useForm = () => {
  const dataForEditing = useSelector(selectPromoDiscountForEditing);
  const fk = usePromoDiscountFormik(dataForEditing);
  const options = useSelector(selectPlansOptionsValueID);
  const isEdit = !!dataForEditing;

  const state = {
    isEdit,
    fk,
    options,
  };

  return [state] as [typeof state];
};
