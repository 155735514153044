import React, { createContext, FC, useCallback, useEffect, useState } from 'react';
import getProductPlans, { IProductWithPlans } from '../../services/api/requests/getProductPlans';
import { getSearchParam, modifyQueryParams } from '../../utils/helpers';
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

export type ProductContextType = {
  product: IProductWithPlans | null;
  fetchProduct: (prodId?: string) => Promise<void>;
};

export const ProductContext = createContext<ProductContextType>({
  product: null,
  fetchProduct: async () => {},
});

export const ProductProvider: FC<{}> = ({ children }) => {
  const [product, setProduct] = useState<IProductWithPlans | null>(null);
  const productId = getSearchParam('productId');
  const dispatch = useDispatch();

  const fetchProduct = useCallback(
    async (id: string | void) => {
      try {
        const response = await getProductPlans(id || (productId as string));
        setProduct(response.data.data);
        dispatch(push(modifyQueryParams('productId', String(response.data.data.id))));
      } catch (e) {
        console.warn('Error', e);
      }
    },
    [productId]
  );

  useEffect(() => {
    if (productId && !product) {
      fetchProduct().then(console.log);
    }
  }, [productId, fetchProduct, product]);

  const value = { product, fetchProduct };

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};
