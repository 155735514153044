import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { ICountry } from './getCountries';

export interface IPlamMissedCountriesResponse {
  data: ICountry[];
}

const request = (id: string | number) =>
  apiClient
    .get<IPlamMissedCountriesResponse>(ApiUrls.planMissedCountries.replace('{id}', id as string))
    .then((response) => response);

export const getPlanMissedCountries = (id: string | number) =>
  apiClient
    .get<IPlamMissedCountriesResponse>(ApiUrls.planMissedCountries.replace('{id}', id as string))
    .then((response) => response);

export default request;
