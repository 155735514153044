import { IDateState } from "@root/components/OrdersSearch/types";

export enum OrderTypes {
  none = '',
  Multiple = 'Multiple',
  Renewal = 'Renewal',
  Subscription = 'Subscription',
  'One-time' = 'One-time',
  Upgrade = 'Upgrade',
  AffiliateUpgrade = 'Affiliate Upgrade',
}
export interface IFormValues {
  orderId: string;
  userId: string;
  firstName: string;
  lastName: string;
  exigoOrderId: string;
  dateState: IDateState;
  orderType?: OrderTypes;
}

export interface IProps {
  onSubmit(s: IFormValues): void;
  handleDownloadInvoices(): void;
  downloading: boolean;
  placeholder?: string;
}
