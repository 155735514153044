import { IMAGE_UPLOAD_ENTITY } from '../../../constants';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import awsImageUpload from './awsImageUpload';

interface IUploadUrl {
  entity: IMAGE_UPLOAD_ENTITY;
  mimetype: any;
  file?: File;
}

//get aws signed url from BE, then direct upload to s3
const request = (data: IUploadUrl) =>
  apiClient
    .get<IResponseAxios>(ApiUrls.uploadUrl + `?mimetype=${data.mimetype}&entity=${data.entity}`)
    .then((response) => awsImageUpload(response.data?.data, data?.file))
    .catch((error) => {
      throw error;
    });

export default request;
