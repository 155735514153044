export const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'updateAt', numeric: false, disablePadding: false, label: 'Date Update' },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    disableSort: true,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Delete',
    disableSort: true,
  },
];

export const sageRoleInitialValue = {
  name: '',
  type: '',
};
