import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().trim().max(40, "Value can't be more than 40 characters long"),
  email: Yup.string().trim().email(),
  phone: Yup.string()
    .trim()
    .matches(/^[0-9 +]+$/, 'Invalid phone format')
    .max(15, "Value can't be more than 15 characters long"),
  registrationNumber: Yup.string().trim().max(40, "Value can't be more than 40 characters long"),
  addressLine1: Yup.string().max(200, "Value can't be more than 200 characters long"),
  addressLine2: Yup.string().max(200, "Value can't be more than 200 characters long"),
  addressLine3: Yup.string().max(200, "Value can't be more than 200 characters long"),
  twitter: Yup.string().max(500, "Value can't be more than 500"),
  facebook: Yup.string().max(500, "Value can't be more than 500"),
  instagram: Yup.string().max(500, "Value can't be more than 500"),
});
