import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IPlan {
  currency: string;
  id: number;
  name: string;
  productName: string;
  rebillyPlanIds: string[];
  recurringPrice: number;
  recurringRetailPrice: number;
  recurringSKU: string;
  retailPrice: number;
  services: any[];
  setupPrice: number;
  sku: string;
  isRecurring: boolean;
  hasRetail: boolean;
  recurringType: string;
  groupId?: number;
}

export interface IProduct {
  id: number;
  name: string;
  plans: IPlan[];
  rebillyProductId: string;
  urlParam: string;
  groupId?: number;
}

export interface IProductsResponse {
  data: IProduct[];
}

const request = () =>
  apiClient.get<IProductsResponse>(ApiUrls.products).then((response) => response);

export default request;
