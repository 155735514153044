import { OrderTypes } from "@root/components/SearchPanel/types";
import { createQueryParams } from '@root/utils/helpers';
import ApiUrls from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import { IMeta, IPaginationParams } from '@root/services/api/types';

export interface IInvoice {
  createdAt: Date;
  firstName: string;
  lastName: string;
  userId: number;
  id: number;
  exigoOrderId: number;
  orderId: number;
  url: string;
}

export interface IParams extends IPaginationParams {
  orderId: string;
  userId: string;
  firstName: string;
  lastName: string;
  exigoOrderId: string;
  orderType?: OrderTypes,
  startDate?: string | Date;
  endDate?: string | Date;
}

export interface IInvoiceResponse {
  data: {
    items: IInvoice[];
    meta: IMeta;
  };
}

export const getInvoices = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<IInvoiceResponse>(ApiUrls.invoices + `?${query}`)
    .then((response) => response.data);
};

export const getDownloadInvoices = (params: IParams): Promise<any>  => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get(ApiUrls.downloadInvoices + `?${query}`, {
      responseType: 'blob',
    })
    .then((res) => res.data)
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Invoices.zip');

      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
