import { IExchangeRate, PointesExchangeCurrencies } from '@root/pages/ExchangeRateList/types';
import ApiUrls from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import { IMeta } from '@root/services/api/types';
import { createQueryParams } from '@root/utils/helpers';
import { AxiosResponse } from 'axios';

export interface IParams {
  page: number;
  limit: number;
  sortDirection: string;
}

export interface ICreateExchangeRate {
  rate: number;
  currencyFrom: PointesExchangeCurrencies;
  currencyTo: PointesExchangeCurrencies;
}

type RateType = Pick<ICreateExchangeRate, 'rate'>;

export interface IExchangeRateResponse {
  data: {
		items: IExchangeRate[]
	};
  meta: IMeta;
}

export const getExchangeRate = async (
  params: IParams
): Promise<AxiosResponse<IExchangeRateResponse>> => {
  const query = createQueryParams(params);
  return apiClient.get(`${ApiUrls.exchangeRate}?${query}`);
};

export const changeExchangeRate = async (id: number, body: RateType) => {
  return apiClient.put(`${ApiUrls.createEditExchangeRate}/${id}`, body);
};
