import { IFilterFields } from '@root/components/MembershipLevelsTable/types';
import membershipLevelRequests from '@root/services/api/requests/membershipLevels';
import { setMembershipLevels } from '@root/store/entities/actions';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from "react-redux";

export const useMembershipLevelTable = () => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const [filterFields, setFilterFields] = useState<IFilterFields>({
    recurringTypes: undefined,
    isRegional: false,
    countries: undefined,
    countryGroups: undefined,
  });
  const dispatch = useDispatch();

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  };

  const handleChangeCountries = (_: string, list: string[]) => {
    setFilterFields({
      ...filterFields,
      countries: list,
    });
  };

  const handleRecurringTypesChange = (value: string[]) => {
    setFilterFields({
      ...filterFields,
      recurringTypes: value,
    });
  };

  const handleIsRegionalChange = (e: ChangeEvent<{}>) => {
    setFilterFields({
      ...filterFields,
      isRegional: (e.target as HTMLInputElement).checked,
    });
  };

  const handleCountryGroupsChange = (_: ChangeEvent<{}>, selectedOptions: string[]) => {
    setFilterFields({
      ...filterFields,
      countryGroups: selectedOptions,
    });
  };

  const onSubmitFilter = async () => {
    try {
      const res = await membershipLevelRequests.readMembershipLevelList(filterFields);
      dispatch(setMembershipLevels(res.data.data));
    } catch (e) {
      console.warn(e.message);
    }
  };

  const state = {
    isFilterPanelOpen,
    filterFields,
  };

  const handlers = {
    toggleFilterPanel,
    filterHandlers: {
      handleChangeCountries,
      handleIsRegionalChange,
      handleRecurringTypesChange,
      onSubmitFilter,
      handleCountryGroupsChange,
    },
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
