import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import InputSearch from '../../components/InputSearch';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import readBaseSpendingUserList, {
  IBaseSpending,
  IParams,
} from '../../services/api/requests/baseSpending';
import { IMeta } from '../../services/api/types';
import { selectUserPermissions } from '../../store/system/selectors';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount', disableSort: true },
  { id: 'comment', numeric: false, disablePadding: false, label: 'Comment', disableSort: true },
];

export default function MoneySpending() {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const permissions = useSelector(selectUserPermissions);
  const [spendingList, setSpendingList] = React.useState<IBaseSpending[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [isManual, setIsManual] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 10, page: 1, total: 0, totalPages: 0 });
  const showTable = !loading && !!spendingList?.length;

  const fetchMoneySpendingList = useCallback(
    async (params: IParams) => {
      if (permissions?.includes(Permissions.readBaseSpending)) {
        try {
          const response = await readBaseSpendingUserList.readBaseSpendingUserList(params);
          setLoading(false);
          setSpendingList(response.data.data.items);
          setMeta(response.data.data.meta);
        } catch (e) {
          console.warn(e.message);
        }
      }
    },
    [permissions]
  );

  useEffect(() => {
    fetchMoneySpendingList({
      ...(searchTerm ? { userId: searchTerm } : ''),
      isManual,
      page,
      limit: rowsPerPage,
    });
  }, [searchTerm, rowsPerPage, page, isManual, fetchMoneySpendingList]);

  const createBaseSpending = () => {
    dispatch(push(pageLinks.createEditMoneySpending));
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Grid className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Money Spending</Title>
            <Can perform={Permissions.createBaseSpending}>
              <Button
                onClick={createBaseSpending}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AddCircleIcon />}
              >
                Add Money Spending
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <Grid container spacing={2} className={classes.searchRow}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="flex-end" flexDirection="row">
              <FormControlLabel
                control={
                  <Switch
                    id={'fsbEnabled'}
                    checked={isManual}
                    onChange={() => setIsManual(!isManual)}
                    name="fsbEnabled"
                    color="primary"
                  />
                }
                label="Is Manual"
              />
              <Box>
                <InputSearch
                  placeholder="User ID ..."
                  onSubmit={(val) => {
                    setPage(1);
                    setSearchTerm(val);
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {showTable && (
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <CustomTableHead classes={classes} headCells={headCells} />
              <TableBody>
                {loading && <TableSkeleton />}

                {showTable &&
                  spendingList?.map((spending) => {
                    const { amount, comment, id } = spending;

                    return (
                      <TableRow key={id}>
                        <TableCell>{`$${amount}`}</TableCell>
                        <TableCell>{comment}</TableCell>
                      </TableRow>
                    );
                  })}
                {!loading && !!spendingList && !spendingList?.length && (
                  <TableRow>
                    <TableCell>
                      There are no money spendings{' '}
                      {searchTerm.length > 0 ? `for current search - ${searchTerm} ` : ''}{' '}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
}
