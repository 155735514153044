import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IProductCategoryListItem {
  externalLink: string;
  id: number;
  isVisible: boolean;
  name: string;
  urlParam: string;
  imageUrl: string;
  products?: any;
}

export interface IParams {
  page: number;
  limit: number;
  sortDirection: 'ASC' | 'DESC';
  searchTerm: string;
  sortBy: string;
}

export interface IAdminProductResponse {
  data: IProductCategoryListItem[];
}

const request = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<IResponseAxios>(ApiUrls.productCategoriesList + `?${query}`)
    .then((response) => response);
};

export default request;
