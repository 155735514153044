import { Box, Tooltip } from '@material-ui/core';
import useCountryOption from '@root/hooks/instances';
import { FC } from 'react';

export interface ICountryProps {
  countries: string[];
}

// Passing an array with country codes to this function
export const DisplayCountries: FC<ICountryProps> = ({ countries }) => {
  const countriesList = useCountryOption(countries, false, countries, true, true);

  if (!Array.isArray(countriesList.selected)) {
    return null;
  }

  return (
    <>
      {countriesList.selected.map((country, idx, arr) => {
        const { label, value } = country;
        return (
          <Tooltip title={label} key={value}>
            <Box m={0.5} display="flex" flexWrap="wrap">{`${
              typeof value !== 'number' && arr.length > 10 ? value : label
            }${arr.length - 1 === idx ? '' : ', '}`}</Box>
          </Tooltip>
        );
      })}
    </>
  );
};
