import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: 'calc(100vh - 200px )',
    maxWidth: '90vw',
    overflowY: 'scroll',
    width: 'auto',
    minWidth: '70vw',
  },
}));

interface IProps {
  button: React.ReactNode;
  renderContent: (f: any) => React.ReactNode;
  onCloseCb?(): void;
  disabled?: boolean;
  forceOpen?: boolean;
}

const TransitionsModal: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(!!props.forceOpen);

  const handleOpen = () => {
    if (!props.disabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (!!props.onCloseCb) {
      props.onCloseCb();
    }
  };

  return (
    <>
      <span onClick={handleOpen}>{props.button}</span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>{props.renderContent(handleClose)}</div>
        </Fade>
      </Modal>
    </>
  );
};

export default TransitionsModal;
