import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IUmustlookItem {
  id: number | string;
  name: string;
  description: string;
  priority: number;
  externalLink: string;
  imageUrl: string;
  isBestSeller: boolean;
  isActive: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface IUmustlookItemResponse {
  data: IUmustlookItem[];
}

export interface ICreateUmustlookItem extends Omit<IUmustlookItem, 'id'> {}

export interface ICreateEditDeleteUmustlookItemResponse {
  data: IUmustlookItem;
}

export interface IEditUmustlookItemParams extends Partial<Omit<IUmustlookItem, 'id'>> {
  id: number | string;
}

const readUmustlookItemList = () => {
  return apiClient
    .get<IUmustlookItemResponse>(ApiUrls.umustlookItemList)
    .then((response) => response);
};

const createUmustlookItem = (data: ICreateUmustlookItem) => {
  return apiClient
    .post<ICreateEditDeleteUmustlookItemResponse>(ApiUrls.createUmustlookItem, data)
    .then((response) => response);
};

const editUmustlookItem = (data: IEditUmustlookItemParams) =>
  apiClient
    .put<ICreateEditDeleteUmustlookItemResponse>(ApiUrls.editUmustlookItem + data.id, data)
    .then((response) => response);

const deleteUmustlookItem = (id: string | number) => {
  return apiClient
    .delete<ICreateEditDeleteUmustlookItemResponse>(ApiUrls.deleteUmustlookItem + `${id}`)
    .then((response) => response);
};

const umustlookItemRequests = {
  readUmustlookItemList,
  createUmustlookItem,
  editUmustlookItem,
  deleteUmustlookItem,
};

export default umustlookItemRequests;
