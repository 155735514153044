import DateFnsUtils from '@date-io/date-fns';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';
import { createQueryParams } from "@root/utils/helpers";
import { IGetOrdersParams } from "@root/services/api/requests/orders";

const dateFns = new DateFnsUtils();

export interface IOrderTransaction {
  amount: number;
  amountFee: number;
  amountVat: number;
  currency: 'USD';
  fullAmount: number;
  id: number;
  paymentMethod: string;
  rebillyTransactionId: string;
  source: string;
  status: string;
}

export interface IInstallmentOrder {
  orderId: number;
  createdAt: string;
  name: string;
  email: string;
  username: number;
  amount: string;
  items: string;
  status: 'approve' | 'deny';
  transactions: IOrderTransaction[];
}

export interface IInstallmentOrdersResponse {
  items: IInstallmentOrder[];
  meta: IMeta;
}

const getList = ({
  params,
  planData,
}: {params: IPaginationParams; planData: Record<number, { name: string }>}): Promise<IInstallmentOrdersResponse> => {
   const query = createQueryParams<IGetOrdersParams>(params);
  return apiClient.get<any>(ApiUrls.installmentsOrders + `?${query}`).then((response) => ({
    meta: response.data.data.meta,
    items: response.data.data.items.map((item: any) => ({
      orderId: item.orderId,
      createdAt: dateFns.format(new Date(item.createdAt), 'dd/MM/yyyy hh:mm'),
      name: item.firstName + ' ' + item.lastName,
      email: item.email,
      username: item.username,
      amount: item.transactions.reduce(
        (
          acc: string,
          transaction: IOrderTransaction,
          index: number,
          initialArray: IOrderTransaction[]
        ) => {
          if (initialArray.length === 1) {
            return transaction.fullAmount.toFixed(2) + ' ' + transaction.currency;
          } else if (initialArray[0].currency === initialArray[1].currency) {
            return (
              (initialArray[0].fullAmount + initialArray[1].fullAmount).toFixed(2) +
              ' ' +
              initialArray[0].currency
            );
          } else if (initialArray[0].currency !== initialArray[1].currency) {
            return (
              initialArray[0].fullAmount.toFixed(2) +
              ' ' +
              initialArray[0].currency +
              ' & ' +
              initialArray[1].fullAmount.toFixed(2) +
              ' ' +
              initialArray[1].currency
            );
          }
          return acc;
        },
        0
      ),
      items: item.items
        .map((i: any) => ({
          ...i,
          plan: {
            name: planData[i.plan.id]?.name || i.plan.name,
            id: i.plan.id,
          },
        }))
        .map((item: any) => `${item.plan.name}(${item.plan.id})`)
        .join(', '),
      transactions: item.transactions,
      status: item.status,
    })),
  }));
};

const changeOrderStatus = (id: number, status: IInstallmentOrder['status']) => {
  return apiClient
    .post<unknown>(ApiUrls.installmentsOrders, { transactionId: id, status })
    .then((response) => response);
};

const installmentRequests = {
  changeOrderStatus,
  getList,
};

export default installmentRequests;
