import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams, IResponseAxios } from '../types';
import { IOrderListResponse } from './orders';

export interface IProductActivationParams {
  verifyFilter?: boolean | 'all';
  searchByUser?: number;
  statusFilter?: string;
}

interface IProps extends IPaginationParams, IProductActivationParams {}

export interface IProductsActivationsResponse {
  data: {
    items: {
      userId: number;
      orderId: number;
      username: string;
      product: string;
      createdAt: string;
      status: boolean;
    }[];
    meta: IMeta;
  };
}

export const changeOrderStatus = (id: number) => {
  return apiClient
    .put<IResponseAxios>(`${ApiUrls.verifyOrder}/${id}/items/verify`)
    .then((response) => response.data);
};

const request = async (params: IProps): Promise<IProductsActivationsResponse> => {
  const query = createQueryParams<IProps>({
    ...params,
    statusFilter: 'Delivered',
  });

  const result: IOrderListResponse = await apiClient
    .get(`${ApiUrls.orderList}?${query}`)
    .then((response) => response.data);

  return {
    data: {
      ...result.data,
      items: result.data.items.map((it) => ({
        userId: it.user.id,
        orderId: it.id,
        username: it.user.username,
        product: it.product,
        createdAt: it.createdAt,
        status: it.verified,
      })),
    },
  };
};

export default request;
