import { IconButton, TableCell, TableRow, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Can, { Permissions } from '@root/components/Can';
import InputSelect from '@root/components/InputSelect';
import { IRecurringType } from '@root/services/api/requests/recurringTypes';
import * as React from 'react';
import { useState } from 'react';

interface IProps {
  item: IRecurringType;
  onEditRow(p: IRecurringType): Promise<boolean>;
  deleteNewItem(): void;
}

const BundleGroupRow: React.FC<IProps> = ({ item, deleteNewItem, onEditRow }) => {
  const [editable, setEditable] = useState(!item.id || false);
  const [itemForEdit, setItemForEdit] = useState<IRecurringType>(item);

  const { id, name, status, lengthInDays, code } = itemForEdit || {};
  const isValid = code && lengthInDays && name;

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setItemForEdit((prev) => ({ ...prev, [name]: value }));
  };

  const onSave = async () => {
    if (!isValid) {
      return;
    }
    const isSuccess = await onEditRow(itemForEdit);
    if (isSuccess) {
      setEditable(false);
    }
  };

  return (
    <TableRow key={id}>
      <TableCell align="left">{id}</TableCell>
      <TableCell align="left">
        {editable ? (
          <TextField
            required
            value={code}
            name="code"
            onChange={setValue}
            error={!code}
            helperText={!code && 'Required'}
          />
        ) : (
          code
        )}
      </TableCell>
      <TableCell align="left">
        {editable ? (
          <TextField
            required
            type="number"
            value={lengthInDays}
            name="lengthInDays"
            onChange={setValue}
            error={lengthInDays <= 0}
            helperText={lengthInDays <= 0 && 'Please enter a valid number'}
          />
        ) : (
          lengthInDays
        )}
      </TableCell>
      <TableCell align="left">
        {editable ? (
          <TextField
            required
            value={name}
            name="name"
            onChange={setValue}
            error={!name}
            helperText={!name && 'Required'}
          />
        ) : (
          name
        )}
      </TableCell>

      <TableCell align="left">
        {editable ? (
          <InputSelect
            required
            id="status"
            name="status"
            value={status}
            onChange={(e) => setValue(e as React.ChangeEvent<HTMLInputElement>)}
            options={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' },
            ]}
          />
        ) : (
          status
        )}
      </TableCell>

      <TableCell align="left">
        {editable ? (
          <>
            <IconButton
              aria-label="edit"
              title={'Cancel'}
              size={'small'}
              onClick={() => {
                setEditable(false);
                setItemForEdit(item);
                !item.id && deleteNewItem();
              }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              type="button"
              aria-label="edit"
              disabled={!isValid}
              title={'Save'}
              size={'small'}
              onClick={onSave}
            >
              <CheckIcon />
            </IconButton>
          </>
        ) : (
          <Can perform={Permissions.updateRecurringType}>
            <IconButton
              aria-label="edit"
              title={'Edit'}
              size={'small'}
              onClick={() => setEditable(true)}
            >
              <EditIcon />
            </IconButton>
          </Can>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BundleGroupRow;
