import {
  ICreateMembershipLevel,
  IMembershipLevel,
} from '../../services/api/requests/membershipLevels';
import { IValues } from './types';

export class MembershipLevelFormikMapper {
  public static toDomain(item: IMembershipLevel): IValues {
    const { plans, ...rest } = item;
    return {
      ...rest,
      planIds: plans?.map((p) => p.id) || [],
    };
  }

  public static toPersistence(values: IValues): ICreateMembershipLevel {
    return {
      ...values,
      name: values.name.trim(),
    };
  }
}
