import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ISKUItem } from '../../services/api/requests/getSKUList';
import { selectSKUList } from '../../store/entities/selectors';

interface Option {
  value: string;
  label: {
    sku: string;
    description: string;
  };
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface IProps {
  value: string;
  id: string;
  disabled?: boolean;
  required?: boolean;
  onChange(a: string, b: string | string[], c?: boolean): void;
  error?: boolean;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void;
  helperText?: string;
  label?: string;
}

const SKUSelect: React.FC<IProps> = ({ value, id, onChange, label, disabled = false, ...rest }) => {
  const classes = useStyles();

  const skuList = useSelector(selectSKUList);

  const options = useMemo(
    () =>
      [...skuList].map((item: ISKUItem) => ({
        value: item.sku,
        label: { sku: item.sku, description: item.description },
      })),
    [skuList]
  );

  const selectedValue = useMemo(
    () => options.find((item: any) => item.value === value),
    [options, value]
  );

  const onSelectChange = (e: any, selectedOption: Option | null) => {
    return onChange(id, (selectedOption as Option)?.value);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: ({ label }: Option) => `${label.sku} ${label.description}`,
  });

  return (
    <Autocomplete
      id={id}
      options={options as Option[] | []}
      value={selectedValue || null}
      onChange={onSelectChange}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) => option.label.sku}
      filterOptions={filterOptions}
      renderOption={(option) => (
        <React.Fragment>
          <div>
            <span>{option.label.sku}</span>
            {option.label.sku !== option.label.description && (
              <small style={{ opacity: 0.7 }}>{option.label.description}</small>
            )}
          </div>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={rest.error}
          label={label || 'Choose a SKU'}
          variant="outlined"
          fullWidth
          required={rest.required}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default SKUSelect;
