import pageLinks from '@root/utils/pageLinks';
import { IExchangeRate } from '@root/pages/ExchangeRateList/types';
import { getExchangeRate } from '@root/services/api/requests/exchangeRateList';
import { editExchangeRate } from "@root/store/entities/actions";
import { push } from "connected-react-router";
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

export const useExchangeRate = () => {
  const [exchangeRatesList, setExchangeRateList] = useState<IExchangeRate[]>([]);
  const dispatch = useDispatch();


  useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await getExchangeRate({
          page: 1,
          limit: 10,
          sortDirection: 'DESC',
        });

        setExchangeRateList(response.data.data.items);
      } catch (err) {
        console.error(err);
      }
    };

    fetchList();
  }, []);

  const handleEdit = (data: IExchangeRate) => {
    const productID = `?exchangeRateId=${data.id}`;

    dispatch(editExchangeRate(data));
    dispatch(push(pageLinks.editExchangeRate + productID));
  };

  const state = {
    exchangeRatesList
  }

  const handlers = {
    handleEdit
  }

  return [state, handlers] as [typeof state, typeof handlers];
};
