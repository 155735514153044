import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IService {
  exigoId: number;
  name: string;
  id: number;
  skus: Record<string, string>;
  oneDayDeductSku: string;
  oneDayRecurringSku: string;
  recurringSkus: Record<string, string>;
}

export interface IServiceResponse {
  data: IService[];
}

const request = () =>
  apiClient.get<IServiceResponse>(ApiUrls.services).then((response) => response);

export const getAvailableServices = () =>
  apiClient.get<IServiceResponse>(ApiUrls.primaryService).then((response) => response);

export default request;
