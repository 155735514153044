import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useToolbarStyles } from '@root/components/FilterToggle/styles';

export const FilterToggle: React.FC<{
  onFilterClick(e: React.SyntheticEvent): void;
}> = (props) => {
  const classes = useToolbarStyles();
  const { onFilterClick } = props;
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title} />
      <Tooltip title="Filter list" onClick={onFilterClick}>
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};
