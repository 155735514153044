import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import 'date-fns';
import { FieldArray, FormikProvider } from 'formik';
import * as React from 'react';
import BackButton from '../../components/BackButton';
import CountrySelect from '../../components/CountrySelect';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import pageLinks from '../../utils/pageLinks';
import { MenuProps } from './static-data';
import { IValues } from './types';
import { useForm } from './use-form';

const CreateEditMindhubGroup: React.FC<{}> = () => {
  const classes = useStandardFormStyles();
  const [{ isEdit, fk, services, availableCountries }] = useForm();
  const actionText = isEdit ? 'Edit' : 'Create';
  const createInputField = createInput<IValues>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.mindhubGroups} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Mindhub group`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('name', 'Name', 12)}
              {createInputField('mindhubBranchId', 'Mindhub Branch ID', 12, 'number')}

              <Grid item xs={12}>
                <InputSelect
                  id="status"
                  label={'Status'}
                  value={fk.values.status}
                  onChange={(e) => fk.setFieldValue('status', e.target.value)}
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </Grid>

              <Grid item xs={12} style={{ marginLeft: 8, marginBottom: 8 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel style={{ marginLeft: -8 }}>Services</InputLabel>
                  <Select
                    id="serviceIDs"
                    label={'Services'}
                    variant="outlined"
                    multiple
                    value={fk.values.serviceIds}
                    onChange={(e) => fk.setFieldValue('serviceIds', e.target.value)}
                    input={<Input />}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {services.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.serviceIds.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ marginBottom: 12 }}>
                <Typography color="primary">Mindhub Group IDs</Typography>
                <FormikProvider value={fk}>
                  <FieldArray name="formGroupIds">
                    {({ remove, push }) => (
                      <>
                        {fk.values?.formGroupIds &&
                          fk.values?.formGroupIds?.length > 0 &&
                          (fk.values.formGroupIds as any).map((item: string, index: number) => (
                            <Grid container spacing={2} className={classes.bordered}>
                              <Grid item xs={12}>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                >
                                  <Typography>Mindhub Group ID</Typography>
                                  <IconButton
                                    aria-label="delete"
                                    title={'Delete'}
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  label="Mindhub Group ID"
                                  placeholder="Add an Group ID"
                                  variant="outlined"
                                  type={'string'}
                                  fullWidth
                                  id={`formGroupIds.${index}.id`}
                                  required
                                  name={`formGroupIds.${index}.id`}
                                  autoComplete="new-password"
                                  onBlur={fk.handleBlur}
                                  value={
                                    fk.values?.formGroupIds && fk.values?.formGroupIds[index]['id']
                                  }
                                  onChange={fk.handleChange}
                                  error={
                                    // @ts-ignore
                                    !!fk.touched.formGroupIds?.[index]?.['id'] &&
                                    // @ts-ignore
                                    Boolean(fk.errors.formGroupIds?.[index]?.id)
                                  }
                                  helperText={
                                    // @ts-ignore
                                    !!fk.touched.formGroupIds?.[index]?.['id'] &&
                                    // @ts-ignore
                                    fk.errors.formGroupIds?.[index]?.id
                                  }
                                />
                              </Grid>
                            </Grid>
                          ))}

                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          disabled={!!fk.errors.formGroupIds}
                          startIcon={<AddIcon />}
                          style={{ marginTop: 4 }}
                          onClick={() =>
                            push({
                              id: '',
                            })
                          }
                        >
                          Add an Group ID
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </FormikProvider>
              </Grid>

              <Grid item xs={12} style={{ marginBottom: 12 }}>
                <Typography color="primary">Audience tags</Typography>
                <FormikProvider value={fk}>
                  <FieldArray name="formAudienceTags">
                    {({ remove, push }) => (
                      <>
                        {fk.values?.formAudienceTags &&
                          fk.values?.formAudienceTags?.length > 0 &&
                          (fk.values.formAudienceTags as any).map((item: string, index: number) => (
                            <Grid container spacing={2} className={classes.bordered}>
                              <Grid item xs={12}>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                >
                                  <Typography>Audience Tag</Typography>
                                  <IconButton
                                    aria-label="delete"
                                    title={'Delete'}
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  label="Audience Tag"
                                  placeholder="Add an tag"
                                  variant="outlined"
                                  type={'string'}
                                  fullWidth
                                  id={`formAudienceTags.${index}.tag`}
                                  required
                                  name={`formAudienceTags.${index}.tag`}
                                  autoComplete="new-password"
                                  onBlur={fk.handleBlur}
                                  value={
                                    fk.values?.formAudienceTags &&
                                    fk.values?.formAudienceTags[index]['tag']
                                  }
                                  onChange={fk.handleChange}
                                  error={
                                    // @ts-ignore
                                    !!fk.touched.formAudienceTags?.[index]?.['tag'] &&
                                    // @ts-ignore
                                    Boolean(fk.errors.formAudienceTags?.[index]?.tag)
                                  }
                                  helperText={
                                    // @ts-ignore
                                    !!fk.touched.formAudienceTags?.[index]?.['tag'] &&
                                    // @ts-ignore
                                    fk.errors.formAudienceTags?.[index]?.tag
                                  }
                                />
                              </Grid>
                            </Grid>
                          ))}

                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          disabled={!!fk.errors.formAudienceTags}
                          startIcon={<AddIcon />}
                          style={{ marginTop: 4 }}
                          onClick={() =>
                            push({
                              tag: '',
                            })
                          }
                        >
                          Add tag
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </FormikProvider>
              </Grid>

              {createInputField('priority', 'Priority', 12, 'number')}

              <Grid item xs={12}>
                <CountrySelect
                  id={'countryCodes'}
                  value={(fk.values.countryCodes as string[]) || []}
                  onChange={fk.setFieldValue}
                  withGroups
                  required={!fk.values.countryCodes.length}
                  error={!!fk.errors?.countryCodes}
                  onBlur={fk.handleBlur}
                  disableCloseOnSelect
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditMindhubGroup;
