import { Box, Button, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { RecurringChargeTable } from '@root/pages/RecurringTypes/recurring-charges-table';
import { RecurringTypeTable } from '@root/pages/RecurringTypes/recurring-type-table';
import { IRecurringType } from '@root/services/api/requests/recurringTypes';
import React, { ChangeEvent, FC, useState } from 'react';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import Title from '../../components/Title';
import { emptyItem } from './static-data';
import { useStyles } from "@root/pages/RecurringTypes/use-styles";

const RecurringTypes: FC = () => {
  const classes = {...useStandardTableStyles(), ...useStyles()}
  const [newItem, setNewItem] = React.useState<IRecurringType | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const addNewItem = () => {
    setNewItem(emptyItem);
  };

  const handleChangeTab = (_: ChangeEvent<{}>, value: number) => {
    setSelectedTab(value);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Recurring Types</Title>
            <Can perform={Permissions.createRecurringType}>
              <Box display={'flex'}>
                <Button
                  onClick={addNewItem}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<AddCircleIcon />}
                >
                  Add Recurring Type
                </Button>
              </Box>
            </Can>
          </div>
        </Paper>
      </Grid>
      <Paper className={classes.paper}>
        <Tabs
          onChange={handleChangeTab}
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Recurring Types">Recurring Types</Tab>
          <Tab label="Recurring Charge">Recurring Charge</Tab>
        </Tabs>
        {!selectedTab ? (
          <RecurringTypeTable selectedTab={selectedTab} newItem={newItem} setNewItem={setNewItem} />
        ) : (
          <RecurringChargeTable selectedTab={selectedTab} />
        )}
      </Paper>
    </div>
  );
};

export default RecurringTypes;
