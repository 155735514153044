import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import BackButton from '@root/components/BackButton';
import Can, { Permissions } from '@root/components/Can';
import { useStandardTableStyles } from '@root/components/DefaultTable';
import Title from '@root/components/Title';
import { useStyles } from '@root/pages/EditExchangeRate/styles';
import { useCreateExchangeRate } from '@root/pages/EditExchangeRate/use-create-exchange-rate';
import { ExchangeRateLabels } from '@root/pages/ExchangeRateList/types';
import { selectEditExchangeRateItem } from '@root/store/entities/selectors';
import pageLinks from '@root/utils/pageLinks';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

export const EditExchangeRate = () => {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const exchangeRate = useSelector(selectEditExchangeRateItem);

  const [
    { exchangeRateFieldsData },
    { handleCalcExchangeRateFrom, handleCalcExchangeRateTo, submitNewExchangeRate },
  ] = useCreateExchangeRate();

  return (
    <Can perform={Permissions.updatePointsExchange} otherwise={<Redirect to={pageLinks.home} />}>
      <Grid xs={12}>
        <BackButton name={'Back'} link={pageLinks.exchangeRateList} margin={'0 0 10px 0'} />
        {exchangeRate && (
          <Paper className={classes.paper}>
            <div className={classes.exchangeRateWrapper}>
              <Title>Edit exchange rate</Title>
              <form onSubmit={submitNewExchangeRate} className={classes.exchangeRateForm}>
                <TextField
                  variant="outlined"
                  label={
                    ExchangeRateLabels[exchangeRate.currencyFrom] || exchangeRate?.currencyFrom
                  }
                  size="small"
                  type="number"
                  inputProps={{ min: 0, max: 100000 }}
                  className={classes.input}
                  value={exchangeRateFieldsData.from}
                  onChange={handleCalcExchangeRateFrom}
                />
                =
                <TextField
                  variant="outlined"
                  label={ExchangeRateLabels[exchangeRate.currencyTo] || exchangeRate?.currencyTo}
                  size="small"
                  type="number"
                  inputProps={{ min: 0, max: 100000 }}
                  className={classes.input}
                  value={exchangeRateFieldsData.to}
                  onChange={handleCalcExchangeRateTo}
                />
                <Button type="submit" color="primary" variant="contained" size="small">
                  Save
                </Button>
              </form>
              <TextField
                variant="outlined"
                className={classes.inputRange}
                size="small"
                label={ExchangeRateLabels.rate}
                value={exchangeRateFieldsData.newRate}
                disabled={true}
              />
            </div>
          </Paper>
        )}
      </Grid>
    </Can>
  );
};
