import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FilterToggle } from '@root/components/FilterToggle';
import { FC, useCallback, useState } from 'react';

interface IWhiteListFilters {
  filters: {
    ipAddress: string;
    description: string;
  };
  handleFilterChange: (
    e: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>
  ) => void;
  handleFilterApply: () => void;
  handleFilterReset: () => void;
}

const useStyles = makeStyles(() => ({
  filterWrapper: {
    padding: 16,
  },
}));

export const WhiteListFilter: FC<IWhiteListFilters> = ({
  filters,
  handleFilterChange,
  handleFilterApply,
  handleFilterReset,
}) => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const toggleFilterPanel = useCallback(() => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  }, [isFilterPanelOpen]);
  const classes = useStyles();

  return (
    <>
      <FilterToggle onFilterClick={toggleFilterPanel} />
      {isFilterPanelOpen && (
        <Grid className={classes.filterWrapper} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="IP Address"
              label="IP Address"
              name="ipAddress"
              variant="outlined"
              fullWidth
              value={filters.ipAddress}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Description"
              label="Description"
              name="description"
              variant="outlined"
              fullWidth
              value={filters.description}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth onClick={handleFilterApply}>
              Apply
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="outlined" color="primary" fullWidth onClick={handleFilterReset}>
              Clear
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
