import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IPaginationParams } from './../types';
import { IMeta } from './getAdminList';

export interface IMindhubGroup {
  id: number | string;
  name: string;
  serviceIds: number[];
  mindhubBranchId: number | string | null;
  groupIds: number[] | null;
  countryIds: number[];
  status: string | null;
  priority: number;
  audienceTags: string[] | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface IMindhubGroupsResponse {
  data: {
    items: IMindhubGroup[];
    meta: IMeta;
  };
}

export interface ICreateMindhubGroup extends Omit<IMindhubGroup, 'id'> {}

export interface ICreateEditDeleteMindhubGroupResponse {
  data: IMindhubGroup;
}

export interface IEditMindhubGroupParams extends Partial<Omit<IMindhubGroup, 'id'>> {
  id: number | string;
}

export const readMindhubGroupListPagination = ({
  page,
  limit,
}: IPaginationParams): Promise<IMindhubGroupsResponse> => {
  const query = `?page=${page}&limit=${limit}&sortBy=id`;
  return apiClient
    .get<IMindhubGroupsResponse>(ApiUrls.mindhubGroupList + query)
    .then((response) => response.data);
};

const createMindhubGroup = (data: ICreateMindhubGroup) => {
  return apiClient
    .post<ICreateEditDeleteMindhubGroupResponse>(ApiUrls.createMindhubGroup, data)
    .then((response) => response);
};

const editMindhubGroup = (data: IEditMindhubGroupParams) =>
  apiClient
    .put<ICreateEditDeleteMindhubGroupResponse>(ApiUrls.editMindhubGroup + data.id, data)
    .then((response) => response);

const deleteMindhubGroup = (id: string | number) => {
  return apiClient
    .delete<ICreateEditDeleteMindhubGroupResponse>(ApiUrls.deleteMindhubGroup + `${id}`)
    .then((response) => response);
};

const mindhubGroupRequests = {
  readMindhubGroupListPagination,
  createMindhubGroup,
  editMindhubGroup,
  deleteMindhubGroup,
};

export default mindhubGroupRequests;
