import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FilterToggle } from '@root/components/FilterToggle';
import { IFiltersFields } from '@root/components/GatewayList/types';
import InputSelect from '@root/components/InputSelect';
import { Dispatch, FC, SetStateAction, useState } from 'react';

const statusOptions = [
  { value: 'all', label: 'All'},
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const useStyles = makeStyles(() => ({
  filterWrapper: {
    padding: 20,
  },
}));

export const GatewayListFilter: FC<{
  filtersFields: IFiltersFields;
  setFiltersFields?: Dispatch<SetStateAction<IFiltersFields>>;
  handleFilterChange: (e: any) => void;
  handleFilterApply: () => void;
}> = ({ filtersFields, handleFilterChange, handleFilterApply }) => {
  const [toggleFilterPanel, setToggleFilterPanel] = useState(false);
  const classes = useStyles();

  return (
    <>
      <FilterToggle onFilterClick={() => setToggleFilterPanel(!toggleFilterPanel)} />
      {toggleFilterPanel && (
        <Grid className={classes.filterWrapper} container spacing={2} alignItems={'center'}>
          <Grid item xs={12} sm={4}>
            <InputSelect
              label="Status"
              name="status"
              variant="outlined"
              fullWidth
              options={statusOptions}
              value={filtersFields.status}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="Transaction Field"
              label="Transaction Field"
              name="exigoTransactionField"
              variant="outlined"
              fullWidth
              value={filtersFields.exigoTransactionField}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="Company Descriptor"
              label="Company Descriptor"
              name="companyName"
              variant="outlined"
              fullWidth
              value={filtersFields.companyName}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="Processing fee"
              label="Processing fee"
              name="processingFee"
              variant="outlined"
              fullWidth
              type="number"
              value={filtersFields.processingFee}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="Name"
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              value={filtersFields.name}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" color="primary" fullWidth onClick={handleFilterApply}>
              Apply
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
