import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import * as React from 'react';
import { IOption } from '../../utils/commonTypes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IProps extends SelectProps {
  options: IOption[];
  helperText?: string;
  size?: 'small' | 'medium' | undefined;
}

const InputSelect: React.FC<IProps> = (props) => {
  return (
    <FormControl fullWidth variant="outlined" size={props.size || undefined}>
      <InputLabel id={`${props.id}-label`}>{props.label}</InputLabel>
      <Select labelId={`${props.id}-label`} variant="outlined" MenuProps={MenuProps} {...props}>
        {props.options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InputSelect;
