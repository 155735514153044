import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	changeStatusButton: {
		width: '10px',
		fontSize: '10px',
		padding: 0,
	},

	boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
}))
