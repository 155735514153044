import { MembershipSetting } from '@root/pages/CreateEditSwitchSubscriptionRule/types';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface PointRewardsAdjustment {
  cv: number;
  qv: number;
  scv: number;
  sqv: number;
  fsb: number;
}

export interface ISwitchSubscriptionRule {
  id: number | null;
  enabled: boolean;
  fsbPeriod: number;
  priceAdjustment: number;
  sku: string;
  spendingsAdjustment: number;
  pointRewardsAdjustment: PointRewardsAdjustment;
  planIdFrom: number;
  planIdTo: number;
  membershipId: number;
  withRenew: boolean;
  freeRenew: boolean;
  changeNested: boolean;
  splitValues: number;
  splitFSB: number;
  splitSKU: string;
  splitBaseValue: number;
  membershipSettings: MembershipSetting[];
}

export type SwitchSubscriptionRuleResponse = Record<'data', ISwitchSubscriptionRule[]>;

export interface ICreateSwitchSubscriptionRule extends Omit<ISwitchSubscriptionRule, 'id'> {}

export interface IEditSwitchSubscriptionRule extends Pick<ISwitchSubscriptionRule, 'id'> {}

const readSwitchSubscriptionRuleList = () => {
  return apiClient
    .get<SwitchSubscriptionRuleResponse>(ApiUrls.switchSubscriptionRuleList)
    .then((response) => response);
};

const editSwitchSubscriptionRule = (data: IEditSwitchSubscriptionRule) => {
  return apiClient
    .put<SwitchSubscriptionRuleResponse>(ApiUrls.editSwitchSubscriptionRule + data.id, data)
    .then((response) => response);
};

const createSwitchSubscriptionRule = (data: ICreateSwitchSubscriptionRule) => {
  return apiClient
    .post<SwitchSubscriptionRuleResponse>(ApiUrls.createSwitchSubscriptionRule, data)
    .then((response) => response);
};

const deleteSwitchSubscriptionRule = (id: string | number) => {
  return apiClient
    .delete<SwitchSubscriptionRuleResponse>(ApiUrls.deleteSwitchSubscriptionRule + `${id}`)
    .then((response) => response);
};

const switchSubscriptionRuleRequests = {
  readSwitchSubscriptionRuleList,
  editSwitchSubscriptionRule,
  createSwitchSubscriptionRule,
  deleteSwitchSubscriptionRule,
};

export default switchSubscriptionRuleRequests;
