import { FormikProps, useFormik } from 'formik';
import { initialValues } from './static-data';
import { IValues } from './types';
import { validationSchema } from './validation';

export const useFormikHandler = (
  membershipId: number,
  items: IValues['regionalSettings'] | undefined
) => {
  const fk: FormikProps<IValues> = useFormik({
    initialValues: !!items ? ({ regionalSettings: items } as IValues) : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  return fk;
};
