import DateFnsUtils from '@date-io/date-fns';
import fileDownload from 'js-file-download';
import { USER_KYC_REPORT_DATE_FORMAT } from '../../../constants';
import setNotification from '../../../utils/notifications';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

const request = (isAll: boolean, verificationStatus: string, dateFrom?: Date, dateTo?: Date) => {
  const dateFns = new DateFnsUtils();
  const dateRangeValue =
    dateFrom && dateFns.isValid(dateFrom) && dateTo && dateFns.isValid(dateTo)
      ? `${dateFns.format(new Date(dateFrom), USER_KYC_REPORT_DATE_FORMAT)}_${dateFns.format(
          new Date(dateTo),
          USER_KYC_REPORT_DATE_FORMAT
        )}`
      : '';

  const fileNameDate = isAll
    ? dateFns.format(new Date(), USER_KYC_REPORT_DATE_FORMAT)
    : dateRangeValue;

  const fileName = `user_kyc_${fileNameDate}.csv`;
  const query = !isAll
    ? `?startDate=${dateFns.format(
        new Date(dateFrom as Date),
        USER_KYC_REPORT_DATE_FORMAT
      )}&endDate=${dateFns.format(new Date(dateTo as Date), USER_KYC_REPORT_DATE_FORMAT)}${
        verificationStatus !== 'all' ? `&verificationStatus=${verificationStatus}` : ''
      }`
    : '';

  console.log('fileName', fileName);
  console.log('query', query);

  return apiClient
    .get<string>(`${ApiUrls.userKycStatuses}${query}`, {
      headers: {
        'Content-Disposition': 'attachment;filename=report.csv',
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then((response) => {
      if (response?.data) {
        fileDownload(response.data, fileName);
      }
    })
    .catch(async (error) => {
      const blobError = JSON.parse(await error.response.data.text());

      setNotification('error', { message: blobError.message });
      throw error;
    });
};

export default request;
