import { Box, Button, CircularProgress, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import Title from '../../components/Title';
import getConversionTemplatesList, {
  IConversionTemplate,
} from '../../services/api/requests/getConversionTemplatesList';
import getPayoutList, {
  downloadColsCardInfo,
  IPayoutItem,
} from '../../services/api/requests/getPayoutList';
import { IMeta } from '../../services/api/types';
import pageLinks from '../../utils/pageLinks';
import ConversionTemplates from '../ConversionTemplates';
import PayoutReport from '../PayoutReport';

const defaultMeta: IMeta = {
  limit: 0,
  page: 1,
  total: 0,
  totalPages: 0,
};

export interface PayoutTablesData {
  loading: boolean;
  meta: IMeta;
  handleChangePage: (e: unknown, newPage: number, type: PayoutTabs) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>, type: PayoutTabs) => void;
}

type TabValues = 0 | 1 | 2;
const titles = ['Payout Export', 'Segments', 'Conversion templates'];
type PaginationValues = 5 | 10 | 25;

export enum PayoutTabs {
  PAYOUT = 'PAYOUT',
  SEGMENTS = 'SEGMENTS',
  CONVERSION_TEMPLATES = 'CONVERSION_TEMPLATES',
}

const tabValuesTypes = [PayoutTabs.PAYOUT, PayoutTabs.SEGMENTS, PayoutTabs.CONVERSION_TEMPLATES];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState<{
    [PayoutTabs.PAYOUT]: number;
    [PayoutTabs.SEGMENTS]: number;
    [PayoutTabs.CONVERSION_TEMPLATES]: number;
  }>({
    [PayoutTabs.PAYOUT]: 1,
    [PayoutTabs.SEGMENTS]: 1,
    [PayoutTabs.CONVERSION_TEMPLATES]: 1,
  });
  const [rowsPerPage, setRowsPerPage] = React.useState<{
    [PayoutTabs.PAYOUT]: PaginationValues;
    [PayoutTabs.SEGMENTS]: PaginationValues;
    [PayoutTabs.CONVERSION_TEMPLATES]: PaginationValues;
  }>({
    [PayoutTabs.PAYOUT]: 10,
    [PayoutTabs.SEGMENTS]: 10,
    [PayoutTabs.CONVERSION_TEMPLATES]: 10,
  });
  const [list, setList] = React.useState<{
    [PayoutTabs.PAYOUT]: IPayoutItem[] | null;
    [PayoutTabs.SEGMENTS]: any[] | null;
    [PayoutTabs.CONVERSION_TEMPLATES]: IConversionTemplate[] | null;
  }>({
    [PayoutTabs.PAYOUT]: null,
    [PayoutTabs.SEGMENTS]: null,
    [PayoutTabs.CONVERSION_TEMPLATES]: null,
  });
  const [meta, setMeta] = React.useState<{
    [PayoutTabs.PAYOUT]: IMeta;
    [PayoutTabs.SEGMENTS]: IMeta;
    [PayoutTabs.CONVERSION_TEMPLATES]: IMeta;
  }>({
    [PayoutTabs.PAYOUT]: defaultMeta,
    [PayoutTabs.SEGMENTS]: defaultMeta,
    [PayoutTabs.CONVERSION_TEMPLATES]: defaultMeta,
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [downloading, setDownloading] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<TabValues>(0);

  useEffect(() => {
    fetchList(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const fetchList = (tabValue: TabValues) => {
    if (!loading) {
      setLoading(true);
    }
    switch (tabValue) {
      case 0:
        return getPayoutList({
          page: page[PayoutTabs.PAYOUT],
          limit: rowsPerPage[PayoutTabs.PAYOUT],
        }).then((response) => {
          if (response) {
            setLoading(false);
            setList({ ...list, [PayoutTabs.PAYOUT]: response.data.data.items });
            setMeta({ ...meta, [PayoutTabs.PAYOUT]: response.data.data.meta });
          }
        });
      case 1:
        return Promise.resolve();
      case 2:
        return getConversionTemplatesList({
          page: page[PayoutTabs.CONVERSION_TEMPLATES],
          limit: rowsPerPage[PayoutTabs.CONVERSION_TEMPLATES],
        }).then((response) => {
          if (response) {
            setLoading(false);
            setList({ ...list, [PayoutTabs.CONVERSION_TEMPLATES]: response.data.data.items });
            setMeta({ ...meta, [PayoutTabs.CONVERSION_TEMPLATES]: response.data.data.meta });
          }
        });
    }
  };

  const createListItem = () => {
    dispatch(push(pageLinks.addCsvReport));
  };

  const handleChangePage = (e: unknown, newPage: number, table: PayoutTabs) => {
    setPage({ ...page, [table]: newPage + 1 });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
    table: PayoutTabs
  ) => {
    setRowsPerPage({
      ...rowsPerPage,
      [table]: parseInt(event.target.value, 10) as PaginationValues,
    });
    setPage({ ...page, [table]: 1 });
  };

  const handleTabClick = async (event: React.ChangeEvent<{}>, newValue: TabValues) => {
    setTab(newValue);
    if (!list[tabValuesTypes[newValue]]?.length) {
      await fetchList(newValue);
    }
  };

  const downloadPrepaidCardInfo = async () => {
    setDownloading(true);
    await downloadColsCardInfo();
    setDownloading(false);
  };

  const tabSwitch = (tab: TabValues) => {
    switch (tab) {
      case 0:
        return (
          <PayoutReport
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            meta={meta[PayoutTabs.PAYOUT]}
            list={list[PayoutTabs.PAYOUT]}
            loading={loading}
          />
        );
      case 1:
        return <div />;
      case 2:
        return (
          <ConversionTemplates
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            meta={meta[PayoutTabs.CONVERSION_TEMPLATES]}
            list={list[PayoutTabs.CONVERSION_TEMPLATES]}
            loading={loading}
          />
        );
      default:
        return <div />;
    }
  };

  const buttonsSwitch = (tab: TabValues) => {
    switch (tab) {
      case 0:
        return (
          <Can perform={Permissions.generateUsersPayouts}>
            <Button
              onClick={createListItem}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<GroupAddIcon />}
            >
              Create Request
            </Button>
          </Can>
        );
      case 1:
        return <div />;
      case 2:
        return (
          <Can perform={Permissions.generateUsersPayouts}>
            <Button
              onClick={() => dispatch(push(pageLinks.createEditConversionTemplate))}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<NoteAddIcon />}
            >
              Create Request
            </Button>
          </Can>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>{titles[tab]}</Title>
            <Box display="flex">
              <Box mr={5}>
                <Button
                  onClick={downloadPrepaidCardInfo}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={downloading ? <CircularProgress size={18} /> : <GetAppIcon />}
                  disabled={downloading}
                  className={classes.downloadButton}
                >
                  {downloading ? 'Downloading prepaid card data' : 'Download prepaid card data'}
                </Button>
              </Box>
              {buttonsSwitch(tab)}
            </Box>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabClick}
          aria-label="disabled tabs example"
        >
          <Tab label="Payout Report" disabled={loading} />
          <Can perform={Permissions.readSegment}>
            <Tab label="Segments" disabled={loading} />
          </Can>
          {Can({
            perform: Permissions.readConversionTemplateList,
            children: (
              <Tab
                label="Conversion templates"
                // disabled={loading}
                disabled={true}
                style={{
                  borderBottom: `1px solid ${tab === 2 ? '#673ab7' : 'white'}`,
                }}
              />
            ),
          })}
        </Tabs>
        {tabSwitch(tab)}
      </Paper>
    </div>
  );
};

export default EnhancedTable;
