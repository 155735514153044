import { FormikProps, useFormik } from 'formik';
import KYC, { IKycAssignManually } from '../../services/api/requests/kyc';
import setNotification from '../../utils/notifications';
import { initialValues } from './static-data';
import { IValues } from './types';
import { validationSchema } from './validation';

export const useAssignKycFormik = (close: () => void) => {
  const fk: FormikProps<IValues> = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const levels = [];
      if (values.level1) {
        levels.push('level1');
      }
      if (values.level2) {
        levels.push('level2');
      }
      const createData: IKycAssignManually = {
        userId: `${values.userId}`,
        levels,
      };

      if (levels.length > 0) {
        KYC.assignKyc(createData)
          .then((response) => {
            if (response) {
              setNotification('success', {
                message: 'Success',
              });
            }
          })
          .finally(() => close());
      }
    },
  });

  return fk;
};
