import { IValues } from '../../../pages/CreateEditCurrencyConverter/types';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

interface ICurrency {
  id: string;
  code: string;
  name: string;
  symbol: string;
}

export interface ICurrencyData {
  id: number;
  to: ICurrency;
  from: ICurrency;
  feeAmount: number;
  feeCurrency: 'VALUE' | '%';
  enabled: boolean;
  round: 0 | 1 | 0.5 | 0.25 | 0.99;
  rateWithoutFee: number;
  rateWithFee: number;
}

const getRates = (code: string) => {
  return apiClient
    .get<Record<'data', number>>(ApiUrls.currencyConverterRates + code)
    .then((response) => response);
};

const get = () => {
  return apiClient
    .get<Record<'data', ICurrencyData[]>>(ApiUrls.currencyConverterSettings)
    .then((response) => response);
};

const post = (data: IValues) => {
  return apiClient
    .post<ICurrencyData>(ApiUrls.currencyConverterSettings, data)
    .then((response) => response);
};

const put = (data: IValues) =>
  apiClient
    .put<ICurrencyData>(ApiUrls.currencyConverterSettings + `/${data.id}`, data)
    .then((response) => response);

const deleteCurrencySetting = (id: string | number) => {
  return apiClient
    .delete<ICurrencyData>(ApiUrls.currencyConverterSettings + `/${id}`)
    .then((response) => response);
};

const companyInfoRequests = {
  get,
  post,
  put,
  delete: deleteCurrencySetting,
  getRates,
};

export default companyInfoRequests as typeof companyInfoRequests;
