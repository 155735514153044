import { Box, Button, CssBaseline, FormControlLabel, Grid, Paper, Switch } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC } from 'react';
import BackButton from '../../components/BackButton';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import pageLinks from '../../utils/pageLinks';
import { roundHint } from './constants';
import { roundOptions } from './static-data';
import { IValues } from './types';
import { useForm } from './use-form';

const CreateEditCurrencyConverter: FC = () => {
  const classes = useStandardFormStyles();
  const [{ isEdit, fk, options, rate }] = useForm();
  const createEditText = isEdit ? 'Edit' : 'Create';
  const createInputField = createInput<IValues>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.currencyConverter} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{createEditText} Converter </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField(
                'currencyCodeDefault',
                'Default currency',
                6,
                'text',
                true,
                undefined,
                true
              )}

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="currencyCodeTo"
                  options={options}
                  value={options.find((item) => fk.values.currencyCodeTo === item.value)}
                  onChange={(_, option) => fk.setFieldValue('currencyCodeTo', option?.value || '')}
                  autoHighlight
                  fullWidth
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add a currency"
                      variant="outlined"
                      name="currencyCodeTo"
                      fullWidth
                      required
                      error={!!fk.errors.currencyCodeTo}
                      helperText={fk.errors?.currencyCodeTo}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  label={'Currency exchange rate'}
                  value={rate}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="feeCurrency"
                  label={'Conversion fee type'}
                  value={fk.values.feeCurrency}
                  onChange={(e) => fk.setFieldValue('feeCurrency', e.target.value)}
                  options={[
                    { value: '%', label: '%' },
                    { value: 'VALUE', label: 'VALUE' },
                  ]}
                />
              </Grid>

              {createInputField(
                'feeAmount',
                `Fee Amount ${fk.values.feeCurrency === '%' ? 'percentage' : 'value'} `,
                6,
                'text',
                true
              )}

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="round"
                  label={'Rounding rule'}
                  value={fk.values.round}
                  onChange={(e) => fk.setFieldValue('round', e.target.value)}
                  options={roundOptions.map((item) => ({ value: item, label: item }))}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box color="#673ab7">
                  Rounding rule explanation:
                  {roundHint[fk.values.round].map((item) => (
                    <div> - {item}</div>
                  ))}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'enabled'}
                      checked={fk.values.enabled}
                      onChange={fk.handleChange}
                      name="enabled"
                      color="primary"
                    />
                  }
                  label="Enabled"
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!fk.isValid}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditCurrencyConverter;
