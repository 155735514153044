import { createReducer } from '@reduxjs/toolkit';
import * as EntityActions from './actions';
import { EntitiesState } from './types';

const initialState: EntitiesState = {
  products: [],
  permissions: [],
  permissionGroups: [],
  roles: null,
  userForEditing: null,
  couponForEditing: null,
  instanceForEditing: null,
  membershipRulesForEditing: null,
  membershipLevelForEditing: null,
  gatewayForEditing: null,
  settingForEditing: null,
  conversionTemplateForEditing: null,
  paymentMethodForEditing: null,
  planForEditing: null,
  instances: [],
  countries: [],
  countriesWithoutInstances: [],
  productForEditing: null,
  comboForEditing: null,
  payoutDetails: null,
  comboPlanForEditing: null,
  productCategoryForEditing: null,
  services: [],
  skuList: [],
  paymentMethods: null,
  payoutRequestDetails: null,
  legalDocumentForEditing: null,
  purchaseRules: [],
  couponUsageRules: [],
  countryGroups: null,
  userDetails: null,
  serviceForEditing: null,
  membershipLevels: null,
  umustlookItems: null,
  umustlookItemForEditing: null,
  mindhubGroups: null,
  mindhubGroupForEditing: null,
  notifications: null,
  notificationForEditing: null,
  faqItems: null,
  faqItemForEditing: null,
  switchSubscriptionRulesForEditing: null,
  companyInfoItems: null,
  companyInfoForEditing: null,
  vatForEditing: null,
  currencyConverterForEditing: null,
  recurringTypes: null,
  promoDiscountForEditing: null,
  upsellingRulesServices: null,
  exchangeRateForEditing: null,
};

export const entitiesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(EntityActions.saveServices, (state, action) => {
      return { ...state, services: action.payload };
    })
    .addCase(EntityActions.saveUpsellingRulesServices, (state, action) => {
      return { ...state, upsellingRulesServices: action.payload };
    })
    .addCase(EntityActions.saveSKUList, (state, action) => {
      return { ...state, skuList: action.payload };
    })
    .addCase(EntityActions.savePaymentMethods, (state, action) => {
      return { ...state, paymentMethods: action.payload };
    })

    .addCase(EntityActions.setProducts, (state, action) => {
      return { ...state, products: action.payload };
    })

    .addCase(EntityActions.setPermissions, (state, action) => {
      return { ...state, permissions: action.payload };
    })

    .addCase(EntityActions.setPermissionGroups, (state, action) => {
      return { ...state, permissionGroups: action.payload };
    })

    .addCase(EntityActions.editAdminUser, (state, action) => {
      return { ...state, userForEditing: action.payload };
    })
    .addCase(EntityActions.setAdminRoles, (state, action) => {
      return { ...state, roles: action.payload };
    })
    .addCase(EntityActions.editProduct, (state, action) => {
      return { ...state, productForEditing: action.payload };
    })
    .addCase(EntityActions.editProductCategory, (state, action) => {
      return { ...state, productCategoryForEditing: action.payload };
    })
    .addCase(EntityActions.editCombo, (state, action) => {
      return { ...state, comboForEditing: action.payload };
    })

    .addCase(EntityActions.editCouponData, (state, action) => {
      return { ...state, couponForEditing: action.payload };
    })

    .addCase(EntityActions.editInstanceData, (state, action) => {
      return { ...state, instanceForEditing: action.payload };
    })

    .addCase(EntityActions.editMembershipRulesData, (state, action) => {
      return { ...state, membershipRulesForEditing: action.payload };
    })

    .addCase(EntityActions.editSwitchSubscriptionRulesData, (state, action) => {
      return { ...state, switchSubscriptionRulesForEditing: action.payload };
    })

    .addCase(EntityActions.editMembershipLevelData, (state, action) => {
      return { ...state, membershipLevelForEditing: action.payload };
    })

    .addCase(EntityActions.editUmustlookItemData, (state, action) => {
      return { ...state, umustlookItemForEditing: action.payload };
    })

    .addCase(EntityActions.editMindhubGroupData, (state, action) => {
      return { ...state, mindhubGroupForEditing: action.payload };
    })

    .addCase(EntityActions.editNotificationData, (state, action) => {
      return { ...state, notificationForEditing: action.payload };
    })

    .addCase(EntityActions.editFaqItemData, (state, action) => {
      return { ...state, faqItemForEditing: action.payload };
    })

    .addCase(EntityActions.editCompanyInfoData, (state, action) => {
      return { ...state, companyInfoForEditing: action.payload };
    })

    .addCase(EntityActions.editPlan, (state, action) => {
      return { ...state, planForEditing: action.payload };
    })

    .addCase(EntityActions.editGatewayData, (state, action) => {
      return { ...state, gatewayForEditing: action.payload };
    })

    .addCase(EntityActions.editSettingData, (state, action) => {
      return { ...state, settingForEditing: action.payload };
    })

    .addCase(EntityActions.editConversionTemplate, (state, action) => {
      return { ...state, conversionTemplateForEditing: action.payload };
    })

    .addCase(EntityActions.editPaymentMethodData, (state, action) => {
      return { ...state, paymentMethodForEditing: action.payload };
    })

    .addCase(EntityActions.editComboPlan, (state, action) => {
      return { ...state, comboPlanForEditing: action.payload };
    })

    .addCase(EntityActions.payoutDetails, (state, action) => {
      return { ...state, payoutDetails: action.payload };
    })

    .addCase(EntityActions.saveCountries, (state, action) => {
      return { ...state, countries: action.payload };
    })

    .addCase(EntityActions.saveInstances, (state, action) => {
      return { ...state, instances: action.payload };
    })

    .addCase(EntityActions.setCountriesWithoutInstances, (state, action) => {
      return { ...state, countriesWithoutInstances: action.payload };
    })

    .addCase(EntityActions.editLegalDocument, (state, action) => {
      return { ...state, legalDocumentForEditing: action.payload };
    })

    .addCase(EntityActions.showPayoutRequestInfo, (state, action) => {
      return { ...state, payoutRequestDetails: action.payload };
    })

    .addCase(EntityActions.setPurchaseRules, (state, action) => {
      return { ...state, purchaseRules: action.payload };
    })

    .addCase(EntityActions.setCouponUsageRules, (state, action) => {
      return { ...state, couponUsageRules: action.payload };
    })

    .addCase(EntityActions.saveCountryGroups, (state, action) => {
      return { ...state, countryGroups: action.payload };
    })

    .addCase(EntityActions.setMembershipLevels, (state, action) => {
      return { ...state, membershipLevels: action.payload };
    })

    .addCase(EntityActions.setUmustlookItems, (state, action) => {
      return { ...state, umustlookItems: action.payload };
    })

    .addCase(EntityActions.setMindhubGroups, (state, action) => {
      return { ...state, mindhubGroups: action.payload };
    })

    .addCase(EntityActions.setNotifications, (state, action) => {
      return { ...state, notifications: action.payload };
    })

    .addCase(EntityActions.setFaqItems, (state, action) => {
      return { ...state, faqItems: action.payload };
    })

    .addCase(EntityActions.setCompanyInfoItems, (state, action) => {
      return { ...state, companyInfoItems: action.payload };
    })

    .addCase(EntityActions.setUserDetails, (state, action) => {
      return { ...state, userDetails: action.payload };
    })

    .addCase(EntityActions.editServiceData, (state, action) => {
      return { ...state, serviceForEditing: action.payload };
    })
    .addCase(EntityActions.editVatItem, (state, action) => {
      return { ...state, vatForEditing: action.payload };
    })
    .addCase(EntityActions.editPromoDiscount, (state, action) => {
      return { ...state, promoDiscountForEditing: action.payload };
    })
    .addCase(EntityActions.editCurrencyConverterItem, (state, action) => {
      return { ...state, currencyConverterForEditing: action.payload };
    })
    .addCase(EntityActions.setRecurringTypes, (state, action) => {
      return { ...state, recurringTypes: action.payload };
    })
    .addCase(EntityActions.editExchangeRate, (state, action) => {
      return { ...state, exchangeRateForEditing: action.payload };
    })
);
