import { Button, FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CountrySelect from '@root/components/CountrySelect';
import { IFilterFields } from '@root/components/MembershipLevelsTable/types';
import RecurringTypeSelect from '@root/components/RecurringTypeSelect';
import { selectCountryGroups } from "@root/store/entities/selectors";
import { IOption } from "@root/utils/commonTypes";
import { ChangeEvent, FC } from 'react';
import { useSelector } from "react-redux";

interface IProps {
  filterFields: IFilterFields;
  filterHandlers: {
    handleChangeCountries: (name: string, list: string[]) => void;
    handleIsRegionalChange: (e: ChangeEvent<{}>) => void;
    handleRecurringTypesChange: (value: string[]) => void;
    onSubmitFilter: () => void;
    handleCountryGroupsChange: (event: ChangeEvent<{}>, value: string[]) => void;
  };
}

export const MembershipLevelsFilter: FC<IProps> = ({ filterFields, filterHandlers }) => {
  const {
    handleRecurringTypesChange,
    handleIsRegionalChange,
    handleChangeCountries,
    onSubmitFilter,
    handleCountryGroupsChange,
  } = filterHandlers;
  const countriesGroup = useSelector(selectCountryGroups);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <RecurringTypeSelect
          value={filterFields.recurringTypes || []}
          onChange={handleRecurringTypesChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CountrySelect
          id="country"
          value={filterFields.countries || []}
          onChange={handleChangeCountries}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="countryGroup"
          multiple
          options={countriesGroup ? countriesGroup.map((group) => group.name) : []}
          getOptionLabel={(option: string) => option}
          value={filterFields.countryGroups || []}
          onChange={handleCountryGroupsChange}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant='outlined'              label="Country group"
              placeholder="Country group"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          label="Is Regional"
          control={
            <Switch
              id="isRegional"
              color="primary"
              name="isRegional"
              onChange={handleIsRegionalChange}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" color="primary" fullWidth onClick={onSubmitFilter}>
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};
