import { Box, Grid, IconButton, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import FlagIcon from '@material-ui/icons/Flag';
import { useStyles } from '@root/components/CreateEditProduct/group-item-styles';
import { IPlanGroupItemProps } from '@root/components/CreateEditProduct/types';
import 'date-fns';
import * as React from 'react';
import DeleteModalButton from '../DeleteModalButton';
import EditStatusButton from '../EditStatusButton';
import { useStandardFormStyles } from '../FormBase';

const PlanGroupItem: React.FC<IPlanGroupItemProps> = (props) => {
  const classes = { ...useStandardFormStyles(), ...useStyles() };
  const {
    plan,
    isGrid,
    handleDeletePlan,
    handleEditPlan,
    handlePlanStatusChange,
    showPlanCountries,
    groupItemState: { selectedItems, newPlanName, newPrices, editingPlanId },
    groupItemHandlers: {
      handleChangeField,
      handleMultipleSelectItems,
      submitNewData,
      changeEditingPlanData,
      handleClonePlan,
    },
  } = props;

  const price = `$${plan.setupPrice}/$${plan.recurringPrice || '-'}`;

  return (
    <Grid item sm={12} lg={isGrid ? 4 : 12} direction={isGrid ? 'column' : 'row'}>
      <Button
        className={`${classes.boxButton} ${classes.noMargin} ${classes.boxButtonPadding}`}
        fullWidth
        color="primary"
        variant="outlined"
      >
        {/* TODO: than checkbox is a part of multiply functionality */}
        {/* <Checkbox
          size="medium"
          color="primary"
          onChange={(e) => handleMultipleSelectItems(e, plan.id)}
          checked={selectedItems.includes(plan.id)}
        /> */}
        <Box
          display="flex"
          flexDirection={isGrid ? 'column' : 'row'}
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          {editingPlanId === plan.id ? (
            <Box
              display="flex"
              flexDirection={isGrid ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems="center"
              paddingBottom={isGrid ? 2 : 0}
              width="100%"
            >
              <TextField
                type="text"
                size="small"
                name="newPlanName"
                value={newPlanName}
                onChange={handleChangeField}
              />
              <Box padding="10px 0">
                <span>$</span>
                <TextField
                  className={classes.changeField}
                  type="text"
                  size="small"
                  name="setupPrice"
                  value={newPrices.setupPrice}
                  onChange={handleChangeField}
                />
                <span>/$</span>
                <TextField
                  className={classes.changeField}
                  type="text"
                  size="small"
                  name="recurringPrice"
                  value={newPrices.recurringPrice}
                  onChange={handleChangeField}
                />
              </Box>
              <EditStatusButton
                status={plan.status}
                onChange={(status: string) =>
                  handlePlanStatusChange({ id: plan.id as number, status })
                }
              />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection={isGrid ? 'column' : 'row'}
              justifyContent="space-between"
              width="85%"
              flex="1 1 auto"
            >
              <div>
                <span>{plan.name}</span>
                <IconButton
                  onClick={() => showPlanCountries(plan.countryCodes)}
                  aria-label="countries"
                  title={'Countries'}
                  size={'small'}
                >
                  <FlagIcon />
                </IconButton>
              </div>
              <span>{price}</span>
              <span>{plan.status}</span>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={isGrid ? 'center' : 'space-between'}
          >
            <Box display="flex" justifyContent="center" padding="12px">
              {editingPlanId === plan.id && (
                <IconButton
                  type="button"
                  aria-label="edit"
                  title="Save"
                  size="small"
                  onClick={() => submitNewData(plan.id as number)}
                >
                  <CheckIcon />
                </IconButton>
              )}
              <IconButton
                aria-label="edit-price"
                title="Quick edit"
                size="small"
                onClick={() => {
                  changeEditingPlanData(editingPlanId === plan.id ? null : plan.id);
                }}
              >
                {editingPlanId === plan.id ? <CloseIcon /> : <EditIcon />}
              </IconButton>
            </Box>
            <Button
              className={classes.editButton}
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => handleEditPlan(plan)}
            >
              Edit
            </Button>
            <DeleteModalButton
              name={plan.name}
              entity="Bundle"
              onDelete={() => handleDeletePlan(plan.id as number)}
            />
            <IconButton
              title="Create Clone"
              size="small"
              onClick={() => {
                handleClonePlan(plan);
              }}
            >
              <AddToPhotosOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Button>
    </Grid>
  );
};

export default PlanGroupItem;
