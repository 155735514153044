import { Button, Paper, TablePagination } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import AgreementsSettings from '../../components/AgreementsSettings';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import KycLevelSettings from '../../components/KycLevelSettings';
import SettingsList from '../../components/SettingList';
import Title from '../../components/Title';
import { IMeta } from '../../services/api/types';
import pageLinks from '../../utils/pageLinks';

const EnhancedTable: FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  const createSetting = () => {
    dispatch(push(pageLinks.createEditSetting));
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div className={classes.root}>
      <AgreementsSettings />
      <KycLevelSettings />

      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <Title>Settings</Title>
          <Can perform={Permissions.createSettings}>
            <Button
              onClick={createSetting}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<GroupAddIcon />}
            >
              Add setting
            </Button>
          </Can>
        </div>

        <SettingsList
          loading={loading}
          setLoading={setLoading}
          setMeta={setMeta}
          page={page}
          rowsPerPage={rowsPerPage}
        />

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
