import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICountry } from '../../services/api/requests/getInstances';
import { editMindhubGroupData } from '../../store/entities/actions';
import {
  selectCountries,
  selectMindhubGroupForEditing,
  selectServicesOptions,
} from '../../store/entities/selectors';
import { IOption } from './../../utils/commonTypes';
import { useModuleFormik } from './use-formik';

export const useForm = () => {
  const dispatch = useDispatch();
  const itemForEditing = useSelector(selectMindhubGroupForEditing);
  const fk = useModuleFormik(itemForEditing);
  const countries: ICountry[] = useSelector(selectCountries);
  const services: IOption[] = useSelector(selectServicesOptions);
  const isEdit = !!itemForEditing;

  // @ts-ignore
  const usedCountryCodes = fk.values?.countryCodes?.flatMap((item) => item.countryCodes);
  const availableCountries = countries
    .filter((item) => !usedCountryCodes?.includes(item.code))
    .map((item) => item.code);

  useEffect((): any => {
    return () => dispatch(editMindhubGroupData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = {
    isEdit,
    fk,
    availableCountries,
    services,
    countries,
  };

  return [state] as [typeof state];
};
