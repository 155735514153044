import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Permissions } from '@root/components/Can';
import { CustomTableHead, useStandardTableStyles } from '@root/components/DefaultTable';
import TableSkeleton from '@root/components/TableSkeleton';
import RecurringTypeRow from '@root/pages/RecurringTypes/editable-recurring-type';
import { recurringTypeHeadCells } from '@root/pages/RecurringTypes/static-data';
import {
  addRecurringTypes,
  editRecurringTypes,
  getRecurringTypes,
  IRecurringType,
} from '@root/services/api/requests/recurringTypes';
import { setRecurringTypes } from '@root/store/entities/actions';
import { selectRecurringTypes } from '@root/store/entities/selectors';
import { selectUserPermissions } from '@root/store/system/selectors';
import setSuccessNotification from '@root/utils/notifications';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IRecurringTypeTableProps {
  selectedTab: number;
  newItem: IRecurringType | null;
  setNewItem: Dispatch<SetStateAction<IRecurringType | null>>;
}

export const RecurringTypeTable: FC<IRecurringTypeTableProps> = ({
  selectedTab,
  newItem,
  setNewItem,
}) => {
  const classes = useStandardTableStyles();
  // const [newItem, setNewItem] = useState<IRecurringType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const permissions = useSelector(selectUserPermissions);
  const list = useSelector(selectRecurringTypes);
  const dispatch = useDispatch();

  const fetchList = async () => {
    try {
      const response = await getRecurringTypes();
      dispatch(setRecurringTypes(response.data));
    } catch (e) {
      console.error('fetch KYC List error', e);
    } finally {
      setLoading(false);
    }
  };

  const addUpdateRow = async (data: IRecurringType) => {
    try {
      const params = {
        ...data,
        lengthInDays: Number(data.lengthInDays),
      };
      if (data.id) {
        await editRecurringTypes(params);
      } else {
        await addRecurringTypes(params);
      }
      setSuccessNotification('success', {
        message: 'Success',
      });
      setNewItem(null);
      fetchList();
      return true;
    } catch (e) {
      console.error('UPDATE KYC ITEM ERROR', e);
      return false;
    }
  };

  const deleteNewItem = () => {
    setNewItem(null);
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readKycVerificationListLevel1) && !selectedTab) {
      fetchList();
    }
  }, [selectedTab]);

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={'medium'}
        aria-label="enhanced table"
      >
        <CustomTableHead headCells={recurringTypeHeadCells} order={'asc'} orderBy={'id'} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}
          {!loading && newItem && (
            <RecurringTypeRow
              key="new-item"
              onEditRow={addUpdateRow}
              deleteNewItem={deleteNewItem}
              item={newItem}
            />
          )}
          {!loading &&
            list?.map((item) => (
              <RecurringTypeRow
                key={item.id}
                onEditRow={addUpdateRow}
                deleteNewItem={deleteNewItem}
                item={item}
              />
            ))}
          {!loading && !list?.length && (
            <TableRow>
              <TableCell>There are no Recurring Types</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
