import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import SERVICE, { IServiceItem } from '../../services/api/requests/services';
import notify, { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { initialValues } from './static-data';
import { IValues } from './types';
import { getValuesFromEditedService } from './utils';
import { validationSchema } from './validation';

export const useServiceFormik = (editService: IServiceItem | null) => {
  const dispatch = useDispatch();
  const isEdit = !!editService;

  const fk: FormikProps<IValues> = useFormik({
    initialValues: isEdit
      ? getValuesFromEditedService(editService as IServiceItem, initialValues)
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const data = {
          name: values.name,
          enabled: values.enabled,
          isVisible: values.isVisible,
          ...(values.isTechnical
            ? {}
            : {
                exigoId: values.exigoId,
                recurringSkus: values.recurringSkus,
                skus: values.skus,
                oneDayRecurringSku: values.oneDayRecurringSku,
                oneDayDeductSku: values.oneDayDeductSku,
              }),
          imageUrl: values.imageUrl,
          ...(values.websiteUrl ? { websiteUrl: values.websiteUrl } : {}),
          ...(values.mobileAppUrlIos ? { mobileAppUrlIos: values.mobileAppUrlIos } : {}),
          ...(values.mobileAppUrlAndroid
            ? { mobileAppUrlAndroid: values.mobileAppUrlAndroid }
            : {}),
          bullets: values.bullets,
          isTechnical: values.isTechnical,
          isAgreementRequired: values.isAgreementRequired,
          isExtended: values.isExtended,
          createdAt: values.createdAt,
          updatedAt: values.updatedAt,
          isOneTime: values.isOneTime,
          pointRewards: {
            cv: values.cv || initialValues.cv,
            qv: values.qv || initialValues.qv,
            scv: values.scv || initialValues.scv,
            sqv: values.sqv || initialValues.sqv,
            fsb: values.fsb || initialValues.fsb,
            rcv: values.rcv || initialValues.rcv,
            rqv: values.rqv || initialValues.rqv,
            rscv: values.rscv || initialValues.rscv,
            rsqv: values.rsqv || initialValues.rsqv,
            rfsb: values.rfsb || initialValues.rfsb,
            matrixBV: values.matrixBV || initialValues.matrixBV,
            rmatrixBV: values.rmatrixBV || initialValues.rmatrixBV,
          },
          questRewards: {
            INITIAL: values.INITIAL || initialValues.INITIAL,
            RENEW: values.RENEW || initialValues.RENEW,
          },
          reduceCoreRewards: values.reduceCoreRewards,
          addSageRoles: !!values.addSageRoles?.length ? values.addSageRoles : undefined,
          ghlTag: values.ghlTag || null,
        };

        if (editService?.id) {
          await SERVICE.updateService(editService?.id, data);
        } else {
          await SERVICE.createService(data);
        }

        dispatch(push(pageLinks.services));
        setSuccessNotification();
      } catch (error) {
        if (editService?.id) {
          notify('error', {
            message: 'There was a problem trying to clone this service, please try again',
          });
        }
        console.log('error', (error as Error).message);
      }
    },
  });

  return fk;
};
