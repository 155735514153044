import DateFnsUtils from '@date-io/date-fns';
import { Button, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import createPayoutRequest from '../../services/api/requests/createPayoutRequest';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({});

interface IValues {
  method: 'all' | 'PayPal' | 'ACH' | 'bankWire' | 'cash' | 'pushToCard' | 'payoneer';
  createdFrom: string;
  createdTo: string;
  editedFrom: string;
  editedTo: string;
}

const initialValues: IValues = {
  method: 'all',
  createdFrom: '',
  createdTo: '',
  editedFrom: '',
  editedTo: '',
};

const CreateEditInstance: React.FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const actionText = 'Create';

  const fk: FormikProps<IValues> = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { createdFrom, createdTo, editedFrom, editedTo, method } = values;

      const methodParam = method !== 'all' ? { method } : {};

      const createData = {
        ...methodParam,
        createdFrom: createdFrom ? dateFns.format(new Date(createdFrom), 'yyyy-MM-dd') : null,
        createdTo: createdTo ? dateFns.format(new Date(createdTo), 'yyyy-MM-dd') : null,
        editedFrom: editedFrom ? dateFns.format(new Date(editedFrom), 'yyyy-MM-dd') : null,
        editedTo: editedTo ? dateFns.format(new Date(editedTo), 'yyyy-MM-dd') : null,
      };

      createPayoutRequest(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.csvReport));
        }
      });
    },
  });

  const { createdFrom, createdTo, editedFrom, editedTo } = fk.values;

  const createInputValue = (fromDate: string, toDate: string) =>
    fromDate && toDate && dateFns.isValid(fromDate) && dateFns.isValid(toDate)
      ? `${dateFns.format(new Date(fromDate), 'MMM d, yyyy')} - ${dateFns.format(
          new Date(toDate),
          'MMM d, yyyy'
        )}`
      : '';

  const isString = (value: any) => typeof value === 'string';
  const disabledCreateDates = !isString(editedFrom) && !isString(editedFrom);
  const disabledUpdateDates = !isString(createdFrom) && !isString(createdTo);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.csvReport} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Report`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputSelect
                  id="method"
                  label={'Payment Method'}
                  value={fk.values.method}
                  onChange={(e) => fk.setFieldValue('method', e.target.value)}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'PayPal', label: 'PayPal' },
                    { value: 'ACH', label: 'ACH' },
                    { value: 'bankWire', label: 'bankWire' },
                    { value: 'cash', label: 'cash' },
                    { value: 'pushToCard', label: 'pushToCard' },
                    { value: 'prepaidCard', label: 'Prepaid Card(COLS)' },
                    { value: 'payoneer', label: 'Payoneer' },
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <Modal
                  button={
                    <TextField
                      autoComplete="new-password"
                      variant="outlined"
                      fullWidth
                      required={!disabledCreateDates}
                      type={'text'}
                      onClick={(e) => (disabledCreateDates ? e.stopPropagation() : null)}
                      label={'Date Created interval'}
                      disabled={disabledCreateDates}
                      value={createInputValue(createdFrom, createdTo)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                fk.setValues(
                                  {
                                    ...fk.values,
                                    createdFrom: '',
                                    createdTo: '',
                                  },
                                  false
                                );
                              }}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  renderContent={(onClose) => (
                    <>
                      <DateRangePicker
                        onChange={({ selection }: any) => {
                          fk.setValues(
                            {
                              ...fk.values,
                              createdFrom: selection.startDate || createdFrom,
                              createdTo: selection.endDate || createdTo,
                            },
                            false
                          );
                        }}
                        moveRangeOnFirstSelection={true}
                        months={2}
                        ranges={[
                          {
                            startDate: createdFrom ? new Date(createdFrom) : undefined,
                            endDate: createdTo ? new Date(createdTo) : undefined,
                            key: 'selection',
                          },
                        ]}
                        direction="horizontal"
                      />

                      <IconButton onClick={onClose} edge="end">
                        <CloseIcon />
                      </IconButton>
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Modal
                  button={
                    <TextField
                      autoComplete="new-password"
                      variant="outlined"
                      fullWidth
                      type={'text'}
                      required={!disabledUpdateDates}
                      label={'Date Updated interval'}
                      disabled={disabledUpdateDates}
                      onClick={(e) => (disabledUpdateDates ? e.stopPropagation() : null)}
                      value={createInputValue(editedFrom, editedTo)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                fk.setValues(
                                  {
                                    ...fk.values,
                                    editedFrom: '',
                                    editedTo: '',
                                  },
                                  false
                                );
                              }}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  renderContent={(onClose) => (
                    <>
                      <DateRangePicker
                        onChange={({ selection }: any) => {
                          fk.setValues(
                            {
                              ...fk.values,
                              editedFrom: selection.startDate || editedFrom,
                              editedTo: selection.endDate || editedTo,
                            },
                            false
                          );
                        }}
                        moveRangeOnFirstSelection={true}
                        months={2}
                        ranges={[
                          {
                            startDate: editedFrom ? new Date(editedFrom) : undefined,
                            endDate: editedTo ? new Date(editedTo) : undefined,
                            key: 'selection',
                          },
                        ]}
                        direction="horizontal"
                      />
                      <IconButton onClick={onClose} edge="end">
                        <CloseIcon />
                      </IconButton>
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditInstance;
