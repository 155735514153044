export const typeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Multiple', value: 'Multiple' },
  { label: 'Renewal', value: 'Renewal' },
  { label: 'Subscription', value: 'Subscription' },
  { label: 'Upgrade', value: 'Upgrade' },
  { label: 'One-time', value: 'One-time' },
];

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Paid', value: 'Paid' },
  { label: 'On hold', value: 'On hold' },
  { label: 'Processing', value: 'Processing' },
  { label: 'Canceled', value: 'Canceled' },
  { label: 'Refunded', value: 'Refunded' },
  { label: 'Waiting for payment', value: 'Waiting for payment' },
  { label: 'Blockchain Pending', value: 'Blockchain Pending' },
  { label: 'Error', value: 'Error' },
  { label: 'Expired', value: 'Expired' },
  { label: 'Partially paid', value: 'Partially paid' },
];

export const methodOptions = [
  { label: 'All', value: 'all' },
  { label: 'Stripe', value: 'Stripe' },
  { label: 'PayPal', value: 'PayPal' },
  { label: 'TestProcessor', value: 'TestProcessor' },
  { label: 'EBANX', value: 'EBANX' },
  { label: 'NGenius', value: 'NGenius' },
  { label: 'BitPay', value: 'BitPay' },
  { label: 'CoinPayments', value: 'CoinPayments' },
  { label: 'PayULatam', value: 'PayULatam' },
  { label: 'AstroPayCard', value: 'AstroPayCard' },
  { label: 'Plaid', value: 'Plaid' },
  { label: 'e-wallet', value: 'e-wallet' },
  { label: 'MyFatoorah', value: 'MyFatoorah' },
  { label: 'Unknown', value: 'Unknown' },
];
