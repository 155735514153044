import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MembershipSettings from '@root/pages/CreateEditSwitchSubscriptionRule/membershipSettings';
import { useForm } from '@root/pages/CreateEditSwitchSubscriptionRule/use-form';
import 'date-fns';
import * as React from 'react';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import SKUSelect from '../../components/SKUSelect';
import Title from '../../components/Title';
import pageLinks from '../../utils/pageLinks';

const CreateEditSwitchSubscriptionRule: React.FC = () => {
  const classes = useStandardFormStyles();

  const [
    {
      actionText,
      fk,
      isMainPlanSelected,
      planIdToOptions,
      planIdFromOptions,
      membershipLevelsOptions,
      productListOptions,
      productId,
      membershipLevels,
      splitOption,
    },
    { createInputField, setProductId, setSplitOption },
  ] = useForm();

  return (
    <Grid item xs={12}>
      <BackButton
        name={'Back'}
        link={pageLinks.bundleSwitchSubscriptionRule}
        margin={'0 0 10px 0'}
      />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Switch Subscription Rule`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'freeRenew'}
                      checked={fk.values.freeRenew}
                      onChange={fk.handleChange}
                      name="freeRenew"
                      color="primary"
                    />
                  }
                  label="Renew free"
                />
              </Grid>{' '}
              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id="changeNested"
                      checked={fk.values.changeNested}
                      onChange={fk.handleChange}
                      name="changeNested"
                      color="primary"
                      disabled={!isMainPlanSelected}
                    />
                  }
                  label="Change Nested"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'withRenew'}
                      checked={fk.values.withRenew}
                      onChange={fk.handleChange}
                      name="withRenew"
                      color="primary"
                    />
                  }
                  label="With Renew"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                justify={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'enabled'}
                      checked={fk.values.enabled}
                      onChange={fk.handleChange}
                      name="enabled"
                      color="primary"
                    />
                  }
                  label="Is Enabled"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputSelect
                  id="product"
                  label={'Product'}
                  value={productId}
                  onChange={(e): void => setProductId(e.target.value as number)}
                  options={productListOptions}
                  error={!productId && !!fk.errors.planIdFrom && !!fk.errors.planIdTo}
                />
              </Grid>
              <Grid item xs={12}>
                <SKUSelect
                  id={'sku'}
                  label={'SKU'}
                  value={fk.values.sku}
                  onChange={fk.setFieldValue}
                />
              </Grid>{' '}
              <Grid item xs={12}>
                <InputSelect
                  id="adjustmentSku"
                  label={'Adjustment Sku'}
                  value={fk.values.adjustmentSku}
                  onChange={(e) => fk.setFieldValue('adjustmentSku', e.target.value)}
                  options={[
                    { label: 'ITM-CHNGE', value: 'ITM-CHNGE' },
                    { label: 'ITM-CHNGE-TRVL', value: 'ITM-CHNGE-TRVL' },
                  ]}
                  error={!!(fk.errors.adjustmentSku && fk.touched.adjustmentSku)}
                  onBlur={fk.handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="planIdFrom"
                    label={'Plan From'}
                    value={fk.values.planIdFrom}
                    onChange={(e) => {
                      fk.setFieldValue('planIdFrom', e.target.value);
                      fk.setFieldValue('planIdTo', '');
                    }}
                    // @ts-ignore
                    options={planIdFromOptions}
                    error={!!fk.errors.planIdFrom && fk.touched.planIdFrom}
                    onBlur={fk.handleBlur}
                    disabled={!productId}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('planIdFrom', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="planIdTo"
                    label={'Plan To'}
                    value={fk.values.planIdTo}
                    onChange={(e) => fk.setFieldValue('planIdTo', e.target.value)}
                    // @ts-ignore
                    options={planIdToOptions}
                    error={!!(fk.errors.planIdTo && fk.touched.planIdTo)}
                    onBlur={fk.handleBlur}
                    disabled={!productId}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('planIdTo', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="membershipId"
                    label={'Membership'}
                    value={fk.values.membershipId}
                    onChange={(e) => {
                      fk.setFieldValue('membershipId', e.target.value);
                    }}
                    options={membershipLevelsOptions}
                    error={!!fk.errors.membershipId && fk.touched.membershipId}
                    onBlur={fk.handleBlur}
                    disabled={!membershipLevels?.length}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('membershipId', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              {createInputField('ghlTag', 'GHL Tag', 6)}
              {createInputField('uiOriginalPrice', 'IU Original Price', 6, 'number')}
              {createInputField('fsbPeriod', 'FSB period for bundle switching, days', 6, 'number')}
              {createInputField('priceAdjustment', 'Price Adjustment', 6, 'number')}
              {createInputField('spendingsAdjustment', 'Spendings Adjustment', 6, 'number')}
              <Grid item xs={12} md={12}>
                <Box ml={1} mt={0} color="#673ab7">
                  Point Rewards Adjustment:
                </Box>
              </Grid>
              {createInputField('cv', 'Entry CV', 6, 'number')}
              {createInputField('qv', 'Entry QV', 6, 'number')}
              {createInputField('scv', 'Entry SCV', 6, 'number')}
              {createInputField('sqv', 'Entry SQV', 6, 'number')}
              {createInputField('fsb', 'Entry FSB', 6, 'number')}
              {createInputField('matrixBV', 'Matrix BV', 6, 'number')}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          id="isSplit"
                          name="isSplit"
                          checked={splitOption}
                          onChange={(e) => {
                            const flag = e.target.checked;
                            setSplitOption(flag);
                            if (!flag) {
                              fk.setValues({
                                ...fk.values,
                                splitSKU: '',
                                splitBaseValue: 0,
                                splitValues: 0,
                                splitFSB: 0,
                                splitPeriod: 1,
                              });
                            }
                          }}
                        />
                      }
                      // disabled={isAvailableForEditing('regional', 'splitSKU')}
                      label="Show Splitting section"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              {splitOption && (
                <>
                  <Grid item xs={12}>
                    <Typography color="primary">Splitting section</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SKUSelect
                      id={'splitSKU'}
                      label={'Split SKU'}
                      value={fk.values.splitSKU}
                      onChange={fk.setFieldValue}
                      // disabled={isAvailableForEditing('regional', 'splitSKU')}
                    />
                  </Grid>
                  {/* splitBaseValue, splitFSB, splitSKU, splitValues */}

                  {createInputField('splitBaseValue', 'Split Base Value', 6, 'number')}
                  {createInputField('splitValues', 'Split Values', 6, 'number')}
                  {createInputField('splitFSB', 'Split FSB', 6, 'number')}
                  {createInputField('splitPeriod', 'splitPeriod', 6, 'number')}
                </>
              )}
            </Grid>

            <MembershipSettings fk={fk} membershipLevelsOptions={membershipLevelsOptions} />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditSwitchSubscriptionRule;
