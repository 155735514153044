import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	declineOrderWrapper: {
		padding: 20,
		alignContent: 'center'
	},

	inputWidth: {
		width: '60%'
	}
}))
