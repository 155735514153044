import { IRecurringCharge } from '@root/pages/RecurringTypes/types';
import { AxiosResponse } from 'axios';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IRecurringType {
  code: string;
  createdAt?: string;
  id?: number;
  lengthInDays: number;
  name: string;
  status: string;
  updatedAt?: string;
}

export interface IGetIRecurringCharge {
  data: IRecurringCharge[];
}

interface IEditRecurringData {
  serviceIds: number[];
  // chargeTypeImpact: boolean;
}

export const addRecurringTypes = (data: IRecurringType) =>
  apiClient.post(ApiUrls.recurringTypes, data).then((response) => response);

export const getRecurringTypes = () =>
  apiClient
    .get<{ data: IRecurringType[] }>(ApiUrls.getRecurringTypes)
    .then((response) => response.data);

export const editRecurringTypes = (data: IRecurringType) => {
  const url = `${ApiUrls.recurringTypes}/${data.id}`;
  return apiClient.put(url, data).then((response) => response);
};

export const getRecurringChargeList = (search?: string): Promise<AxiosResponse<IGetIRecurringCharge>> => {
  return apiClient.get(`${ApiUrls.getRecurringChargeList}?search=${search}`);
}

export const editRecurringChargeList = (data: IEditRecurringData) =>
  apiClient.post(ApiUrls.editRecurringCharge, data);
