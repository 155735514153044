import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IFaqItem {
  id: number | string;
  question: string;
  answer: string;
  priority: number;
  planIds?: number[];
}

export interface IFaqItemResponse {
  data: IFaqItem[];
}

export interface ICreateFaqItem extends Omit<IFaqItem, 'id'> {}

export interface ICreateEditFaqItemResponse {
  data: IFaqItem;
}

export interface IEditFaqItemParams extends Partial<Omit<IFaqItem, 'id'>> {
  id: number | string;
}

const readFaqItemList = () => {
  return apiClient.get<IFaqItemResponse>(ApiUrls.faqItemList).then((response) => response);
};

const createFaqItem = (data: ICreateFaqItem) => {
  return apiClient
    .post<ICreateEditFaqItemResponse>(ApiUrls.createFaqItem, data)
    .then((response) => response);
};

const editFaqItem = (data: IEditFaqItemParams) =>
  apiClient
    .put<ICreateEditFaqItemResponse>(ApiUrls.editFaqItem + data.id, data)
    .then((response) => response);

const deleteFaqItem = (id: string | number) => {
  return apiClient
    .delete<ICreateEditFaqItemResponse>(ApiUrls.deleteFaqItem + `${id}`)
    .then((response) => response);
};

const readPlanFaqItemList = () => {
  return apiClient.get<IFaqItemResponse>(ApiUrls.planFaq).then((response) => response);
};

const createPlanFaqItem = (data: ICreateFaqItem) => {
  return apiClient
    .post<ICreateEditFaqItemResponse>(ApiUrls.planFaq, data)
    .then((response) => response);
};

const editPlanFaqItem = (data: IEditFaqItemParams) =>
  apiClient
    .put<ICreateEditFaqItemResponse>(ApiUrls.planFaq + data.id, data)
    .then((response) => response);

const deletePlanFaqItem = (id: string | number) => {
  return apiClient
    .delete<ICreateEditFaqItemResponse>(ApiUrls.planFaq + `${id}`)
    .then((response) => response);
};

export const faqItemRequests = {
  readFaqItemList,
  createFaqItem,
  editFaqItem,
  deleteFaqItem,
};

export const planFaqItemRequests = {
  readFaqItemList: readPlanFaqItemList,
  createFaqItem: createPlanFaqItem,
  editFaqItem: editPlanFaqItem,
  deleteFaqItem: deletePlanFaqItem,
};

export default faqItemRequests;
