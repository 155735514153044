import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ICountry } from '../../services/api/requests/getInstances';
import { selectCountries } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import mindhubGroupRequests, {
  ICreateMindhubGroup,
  IEditMindhubGroupParams,
  IMindhubGroup,
} from './../../services/api/requests/mindhubGroups';
import { IValues } from './types';
import { validationSchema } from './validation';

export const useModuleFormik = (editItem: IMindhubGroup | null) => {
  const dispatch = useDispatch();
  const isEdit = !!editItem;

  const countries: ICountry[] = useSelector(selectCountries);

  const initialValues: IValues = {
    name: '',
    mindhubBranchId: '',
    groupIds: [],
    serviceIds: [],
    countryCodes: countries.map((country) => country.code),
    countryIds: countries.map((country) => country.id),
    status: 'active',
    priority: 0,
    audienceTags: [],
    formAudienceTags: [],
    formGroupIds: [],
  };

  const fk: FormikProps<IValues> = useFormik({
    initialValues: isEdit
      ? {
          name: editItem?.name || initialValues.name,
          serviceIds: editItem?.serviceIds || initialValues.serviceIds,
          mindhubBranchId: editItem?.mindhubBranchId || '',
          groupIds: editItem?.groupIds || initialValues.groupIds,
          countryCodes:
            countries
              .filter((country) => editItem?.countryIds.includes(country.id))
              .map((item) => item.code) || [],
          countryIds: editItem?.countryIds || initialValues.countryIds,
          status: editItem?.status || initialValues.status,
          priority: editItem?.priority || initialValues.priority,
          audienceTags: editItem?.audienceTags || initialValues.audienceTags,
          formAudienceTags:
            editItem?.audienceTags?.map((item) => ({ tag: item })) ||
            initialValues.formAudienceTags,
          formGroupIds:
            editItem?.groupIds?.map((item) => ({ id: item })) || initialValues.formGroupIds,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values: IValues) => {
      const createData: ICreateMindhubGroup = {
        name: values.name.trim(),
        serviceIds: values.serviceIds,
        countryIds: countries
          .filter((country) => values.countryCodes.includes(country.code))
          .map((item) => item.id),
        mindhubBranchId: values.mindhubBranchId || null,
        groupIds: values.formGroupIds?.length
          ? values.formGroupIds.map((item) => Number(item.id))
          : null,
        status: values.status,
        priority: values.priority,
        audienceTags: values.formAudienceTags?.length
          ? values.formAudienceTags.map((item) => item.tag)
          : null,
      };

      if (editItem) {
        const editData: IEditMindhubGroupParams = {
          ...createData,
          id: editItem.id,
        };

        await mindhubGroupRequests.editMindhubGroup(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.mindhubGroups));
          }
        });
        return;
      }

      await mindhubGroupRequests.createMindhubGroup(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.mindhubGroups));
        }
      });
    },
  });

  return fk;
};
