import { IFormValues, OrderTypes } from '@root/components/SearchPanel/types';
import {
  getDownloadInvoices,
  getInvoices,
  IInvoice,
  IParams,
} from '@root/services/api/requests/invoices';
import { IMeta } from '@root/services/api/types';
import { toIsoString } from '@root/utils/helpers';
import React, { useEffect, useState } from 'react';

export const useInvoice = () => {
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [list, setList] = useState<IInvoice[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [downloading, setDownloading] = useState<boolean>(false);
  const [searchValues, setSearchValues] = useState<IFormValues>({
    orderId: '',
    userId: '',
    firstName: '',
    lastName: '',
    exigoOrderId: '',
    orderType: OrderTypes.none,
    dateState: {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    },
  });
  const { orderId, firstName, lastName, exigoOrderId, userId, orderType, dateState } = searchValues;

  const fetchList = async () => {
    try {
      const response = await getInvoices({
        page,
        limit: rowsPerPage,
        orderId,
        firstName,
        lastName,
        exigoOrderId,
        userId,
        orderType,
        startDate: dateState.startDate ? toIsoString(dateState.startDate) : 'all',
        endDate: dateState.endDate ? toIsoString(dateState.endDate) : 'all',
      });

      setLoading(false);
      setList(response.data.items);

      if (response?.data.meta) {
        setMeta(response?.data?.meta);
      }
    } catch (e) {
      console.warn('SIGNED_DOCS_ERROR', e);
    }
  };
  
  let requestsOfDownloading: Promise<any>[] = [];
  const handleDownloadInvoices = async () => {
    try {
      setDownloading(true);
      if (meta.total < 80) {
        await getDownloadInvoices({
          page,
          limit: meta.total,
          orderId,
          firstName,
          lastName,
          exigoOrderId,
          userId,
          orderType,
          startDate: dateState.startDate ? toIsoString(dateState.startDate) : 'all',
          endDate: dateState.endDate ? toIsoString(dateState.endDate) : 'all',
        });
      } else {
        let countOfDownload = Math.ceil(meta.total / 80);
        while (countOfDownload > 0) {
          requestsOfDownloading.push(
            getDownloadInvoices({
              page: countOfDownload,
              limit: 80,
              orderId,
              firstName,
              lastName,
              exigoOrderId,
              userId,
              orderType,
              startDate: dateState.startDate ? toIsoString(dateState.startDate) : 'all',
              endDate: dateState.endDate ? toIsoString(dateState.endDate) : 'all',
            })
          );
          countOfDownload--;
        }
       await Promise.all(requestsOfDownloading);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setDownloading(false);
    }
  };
  
  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchList();
  }, [page, rowsPerPage, searchValues]);

  const state = {
    list,
    loading,
    meta,
    downloading,
  };

  const handlers = {
    handleDownloadInvoices,
    handleChangePage,
    handleChangeRowsPerPage,
    setSearchValues,
    fetchList,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
