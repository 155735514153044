import { IDateState } from '@root/components/OrdersSearch/types';
import { getOptionsFromEnum } from '@root/utils/helpers';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

export enum CouponReportStatuses {
  ACTIVE = 'Active',
  REDEEMED = 'Redeemed',
}

export enum CouponReportPurchaseTypes {
  RENEW = 'RENEW',
  INITIAL = 'INITIAL',
  AFFILIATE_UPGRADE = 'AFFILIATE_UPGRADE',
}

export interface IGiftCardsReport {
  id: number;
  couponCode: string;
  amount: number;
  createdAt: string;
  status: CouponReportStatuses;
  exigoOrderId: number;
  usedBy: number;
  planId: number;
  orderType: CouponReportPurchaseTypes;
  redeemedAt: string;
}

export interface IGiftCardsReportFilters {
  purchaseType: CouponReportPurchaseTypes | 'All';
  status: CouponReportStatuses | 'All';
  amount: {
    amountTo: number | null;
    amountFrom: number | null;
  };
}

export interface IGiftCardsReportsFiltersProps {
  filtersState: {
    filtersFields: IGiftCardsReportFilters;
    redeemedDateState: IDateState;
    dateRangeValue: string;
  };
  filtersHandlers: {
    setRedeemedDateState: Dispatch<SetStateAction<IDateState>>;
    handleRangeClear: (e: React.SyntheticEvent<{}>) => void;
    handleFilterChange: (
      e: ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>
    ) => void;
    filtersApply: () => void;
  };
}

export const statusOptions = getOptionsFromEnum(CouponReportStatuses);
export const purchaseTypeOptions = getOptionsFromEnum(CouponReportPurchaseTypes);
