import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import companyInfoRequests, {
  ICompanyInfo,
  ICreateCompanyInfo,
  IEditCompanyInfoParams,
} from '../../services/api/requests/companyInfo';
import { fetchCompanyInfoItems } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { initialValues } from './static-data';
import { IValues } from './types';
import { validationSchema } from './validation';

export const useCompanyInfoFormik = (selectedCompanyInfo: ICompanyInfo | null) => {
  const dispatch = useDispatch();

  const fk: FormikProps<IValues> = useFormik({
    initialValues: selectedCompanyInfo
      ? {
          name: selectedCompanyInfo.name,
          email: selectedCompanyInfo.email,
          phone: selectedCompanyInfo.phone,
          registrationNumber: selectedCompanyInfo.registrationNumber,
          isShown: selectedCompanyInfo.isShown,
          isDefault: selectedCompanyInfo.isDefault,
          addressLine1: selectedCompanyInfo.address.line1,
          addressLine2: selectedCompanyInfo.address.line2,
          addressLine3: selectedCompanyInfo.address.line3,
          twitter: selectedCompanyInfo.socialMedia.twitter,
          facebook: selectedCompanyInfo.socialMedia.facebook,
          instagram: selectedCompanyInfo.socialMedia.instagram,
          instanceIds: selectedCompanyInfo.instances.map((item) => item.id),
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const createData: ICreateCompanyInfo = {
        name: values.name.trim(),
        email: values.email.trim(),
        phone: values.phone,
        registrationNumber: values.registrationNumber,
        isShown: values.isShown,
        isDefault: values.isDefault,
        instanceIds: values.instanceIds,
        address: {
          line1: values.addressLine1,
          line2: values.addressLine2,
          line3: values.addressLine3,
        },
        socialMedia: {
          twitter: values.twitter,
          facebook: values.facebook,
          instagram: values.instagram,
        },
      };

      if (selectedCompanyInfo) {
        const editData: IEditCompanyInfoParams = {
          ...createData,
          id: selectedCompanyInfo.id,
        };
        companyInfoRequests.editCompanyInfo(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.companyInfo));
            dispatch(fetchCompanyInfoItems());
          }
        });
        return;
      }

      companyInfoRequests.createCompanyInfo(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.companyInfo));
          dispatch(fetchCompanyInfoItems());
        }
      });
    },
  });

  return fk;
};
