import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  value: Yup.number().max(100).min(1),

  data: Yup.object().shape({
    RENEW: Yup.object().shape({
      '3': Yup.number().max(100, 'Value must be less than or equal to 100').min(1),
      '6': Yup.number().max(100, 'Value must be less than or equal to 100').min(1),
    }),
  }),
});
