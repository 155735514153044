import { Box, FormHelperText, InputLabel } from '@material-ui/core';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.css';

interface ITextEditorProps {
  value: string;
  setFieldValue: (val: string) => void;
  label?: string;
  error?: string;
}

const toolbarConfig = {
  options: ['inline', 'link', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  link: {
    options: ['link'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};

export const RichEditorField = ({ label, value, setFieldValue, error }: ITextEditorProps) => {
  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFieldValue(forFormik);
    setEditorState(editorState);
  };

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <Editor
        editorState={editorState}
        wrapperClassName="rich-editor-custom-wrapper"
        editorClassName="rich-editor-custom-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarConfig}
      />
      <Box mt={1} mb={3}>
        <FormHelperText error={!!error}>{error}</FormHelperText>
      </Box>
    </div>
  );
};
