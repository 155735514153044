import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { PAYMENT_METHOD_STATUSES } from './getPaymentMethodList';

export interface ICreatePaymentMethod {
  defaultProcessingFee: number;
  priority: number;
  imageUrls: string[];
  countries: string[];
  title: string;
  customFlow: boolean;
  status: PAYMENT_METHOD_STATUSES;
}

const request = (data: ICreatePaymentMethod) =>
  apiClient.post<IResponseAxios>(ApiUrls.createPaymentMethod, data).then((response) => response);

export default request;
