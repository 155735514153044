import DateFnsUtils from '@date-io/date-fns';
import { Grid, IconButton, TableCell, TableRow } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as React from 'react';
import { useState } from 'react';
import { IKycItem, IKycPutItemParams } from '../../services/api/requests/kyc';
import { IOption } from '../../utils/commonTypes';
import InputSelect from '../InputSelect';

export const STATUSES_LEVEL_1: IOption[] = [
  { value: 'SUCCESS', label: 'Success' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'ACCEPTABLE', label: 'Success with Names or country mismatch' },
  { value: 'ERROR', label: 'ERROR' },
];

export const STATUSES_LEVEL_2: IOption[] = [
  { value: 'SUCCESS', label: 'Success' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'ACCEPTABLE', label: 'Success with Names or country mismatch' },
  { value: 'ERROR', label: 'ERROR' },
];

const statuses = {
  1: STATUSES_LEVEL_1,
  2: STATUSES_LEVEL_2,
};

interface IProps {
  kycData: IKycItem;
  onEditRow(p: IKycPutItemParams): void;
  level: 1 | 2;
}

const EditableRow: React.FC<IProps> = ({ kycData, onEditRow, level }) => {
  const {
    id,
    userId,
    data: { firstName, lastName },
    verificationStatus,
    skipDeadline,
  } = kycData;

  const [editable, setEditable] = useState(false);
  const [status, setStatus] = useState<string>(verificationStatus as string);
  const [deadline, setDeadline] = useState<any>(skipDeadline);

  const statusOptions = statuses[level].filter((i) =>
    editable ? !['PROCESSING'].includes(i.value) : i
  );

  const onSave = () => {
    if (status !== verificationStatus || deadline !== skipDeadline) {
      onEditRow({
        userId,
        skipDeadline: deadline,
        verificationStatus: status,
      });
      setEditable(false);
    }
  };

  return (
    <TableRow key={id}>
      <TableCell align="left">{userId || id}</TableCell>
      <TableCell align="left">{firstName}</TableCell>
      <TableCell align="left">{lastName}</TableCell>

      {level === 1 && (
        <TableCell align="left">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                id={'startAt'}
                fullWidth
                format="dd-MM-yyyy"
                value={skipDeadline || deadline}
                onChange={setDeadline}
                disabled={!editable}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </TableCell>
      )}

      <TableCell align="left">
        <InputSelect
          id="forAction"
          value={status || verificationStatus}
          onChange={(e): void => setStatus(e.target.value as string)}
          options={statusOptions}
          disabled={!editable}
        />
      </TableCell>

      <TableCell align="left">
        {editable ? (
          <>
            <IconButton
              aria-label="edit"
              title={'Cancel'}
              size={'small'}
              onClick={() => setEditable(false)}
            >
              <CloseIcon />
            </IconButton>
            <IconButton aria-label="edit" title={'Save'} size={'small'} onClick={onSave}>
              <CheckIcon />
            </IconButton>
          </>
        ) : (
          <IconButton
            aria-label="edit"
            title={'Edit'}
            size={'small'}
            onClick={() => setEditable(true)}
          >
            <EditIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EditableRow;
