import { makeStyles } from '@material-ui/core/styles';

const useCustomTableStyles = makeStyles((theme) => ({
  smallCell: {
    width: '5%',
  },
  cell: {
    width: '15%',
  },
  hugeCell: {
    width: '30%',
  },
  detailsCell: {
    width: '13%',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
    height: '300px',
  },
}));
export default useCustomTableStyles;
