import { getSageRolesList } from '@root/services/api/requests/sageRole';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

interface ISageRolesOptions {
  label: string;
  value: string;
}

export const useSageRolesList = () => {
  const [sageRoleList, setSageRoleList] = useState<ISageRolesOptions[]>([]);

  const getSageRoleList = async () => {
    try {
      const res = await getSageRolesList();

      if (res) {
        setSageRoleList(
          res.data.data.map((option) => ({
            label: option.name,
            value: option.name,
          }))
        );
      }
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  useEffect(() => {
    getSageRoleList();
  }, []);

  return sageRoleList;
};
