import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface ICreateSwitchPlan {
  customerIds: number[] | number;
  fromPlanId: number;
  toPlanId: number;
}

export interface ISwitchPlanResponse {}

const createSwitchBundle = (data: ICreateSwitchPlan) => {
  return apiClient
    .post<ISwitchPlanResponse>(ApiUrls.createSwitchPlan, data)
    .then((response) => response);
};

const manageCustomerBundlesRequests = {
  createSwitchBundle,
};

export default manageCustomerBundlesRequests;
