import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStandardFormStyles } from '@root/components/FormBase';
import { IOption } from '@root/utils/commonTypes';
import { FieldArray, FormikProps, FormikProvider } from 'formik';
import { get } from 'lodash';
import React, { FC } from 'react';
import { emptySettings } from './static-data';
import { IValues, MembershipSetting } from './types';

const MembershipSettings: FC<{ fk: FormikProps<IValues>; membershipLevelsOptions: IOption[] }> = ({
  fk,
  membershipLevelsOptions,
}) => {
  const classes = useStandardFormStyles();

  return (
    <div className={classes.formContainer}>
      <form className={classes.form} onSubmit={fk.handleSubmit}>
        <Grid item xs={12}>
          <Typography color="primary"> Membership Settings </Typography>
          <FormikProvider value={fk}>
            <FieldArray name="membershipSettings">
              {({ remove, push }) => (
                <>
                  {fk.values?.membershipSettings?.length > 0 &&
                    fk.values.membershipSettings.map((item: MembershipSetting, index: number) => (
                      <Grid container spacing={2} className={classes.bordered}>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography>
                              {
                                membershipLevelsOptions.find(
                                  (m) => m.value === item.membershipLevelId
                                )?.label
                              }
                            </Typography>
                            <IconButton
                              aria-label="delete"
                              title={'Delete'}
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Grid>

                        {createFields(fk, index)}
                      </Grid>
                    ))}

                  {membershipLevelsOptions
                    .filter(
                      (item) =>
                        !fk.values.membershipSettings.some(
                          (settingItem) => settingItem.membershipLevelId === item.value
                        )
                    )
                    .map((item) => (
                      <Box my={1}>
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          size="small"
                          fullWidth
                          startIcon={<AddIcon />}
                          onClick={() => {
                            push({ ...emptySettings, membershipLevelId: item.value });
                          }}
                        >
                          Create settings for {item.label} membership
                        </Button>
                      </Box>
                    ))}
                </>
              )}
            </FieldArray>
          </FormikProvider>
        </Grid>
      </form>
    </div>
  );
};

export default MembershipSettings;

const createFields = (fk: FormikProps<IValues>, index: number) => {
  if (fk.values.membershipSettings[index]) {
    return [
      'priceAdjustment',
      'uiOriginalPrice',
      'splitBaseValue',
      'splitFSB',
      'splitValues',
      'splitPeriod',
      'fsbPeriod',
      'spendingsAdjustment',
      `pointRewardsAdjustment.cv`,
      `pointRewardsAdjustment.qv`,
      `pointRewardsAdjustment.scv`,
      `pointRewardsAdjustment.sqv`,
      `pointRewardsAdjustment.fsb`,
      `pointRewardsAdjustment.matrixBV`,
    ].map((item) => {
      return (
        <Grid item xs={12} md={6} direction={'row'} alignItems={'center'}>
          <TextField
            key={item}
            variant="outlined"
            type={'number'}
            fullWidth
            id={`membershipSettings.${index}.${item}`}
            label={item}
            required
            name={`membershipSettings.${index}.${item}`}
            autoComplete="new-password"
            onBlur={fk.handleBlur}
            value={get(fk.values.membershipSettings[index], item)}
            onChange={fk.handleChange}
          />
        </Grid>
      );
    });
  }
};
