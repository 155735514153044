import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    submitContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: '1 1 auto',
      height: 56,
      gap: 10,
      margin: '4px',
      width: 'auto',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 15px',
      border: '1px solid #E0E0E0',
      height: 56,
      width: '100%',
    },
  })
);
