import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyConverter from '../../services/api/requests/currencyConverter';
import { editCurrencyConverterItem } from '../../store/entities/actions';
import { selectCurrencyConverterForEditing } from '../../store/entities/selectors';
import { currencies } from './currencies';
import { useModuleFormik } from './use-formik';

export const useForm = () => {
  const dispatch = useDispatch();
  const itemForEditing = useSelector(selectCurrencyConverterForEditing);
  const fk = useModuleFormik(itemForEditing);
  const [rate, setRate] = useState<number>(0);
  const isEdit = !!itemForEditing;
  const currencyCodeTo = fk.values.currencyCodeTo;

  const { setFieldError } = fk;

  const getRate = useCallback(async () => {
    if (!currencyCodeTo) return;
    try {
      const response = await CurrencyConverter.getRates(currencyCodeTo as string);
      setRate(response.data.data);
      delete fk.errors.currencyCodeTo;
    } catch (e) {
      setRate(0);
      setFieldError('currencyCodeTo', `Currency ${currencyCodeTo} was not found`);
    }
  }, [setRate, currencyCodeTo, setFieldError, fk.errors.currencyCodeTo]);

  const options = useMemo(
    () =>
      currencies.map((item) => ({
        value: item.AlphabeticCode,
        label: `${item.Currency}(${item.AlphabeticCode}-${item.Entity})`,
      })),
    []
  );

  useEffect(() => {
    getRate().catch(console.log);
  }, [getRate]);

  useEffect(() => {
    return () => {
      dispatch(editCurrencyConverterItem(null));
    };
  }, [dispatch]);

  const state = {
    isEdit,
    fk,
    options,
    rate,
  };

  return [state] as [typeof state];
};
