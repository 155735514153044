import { SAGE_ROLE_TYPE } from '@root/pages/SageRoles/types';
import { ISageRoleData, updateSageRole } from '@root/services/api/requests/sageRole';
import { AxiosError } from 'axios';
import { ChangeEvent, useState } from 'react';

export const useSageRoleRow = (type: SAGE_ROLE_TYPE) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [newType, setNewType] = useState<SAGE_ROLE_TYPE>(type);

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    setNewType(e.target.value as SAGE_ROLE_TYPE);
  };

  const onSave = async (data: ISageRoleData) => {
    try {
      const res = await updateSageRole(data);

      if (res) {
        setEditable(false);
      }
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  const state = {
    editable,
    newType,
  };

  const handlers = {
    setEditable,
    setNewType,
    setValue,
    onSave,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
