import DateFnsUtils from '@date-io/date-fns';
import { TableContainer } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from '@material-ui/core/Typography';
import { useStandardTableStyles } from '@root/components/DefaultTable';
import InputSearch from '@root/components/InputSearch';
import Title from '@root/components/Title';
import { EditableCell } from '@root/pages/DeclineOrders/declineOrders-editableCell';
import { headCells } from '@root/pages/DeclineOrders/static-data';
import { useStyles } from '@root/pages/DeclineOrders/styles';
import { useDeclineOrders } from '@root/pages/DeclineOrders/use-decline-orders';
import { ITransactions } from '@root/services/api/requests/orders';
import { FC } from 'react';

const EnhancedTable: FC = () => {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const [
    { failedOrderData, failedOrderId, failedTransaction, gateways },
    { clearFailedOrder, setFailedOrderId },
  ] = useDeclineOrders();
  const dateFns = new DateFnsUtils();

  return (
    <Grid className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Declined order</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={`${classes.root} ${classes.declineOrderWrapper}`}>
        <InputSearch
          isLarge
          onSubmit={(val) => setFailedOrderId(val)}
          clearCallback={clearFailedOrder}
        />
        {failedOrderId.length > 0 && failedTransaction && (
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
              <TableBody>
                <>
                  <TableRow>
                    <TableCell>Customer ID</TableCell>
                    <TableCell align="right">{failedOrderData?.user.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell align="right">{failedOrderData?.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Orders Details</TableCell>
                    {failedOrderData &&
                      failedOrderData.items.map((item) => (
                        <TableCell key={item.id} align="right">
                          {item.level}
                        </TableCell>
                      ))}
                  </TableRow>
                  {failedOrderData?.transactions.map((transaction, numOfTransaction) =>
                    headCells.map((el, index) => (
                      <TableRow key={index}>
                        <TableCell variant="head">
                          {numOfTransaction > 0 ? el.label + ' 2' : el.label}
                        </TableCell>
                        <TableCell align="right">
                          {el.id === 'createdAt'
                            ? (dateFns.format(
                                new Date(transaction[el.id]),
                                'dd/MM/yyyy hh:mm'
                              ) as keyof ITransactions)
                            : transaction[el.id as keyof ITransactions] || '---'}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                  <EditableCell gateways={gateways} failedTransaction={failedTransaction} />
                </>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {failedOrderData && !failedTransaction && (
          <Text variant="body1">
            The order with that ID doesn't have any declined transactions.
          </Text>
        )}
      </Paper>
    </Grid>
  );
};

export default EnhancedTable;
