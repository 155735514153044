import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Can, { Permissions } from '@root/components/Can';
import { CustomTableHead, useStandardTableStyles } from '@root/components/DefaultTable';
import InputSelect from '@root/components/InputSelect';
import TableSkeleton from '@root/components/TableSkeleton';
import Title from '@root/components/Title';
import { SageRoleRow } from '@root/pages/SageRoles/editable-row';
import { headCells, sageRoleInitialValue } from '@root/pages/SageRoles/static-data';
import { sageRolesOptions, SAGE_ROLE_TYPE } from '@root/pages/SageRoles/types';
import { useSageRoles } from '@root/pages/SageRoles/use-sage-roles';
import { FC } from 'react';

const SageRoles: FC = () => {
  const classes = useStandardTableStyles();
  const [
    { sageRolesList, loading, newItem, newSageRole },
    {
      handleDelete,
      fetchSageRolesList,
      setNewItem,
      setNewSageRole,
      handleChangeName,
      handleChangeType,
      addNewSageRole,
    },
  ] = useSageRoles();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Sage Roles</Title>
            <Can perform={Permissions.createSageRole}>
              <Button
                onClick={() => setNewItem(!newItem)}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add Sage Roles
              </Button>
            </Can>
          </div>
        </Paper>

        <Paper className={`${classes.root}`}>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
              <CustomTableHead classes={classes} headCells={headCells} />
              <TableBody>
                {loading && <TableSkeleton />}
                {newItem && (
                  <TableRow>
                    <TableCell>
                      <TextField value={newSageRole.name} onChange={handleChangeName} />
                    </TableCell>
                    <TableCell>
                      <InputSelect
                        size="small"
                        value={newSageRole.type}
                        options={sageRolesOptions}
                        variant="outlined"
                        name="type"
                        onChange={(e) => handleChangeType(e.target.value as SAGE_ROLE_TYPE)}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <>
                        <IconButton
                          aria-label="edit"
                          title="Cancel"
                          size="small"
                          onClick={() => {
                            setNewItem(!newItem);
                            setNewSageRole(sageRoleInitialValue);
                            // !item.id && deleteNewItem();
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <IconButton
                          type="button"
                          aria-label="edit"
                          title="Save"
                          size="small"
                          onClick={addNewSageRole}
                        >
                          <CheckIcon />
                        </IconButton>
                      </>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {sageRolesList &&
                  sageRolesList.map((sageRole) => {
                    return (
                      <SageRoleRow
                        item={sageRole}
                        fetchSageRolesList={fetchSageRolesList}
                        handleDelete={handleDelete}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </div>
  );
};

export default SageRoles;
