import { IFormValues, OrderTypes } from './types';

export const initialValues: IFormValues = {
  orderId: '',
  userId: '',
  firstName: '',
  lastName: '',
  exigoOrderId: '',
  orderType: OrderTypes.none,
  dateState: {
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  }
};
