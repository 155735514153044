import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import FileInput from '../../components/FileInput';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import createProductCategory from '../../services/api/requests/createProductCategory';
import editProductCategoryItem from '../../services/api/requests/editProductCategoryItem';
import { IProductCategoryListItem } from '../../services/api/requests/getProductCategoryList';
import getProductList, { IProductListItem } from '../../services/api/requests/getProductList';
import { editProductCategory } from '../../store/entities/actions';
import { selectProductCategoryForEditing } from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('name is required')
    .max(40, '40 characters max')
    .matches(/^[a-zA-Z\s]+([\s]?)+[a-zA-Z]+$/, 'These characters are not supported'),
  urlParam: Yup.string().nullable().required('URL param is required'),
  isVisible: Yup.boolean(),
  imageUrl: Yup.string().nullable(),
});

interface IFormValues {
  id?: number;
  name: string;
  externalLink: string;
  isVisible: boolean;
  urlParam: string;
  imageUrl: string;
  products?: any;
  productIds?: any[];
}

const initialValues: IFormValues = {
  name: '',
  externalLink: '',
  isVisible: false,
  urlParam: '',
  imageUrl: '',
  productIds: [],
};

const initialOptions: IOption[] = [
  {
    label: '-',
    value: '-',
  },
];

export default function CreateEditProductCategory() {
  const classes = useStandardFormStyles();
  const productCategoryForEditing = useSelector(selectProductCategoryForEditing);
  const [productOption, setProductOptions] = React.useState<IOption[]>(initialOptions);
  const dispatch = useDispatch();

  const { name, externalLink, isVisible, urlParam, imageUrl } =
    productCategoryForEditing || initialValues;

  const makOptions = (items: IProductListItem[]): IOption[] => {
    if (!items) {
      return initialOptions;
    }

    return items.map((i: IProductListItem) => ({
      label: i.name,
      value: i.id,
    }));
  };

  const fetchProductList = () => {
    getProductList({
      page: 1,
      sortBy: 'updatedAt',
      sortDirection: 'DESC',
      limit: 50,
      searchTerm: '',
    }).then((response) => {
      if (response) {
        const options = makOptions(response.data.data.items);
        setProductOptions(options);
      }
    });
  };

  useEffect(() => {
    fetchProductList();
    return () => {
      dispatch(editProductCategory(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<IFormValues> = useFormik({
    initialValues: {
      name,
      externalLink,
      isVisible,
      urlParam,
      imageUrl,
      productIds:
        productCategoryForEditing?.products?.map((p: IProductCategoryListItem) => p.id) || [],
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: IFormValues) => {
      const requestData = {
        externalLink: values.externalLink,
        isVisible: values.isVisible,
        name: values.name,
        urlParam: values.urlParam,
        imageUrl: values.imageUrl,
      };

      try {
        const response = productCategoryForEditing
          ? await editProductCategoryItem({
              ...requestData,
              ...(!!values.productIds?.length ? { products: values.productIds } : {}),
              id: productCategoryForEditing.id,
            })
          : await createProductCategory({
              ...requestData,
              ...(!!values.productIds?.length ? { products: values.productIds } : {}),
            });

        // @ts-ignore
        dispatch(editProductCategory(response.data as IProductCategoryListItem));
        setSuccessNotification();
        dispatch(push(pageLinks.products));
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  const createInputField = createInput<IFormValues>(fk);
  const createEditText = productCategoryForEditing ? 'Edit' : 'Create';

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.products} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{createEditText} Product Category</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Box mb={2}>
              <Grid item xs={12}>
                <Typography color="primary">Category Image</Typography>

                <FileInput
                  id={'imageUrl'}
                  label={''}
                  imageUrl={fk.values.imageUrl}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.CATEGORY}
                />
              </Grid>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Product Category Name"
                  error={!!(fk.errors.name && fk.touched.name)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.name && fk.errors.name}
                  value={fk.values.name}
                  onChange={fk.handleChange}
                />
              </Grid>

              {createInputField('urlParam', 'Shop URL name', 6)}
              {createInputField('externalLink', 'External link', 6)}
            </Grid>

            <Box mt={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Switch
                        id={'isVisible'}
                        checked={fk.values.isVisible}
                        onChange={(e) => {
                          fk.handleChange(e);
                        }}
                        name="isVisible"
                        color="primary"
                      />
                    }
                    label="Visible"
                  />
                </FormControl>
              </Grid>
            </Box>
            {
              <>
                <Grid container xs={12}>
                  <Box margin={'20px 0 0 0'} width="100%">
                    <Title>Products</Title>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      id="productIds"
                      variant="outlined"
                      multiple
                      value={fk.values.productIds}
                      onChange={(e) => fk.setFieldValue('productIds', e.target.value)}
                      input={<Input />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {productOption.map((p) => (
                        <MenuItem className={classes.textWrap} key={p.label} value={p.value}>
                          <Checkbox
                            // ts-ignore
                            checked={fk.values.productIds?.includes(p.value)}
                          />
                          <ListItemText primary={p.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            }

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
}
