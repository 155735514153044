import { IValues } from './types';

export const initialValues: IValues = {
  id: undefined,
  enabled: false,
  currencyCodeTo: '',
  currencyCodeDefault: 'USD',
  feeAmount: 0,
  feeCurrency: 'VALUE',
  round: 1,
};

export const roundOptions = ['0', '1', '0.5', '0.25', '0.99'];
