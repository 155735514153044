import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import Image from 'material-ui-image';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import EditStatusButton from '../../components/EditStatusButton';
import InputSearch from '../../components/InputSearch';
import Popover from '../../components/Popover';
import PreviewModalButton from '../../components/PreviewModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import changeProductStatus from '../../services/api/requests/changeProductStatus';
import deleteProduct from '../../services/api/requests/deleteProduct';
import deleteProductCategory from '../../services/api/requests/deleteProductCategory';
import getProductCategoryList, {
  IProductCategoryListItem,
} from '../../services/api/requests/getProductCategoryList';
import getProductList, { IProductListItem } from '../../services/api/requests/getProductList';
import { IMeta, IResponseAxios } from '../../services/api/types';
import { editProduct, editProductCategory } from '../../store/entities/actions';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'imageUrl', numeric: false, disablePadding: false, label: 'Picture' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date created' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Date updated' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

const productCategoryHeadCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
];

interface IProductMeta extends IMeta {
  limit: number;
  page: number;
}
const metaInitialState = { limit: 0, page: 1, total: 0, totalPages: 0 };

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const permissions = useSelector(selectUserPermissions);
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = React.useState<string>('name');
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [list, setList] = React.useState<IProductListItem[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isCategories, setCategories] = React.useState<number>(0);
  const [meta, setMeta] = React.useState<IProductMeta>(metaInitialState);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readProductList)) {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortDirection, rowsPerPage, searchTerm, isCategories]);

  const fetchList = () => {
    const apiRequest = isCategories ? getProductCategoryList : getProductList;

    apiRequest({
      page,
      sortBy,
      sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
      limit: rowsPerPage,
      searchTerm,
    }).then((response) => {
      if (response) {
        setLoading(false);
        setList(isCategories ? response.data.data : response.data.data.items);
        if (!isCategories) {
          setMeta(response.data.data.meta);
        }
      }
    });
  };

  const createListItem = () => {
    dispatch(push(pageLinks.createEditProduct));
    dispatch(editProduct(null));
  };

  const createProductCategoryItem = () => {
    dispatch(push(pageLinks.createEditProductCategory));
    dispatch(editProductCategory(null));
  };

  const pushToNestedRules = () => {
    dispatch(push(pageLinks.nestedRules));
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDelete = (id: string) => {
    const deleteRequest = isCategories ? deleteProductCategory(id) : deleteProduct(id);
    deleteRequest.then((response: IResponseAxios) => {
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        fetchList();
      }
    });
  };

  const handleStatusChange = async (data: { status: string; id: string }) => {
    const changeRequest = changeProductStatus;
    const response = await changeRequest(data);
    if (response) {
      setNotification('success', {
        message: 'Success',
      });
      fetchList();
    }
  };

  const handleEdit = (data: IProductListItem | IProductCategoryListItem) => {
    const productID = `?productId=${data.id}`;

    if (isCategories) {
      dispatch(editProductCategory(data as IProductCategoryListItem));
      dispatch(push(pageLinks.createEditProductCategory + productID));
      return;
    }
    dispatch(editProduct(data as IProductListItem));
    dispatch(push(pageLinks.createEditProduct + productID));
  };

  const createEditService = () => {
    dispatch(
      push({ pathname: pageLinks.createEditService, state: { prevPath: pageLinks.products } })
    );
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPage(1);
    setList(null);
    setCategories(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Products</Title>
            <div>
              <Can perform={Permissions.createService}>
                <Button
                  className={classes.marginRight}
                  onClick={createEditService}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<GroupAddIcon />}
                >
                  Add a service
                </Button>
              </Can>
              <Can perform={Permissions.createProduct}>
                <Button
                  className={classes.marginRight}
                  onClick={pushToNestedRules}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<GroupAddIcon />}
                >
                  Nested Rules
                </Button>
              </Can>
              <Can perform={Permissions.createProduct}>
                <Button
                  className={classes.marginRight}
                  onClick={createProductCategoryItem}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<GroupAddIcon />}
                >
                  Add Category
                </Button>
              </Can>
              <Can perform={Permissions.createProduct}>
                <Button
                  onClick={createListItem}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<GroupAddIcon />}
                >
                  Add Product
                </Button>
              </Can>
            </div>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <Tabs
          value={isCategories}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabClick}
          aria-label="disabled tabs example"
        >
          <Tab label="Products" disabled={loading} />
          <Tab label="Categories" disabled={loading} />
        </Tabs>

        <div className={classes.searchRow}>
          <InputSearch
            onSubmit={(val) => {
              setPage(1);
              setSearchTerm(val);
            }}
          />
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={sortDirection}
              orderBy={sortBy}
              onRequestSort={handleRequestSort}
              headCells={isCategories ? productCategoryHeadCells : headCells}
            />

            <TableBody>
              {loading && <TableSkeleton />}

              {!isCategories &&
                !loading &&
                list?.map((item) => {
                  const { createdAt, updatedAt, id, name, description, imageUrl, status } = item;
                  const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
                  const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');
                  const img = imageUrl ? imageUrl.replace('.png', '') : imageUrl;

                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{description}</TableCell>
                      <TableCell align="left">
                        <Popover
                          content={
                            <Image
                              src={img || ''}
                              aspectRatio={16 / 20}
                              style={{ width: '200px' }}
                              disableSpinner
                            />
                          }
                        >
                          <Image
                            src={img || ''}
                            aspectRatio={3 / 4}
                            iconContainerStyle={{ paddingTop: 0 }}
                            imageStyle={{ height: '40px', width: '30px' }}
                            style={{ padding: '10px 0' }}
                            disableSpinner
                          />
                        </Popover>
                      </TableCell>
                      <TableCell>{create}</TableCell>
                      <TableCell>{update}</TableCell>

                      <TableCell align="left">
                        <EditStatusButton
                          status={status}
                          onChange={(status: string) => handleStatusChange({ id, status })}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <PreviewModalButton data={item} />
                      </TableCell>

                      <TableCell align="left">
                        <Can perform={Permissions.updateProduct}>
                          <IconButton
                            aria-label="edit"
                            title={'Edit'}
                            onClick={() => handleEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Can>
                      </TableCell>

                      <TableCell align="left">
                        <Can perform={Permissions.deleteProduct}>
                          <DeleteModalButton
                            name={name}
                            entity={'Product'}
                            onDelete={() => handleDelete(id)}
                          />
                        </Can>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {!!isCategories &&
                !loading &&
                list?.map((item) => {
                  const { id, name } = item;
                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{name}</TableCell>

                      <TableCell size="small" align="right">
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Can perform={Permissions.updateProduct}>
                              <IconButton
                                aria-label="edit"
                                title={'Edit'}
                                onClick={() => handleEdit(item)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Can>
                          </Grid>
                          <Grid item xs={4}>
                            <Can perform={Permissions.deleteProduct}>
                              <DeleteModalButton
                                name={name}
                                entity={isCategories ? 'Category' : 'Product'}
                                onDelete={() => handleDelete(id)}
                              />
                            </Can>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>
                    There are no products{' '}
                    {searchTerm.length > 0 ? `for current search - ${searchTerm} ` : ''}{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!isCategories && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={meta.total}
            rowsPerPage={meta.limit}
            page={meta.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
};

export default EnhancedTable;
