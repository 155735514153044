import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { GatewayListFilter } from '@root/components/GatewayList/GatewayListFilter';
import { IFiltersFields } from '@root/components/GatewayList/types';
import { push } from 'connected-react-router';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import TableSkeleton from '../../components/TableSkeleton';
import deleteGateway from '../../services/api/requests/deleteGateway';
import {
  getGatewayList,
  IGateway,
  updateGateway,
} from '../../services/api/requests/getGatewayList';
import { IMeta } from '../../services/api/types';
import { editGatewayData } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import DeleteModalButton from '../DeleteModalButton';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', disableSort: true },
  {
    id: 'rebillyId',
    numeric: false,
    disablePadding: false,
    label: 'Rebilly Id',
    disableSort: true,
  },
  {
    id: 'currencies',
    numeric: false,
    disablePadding: false,
    label: 'Currencies',
    disableSort: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
  },
  {
    id: 'processingFee',
    numeric: false,
    disablePadding: false,
    label: 'Processing Fee',
    disableSort: true,
  },
  {
    id: 'exigoTransactionField',
    numeric: false,
    disablePadding: false,
    label: 'Exigo Transaction Field',
    disableSort: true,
  },
  {
    id: 'invoiceAbbr',
    numeric: false,
    disablePadding: false,
    label: 'Invoice Abbr',
    disableSort: true,
  },
  {
    id: 'gatewayMethod',
    numeric: false,
    disablePadding: false,
    label: 'Method',
    disableSort: true,
  },
  {
    id: 'companyName',
    numeric: false,
    disablePadding: false,
    label: 'Company Descriptor',
    disableSort: true,
  },
  {
    id: 'invoiceAddress',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Updated',
    disableSort: true,
  },
];

const GatewayList: FC<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setMeta: (meta: IMeta) => void;
  page: number;
  rowsPerPage: number;
}> = (props) => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const dispatch = useDispatch();
  const [list, setList] = useState<IGateway[] | null>(null);
  const [quickEditGateway, setQuickEditGateway] = useState<number | null>(null);
  const [newData, setNewData] = useState<Pick<IGateway, 'processingFee' | 'companyName'>>({
    processingFee: 0,
    companyName: '',
  });
  const [filtersFields, setFiltersFields] = useState<IFiltersFields>({
    status: '',
    exigoTransactionField: '',
    companyName: '',
    processingFee: 0,
    name: '',
  });

  const { loading, setLoading, setMeta, page, rowsPerPage } = props;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchGatewayList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const fetchGatewayList = () => {
    getGatewayList({
      page,
      limit: rowsPerPage,
      ...filtersFields,
    }).then((response) => {
      if (response) {
        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    });
  };

  const handleGatewayDelete = (id: number) => {
    deleteGateway(id).then((response) => {
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        fetchGatewayList();
      }
    });
  };

  const handleGatewayEdit = (gateway: IGateway) => {
    dispatch(editGatewayData(gateway));
    dispatch(push(pageLinks.createEditGateway));
  };

  const handleNewDataChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;

    if (name === 'processingFee') {
      setNewData({
        ...newData,
        processingFee: value as number,
      });
    } else {
      setNewData({
        ...newData,
        [name as string]: value,
      });
    }
  };


  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;

    if (name === 'processingFee') {
      setFiltersFields({
        ...filtersFields,
        processingFee: value as number,
      });
    } else {
      setFiltersFields({
        ...filtersFields,
        [name as string]: value,
      });
    }
  };

  const handleFilterApply = () => {
    fetchGatewayList();
  };

  const submitNewData = (id: number) => {
    console.log('submitNewData', id, newData);
    if (newData.processingFee && newData.companyName) {
      updateGateway(id, { ...newData, processingFee: Number(newData.processingFee) }).then(
        (response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            fetchGatewayList();
            setQuickEditGateway(null);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (quickEditGateway) {
      const gateway = list?.find((gateway) => gateway.id === quickEditGateway);
      setNewData({
        processingFee: gateway?.processingFee || 0,
        companyName: gateway?.companyName || '',
      });
    }
  }, [quickEditGateway]);

  return (
    <>
      <GatewayListFilter
        filtersFields={filtersFields}
        handleFilterChange={handleFilterChange}
        handleFilterApply={handleFilterApply}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              list?.map((gateway: IGateway) => {
                const {
                  id,
                  rebillyId,
                  name,
                  currencies,
                  status,
                  processingFee,
                  exigoTransactionField,
                  invoiceAbbr,
                  gatewayMethod,
                  createdAt,
                  updatedAt,
                  companyName,
                  invoiceAddress,
                } = gateway;
                const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
                const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

                return (
                  <TableRow key={id}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{rebillyId}</TableCell>
                    <TableCell align="left">
                      <Box display="flex" flexWrap="wrap">
                        {currencies.map((currency: string, idx: number, arr: any) => (
                          <Box m={0.5} display="flex" flexWrap="wrap" key={currency}>{`${currency}${
                            arr.length - 1 === idx ? '' : ', '
                          }`}</Box>
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell align="left">{status}</TableCell>
                    <TableCell align="left">
                      {quickEditGateway === gateway.id ? (
                        <TextField
                          autoComplete="Processing Fee"
                          name="processingFee"
                          variant="standard"
                          fullWidth
                          id="processingFee"
                          type="number"
                          onChange={handleNewDataChange}
                          value={newData.processingFee}
                          required
                          error={!newData.processingFee}
                        />
                      ) : (
                        processingFee
                      )}
                    </TableCell>
                    <TableCell align="left">{exigoTransactionField}</TableCell>
                    <TableCell align="left">{invoiceAbbr}</TableCell>
                    <TableCell align="left">{gatewayMethod?.name}</TableCell>
                    <TableCell align="left">
                      {quickEditGateway === gateway.id ? (
                        <TextField
                          autoComplete="Company name"
                          name="companyName"
                          variant="standard"
                          fullWidth
                          id="companyName"
                          type="text"
                          onChange={handleNewDataChange}
                          value={newData.companyName}
                          required
                          error={!newData.companyName}
                        />
                      ) : (
                        companyName
                      )}
                    </TableCell>
                    <TableCell align="left">{invoiceAddress}</TableCell>
                    <TableCell>{create}</TableCell>
                    <TableCell>{update}</TableCell>
                    <TableCell align="left">
                      <Can perform={Permissions.updatePaymentGateway}>
                        {quickEditGateway === gateway.id && (
                          <IconButton
                            type="button"
                            aria-label="edit"
                            title="Save"
                            size="small"
                            onClick={() => submitNewData(gateway.id)}
                          >
                            <CheckIcon />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label="edit-price"
                          title="Quick edit"
                          size="small"
                          onClick={() => {
                            setQuickEditGateway(
                              quickEditGateway === gateway.id ? null : gateway.id
                            );
                          }}
                        >
                          {quickEditGateway === gateway.id ? <CloseIcon /> : <EditIcon />}
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="left">
                      <Can perform={Permissions.updatePaymentGateway}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleGatewayEdit(gateway)}
                        >
                          Edit
                        </Button>
                      </Can>
                    </TableCell>
                    <TableCell align="left">
                      <Can perform={Permissions.deletePaymentGateway}>
                        <DeleteModalButton
                          name={name}
                          entity={'Gateway'}
                          onDelete={() => handleGatewayDelete(id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })}

            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no gateways</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GatewayList;
