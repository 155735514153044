import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IEditCountry {
  customBaseUrl?: string;
  customCompanyName?: string;
  phoneVerificationEnabled?: boolean;
}

const request = (data: IEditCountry, id: number) => {
  return apiClient
    .put<IResponseAxios>(ApiUrls.updateCountry + id, data)
    .then((response) => response);
};

export default request;
