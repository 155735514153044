import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AssignKycPortal from '../../components/AssignKycPortal';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import InputSearch from '../../components/InputSearch';
import InputSelect from '../../components/InputSelect';
import EditableRow, { STATUSES_LEVEL_1, STATUSES_LEVEL_2 } from '../../components/KycTableRow';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import KYC, { IKycItem, IKycPutItemParams } from '../../services/api/requests/kyc';
import { IMeta } from '../../services/api/types';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';

const headCells_1 = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Exigo ID', disableSort: true },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First name',
    disableSort: true,
  },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last name', disableSort: true },
  {
    id: 'skipDeadline',
    numeric: false,
    disablePadding: false,
    label: 'Deadline',
    disableSort: true,
  },
  {
    id: 'verificationStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    disableSort: true,
  },
];

const headCells_2 = headCells_1.filter((item) => item.id !== 'skipDeadline');

const KycList: FC = () => {
  const classes = useStandardTableStyles();
  const permissions = useSelector(selectUserPermissions);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [list, setList] = React.useState<IKycItem[] | []>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = React.useState<string>('name');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [level, setLevel] = React.useState<number>(1);
  const [verificationStatusFilter, selectVerificationStatusFilter] = React.useState<string>('all');
  const [isOpenPortal, setIsOpenPortal] = React.useState<boolean>(false);

  const closeAssignKycModal = () => setIsOpenPortal(false);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    if (permissions?.includes(Permissions.readKycVerificationListLevel1)) {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, level, searchTerm, verificationStatusFilter]);

  const fetchList = async () => {
    if (level === 1) {
      try {
        const response = await KYC.getList({
          page,
          limit: rowsPerPage,
          search: searchTerm,
          sortBy,
          verificationStatus: verificationStatusFilter,
          sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
        });

        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      } catch (e) {
        console.error('fetch KYC List error', e);
      } finally {
        setLoading(false);
      }
      return;
    }
    if (level === 2) {
      try {
        const response = await KYC.getListLevel2({
          page,
          limit: rowsPerPage,
          search: searchTerm,
          sortBy,
          verificationStatus: verificationStatusFilter,
          sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
        });

        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      } catch (e) {
        console.error('fetch KYC List error', e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    selectVerificationStatusFilter('all');
    setPage(1);
    setLevel(newValue);
    setLoading(true);
    setList([]);
  };

  const updateKycUserInfo = async (data: IKycPutItemParams) => {
    try {
      if (level === 1) {
        await KYC.updateListItem(data);
      }

      if (level === 2) {
        await KYC.updateListItemLevel2(data);
      }

      setNotification('success', {
        message: 'Success',
      });

      fetchList();
    } catch (e) {
      console.error('UPDATE KYC ITEM ERROR', e);
    }
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const renderSecondTab = () => (
    <>
      <Grid container spacing={2} className={classes.searchRow}>
        <Grid item xs={12} sm={6}>
          <InputSearch
            onSubmit={(val) => {
              setPage(1);
              setSearchTerm(val);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputSelect
            id="verification"
            value={verificationStatusFilter}
            onChange={(e): void => {
              setList([]);
              selectVerificationStatusFilter(e.target.value as string);
            }}
            options={[...STATUSES_LEVEL_2, { value: 'all', label: 'All' }]}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead
            headCells={headCells_2}
            order={sortDirection}
            orderBy={sortBy}
            onRequestSort={handleRequestSort}
            classes={classes}
          />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              (list as IKycItem[]).map((item) => (
                <EditableRow onEditRow={updateKycUserInfo} kycData={item} level={2} />
              ))}

            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no kyc users</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  // @ts-ignore
  const renderFirstTab = () => (
    <>
      <Grid container spacing={2} className={classes.searchRow}>
        <Grid item xs={12} sm={6}>
          <InputSearch
            onSubmit={(val) => {
              setPage(1);
              setSearchTerm(val);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <InputSelect
            id="verification"
            value={verificationStatusFilter}
            onChange={(e): void => {
              setList([]);
              selectVerificationStatusFilter(e.target.value as string);
            }}
            options={[...STATUSES_LEVEL_1, { value: 'all', label: 'All' }]}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead
            headCells={headCells_1}
            order={sortDirection}
            orderBy={sortBy}
            onRequestSort={handleRequestSort}
            classes={classes}
          />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              (list as IKycItem[]).map((item) => (
                <EditableRow onEditRow={updateKycUserInfo} kycData={item} level={1} />
              ))}

            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no kyc users</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>KYC</Title>
            <ClickAwayListener onClickAway={closeAssignKycModal}>
              <Box display={'flex'}>
                <Can perform={Permissions.startVerificationManually}>
                  <Button
                    onClick={() => setIsOpenPortal(true)}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon />}
                  >
                    Assign a KYC
                  </Button>
                </Can>
              </Box>
            </ClickAwayListener>
          </div>
        </Paper>
      </Grid>
      <Paper className={classes.paper}>
        <Tabs
          value={level}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabClick}
          aria-label="disabled tabs example"
        >
          <Tab label="Level 1" value={1} disabled={loading} />
          <Tab label="Level 2" value={2} disabled={loading} />
        </Tabs>

        {level === 1 && renderFirstTab()}
        {level === 2 && renderSecondTab()}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {isOpenPortal ? <AssignKycPortal close={closeAssignKycModal} /> : null}
      </Paper>
    </div>
  );
};

export default KycList;
