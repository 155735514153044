import DateFnsUtils from '@date-io/date-fns';
import { Box, CircularProgress, Portal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import triggerEventRequests, {
  ITriggerEvent,
  ITriggerEventItem,
} from '../../services/api/requests/triggerEvents';
import { Permissions } from '../Can';
import { useStandardTableStyles } from '../DefaultTable';
import PortalListItem from '../PortalListItem';
import { calculateDate } from './helpers';

interface IProps {
  list: ITriggerEvent[];
  activeTriggerEventId: number;
  permissions?: string[];
}

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
}));

const TriggerEventsPortal = ({ list, activeTriggerEventId, permissions }: IProps) => {
  const localClasses = useStyles();
  const dateFns = new DateFnsUtils();
  const [height, setHeight] = useState(0);
  const [triggerEventData, setTriggerEventData] = useState<null | ITriggerEventItem>(null);
  const event = list.find((v) => v.id === activeTriggerEventId);
  const [loading, setLoading] = React.useState<boolean>(true);
  const classes = useStandardTableStyles();
  const {
    updatedAt,
    createdAt,
    jobStart,
    time,
    attempts,
    delayConverted,
    delay,
    delayNow,
    delayNowMs,
    triggerTime,
    triggerTimeDate,
    progress,
  } = calculateDate(dateFns, triggerEventData);

  const fetchTriggerEventItem = async (id: string | number) => {
    if (permissions?.includes(Permissions.readProductList) && id) {
      try {
        const response = await triggerEventRequests.readTriggerEventItem(id);
        setLoading(false);
        setTriggerEventData(response.data.data);
      } catch (e) {
        console.warn(e.message);
      }
    }
  };

  useEffect(() => {
    setTriggerEventData(null);
    if (event?.id) {
      setLoading(true);
      fetchTriggerEventItem(event?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.id]);

  useEffect(() => {
    let top = window.innerHeight / 2 + window.pageYOffset;
    setHeight(top);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  const showJobTitle =
    !!jobStart ||
    !!delayConverted ||
    !!triggerTimeDate ||
    !!triggerEventData?.jobData?.id ||
    !!triggerEventData?.jobData?.attemptsMade ||
    !!triggerEventData?.jobData?.opts?.attempts ||
    !!progress;

  const showInfoTitle =
    !!triggerEventData?.messagePattern ||
    !!time ||
    !!triggerEventData?.status ||
    !!triggerEventData?.name ||
    !!createdAt ||
    !!updatedAt;

  return (
    <Portal>
      <ul
        id="portal"
        className={classes.dropdown}
        style={{ top: height, width: '400px', padding: 20 }}
      >
        {loading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {showJobTitle && (
              <Box
                display="flex"
                width="100%"
                ml={1}
                alignItems="center"
                justifyContent="center"
                color="#673ab7"
              >
                <Typography variant="h6">Job</Typography>
              </Box>
            )}

            <PortalListItem name="Job Start" item={jobStart} />
            <PortalListItem name="Job Delay from Start" item={delayConverted && delay} />
            <PortalListItem name="Job Trigger Time" item={triggerTime} />
            <PortalListItem
              name="Job Delay from Now"
              item={triggerTimeDate && delayNow}
              valueClassName={delayNowMs > 0 ? localClasses.error : ''}
            />
            <PortalListItem name="Job ID" item={triggerEventData?.jobData?.id} />
            <PortalListItem name="Attempts" item={attempts} />
            <PortalListItem name="Progress" item={progress} />
            {showInfoTitle && (
              <Box
                display="flex"
                width="100%"
                ml={1}
                alignItems="center"
                justifyContent="center"
                color="#673ab7"
              >
                <Typography variant="h6">Additional info</Typography>
              </Box>
            )}

            <PortalListItem name="Message Pattern" item={triggerEventData?.messagePattern} />
            <PortalListItem name="Time" item={time} />
            <PortalListItem name="Status" item={triggerEventData?.status} />
            <PortalListItem name="Name" item={triggerEventData?.name} />
            <PortalListItem name="Created At" item={createdAt} />
            <PortalListItem name="Updated At" item={updatedAt} />
          </>
        )}
      </ul>
    </Portal>
  );
};

export default TriggerEventsPortal;
