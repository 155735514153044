export const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Id',
    disableSort: true,
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date and time',
    disableSort: true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSort: true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    disableSort: true,
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'User name',
    disableSort: true,
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Total Amount',
    disableSort: true,
  },
  {
    id: 'items',
    numeric: false,
    disablePadding: false,
    label: 'Items',
    disableSort: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
  },
  { id: 'status', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: '', numeric: false, disablePadding: false, label: '', disableSort: true },
];

export const headCellsTransactions = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Id',
    disableSort: true,
  },
  {
    id: 'paymentMethod',
    numeric: false,
    disablePadding: false,
    label: 'Payment Method',
    disableSort: true,
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
    disableSort: true,
  },
  {
    id: 'fee',
    numeric: false,
    disablePadding: false,
    label: 'Fee',
    disableSort: true,
  },
  {
    id: 'vat',
    numeric: false,
    disablePadding: false,
    label: 'Vat',
    disableSort: true,
  },
  {
    id: 'currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency',
    disableSort: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
  },

  { id: '', numeric: false, disablePadding: false, label: '', disableSort: true },
];
