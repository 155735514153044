import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

const request = (id: string) =>
  apiClient
    .delete<IResponseAxios>(ApiUrls.deleteProductCategories + id)
    .then((response) => response);

export default request;
