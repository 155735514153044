import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import useCustomTableStyles from '../../pages/CustomersLogs/styles';
import getCustomersLogs, { ILog } from '../../services/api/requests/getCustomersLogs';
import CustomersLogsPortal from '../CustomersLogsPortal';
import { useStandardTableStyles } from '../DefaultTable';
import TableSkeleton from '../TableSkeleton';
interface IParams {
  action: string;
  data: any;
  from: string;
  to: string;
  userId: string;
}

export class LogItemMapper {
  public static toDomain(item: any): ILog {
    return {
      id: item.id,
      action: item.action,
      tag: item.tag,
      level: item.level,
      ip: item.ip,
      url: item.url,
      os: item.os,
      browser: item.browser,
      device: item.device,
      data: {
        ...JSON.parse(item.data),
        customerId: Number(JSON.parse(item.data).customerId),
      },
      shopType: item.shop_type,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      result: item.result,
      userId: item.user_id,
    };
  }
}

const LogList = ({ action, data, from, to, userId }: IParams) => {
  const dateFns = new DateFnsUtils();
  const classes = useStandardTableStyles();
  const styles = useCustomTableStyles();
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(25);
  const [activeLogId, setActiveLogId] = React.useState<number>(0);
  const [isOpenPortal, setIsOpenPortal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [logsList, setList] = React.useState<ILog[]>([]);
  const fetchCustomersLogs = async () => {
    try {
      setLoading(true);
      const response = await getCustomersLogs({
        page,
        limit,
        action,
        data,
        from,
        to,
        userId,
      });
      setLoading(false);
      setList(response.data.data.rows.map((item) => LogItemMapper.toDomain(item)));
      // setMeta(response.data.meta);
    } catch (e) {
      console.warn(e.message);
    }
  };
  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchCustomersLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, action, data, from, to, userId]);
  const handleChangePage = (e: unknown, newPage: number) => setPage(newPage + 1);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };
  return (
    <div className={classes.root}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={'medium'}
        aria-label="enhanced table"
      >
        <TableBody>
          {loading && <TableSkeleton />}
          {!loading && !logsList?.length ? (
            <TableRow>
              <TableCell align="center">There are no customers logs</TableCell>
            </TableRow>
          ) : (
            logsList.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell align="left" className={styles.smallCell}>
                    {item.data.customerId || '-----'}
                  </TableCell>
                  <TableCell align="left" className={styles.cell}>
                    {item.data.username || '----------'}
                  </TableCell>
                  <TableCell align="left" className={styles.cell}>
                    {item.action}
                  </TableCell>
                  <TableCell align="left" className={styles.cell}>
                    {item.ip}
                  </TableCell>
                  <TableCell align="left" className={styles.cell}>
                    {dateFns.format(new Date(item.createdAt), 'dd/MM/yyyy hh:mm')}
                  </TableCell>
                  <TableCell align="left" className={styles.cell}>
                    <ClickAwayListener onClickAway={() => setIsOpenPortal(false)}>
                      <div>
                        <Button
                          variant="contained"
                          disabled={!item.data.customerId}
                          onClick={() => {
                            setActiveLogId(item.id);
                            setTimeout(() => setIsOpenPortal(true), 100);
                          }}
                        >
                          View Details
                        </Button>
                      </div>
                    </ClickAwayListener>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
        {isOpenPortal ? (
          <CustomersLogsPortal logsList={logsList} activeLogId={activeLogId} />
        ) : null}
      </Table>
      {logsList?.length ? (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={1000}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
};

export default LogList;
