import { Box, Button, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { FC, useEffect, useState } from 'react';
import upsellingRuleRequests, { IUpsellingRule } from '../../services/api/requests/upsellingRules';
import setNotification from '../../utils/notifications';
// import Can, { Permissions } from '../Can';
import CreateEditUpsellingRule from '../CreateEditUpsellingRule';
import { useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import Title from '../Title';

const UpsellingRulesList: FC<{ planId: number }> = ({ planId }) => {
  const classes = useStandardTableStyles();
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState<IUpsellingRule>();
  const [showCreateRulePanel, togglePanel] = useState<boolean>(false);
  const [list, setList] = useState<IUpsellingRule[]>([]);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchUpsellingRulesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!selectedRule) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedRule]);

  const fetchUpsellingRulesList = async () => {
    try {
      const response = await upsellingRuleRequests.readUpsellingRules({
        planId,
        page: 1,
        limit: 100,
      });

      setList(response.data.data.items.sort((a, b) => a.priority - b.priority));
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRule = async (id: number) => {
    try {
      await upsellingRuleRequests.deleteUpsellingRule(id);
      setNotification('success', {
        message: 'Success',
      });
      fetchUpsellingRulesList();
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const onSubmit = () => {
    closeEditRule();
    fetchUpsellingRulesList();
  };

  const openEditRule = (rule: IUpsellingRule) => {
    setSelectedRule(rule);
    togglePanel(true);
  };
  const openCreateRule = () => {
    togglePanel(true);
  };

  const closeEditRule = () => {
    togglePanel(false);
    setSelectedRule(undefined);
  };

  return (
    <>
      <Grid container xs={12}>
        <Box margin={'20px 0 0 0'} width="100%">
          <Title>Upselling Rules</Title>
        </Box>
      </Grid>
      <Grid container xs={12}>
        <Box display={'flex'} width={'100%'} flexWrap={'wrap'} justifyContent="space-between">
          {list?.map((rule) => {
            return (
              <Button className={classes.boxButton} fullWidth color="primary" variant={'outlined'}>
                <Box
                  display={'flex'}
                  justifyContent="space-between"
                  width={'100%'}
                  alignItems={'center'}
                >
                  {rule.addon.name} (Exigo ID: {rule.addon.exigoId})
                  <div>
                    {/*<Can perform={Permissions.updatePlanUpgradeRules}>*/}
                    <IconButton
                      aria-label="edit"
                      title={'Edit'}
                      size={'small'}
                      onClick={() => openEditRule(rule)}
                    >
                      <EditIcon />
                    </IconButton>
                    {/*</Can>*/}

                    {/*<Can perform={Permissions.deletePlanUpgradeRules}>*/}
                    <DeleteModalButton
                      name={rule.addon.name}
                      entity={'Rule'}
                      onDelete={() => handleDeleteRule(rule.id)}
                    />
                    {/*</Can>*/}
                  </div>
                </Box>
              </Button>
            );
          })}
          <div ref={scrollRef} />
          {showCreateRulePanel ? (
            <CreateEditUpsellingRule
              onCancelClick={closeEditRule}
              ruleForEditing={selectedRule}
              existedList={list}
              onSuccess={onSubmit}
            />
          ) : (
            // TODO: add <Can/> component with Permissions for upselling rules
            <Box
              width={'100%'}
              justifyContent="space-between"
              alignItems={'center'}
              padding={'20px 0 0 0'}
              onClick={openCreateRule}
            >
              <Button fullWidth variant="outlined" color="primary">
                + Add Rule
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default UpsellingRulesList;
