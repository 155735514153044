import { RECURRING_TYPES } from '@root/enums/recurring-type';

export const getNameWithRecurringType = (name: string, recurringType: string) => {
  try {
    if (recurringType === RECURRING_TYPES.MONTHLY) {
      return name;
    } else if (recurringType === RECURRING_TYPES.ANNUAL) {
      if (name.includes(RECURRING_TYPES.ANNUAL)) {
        return name;
      }
      return `${name} (${RECURRING_TYPES.ANNUAL})`;
    }

    return `${name} (${recurringType.split('x')[0]} Months)`;
  } catch (e) {
    console.warn('getNameWithRecurringType', e.message);
    return name;
  }
};
