import Switch from '@material-ui/core/Switch';
import { EntityType, KeysOfEntity } from '@root/pages/CreateCountriesGroup/types';
import { RelatedEntityType } from '@root/services/api/requests/relatedEntity';
import { ChangeEvent } from 'react';

interface IProps {
  entityTitle: KeysOfEntity;
  entity: RelatedEntityType;
  checkedEntityOptions: EntityType<number>;
  handleChangeEntityStatus: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const EntitySwitch = ({
  entityTitle,
  entity,
  checkedEntityOptions,
  handleChangeEntityStatus,
}: IProps) => (
  <Switch
    color="primary"
    inputProps={{ title: `${entityTitle}` }}
    value={entity.id}
    checked={!!checkedEntityOptions[entityTitle]?.includes(entity.id)}
    onChange={handleChangeEntityStatus}
  />
);
