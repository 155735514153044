import {
  Button,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useCustomTableStyles from '../../pages/CustomersLogs/styles';
import { IMeta } from '../../services/api/requests/getAdminList';
import triggerEventRequests, { ITriggerEvent } from '../../services/api/requests/triggerEvents';
import { IPaginationParams } from '../../services/api/types';
import { selectUserPermissions } from '../../store/system/selectors';
import { Permissions } from '../Can';
import { useStandardTableStyles } from '../DefaultTable';
import TableSkeleton from '../TableSkeleton';
import TriggerEventsPortal from '../TriggerEventsPortal';
import TriggerEventStatus from '../TriggerEventStatus';

const TriggerEventsTable = () => {
  const classes = useStandardTableStyles();
  const styles = useCustomTableStyles();
  const permissions = useSelector(selectUserPermissions);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(25);
  const [activeLogId, setActiveLogId] = React.useState<number>(0);
  const [isOpenPortal, setIsOpenPortal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [eventList, setList] = React.useState<ITriggerEvent[]>([]);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const params = { page, limit };

  const fetchTriggerEventList = async () => {
    if (permissions?.includes(Permissions.readProductList)) {
      try {
        const response = await triggerEventRequests.readTriggerEventList(
          params as IPaginationParams
        );
        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      } catch (e) {
        console.warn(e.message);
      }
    }
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchTriggerEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, page]);

  const handleChangePage = (e: unknown, newPage: number) => setPage(newPage + 1);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };
  return (
    <div className={classes.root}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={'medium'}
        aria-label="enhanced table"
      >
        <TableBody>
          {loading && <TableSkeleton />}
          {!loading && !eventList?.length ? (
            <TableRow>
              <TableCell align="center">There are no events</TableCell>
            </TableRow>
          ) : (
            !loading &&
            eventList.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell align="left" className={styles.smallCell}>
                    {item.id || ''}
                  </TableCell>
                  <TableCell align="left" className={styles.hugeCell}>
                    {item.name || ''}
                  </TableCell>
                  <TableCell align="left" className={styles.cell}>
                    <TriggerEventStatus status={item?.status} />
                  </TableCell>
                  <TableCell align="right" className={styles.cell}>
                    <ClickAwayListener onClickAway={() => setIsOpenPortal(false)}>
                      <div>
                        <Button
                          variant="contained"
                          disabled={!item.id}
                          onClick={() => {
                            setActiveLogId(+item.id);
                            setTimeout(() => setIsOpenPortal(true), 100);
                          }}
                        >
                          View Details
                        </Button>
                      </div>
                    </ClickAwayListener>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
        {isOpenPortal ? (
          <TriggerEventsPortal
            list={eventList}
            activeTriggerEventId={activeLogId}
            permissions={permissions}
          />
        ) : null}
      </Table>

      {eventList?.length ? (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={meta?.total}
          rowsPerPage={meta?.limit}
          page={meta?.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
};

export default TriggerEventsTable;
