import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import CurrencyConverter from '../../services/api/requests/currencyConverter';
import { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { initialValues } from './static-data';
import { IValues } from './types';
import { validationSchema } from './validation';

export const useModuleFormik = (editItem: IValues | null) => {
  const dispatch = useDispatch();
  const isEdit = !!editItem;

  const fk: FormikProps<IValues> = useFormik({
    initialValues: editItem ? editItem : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        ...values,
        feeAmount: Number(values.feeAmount),
        round: Number(values.round) || values.round,
      } as IValues;

      try {
        if (isEdit && editItem?.id) {
          await CurrencyConverter.put(data);
        } else {
          await CurrencyConverter.post(data);
        }

        setSuccessNotification();
        dispatch(push(pageLinks.currencyConverter));
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  return fk;
};
