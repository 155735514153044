import { SAGE_PROVIDER_ROLES } from '@root/components/CreateEditService/types';
import { SAGE_ROLES } from '@root/pages/CreateEditPlan/types';
import { RECURRING_TYPES } from '../../../enums/recurring-type';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IAttachment } from './editProductPlan';
import { IPlan, PLAN_RECURRING_CHARGE_TYPE } from './getProductPlans';

export interface IDataSet {
  countryCodes: string[];
  setupPrice?: number;
  CV?: number;
  QV?: number;
  SCV?: number;
  SQV?: number;
  FSB?: number;

  recurringPrice?: number;
  recurringCV?: number;
  recurringQV?: number;
  recurringSCV?: number;
  recurringSQV?: number;
  recurringFSB?: number;

  retailPrice?: number;
  retailCV?: number;
  retailQV?: number;
  retailSCV?: number;
  retailSQV?: number;
  retailFSB?: number;

  recurringRetailPrice?: number;
  retailRecurringCV?: number;
  retailRecurringQV?: number;
  retailRecurringSCV?: number;
  retailRecurringSQV?: number;
  retailRecurringFSB?: number;
}

export interface ICreatePlan {
  icon: string;
  name: string;
  renewalOptions: number[];
  titleDescription: string;
  saleShopType: string;
  recurringSKU: string;
  sku: string;
  additionalInfo: string;
  color: string;
  bgColor: string;
  bullets: string[];
  comboData?: null;
  countryCodes: string[]; //
  currency: 'USD' | 'EUR';
  description: string;
  discountExigoItem: string;
  fullDescription: string;
  file: any;
  intervalSize: number;
  intervalUnit: 'day';
  priority: number; // приоритет важности плана
  fsbEnabled: boolean;
  autopayable: boolean;
  membershipDiscountAllowed: boolean;
  rewardingDays: number;
  rebillyPlanId: string;
  recurringPrice: number;
  oneDayRecurringSKU: string;
  recurringRetailPrice: number;
  retailPrice: number;
  setupPrice: number;
  isRecurring: boolean;
  recurringChargeType: PLAN_RECURRING_CHARGE_TYPE;
  noExigo: boolean;
  hasRetail: boolean;
  serviceIDs: string[];
  cv: number;
  fsb: number;
  qv: number;
  scv: number;
  sqv: number;
  rcv: number;
  rqv: number;
  rscv: number;
  matrixBV: number;
  rmatrixBV: number;
  rsqv: number;
  rfsb: number;
  splitValues: number;
  splitFSB: number;
  splitSKU: string;
  splitBaseValue: number;
  regionalDatasets: IDataSet[] | []; // отличия по регионам
  retailExigoItem: string | null;
  retailRecurringCV: number;
  retailRecurringQV: number;
  retailRecurringSCV: number;
  retailRecurringSQV: number;
  retailRecurringFSB: number;
  imageUrl?: string | null;
  attachmentIds?: [string] | [number] | null;
  attachments: IAttachment[] | [];
  gatewayMethodIds: number[];
  status: string;
  primaryServiceId: string | number;
  requireVerification: boolean;
  tLevelJoin: boolean;
  isSage: boolean;
  enrollerCashback: number;
  customerCashback: number;
  isCashbackActive?: boolean;
  recurringType: RECURRING_TYPES;
  uiPriority: number;
  isRegional: boolean;
  resignDate: string | null;
  addSageRoles?: SAGE_ROLES[];
  splitBaseRenewValue: number;
  stripeCustomPlanId: string | null;
  exigoField1: string | null;
  trialPeriod: number | null;
  uiOriginalPrice: number | null;
  splitPeriod: number;
  ghlTag: string;
}

const request = (data: ICreatePlan, productId: string) => {
  return apiClient
    .post<IResponseAxios<IPlan[]>>(ApiUrls.baseProduct + productId + '/plan', data)
    .then((response) => response);
};

export default request;
