import { SUBSCRIPTION_STATUS } from '../../../utils/commonTypes';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IRequiredPlanRequestBody {
  planId: number;
  shouldHaveStatus: SUBSCRIPTION_STATUS | null;
  serviceId?: number;
}

export interface IParams {
  id: number;
  data: IRequiredPlanRequestBody;
}

const request = ({ id, data }: IParams) => {
  const url = ApiUrls.addRequiredPlanToCouponUsageRule.replace('{id}', `${id}`);
  return apiClient.post<IResponseAxios>(url, data).then((response) => response);
};

export default request;
