import dateFnsUtils from '@date-io/date-fns';
import { IDateState } from '@root/components/OrdersSearch/types';
import { StatusFilter } from '@root/pages/Orders/types';
import { selectPlansAsObjects } from '@root/store/entities/selectors';
import { toIsoString } from '@root/utils/helpers';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import installmentRequests, {
  IInstallmentOrder,
} from '../../services/api/requests/installmentsOrders';
import { IMeta } from '../../services/api/types';
import setNotification from '../../utils/notifications';

export const useInstallmentsOrdersTable = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [list, setList] = useState<IInstallmentOrder[]>([]);
  const planData = useSelector(selectPlansAsObjects);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const [dateState, setDateState] = useState<IDateState>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });
  const [filterStatus, setFilterStatus] = useState<string>('all');
  const [userName, setUserName] = useState<string>('');

  const dateFns = new dateFnsUtils();

  const statusOptions = Object.values(StatusFilter).reduce<{ value: string; label: string }[]>(
    (options, status) => {
      options.push({ value: status, label: status });
      return options;
    },
    []
  );

  const dateRangeValue =
    dateState.startDate &&
    dateFns.isValid(dateState.startDate) &&
    dateState.endDate &&
    dateFns.isValid(dateState.endDate)
      ? `${dateFns.format(new Date(dateState.startDate), 'MMM d, yyyy')} - ${dateFns.format(
          new Date(dateState.endDate),
          'MMM d, yyyy'
        )}`
      : '';

  const fetchList = async () => {
    try {
      setLoading(true);
      const response = await installmentRequests.getList({
        params: {
          page,
          limit: rowsPerPage,
          ...(filterStatus !== 'all' && { status: filterStatus }),
          ...(dateState.startDate && { fromDate: toIsoString(dateState.startDate) }),
          ...(dateState.endDate && { toDate: toIsoString(dateState.endDate) }),
          ...(userName !== '' && { username: userName }),
        },
        planData,
      });

      setLoading(false);
      setList(response.items);

      setMeta(response.meta);
    } catch (e) {
      console.warn((e as AxiosError).message);
    }
  }
  
  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleStatusChange = async (statuses: Record<string, string>) => {
    Promise.all(
      Object.entries(statuses)
        .filter(([, status]) => ['approve', 'deny'].includes(status))
        .map(async ([id, status]) => {
          await installmentRequests.changeOrderStatus(Number(id), status as 'approve' | 'deny');
          setNotification('success', {
            message: 'Success',
          });
        })
    )
      .then(() => fetchList())
      .catch((e) => console.log(e));
  };

  const handleToggleFilterPanel = () => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  };

  const handleRangeClear = (e: React.SyntheticEvent<{}>) => {
    e.stopPropagation();
    setDateState({ startDate: undefined, endDate: undefined, key: 'selection' });
  };

  const handleFilterStatusChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    setFilterStatus(e.target.value as string);
  };

  const handleChangeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  }

  const clearUserName = () => {
    setUserName('');
  }

  const applyFilters = useCallback(() => {
    if (page !== 1) {
      setPage(1);
      return;
    }
    fetchList();
  }, [page, page, rowsPerPage, filterStatus, dateState.startDate, dateState.endDate, userName]);

  useEffect(() => {
    if (!dateState.endDate && !dateState.startDate) {
      fetchList();
    }
  }, [dateState.endDate, dateState.startDate]);

  useEffect(() => {
    fetchList().then(console.log);
  }, [page, setLoading]);

  useEffect(() => {
    if (userName === '') {
      fetchList();
    }
  }, [userName])

  const state = {
    list,
    meta,
    page,
    rowsPerPage,
    loading,
    filterState: {
      isFilterPanelOpen,
      statusOptions,
      filterStatus,
      dateState,
      dateRangeValue,
      userName,
    },
  };

  const handlers = {
    handleStatusChange,
    handleChangePage,
    handleChangeRowsPerPage,
    handleListFetch: fetchList,
    filterHandlers: {
      handleToggleFilterPanel,
      handleFilterStatusChange,
      setDateState,
      handleRangeClear,
      applyFilters,
      handleChangeUserName,
      clearUserName,
    },
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
