import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import CurrencyConverter, { ICurrencyData } from '../../services/api/requests/currencyConverter';
import { editAdminUser, editCurrencyConverterItem } from '../../store/entities/actions';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'from', numeric: false, disablePadding: false, label: 'From currency' },
  { id: 'to', numeric: false, disablePadding: false, label: 'To currency' },
  { id: 'fee_type', numeric: false, disablePadding: false, label: 'Fee type' },
  { id: 'fee_value', numeric: false, disablePadding: false, label: 'Fee value' },
  { id: 'round', numeric: false, disablePadding: false, label: 'Rounding' },
  { id: 'rate', numeric: false, disablePadding: false, label: 'Rate' },
  { id: 'rate_with_fee', numeric: false, disablePadding: false, label: 'Rate with fee' },
  { id: 'edit', numeric: false, disablePadding: false, label: 'Edit' },
  { id: 'delete', numeric: false, disablePadding: false, label: 'Delete' },
];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const permissions = useSelector(selectUserPermissions);
  const [list, setList] = React.useState<ICurrencyData[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await CurrencyConverter.get();
      setList(response.data.data);
    } catch (e) {
      console.warn('Failed to load currency converter', e);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setList]);

  const createNewItem = () => {
    dispatch(push(pageLinks.createEditCurrencyConverter));
    dispatch(editAdminUser(null));
  };

  const handleDelete = async (id: number) => {
    try {
      await CurrencyConverter.delete(id);
      fetchList().catch(console.log);
      setNotification('success');
    } catch (e) {
      console.warn('DELETE_ERROR', e);
    }
  };

  const handleEdit = (data: ICurrencyData) => {
    dispatch(editCurrencyConverterItem(data));
    dispatch(push(pageLinks.createEditCurrencyConverter));
  };

  useEffect(() => {
    if (permissions?.includes(Permissions.readCurrencyConverterList)) {
      fetchList().catch(console.log);
    }
  }, [fetchList, permissions]);

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Currency Convertor Settings</Title>
            <Can perform={Permissions.createCurrencyConverter}>
              <Button
                onClick={createNewItem}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add Converter
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={'asc'}
              orderBy={'sortBy'}
              headCells={headCells}
              onRequestSort={() => {}}
            />
            <TableBody>
              {loading && <TableSkeleton />}
              {!loading &&
                list?.map((item: ICurrencyData) => {
                  const {
                    from,
                    to,
                    feeAmount,
                    feeCurrency,
                    id,
                    round,
                    rateWithoutFee,
                    rateWithFee,
                  } = item;

                  const fromTitle = `${from.name} ${from.code}`;
                  const toTitle = `${to.name} ${to.code}`;

                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{id}</TableCell>
                      <TableCell align="left">{fromTitle}</TableCell>
                      <TableCell align="left">{toTitle}</TableCell>
                      <TableCell align="left">{feeCurrency}</TableCell>
                      <TableCell align="left">{feeAmount}</TableCell>
                      <TableCell align="left">{round}</TableCell>

                      <TableCell align="left">{rateWithoutFee || '-'}</TableCell>
                      <TableCell align="left">{rateWithFee || '-'}</TableCell>

                      <TableCell align="center">
                        <Can perform={Permissions.updateCurrencyConverter}>
                          <IconButton
                            aria-label="edit"
                            title={'Edit'}
                            onClick={() => handleEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Can>
                      </TableCell>
                      <TableCell align="center">
                        <Can perform={Permissions.deleteCurrencyConverter}>
                          <DeleteModalButton
                            name={fromTitle + ' to ' + toTitle}
                            entity={'Currency Converter item'}
                            onDelete={() => handleDelete(item?.id)}
                          />
                        </Can>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>There are no items </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default EnhancedTable;
