import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { FC, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      maxWidth: 700,
      height: 38,
    },

    largeSearchRoot: {
      maxWidth: '100%',
      height: 50,
      marginBottom: 20 
    },

    submit: {
      marginLeft: 10,
      border: '1px solid',
      padding: '0 15px',
    },

    search: {
      display: 'flex',
      position: 'relative',
      border: '1px solid',
      borderColor: '#000',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },

    largeSearch: {
      width: '40%',
    },

    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },

    largeInputRoot: {
      width: '100%',
    },

    largeInputInput: {
      width: '100%',
    },

    inputInput: {
      // padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        // '&:focus': {
        //   width: '20ch',
        // },
      },
    },
  })
);

interface IProps {
  onSubmit(s: string): void;
  placeholder?: string;
  isLarge?: boolean;
  clearCallback?: () => void;
}

const SearchBar: FC<IProps> = ({ onSubmit, placeholder, isLarge = false, clearCallback }) => {
  const classes = useStyles();

  const [value, setValue] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    clearCallback && clearCallback();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(value);
  };

  const handleClear = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setValue('');
    onSubmit('');
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setValue('');
      onSubmit('');
    }
  };

  return (
    <form
      className={isLarge ? `${classes.root} ${classes.largeSearchRoot}` : classes.root}
      onSubmit={handleSubmit}
    >
      <div className={isLarge ? `${classes.search} ${classes.largeSearch}` : classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder={placeholder || 'Search…'}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          classes={{
            input: classes.inputInput,
            root: isLarge ? `${classes.inputRoot} ${classes.largeInputRoot}` : classes.inputRoot,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
      <Button size="small" className={classes.submit} disabled={!value.length} type={'submit'}>
        Search
      </Button>
      <Button
        size="small"
        className={classes.submit}
        disabled={!value.length}
        onClick={handleClear}
      >
        Clear
      </Button>
    </form>
  );
};

export default SearchBar;
