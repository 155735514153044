import ApiUrls from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import { IMeta } from '@root/services/api/types';
import { createQueryParams } from '@root/utils/helpers';
import { AxiosResponse } from 'axios';

export interface IProductListItem {
  createdAt: string | Date;
  description: string | null;
  id: string;
  imageUrl: string;
  fullImageUrl: string;
  file?: any;
  name: string;
  rebillyProductId: string;
  updatedAt: string | Date;
  priority: number;
  color: string;
  bgColor: string;
  urlParam: string;
  status: string;
}

export interface IParams {
  page: number;
  limit: number;
  sortDirection?: 'ASC' | 'DESC';
  searchTerm?: string;
  sortBy?: string;
}

export interface IAdminProductResponse {
  data: {
    items: IProductListItem[];
    meta: IMeta;
  };
}

const request = (params: IParams): Promise<AxiosResponse<IAdminProductResponse>> => {
  const query = createQueryParams<IParams>(params);
  return apiClient.get<IAdminProductResponse>(ApiUrls.productList + `?${query}`);
};

export default request;
