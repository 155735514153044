import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IInstanceWebsite {
  id: number;
  name: string;
  url: number;
}

export interface IInstanceWebsitesResponse {
  data: IInstanceWebsite[];
}

const request = () =>
  apiClient.get<IInstanceWebsitesResponse>(ApiUrls.instanceWebsites).then((response) => response);

export default request;
