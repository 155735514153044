import { ICountryGroup } from '@root/services/api/requests/getCountries';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';
import { ICountry, IGetInstance } from '../services/api/requests/getInstances';
import {
  selectCountryGroups,
  selectCountryList,
  selectInstances,
} from '../store/entities/selectors';

type ToCountryCodesProps = {
  selectedValues: Array<string | number>;
  instances?: IGetInstance[];
  groups?: ICountryGroup[];
};

// helpers to create list for request
export function createCountryCodeList({ selectedValues, instances, groups }: ToCountryCodesProps) {
  let countries: string[] = [];

  if (instances && selectedValues.includes('all')) {
    instances?.forEach((instance) => {
      if (instance.countries.length) {
        instance.countries.forEach((country: ICountry) => countries.push(country.code));
      }
    });
    return { countries: uniq(countries) };
  }

  selectedValues.forEach((value) => {
    const instance = instances?.find((inst) => inst.code === value);
    const group = groups?.find((gr) => gr.id.toString() === value.toString());
    if (instance) {
      countries.push(...instance.countries.map((country) => country.code));
    } else if (group) {
      countries.push(...group.countries.map((country) => country.code));
    } else {
      countries.push(value as string);
    }
  });

  const uniqueCountries = uniq(countries);

  return { countries: uniqueCountries };
}

// helpers to create list countries with instances from server country codes list
export default function useCountryOption(
  countriesValue: string[] | string,
  withInstances: boolean,
  availableOptions: any,
  withGroups: boolean,
  showGroupsAndCountriesTogether?: boolean
) {
  const instances = useSelector(selectInstances);
  const allCountries = useSelector(selectCountryList(withInstances, withGroups));

  const countryGroups = useSelector(selectCountryGroups);

  const countryOptions = (Object.values(allCountries) as ICountry[]).map((country) => ({
    label: country.name,
    value: country.code,
  }));

  if (!Array.isArray(countriesValue)) {
    return {
      selected: countryOptions.find((i) => i.value === countriesValue) || null,
      options: availableOptions
        ? countryOptions.filter((item) => availableOptions.includes(item.value))
        : countryOptions,
    };
  }

  let notFound: string[] = [...uniq(countriesValue)];
  let selected: any[] = [];

  if (withInstances) {
    instances.forEach((instance) => {
      if (instance.countries.length > 0 && notFound.length >= instance.countries.length) {
        const foundInInstance: string[] = [];
        for (let i = 0; i <= instance.countries.length; i++) {
          const code = instance.countries[i]?.code;
          if (notFound.includes(code)) {
            foundInInstance.push(code);
          }
          if (i === instance.countries.length) {
            // if found
            if (foundInInstance.length === instance.countries.length) {
              selected.push({ label: instance.name, value: instance.code });
              notFound = notFound.filter((item) => !foundInInstance.includes(item));
            }
          }
        }
      }
    });
  }

  let countriesWhichAreInGroups: any = [];

  //! Create group from available country list  
  if (withGroups && countryGroups?.length) {
    countryGroups.forEach((group) => {
      if (group.countries.length > 0) {
        const foundInGroup: string[] = [];
        for (let i = 0; i <= group.countries.length; i++) {
          const code = group.countries[i]?.code;
          if (notFound.includes(code) || countriesWhichAreInGroups.includes(code)) {
            foundInGroup.push(code);
          }
          if (i === group.countries.length) {
            // if found
            if (foundInGroup.length === group.countries.length) {
              selected.push({ label: group.name, value: group.id });
              countriesWhichAreInGroups = [...countriesWhichAreInGroups, ...foundInGroup];
              notFound = notFound.filter((item) => !foundInGroup.includes(item));
            }
          }
        }
      }
    });
  }

  notFound.forEach((code) => {
    const data = allCountries && allCountries[code];
    if (data) {
      selected.push({ value: data.code, label: data.name });
    }
  });

  let options = countryOptions.filter((item) => !notFound.includes(item.value));

  if (showGroupsAndCountriesTogether && availableOptions) {
    const presentedGroups: number[] = [];

    for (const index in countryGroups) {
      const group = countryGroups[Number(index) as number];
      const allCountriesIncludesInGroup =
        group.countries.length &&
        group.countries.every((item) => availableOptions.includes(item.code));

      if (allCountriesIncludesInGroup) {
        presentedGroups.push(group.id);
      }
    }

    options = countryOptions.filter((item) =>
      [...presentedGroups, ...availableOptions].includes(item.value)
    );
  }

  if (availableOptions) {
    if (withGroups) {
      options = options.filter(
        (item) => availableOptions.includes(item.value) || typeof item.value === 'number'
      );
    } else {
      options = options.filter((item) => availableOptions.includes(item.value));
    }
  }

  return { selected, options };
}
