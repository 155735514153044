import { Button, FormControlLabel, Grid, Paper, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormikProps, useFormik } from 'formik';
import React, { useEffect } from 'react';
import createKycSettingsLevel, {
  EKycLevels,
  ICreateKycSettingsRequest,
} from '../../services/api/requests/createKycSettingsLevel';
import editKycSettingsLevel from '../../services/api/requests/editKycSettingsLevel';
import getKycSettingsList, {
  IKycSettingsData,
} from '../../services/api/requests/getKycSettingsList';
import setNotification from '../../utils/notifications';
import { useStandardTableStyles } from '../DefaultTable';
import InputSelect from '../InputSelect';
import Title from '../Title';

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(2),
  },
}));

enum EKycLevelsText {
  level1 = 'Level 1',
  level2 = 'Level 2',
}

const kycGlobalSettingsOptions = [
  {
    value: EKycLevels.LVL1,
    label: EKycLevelsText.level1,
  },
  {
    value: EKycLevels.LVL2,
    label: EKycLevelsText.level2,
  },
];

const attemptsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => ({
  value: v,
  label: `${v}`,
}));

interface IValues extends Omit<ICreateKycSettingsRequest, 'enabled'> {
  enabled: boolean;
}

const initialValues = {
  level: EKycLevels.LVL1,
  attempts: 1,
  enabled: false,
};

const KycLevelSettings = () => {
  const localClasses = useStyles();
  const classes = useStandardTableStyles();
  const [kycSetings, setKycSettings] = React.useState<IKycSettingsData[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchKycSettingsList = () => {
    getKycSettingsList().then((response) => {
      if (response) {
        setLoading(false);
        setKycSettings(response.data.data);
      }
    });
  };

  const edit = (values: IValues) => {
    editKycSettingsLevel({ ...values }, fkKycSettings.values.level).then((editRes) => {
      if (editRes) {
        setNotification('success', {
          message: 'Successfully changed',
        });
      }
    });
  };

  const fkKycSettings: FormikProps<IValues> = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (values) => {
      const createData = {
        level: values.level,
        attempts: values.attempts,
        ...(!!values?.enabled ? { enabled: values.enabled } : {}),
      };

      const isLevelExist = kycSetings?.some((settings) => settings.level === values.level);

      isLevelExist
        ? edit(values)
        : createKycSettingsLevel(createData)
            .then((res) => {
              //@ts-ignore
              if (res?.data?.isExist) {
                edit(values);
                fetchKycSettingsList();
                return;
              }

              if (res) {
                fetchKycSettingsList();
                setNotification('success', {
                  message: 'Successfully created',
                });
              }
            })
            .catch((e) => console.log('debug-e createKycSettingsLevel', e));
    },
  });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    fetchKycSettingsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedLevel = kycSetings?.find((kyc) => kyc.level === fkKycSettings.values.level);
    fkKycSettings.setFieldValue('attempts', selectedLevel?.attempts || initialValues.attempts);
    fkKycSettings.setFieldValue('enabled', selectedLevel?.enabled || initialValues.enabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycSetings, fkKycSettings.values.level]);

  const renderKycSettings = () => (
    <>
      <FormControlLabel
        labelPlacement="start"
        label={<Typography className={localClasses.label}>KYC level:</Typography>}
        control={
          <InputSelect
            id="level"
            options={kycGlobalSettingsOptions}
            value={fkKycSettings.values.level}
            onChange={(e): void => {
              fkKycSettings.setFieldValue('level', e.target.value);
            }}
          />
        }
      />
      <FormControlLabel
        labelPlacement="start"
        label={<Typography className={localClasses.label}>Attempts:</Typography>}
        control={
          <InputSelect
            id="attempts"
            options={attemptsOptions}
            value={fkKycSettings.values.attempts}
            onChange={(e): void => {
              fkKycSettings.setFieldValue('attempts', e.target.value);
            }}
          />
        }
      />
      <Grid item xs={2}>
        <FormControlLabel
          labelPlacement="start"
          label="Enabled: "
          control={
            <Switch
              id={'enabled'}
              checked={fkKycSettings.values.enabled}
              onChange={fkKycSettings.handleChange}
              name="enabled"
              color="primary"
            />
          }
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => fkKycSettings.handleSubmit()}
        >
          Save
        </Button>
      </Grid>
    </>
  );

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <Title>KYC Level Settings: </Title>
          {renderKycSettings()}
        </div>
      </Paper>
    </Grid>
  );
};

export default KycLevelSettings;
