import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta } from '../types';
import { IGetOrdersParams } from './orders';

export interface IKycItem {
  id: 0;
  data: {
    firstName: string;
    lastName: string;
  };
  userId: string;
  skipDeadline: Date;
  verificationStatus: string;
}

export interface IKycList {
  data: {
    items: IKycItem[];
    meta: IMeta;
  };
}

export interface IKycPutItemParams {
  userId: string;
  skipDeadline: Date;
  verificationStatus: string;
}

export interface IKycAssignManually {
  userId: string;
  levels: string[];
}

const getList = (params: any) => {
  const query = createQueryParams<IGetOrdersParams>(params);
  return apiClient.get<IKycList>(ApiUrls.listLevel1 + `?${query}`).then((response) => response);
};

const updateListItem = (data: IKycPutItemParams) => {
  return apiClient.put<IKycPutItemParams>(ApiUrls.editKycItem, data).then((response) => response);
};

const getListLevel2 = (params: any) => {
  const query = createQueryParams<IGetOrdersParams>(params);
  return apiClient.get<IKycList>(ApiUrls.listLevel2 + `?${query}`).then((response) => response);
};

const updateListItemLevel2 = (data: IKycPutItemParams) => {
  return apiClient
    .put<IKycPutItemParams>(ApiUrls.editKycItemLevel2, data)
    .then((response) => response);
};

const assignKyc = (data: IKycAssignManually) => {
  return apiClient
    .post<IKycAssignManually>(ApiUrls.assignKycManually, data)
    .then((response) => response);
};

const KYC = {
  getList,
  updateListItem,
  getListLevel2,
  updateListItemLevel2,
  assignKyc,
};

export default KYC;
