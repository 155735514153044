import { Box, Button, Checkbox, FormControlLabel, Grid, Portal } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useStandardTableStyles } from '../DefaultTable';
import { createInput, useStandardFormStyles } from '../FormBase';
import { IValues } from './types';
import { useAssignKycFormik } from './use-formik';

interface IProps {
  close: () => void;
}

const AssignKycPortal = ({ close }: IProps) => {
  const classesForm = useStandardFormStyles();
  const classesTable = useStandardTableStyles();
  const [height, setHeight] = useState(0);
  const fk = useAssignKycFormik(close);

  useEffect(() => {
    let top = window.innerHeight / 2 + window.pageYOffset;
    setHeight(top);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  const createInputField = createInput<IValues>(fk);

  return (
    <Portal>
      <div
        onClick={(e) => e.stopPropagation()}
        id="portal"
        className={classesTable.dropdown}
        style={{ top: height, width: '400px', padding: 20 }}
      >
        <div className={classesForm.formContainer}>
          <form className={classesForm.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('userId', 'User ID', 12, 'number')}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value="level1"
                  control={
                    <Checkbox
                      color="primary"
                      id="level1"
                      name="level1"
                      checked={fk.values.level1}
                      onChange={fk.handleChange}
                    />
                  }
                  label="Level 1"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <FormControlLabel
                    value="level2"
                    control={
                      <Checkbox
                        color="primary"
                        id="level2"
                        name="level2"
                        checked={fk.values.level2}
                        onChange={fk.handleChange}
                      />
                    }
                    label="Level 2"
                    labelPlacement="end"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box display={'flex'} justifyContent={'space-between'} className={classesForm.submit}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!fk.values.userId}
                startIcon={<AddCircleIcon />}
              >
                Add
              </Button>
              <Button onClick={close} variant="contained" color="primary" startIcon={<CloseIcon />}>
                Cancel
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </Portal>
  );
};

export default AssignKycPortal;
