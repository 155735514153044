import * as Yup from 'yup';

export const numberError = 'Please, specify a number';
export const minNumberError = "Can't be less than zero";

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required().max(20),
    memberName: Yup.string().required().max(20),
    color: Yup.string().required(),
    imageUrl: Yup.string().required(),
    iconUrl: Yup.string().required(),
    priority: Yup.number().typeError(numberError).min(0, minNumberError),
    addonsDiscount: Yup.number()
      .typeError(numberError)
      .min(0, minNumberError)
      .max(100, "Can't be more than 100"),
    basePlanId: Yup.string().matches(/^[0-9]/, 'Select base plan, please'),
  });
