import {
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { CustomTableHead, useStandardTableStyles } from '@root/components/DefaultTable';
import { FilterToggle } from '@root/components/FilterToggle';
import TableSkeleton from '@root/components/TableSkeleton';
import { RecurringChargesSearch } from '@root/pages/RecurringTypes/recurring-charges-search';
import { recurringChargesHeadCells } from '@root/pages/RecurringTypes/static-data';
import { IRecurringCharge } from '@root/pages/RecurringTypes/types';
import { useStyles } from '@root/pages/RecurringTypes/use-styles';
import {
  editRecurringChargeList,
  getRecurringChargeList,
} from '@root/services/api/requests/recurringTypes';
import { AxiosError } from 'axios';
import { ChangeEvent, FC, useEffect, useState } from 'react';

export const RecurringChargeTable: FC<{ selectedTab: number }> = ({ selectedTab }) => {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const [recurringChargeList, setRecurringChargeList] = useState<IRecurringCharge[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [updatedItems, setUpdatedItems] = useState<Map<number, number>>(new Map());
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchField, setSearchField] = useState<string>('');

  const fetchList = async () => {
    try {
      const response = await getRecurringChargeList(searchField && searchField);
      setRecurringChargeList(response.data.data);
    } catch (e) {
      console.error('fetch KYC List error', e);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchRecurringCharge = (
    e: ChangeEvent<HTMLInputElement>,
    item: IRecurringCharge
  ) => {
    const value = Number(e.target.value);

    if (updatedItems.has(item.id)) {
      setUpdatedItems((prev) => {
        const newMap = new Map(prev);
        newMap.delete(value);
        return newMap;
      });
    } else {
      setUpdatedItems((prev) => new Map(prev).set(value, value));
    }
  };

  const handleSubmit = async () => {
    const arrayToSend = Array.from(updatedItems.values());
    try {
      await editRecurringChargeList({ serviceIds: arrayToSend });
    } catch (e) {
      console.error((e as AxiosError).message);
    }
    setUpdatedItems(new Map());
    fetchList();
  };

  const handlesChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    if (selectedTab) {
      fetchList();
    }
  }, [selectedTab]);

  return (
    <div className={classes.wrapper}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center" spacing={3} justifyContent="flex-end">
          {updatedItems.size > 0 ? (
            <>
              <Grid item>
                <Typography>
                  Change {updatedItems.size} {updatedItems.size === 1 ? 'item' : 'items'}
                </Typography>
              </Grid>
              <Button onClick={handleSubmit} variant="contained" color="primary">
                Apply
              </Button>
            </>
          ) : (
            <>
              <FilterToggle onFilterClick={() => setIsSearchOpen(!isSearchOpen)} />
              {isSearchOpen && (
                <RecurringChargesSearch
                  searchField={searchField}
                  handlesChangeSearch={handlesChangeSearch}
                  handleSearchApply={fetchList}
                />
              )}
            </>
          )}
        </Grid>
      </Toolbar>

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <CustomTableHead
            headCells={recurringChargesHeadCells}
            order="asc"
            orderBy="id"
            classes={classes}
          />
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading &&
              recurringChargeList.map((item) => (
                <TableRow>
                  <TableCell align="left">
                    <Checkbox
                      size="medium"
                      color="primary"
                      name={item.name}
                      value={item.id}
                      checked={!updatedItems.has(item.id)}
                      onChange={(e) => handleSwitchRecurringCharge(e, item)}
                    />
                  </TableCell>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="left">{item.name}</TableCell>
                </TableRow>
              ))}
            {!loading && recurringChargeList?.length === 0 && (
              <TableRow>
                <TableCell>There are no Recurring Changes</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
